import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import {
  _delete_meeting_content,
  _list_meeting_content,
} from "src/DAL/MeetingContent/meetingContent";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import ReactVideoPlayer from "src/components/ReactVideoPlayer/ReactVideoPlayer";
import {
  get_from_localStorage,
  set_to_localStorage,
} from "src/utils/LocalStorage";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MeetingContentList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [meetingContentList, setMeetingContentList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [openPopUpActive, setOpenPopupActive] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchMode, setSearchMode] = useState(false);
  const [page, setPage] = useState(
    get_from_localStorage("meeting_content_page_number")
      ? get_from_localStorage("meeting_content_page_number")
      : 0
  );
  const [pageCount, setPageCount] = useState(
    get_from_localStorage("meeting_content_page_number")
      ? get_from_localStorage("meeting_content_page_number") + 1
      : 1
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    get_from_localStorage("meeting_content_rowsPerPage")
      ? get_from_localStorage("meeting_content_rowsPerPage")
      : 50
  );

  const getMeetingContentListing = async (_searchData, _page) => {
    setIsLoading(true);
    let loadPage = _page === 0 ? 0 : page;
    let postData = {
      search: _searchData ? _searchData : "",
    };

    const result = await _list_meeting_content(loadPage, rowsPerPage, postData);
    if (result.code === 200) {
      set_to_localStorage("meeting_content_page_number", loadPage);
      setMeetingContentList(result.meeting_contents);
      setTotalCount(result.total_meeting_contents_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await _delete_meeting_content(deleteDoc._id);
    if (result.code === 200) {
      getMeetingContentListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const showContentType = (value) => {
    setSelectedData(value);
    setOpenPopupActive(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const searchFunction = () => {
    setSearchMode(true);
    setPage(0);
    setPageCount(1);
    getMeetingContentListing(searchText, 0);
    localStorage.setItem("meeting_content_search_text", searchText);
    setTimeout(() => setSearchMode(false), 0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
    set_to_localStorage("meeting_content_page_number", 0);
    set_to_localStorage(
      "meeting_content_rowsPerPage",
      parseInt(event.target.value, 10)
    );
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "title", label: " Title", alignRight: false },
    {
      id: "content_type",
      label: "Content Type",
      alignRight: false,
      renderData: (row) => {
        return (
          <Tooltip title="View Content" arrow>
            <p
              className="cursor-pointer text-capitalize"
              onClick={() => showContentType(row)}
            >
              {row.content_type}
            </p>
          </Tooltip>
        );
      },
    },
    { id: "order", label: "Order" },
    { id: "status", label: "Status", alignRight: false, type: "row_status" },
  ];

  const handleNavigate = () => {
    navigate(`/meeting-content/add-meeting-content`);
  };

  const handleEdit = (value) => {
    navigate(`/meeting-content/edit-meeting-content/${value._id}`, {
      state: value,
    });
  };

  useEffect(() => {
    let searchText = get_from_localStorage("meeting_content_search_text")
      ? get_from_localStorage("meeting_content_search_text")
      : "";

    if (searchText) {
      setSearchText(searchText);
    }

    if (!searchMode) {
      getMeetingContentListing(searchText ? searchText : "");
    }
  }, [rowsPerPage, page]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-8 col-sm-12">
            <h2>Meeting Content</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Meeting Content
            </button>
          </div>
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={meetingContentList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>

      <WhatsappPopUpModel
        open={openPopUpActive}
        setOpen={setOpenPopupActive}
        title={"Meeting Content"}
        show_date_and_income={true}
        componentToPassDown={
          <>
            <div className="row">
              {selectedData?.content_type == "video" && (
                <div className="col-12 mt-2">
                  <ReactVideoPlayer url={selectedData.url} />
                </div>
              )}

              {selectedData?.content_type == "audio" && (
                <div className="col-12 mt-3 text-center">
                  <audio className="w-100" controls>
                    <source
                      src={s3baseUrl + selectedData.url}
                      type="audio/mp3"
                    />
                    <track
                      src="captions_en.vtt"
                      kind="captions"
                      srcLang="en"
                      label="english_captions"
                    />
                  </audio>
                </div>
              )}

              {selectedData?.content_type == "other" && (
                <p className="text-white">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedData?.metadata,
                    }}
                  ></div>
                </p>
              )}
            </div>
          </>
        }
      />
    </>
  );
}
