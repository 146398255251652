import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import { Box, Drawer } from "@mui/material";
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
import sidebarConfig from "./SidebarConfig";
import { logout } from "../../DAL/Login/Login";
import { Icon } from "@iconify/react";
import { usePGIMode } from "src/Hooks/PGIModeContext";
const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  drawerWidth,
  SIDEBAR_WIDTH,
}) {
  const { Navlist } = usePGIMode();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let nav_items = sidebarConfig(Navlist);
  let default_paths = [
    "/profile",
    "/welcome",
    "/dynamite-event-categories",
    "/dynamite-event-videos",
    "/calendar-pod",
    "/badge-level",
  ];

  const checkIfPathExists = (navItems, currentPath) => {
    for (const path of default_paths) {
      if (currentPath.includes(path)) {
        return true;
      }
    }
    for (const navItem of navItems) {
      if (navItem.path && currentPath.includes(navItem.path)) {
        return true;
      }
      if (navItem.matches && navItem.matches.includes(currentPath)) {
        return true;
      }
      if (navItem.child_options) {
        for (const childOption of navItem.child_options) {
          if (
            childOption.path &&
            (currentPath.includes(childOption.path) ||
              childOption.path.includes(currentPath))
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };
  const handlePathname = () => {
    if (pathname) {
      const pathParts = pathname.split("/");
      if (pathParts.length > 2) {
        return pathParts.slice(0, 2).join("/");
      } else {
        return pathname;
      }
    }
  };
  useEffect(() => {
    const isPathInNavItems = checkIfPathExists(nav_items, handlePathname());
    if (nav_items.length > 4 && !isPathInNavItems) {
      navigate("/welcome");
    }
  }, [pathname, nav_items]);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box
          component={RouterLink}
          to="/"
          sx={{
            display: "inline-flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Logo />
        </Box>
      </Box>
      <NavSection navConfig={nav_items} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
      className="app-sidebar-box"
    >
      <Drawer
        variant="temporary"
        open={isOpenSidebar}
        onClose={onCloseSidebar}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
          bgcolor: "background.default",
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "var(--sidebars-background-color)",
          },
        }}
      >
        {renderContent}
      </Drawer>
      <Drawer
        className="sidebar-permanent-drawer"
        variant="permanent"
        ModalProps={{
          bgcolor: "background.default",
        }}
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
            backgroundColor: "var(--sidebars-background-color)",
            marginLeft: `${drawerWidth === 0 ? -SIDEBAR_WIDTH : "0"}px`,
          },
        }}
        open={Boolean(drawerWidth)}
      >
        {renderContent}
      </Drawer>
    </Box>
  );
}
