import { useEffect, useState } from "react";
import { Card } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import CustomMUITable from "../GeneralComponents/CustomMUITable/CustomMUITable";
import Label from "../Label";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function RecentMembersTable({ recentMember, title, type }) {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getConsultantListing = async () => {
    setIsLoading(true);
    const data = recentMember.map((member) => {
      let full_name = "N/A";
      let goal_statement_date = "N/A";

      if (member.first_name) {
        full_name = member.first_name + " " + member.last_name;
      }

      if (member.goal_statement_completed_date) {
        if (type == "recent_member") {
          goal_statement_date = moment(member.createdAt).format("DD-MM-YYYY");
        } else {
          goal_statement_date = moment(
            member.goal_statement_completed_date
          ).format("DD-MM-YYYY");
        }
      }

      return {
        ...member,
        full_name,
        goal_statement_date,
      };
    });
    setUserList(data);
    setIsLoading(false);
  };

  const TABLE_HEAD = [
    { id: "full_name", label: "Name" },
    { id: "email", label: "Email" },
    {
      id: "goal_statement_date",
      label: type == "recent_member" ? "Created At " : "Completed Date",
    },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        return (
          <Label
            variant="ghost"
            color={row.status === false ? "error" : "success"}
          >
            {title
              ? row.status === false
                ? "Incomplete"
                : "Completed"
              : row.status === false
              ? "InActive"
              : "Active"}
          </Label>
        );
      },
    },
  ];

  const TABLE_HEAD2 = [
    { id: "full_name", label: "Plan Name" },
    {
      id: "status",
      label: "Plan Status",
      renderData: (row) => {
        return (
          <Label
            variant="ghost"
            color={row.status === false ? "error" : "success"}
          >
            {title
              ? row.status === false
                ? "Incomplete"
                : "Completed"
              : row.status === false
              ? "InActive"
              : "Active"}
          </Label>
        );
      },
    },
  ];

  useEffect(() => {
    getConsultantListing();
  }, [recentMember]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <Card style={{ overflowX: "auto" }}>
        <h3 className="ms-3 mt-3" style={{ color: "#e4c073" }}>
          {title ? title : "Recent Members"}
        </h3>
        {type === "payment_plan" ? (
          <>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD2}
              data={userList}
              className="card-with-background"
              is_hide={true}
            />
          </>
        ) : (
          <>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={userList}
              className="card-with-background"
              is_hide={true}
            />
          </>
        )}
      </Card>
    </>
  );
}
