import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { Card } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  add_fire_setting,
  siteSettingApi,
} from "src/DAL/SiteSetting/siteSetting";
import { useEffect } from "react";
import { project_mode } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const fire_live_credentials = {
  fire_refresh_token: "",
  fire_client_id: "",
  fire_account_no: "",
  fire_client_key: "",
  fire_euro_account_no: "",
  fire_payment_request_url: "",
  fire_redirect_url: "",
};
const fire_sandBox_credentials = {
  fire_refresh_token: "",
  fire_client_id: "",
  fire_account_no: "",
  fire_client_key: "",
  fire_euro_account_no: "",
  fire_payment_request_url: "",
  fire_redirect_url: "",
};

export default function AddFireSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = useState({
    // enable_fire_payment: false,
    dynamite_fire_payment_fee_percentage: "",
    dynamite_fee_payment_payee_id: "",
  });
  const [inputsFireSandBoxCredentials, setInputsFireSandBoxCredentials] =
    useState(fire_sandBox_credentials);
  const [inputsFireLive, setInputsFireLive] = useState(fire_live_credentials);

  const siteSettingData = async () => {
    setIsLoading(true);
    const result = await siteSettingApi();
    if (result.code == 200) {
      console.log(result, "resultresult");
      setInputsFireLive(result?.site_setting.fire_live_credentials);
      setInputsFireSandBoxCredentials(
        result?.site_setting.fire_sandBox_credentials
      );
      setInputs(result?.site_setting);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    let postData = {
      // enable_fire_payment: inputs.enable_fire_payment,
      dynamite_fee_payment_payee_id: inputs.dynamite_fee_payment_payee_id,
      dynamite_fire_payment_fee_percentage:
        inputs.dynamite_fire_payment_fee_percentage,
      fire_sandBox_credentials: inputsFireSandBoxCredentials,
      fire_live_credentials: inputsFireLive,
    };

    const result = await add_fire_setting(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangeDev = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputsFireSandBoxCredentials((values) => ({ ...values, [name]: value }));
  };
  const handleChangeLive = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputsFireLive((values) => ({ ...values, [name]: value }));
  };
  const handleChangefloat = (event) => {
    const inputValue = event.target.value;

    const isValidInput = /^\d*\.?\d*$/.test(inputValue);

    if (isValidInput || inputValue === "") {
      setInputs({
        ...inputs,
        dynamite_fire_payment_fee_percentage: inputValue,
      });
    }
  };

  useEffect(() => {
    siteSettingData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-6">
          <h2>Fire Settings</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Enable Fire Payment
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="enable_fire_payment"
                value={inputs.enable_fire_payment}
                label="Enable Fire Payment"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div> */}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Dynamite Fire Payment Fee Percentage"
              variant="outlined"
              className="default_consultant_commission_inputs"
              fullWidth
              aria-readonly
              type="text"
              InputProps={{
                endAdornment: <span>%</span>,
                inputProps: { min: 0, max: 100 },
              }}
              name="dynamite_fire_payment_fee_percentage"
              value={inputs.dynamite_fire_payment_fee_percentage}
              onChange={handleChangefloat}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Payment Fee Payee Id"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="dynamite_fee_payment_payee_id"
              value={inputs?.dynamite_fee_payment_payee_id}
              onChange={handleChange}
            />
          </div>
          <h3 className="mt-4" style={{ color: "#e4c073" }}>
            Fire Settings for SandBox
          </h3>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Fire Client Key"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="fire_client_key"
              value={inputsFireSandBoxCredentials?.fire_client_key}
              onChange={handleChangeDev}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Fire Refresh Token"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="fire_refresh_token"
              value={inputsFireSandBoxCredentials.fire_refresh_token}
              onChange={handleChangeDev}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Fire Client Id"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="fire_client_id"
              value={inputsFireSandBoxCredentials.fire_client_id}
              onChange={handleChangeDev}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Sterling Account No(GBP)"
              variant="outlined"
              fullWidth
              required
              name="fire_account_no"
              value={inputsFireSandBoxCredentials.fire_account_no}
              onChange={handleChangeDev}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Euro Account No (EUR)"
              variant="outlined"
              fullWidth
              required
              name="fire_euro_account_no"
              value={inputsFireSandBoxCredentials.fire_euro_account_no}
              onChange={handleChangeDev}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Fire Payment Request Url"
              variant="outlined"
              fullWidth
              type="url"
              required
              aria-readonly
              name="fire_payment_request_url"
              value={inputsFireSandBoxCredentials?.fire_payment_request_url}
              onChange={handleChangeDev}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Fire Redirect Url"
              variant="outlined"
              fullWidth
              required
              type="url"
              aria-readonly
              name="fire_redirect_url"
              value={inputsFireSandBoxCredentials.fire_redirect_url}
              onChange={handleChangeDev}
            />
          </div>
          <h3 className="mt-4" style={{ color: "#e4c073" }}>
            Fire Settings for Live
          </h3>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Fire Client Key"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="fire_client_key"
              value={inputsFireLive?.fire_client_key}
              onChange={handleChangeLive}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Fire Refresh Token"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="fire_refresh_token"
              value={inputsFireLive.fire_refresh_token}
              onChange={handleChangeLive}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Fire Client Id"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="fire_client_id"
              value={inputsFireLive.fire_client_id}
              onChange={handleChangeLive}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Sterling Account No(GBP)"
              variant="outlined"
              fullWidth
              required
              name="fire_account_no"
              value={inputsFireLive.fire_account_no}
              onChange={handleChangeLive}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Euro Account No (EUR)"
              variant="outlined"
              fullWidth
              required
              name="fire_euro_account_no"
              value={inputsFireLive.fire_euro_account_no}
              onChange={handleChangeLive}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Fire Payment Request Url"
              variant="outlined"
              fullWidth
              type="url"
              required
              aria-readonly
              name="fire_payment_request_url"
              value={inputsFireLive?.fire_payment_request_url}
              onChange={handleChangeLive}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Fire Redirect Url"
              variant="outlined"
              fullWidth
              required
              type="url"
              aria-readonly
              name="fire_redirect_url"
              value={inputsFireLive.fire_redirect_url}
              onChange={handleChangeLive}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
