import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  _detail_page_pixel_events,
  _update_page_pixel_events,
} from "src/DAL/WebsitePages/ManagePixelEvents";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const EMPTY_OBJECT = {
  trigger_event: "page_load",
  type: "standard",
  name: "",
  value: "",
  currency: "",
  url_type: "equals",
  button: "",
  url_value: "",
  amount: "",
};

export default function ManagePixelEvents({ type = "" }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSourceList, setDataSourceList] = useState([]);
  const [standardEventsList, setStandardEventsList] = useState([]);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [inputList, setInputList] = useState([{ ...EMPTY_OBJECT }]);
  const [pageBittonList, setPageBittonList] = useState([]);
  const [formInputs, setFormInputs] = useState({
    data_source_id: "",
  });

  const scrollTohowItWorks = () =>
    window.scroll({
      top: 99999,
      behavior: "smooth",
    });

  const handleCheckIsShowInputFields = (name) => {
    if (
      name == "AddToCart" ||
      name == "InitiateCheckout" ||
      name == "Lead" ||
      name == "Purchase" ||
      name == "AddToWishlist" ||
      name == "Search" ||
      name == "ViewContent"
    ) {
      return true;
    }
    return false;
  };

  const handleAddClick = (index) => {
    setInputList([
      ...inputList.slice(0, index),
      { ...EMPTY_OBJECT },
      ...inputList.slice(index),
    ]);
    scrollTohowItWorks();
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleChange = (value, name, index) => {
    const list = [...inputList];
    list[index][name] = value;
    if (name == "type") {
      list[index]["name"] = "";
    }

    if (name == "value") {
      if (value == "not_include_value") {
        list[index]["currency"] = "";
        list[index]["amount"] = "";
      } else {
        list[index]["currency"] = "gbp";
      }
    }
    if (name == "name") {
      console.log("list[index]    __handleChange", list[index]["type"]);
      if (
        list[index]["type"] == "custom" ||
        handleCheckIsShowInputFields(value) == false
      ) {
        list[index]["value"] = "";
        list[index]["currency"] = "";
        list[index]["amount"] = "";
      } else {
        list[index]["value"] = "include_value";
        list[index]["currency"] = "gbp";
      }
    }

    setInputList(list);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormInputs((pre) => ({ ...pre, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    let postData = {
      type: type,
      pixel_events: {
        data_source_id: formInputs?.data_source_id,
        events: inputList,
      },
    };

    if (type != "plan") {
      delete postData.type;
    }

    console.log("postData  __postData", postData);
    const result = await _update_page_pixel_events(postData, params?.page_slug);

    if (result.code == 200) {
      navigate(-1);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const getPageDetailPixelEvents = async () => {
    setIsLoading(true);
    const result = await _detail_page_pixel_events(
      params.page_slug,
      type == "plan" ? "plan" : ""
    );
    console.log("result   ____detail_page_pixel_events", result);
    if (result.code == 200) {
      let data = type == "plan" ? result?.plan : result?.sale_page;
      setDataSourceList(result?.pixels);
      setPageBittonList(result?.page_buttons);
      setStandardEventsList(result?.standard_events);
      if (data?.pixel_events) {
        setInputList(
          data?.pixel_events?.events.map((event) => ({
            ...event,
            trigger_event: event.trigger_event ?? "page_load",
          }))
        );
        setFormInputs({
          data_source_id: data?.pixel_events?.data_source_id,
        });
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPageDetailPixelEvents();
  }, []);
  useEffect(() => {
    console.log("inputList   ___inputList", inputList);
  }, [inputList]);

  console.log(pageBittonList, "pageBittonListpageBittonList");

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-1">Manage Pixel Events</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
            <TextField
              fullWidth
              label="Data Source"
              select
              name="data_source_id"
              value={formInputs?.data_source_id}
              onChange={handleInputChange}
              required
            >
              {dataSourceList.map((dataSource, index) => {
                return (
                  <MenuItem key={index} value={dataSource?.id}>
                    {dataSource?.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>

          <div className="px-4">
            {inputList.map((inputs, index) => {
              const selectedButtons = inputList
                .map((input) => input.button)
                .filter((button) => button);

              const availableButtons = pageBittonList.filter(
                (button) =>
                  !selectedButtons.includes(button.button_action) ||
                  button.button_action === inputs.button
              );
              return (
                <div
                  key={index}
                  className="manage-event-pixel-bg row pb-3 mb-4"
                >
                  <div className="col-11 col-lg-11">
                    <div className="row">
                      <div className="col-6 col-lg-6 mt-3">
                        <TextField
                          fullWidth
                          label="Trigger Event"
                          select
                          name="trigger_event"
                          value={inputs?.trigger_event}
                          disabled={type == "plan"}
                          onChange={(e) =>
                            handleChange(e.target.value, "trigger_event", index)
                          }
                          required
                        >
                          <MenuItem value="page_load">
                            {type == "plan" ? "On Subscribe" : "Page Load"}
                          </MenuItem>
                          {availableButtons.length > 0 && (
                            <MenuItem value="button">Button Click</MenuItem>
                          )}
                        </TextField>
                      </div>

                      {inputs?.trigger_event == "button" &&
                        availableButtons.length > 0 && (
                          <div className="col-6 col-lg-6 mt-3">
                            <TextField
                              fullWidth
                              label="Select Button"
                              select
                              name="button"
                              value={inputs?.button}
                              onChange={(e) =>
                                handleChange(e.target.value, "button", index)
                              }
                              required
                            >
                              {availableButtons.map((button, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={button?.button_action}
                                  >
                                    {button?.button_label}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          </div>
                        )}

                      <div className="col-6 col-lg-6 mt-3">
                        <TextField
                          fullWidth
                          label="Event Type"
                          select
                          name="type"
                          value={inputs?.type}
                          onChange={(e) =>
                            handleChange(e.target.value, "type", index)
                          }
                          required
                        >
                          <MenuItem value="standard">Standard Event</MenuItem>
                          <MenuItem value="custom">Custom Event</MenuItem>
                        </TextField>
                      </div>

                      {inputs.type == "custom" ? (
                        <div className="col-6 col-lg-6 mt-3">
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            label="Custom Event Name"
                            variant="outlined"
                            name="name"
                            value={inputs?.name}
                            onChange={(e) =>
                              handleChange(e.target.value, "name", index)
                            }
                            required
                          />
                        </div>
                      ) : (
                        <div className="col-6 col-lg-6 mt-3">
                          <Autocomplete
                            fullWidth
                            options={standardEventsList.filter(
                              (standardEvent) =>
                                !inputList.some(
                                  (input, inputIndex) =>
                                    inputIndex !== index &&
                                    input.type === "standard" &&
                                    input.name === standardEvent.eventName
                                )
                            )}
                            getOptionLabel={(option) => option.eventName || ""}
                            value={
                              standardEventsList.find(
                                (event) => event.eventName === inputs.name
                              ) || null
                            }
                            onChange={(event, newValue) =>
                              handleChange(
                                newValue ? newValue.eventName : "",
                                "name",
                                index
                              )
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.eventName === value.eventName
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Event List"
                                required
                              />
                            )}
                            renderOption={(props, option) => (
                              <li {...props}>
                                <Tooltip
                                  title={
                                    option?.description
                                      ? option?.description
                                      : ""
                                  }
                                >
                                  <span>{option.eventName}</span>
                                </Tooltip>
                              </li>
                            )}
                            noOptionsText="No Option Available"
                          />
                        </div>
                      )}

                      <div className="col-6 col-lg-6 mt-3">
                        <TextField
                          fullWidth
                          label="URL Type"
                          select
                          name="url_type"
                          value={inputs?.url_type}
                          onChange={(e) =>
                            handleChange(e.target.value, "url_type", index)
                          }
                          required
                        >
                          <MenuItem value="equals">URL Equals</MenuItem>
                          <MenuItem value="contains">URL Contains</MenuItem>
                        </TextField>
                      </div>
                      <div className="col-6 col-lg-6 mt-3">
                        <TextField
                          id="outlined-basic"
                          fullWidth
                          label="URL Value"
                          variant="outlined"
                          name="url_value"
                          value={inputs?.url_value}
                          onChange={(e) =>
                            handleChange(e.target.value, "url_value", index)
                          }
                          required
                        />
                      </div>

                      {inputs?.type == "standard" && (
                        <>
                          {handleCheckIsShowInputFields(inputs?.name) ==
                            true && (
                            <div className="col-6 col-lg-6 mt-3">
                              <TextField
                                fullWidth
                                label="Value"
                                select
                                name="value"
                                value={inputs?.value}
                                onChange={(e) =>
                                  handleChange(e.target.value, "value", index)
                                }
                                required
                              >
                                {inputs?.name == "Purchase" && (
                                  <MenuItem value="initiate_checkout">
                                    Use value from initiate checkout
                                  </MenuItem>
                                )}

                                <MenuItem value="include_value">
                                  Include value on page
                                </MenuItem>
                                <MenuItem value="not_include_value">
                                  Don't include value
                                </MenuItem>
                              </TextField>
                            </div>
                          )}

                          {handleCheckIsShowInputFields(inputs?.name) == true &&
                            inputs?.value != "not_include_value" && (
                              <div className="col-6 col-lg-6 mt-3">
                                <FormControl fullWidth required>
                                  <InputLabel id="demo-simple-select-label">
                                    Currency
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Currency"
                                    name="currency"
                                    value={inputs?.currency}
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        "currency",
                                        index
                                      )
                                    }
                                    required
                                  >
                                    <MenuItem value="usd">Dollar</MenuItem>
                                    <MenuItem value="gbp">UK Pounds</MenuItem>
                                    <MenuItem value="eur">Euro</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            )}

                          {handleCheckIsShowInputFields(inputs?.name) == true &&
                            inputs?.value != "not_include_value" && (
                              <div className="col-6 col-lg-6 mt-3">
                                <TextField
                                  id="outlined-basic"
                                  label="Amount"
                                  variant="outlined"
                                  required
                                  type="number"
                                  fullWidth
                                  name="amount"
                                  value={inputs?.amount}
                                  onChange={(e) =>
                                    handleChange(
                                      e.target.value,
                                      "amount",
                                      index
                                    )
                                  }
                                />
                              </div>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-1 col-lg-1 mt-3">
                    <IconButton
                      className="question-survey-update"
                      onClick={() => handleAddClick(index + 1)}
                      sx={{ width: 30, height: 30 }}
                      color="warning"
                    >
                      <AddCircleOutlineIcon sx={{ width: 30, height: 30 }} />
                    </IconButton>

                    {inputList.length !== 1 && (
                      <RemoveCircleOutlineIcon
                        className="question-survey-cross-update"
                        onClick={() => handleRemoveClick(index)}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="text-end" id="fixedbutton">
            <button className="small-contained-button" disabled={isLoadingForm}>
              {isLoadingForm ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
