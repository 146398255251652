import { Chip, Tooltip } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import { convertCurrencyToSign, dd_date_format } from "src/utils/constant";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import OrderHistorysDetail from "./OrderHistorysDetail";

function OrderHistoryProfile({ tabData }) {
  const [totalSales, setTotalSales] = useState([]);
  const [orderObject, setorderObject] = useState({});
  const [Open, setOpen] = useState(false);

  const get_sales_listings = async () => {
    setTotalSales(tabData);
  };
  const handle_privacy_click = (path) => {
    window.open(`${path}`, "_blank");
  };

  const handledetail = (obj) => {
    setOpen(true);
    setorderObject(obj);
  };

  let TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "mintsoft_order_id",
      label: "Order Id",
      className: "text-capitalize",
      renderData: (row) => {
        return (
          <span style={{ cursor: "pointer" }} onClick={() => handledetail(row)}>
            {row.mintsoft_order_id ? row.mintsoft_order_id : "N/A"}
          </span>
        );
      },
    },
    {
      id: "discount",
      label: "Discount",
      className: "text-capitalize",
      renderData: (row) => {
        let discount = 0;
        if (row.discount) {
          discount = row.discount;
        }
        return <span>{`£${discount.toFixed(2)}`}</span>;
      },
    },
    {
      id: "total",
      label: "Total Amount",
      className: "text-capitalize",
      renderData: (row) => {
        return (
          <>
            {row.other_info?.converted_currency ? (
              <span>
                {convertCurrencyToSign(
                  row.other_info.converted_currency.toLowerCase()
                )}
                {Number(row.other_info.converted_total).toFixed(2)}
              </span>
            ) : (
              <span>{"£" + row.total.toFixed(2)}</span>
            )}
          </>
        );
      },
    },
    {
      id: "createdAt",
      label: "Date",
      className: "text-capitalize",
      renderData: (row) => {
        return <span>{dd_date_format(row.createdAt)}</span>;
      },
    },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        let chipColor;
        switch (row.order_status) {
          case "pending":
            chipColor = "#ff9800";
            break;
          case "processing":
            chipColor = "#03a9f4";
            break;
          case "dispatched":
            chipColor = "#0288d1";
            break;
          case "completed":
            chipColor = "#4caf50";
            break;
          case "shipped":
            chipColor = "#cccccc";
            break;
          case "cancelled":
            chipColor = "rgb(211, 47, 47)";
            break;
          default:
            chipColor = "#ff9800";
            break;
        }
        return (
          <div className="d-flex">
            <Chip
              label={
                row.order_status == "initiated" ? "Pending" : row.order_status
              }
              style={{
                backgroundColor: chipColor,
                color: "white",
                textTransform: "capitalize",
              }}
            ></Chip>
          </div>
        );
      },
    },
    {
      id: "Tracking Id",
      label: "Track Package",
      renderData: (row) => {
        return (
          <>
            {row.TrackingURL ? (
              <Tooltip title="Click here to track your order">
                <span
                  onClick={() => handle_privacy_click(row.TrackingURL)}
                  style={{
                    cursor: "pointer",
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#e4c073",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  Click To Track
                </span>
              </Tooltip>
            ) : (
              <Tooltip
                title={`${
                  row.order_status == "cancelled"
                    ? `Your order has been ${row.order_status}`
                    : `Please wait, your order is currently in  ${row.order_status}`
                }`}
              >
                <span
                  style={{
                    cursor: "pointer",
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#6c757d",
                    fontSize: "14px",
                    cursor: "not-allowed",
                  }}
                >
                  Click To Track
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];
  useEffect(() => {
    get_sales_listings();
  }, [tabData]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="mb-3 mt-4">
            <h2>Order History</h2>
          </div>
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={totalSales}
            className="card-with-background"
            hide_search={true}
          />
        </div>
      </div>

      <WhatsappPopUpModel
        open={Open}
        setOpen={setOpen}
        title={"Order Detail"}
        show_date_and_income={true}
        componentToPassDown={<OrderHistorysDetail orderObject={orderObject} />}
      />
    </>
  );
}

export default OrderHistoryProfile;
