import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { _update_question_selection_v2 } from "src/DAL/MissionApi/MissionApiNew";
import { show_proper_words } from "src/utils/constant";
import DoneIcon from "@mui/icons-material/Done";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import RecordNotFound from "src/components/RecordNotFound";
import { Icon } from "@iconify/react";

export default function ManageQuestionComponent({
  dataList,
  selected,
  setSelected,
  type,
  setAfterActionQuestionsList,
  setOnScreenQuestions,
  setIsTabLoading,
}) {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [dataItems, setDataItems] = useState(dataList ?? []);
  const [searchInput, setSearchInput] = useState("");
  const [isCheckedAll, setIsCheckedAll] = useState(false);

  const handleCheckAll = (value) => {
    let updated_array = [];
    if (value) {
      updated_array = dataItems.map((item) => {
        let data_object = { _id: item._id };

        return data_object;
      });
    }
    setSelected(updated_array);
  };

  const checkAllItemsExist = (data1, data2) => {
    let is_selected_all = true;
    data2.forEach((item) => {
      let find_p = data1.find((p) => p._id == item._id);
      if (!find_p) {
        is_selected_all = false;
      }
    });
    return is_selected_all;
  };

  const handleSelect = (value) => {
    let new_value = { _id: value._id };

    let find = selected.find((item) => item._id == value._id);
    if (find) {
      setSelected((old) => old.filter((item) => item._id !== value._id));
    } else {
      setSelected([...selected, new_value]);
    }
  };

  const handleSubmit = async () => {
    setIsLoadingForm(true);
    setIsTabLoading(true);

    let selected_questions = [];

    dataItems
      .filter((all_question) =>
        selected.some(
          (selected_question) => selected_question._id === all_question._id
        )
      )
      .map((item, index) => {
        let selected_object = {
          _id: item._id,
          show_question: type,
          order: index + 1,
        };
        selected_questions.push(selected_object);
      });

    let postData = {
      selected_questions: selected_questions,
      mission_schedule_id: params.id,
      type: type,
    };

    const result = await _update_question_selection_v2(postData);
    if (result.code == 200) {
      const selected_after_action_questions =
        result?.selected_after_action_questions.map((item) => {
          return {
            ...item,
            show_question:
              result.selected_questions.find(
                (selectedItem) => selectedItem._id === item._id
              )?.show_question ?? "after_action",
          };
        });

      const selected_on_screen_questions =
        result?.selected_on_screen_questions.map((item) => {
          return {
            ...item,
            show_question:
              result.selected_questions.find(
                (selectedItem) => selectedItem._id === item._id
              )?.show_question ?? "on_screen",
          };
        });

      setAfterActionQuestionsList(selected_after_action_questions);
      setOnScreenQuestions(selected_on_screen_questions);
      setIsTabLoading(false);
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsTabLoading(false);
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newItems = Array.from(dataItems);

    if (result.type === "PARENT") {
      const [reorderedItem] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, reorderedItem);
    }

    newItems.forEach((item, index) => {
      item.order = index + 1;
    });

    setDataItems(newItems);
  };
  const getItemsList = (array_, query) => {
    if (query) {
      const _nav_list = array_
        .map((data) => {
          const is_main =
            data?.question_statement
              .toLowerCase()
              .indexOf(query.toLowerCase()) !== -1;
          if (is_main) {
            return data;
          }
          return null;
        })
        .filter(Boolean);
      return _nav_list;
    }
    return array_;
  };

  useEffect(() => {
    setIsCheckedAll(checkAllItemsExist(selected, dataItems));
  }, [selected, dataItems]);

  return (
    <div className="">
      <div className="row mobile-margin display-flex mt-2 mb-2">
        <div className="col-12 col-md-9 mt-3">
          {getItemsList(dataItems, searchInput).length > 0 ? (
            <div
              className="select-all-menus"
              onClick={() => handleCheckAll(!isCheckedAll)}
            >
              <div
                className={`select-menu-checkbox ps-3 mt-1 ${
                  isCheckedAll ? "selected" : ""
                }`}
              >
                {isCheckedAll && (
                  <span className="select-menu-tick">
                    <DoneIcon />
                  </span>
                )}
              </div>
              <div className="ms-2">Select All</div>
            </div>
          ) : null}
        </div>
        <div className="col-md-3">
          <div className="menus-search-box">
            <Icon
              fontSize={20}
              className="menus-search-icon"
              icon="akar-icons:search"
            />
            <input
              className="menus-search-input"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="parents" type="PARENT">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {getItemsList(dataItems, searchInput).length > 0 ? (
                getItemsList(dataItems, searchInput).map((item, index) => {
                  let is_checked = selected.find(
                    (option) => option._id == item._id
                  );
                  return (
                    <Draggable
                      key={index}
                      draggableId={`parent-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <>
                            <div className="card mt-2">
                              <div className="main row position-relative pb-3 pb-md-0">
                                <div className="col-1 mt-4">
                                  <div
                                    className={`select-menu-checkbox m-auto ${
                                      is_checked ? "selected" : ""
                                    }`}
                                    onClick={() => {
                                      handleSelect(item);
                                    }}
                                  >
                                    {is_checked && (
                                      <span className="select-menu-tick">
                                        <DoneIcon />
                                      </span>
                                    )}
                                  </div>
                                </div>

                                <div className={`col-10 mt-3 ms-2`}>
                                  <div className="row">
                                    <div className="col-12 col-md-8">
                                      {" "}
                                      <label className="question-statement-label">
                                        Question Statement
                                      </label>
                                      <div
                                        className="question-statement-data"
                                        dangerouslySetInnerHTML={{
                                          __html: item?.question_statement,
                                        }}
                                      ></div>
                                    </div>
                                    <div className="col-12 col-md-2">
                                      {" "}
                                      <label className="question-type-label">
                                        Question Type
                                      </label>
                                      <div className="question-type-data">
                                        {show_proper_words(item?.question_type)}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="menu-box-option col-1"
                                  {...provided.dragHandleProps}
                                >
                                  <div className="menu_parent">
                                    <DragIndicatorIcon />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                      )}
                    </Draggable>
                  );
                })
              ) : (
                <RecordNotFound title="Data" />
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="button_bottom_fix">
        {getItemsList(dataItems, searchInput).length > 0 ? (
          <button className="small-contained-button" onClick={handleSubmit}>
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        ) : null}
      </div>
    </div>
  );
}
