import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Button,
  CircularProgress,
  FormHelperText,
  TextField,
} from "@mui/material";
import {
  get_landingscreen_settings,
  update_landingscreen_settings_api,
} from "src/DAL/SiteSetting/siteSetting";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AppLandingScreen() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = useState({
    button_text: "",
    heading_text: "",
    have_an_account_text: "",
    background_image: "",
  });

  const getDefaultSetting = async () => {
    const result = await get_landingscreen_settings();
    if (result.code === 200) {
      if (result.app_landingscreen_settings) {
        setInputs({
          ...inputs,
          ...result.app_landingscreen_settings,
        });
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const fileChangedHandler = async (e, i) => {
    const { name } = e.target;
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "500");
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        [name]: imageUpload.image_path,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let postData = {
      app_landingscreen_settings: {
        ...inputs,
      },
    };
    const result = await update_landingscreen_settings_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleDeleteImage = (path) => {
    setInputs((prev) => ({
      ...prev,
      [path]: "",
    }));
  };

  useEffect(() => {
    getDefaultSetting();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6">
          <h2>App Landing Setting</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Heading Text"
            variant="outlined"
            fullWidth
            name="heading_text"
            value={inputs.heading_text}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Button Text"
            variant="outlined"
            fullWidth
            name="button_text"
            value={inputs.button_text}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Have An Account Text"
            variant="outlined"
            fullWidth
            name="have_an_account_text"
            value={inputs.have_an_account_text}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0 mb-2">
            <div className="col-5">
              <p className="">Background Image</p>
              <FormHelperText className="pt-0">
                Recommended Size (500 X 500) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {inputs?.background_image && (
                <div className="del_img_icon">
                  <span
                    className="pointer_cursor"
                    onClick={() => {
                      handleDeleteImage("background_image");
                    }}
                  >
                    X
                  </span>
                  <img
                    src={
                      inputs?.background_image
                        ? s3baseUrl + inputs?.background_image
                        : ""
                    }
                    className="imgage_border"
                    height="50"
                  />
                </div>
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file2">
                <Input
                  accept="image/*"
                  id="contained-button-file2"
                  multiple
                  type="file"
                  name="background_image"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>{" "}
        <div className="text-end mt-4">
          <button
            onClick={handleSubmit}
            className="small-contained-button"
            id="fixedbutton"
          >
            {isLoadingForm ? "Updating" : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
}
