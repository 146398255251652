import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import {
  get_paypal_setting_api,
  update_paypal_setting_api,
} from "src/DAL/SiteSetting/PaypalSetting";
import { project_mode } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function PaypalSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = useState({
    sandbox_client_id: "",
    sandbox_client_secret: "",
    client_id: "",
    client_secret: "",
    payment_mode: project_mode === "dev" ? "sandBox" : "live",
  });

  const get_paypal_setting = async () => {
    const result = await get_paypal_setting_api();
    if (result.code == 200) {
      if (result?.paypal_credentials) {
        setInputs(result.paypal_credentials);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let postData = { ...inputs };
    const result = await update_paypal_setting_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    get_paypal_setting();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-6">
          <h2>Paypal Settings</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Sandbox Client ID"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="sandbox_client_id"
              value={inputs.sandbox_client_id}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Sandbox Client Secret"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="sandbox_client_secret"
              value={inputs.sandbox_client_secret}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Live Client ID"
              variant="outlined"
              fullWidth
              required
              name="client_id"
              value={inputs.client_id}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Live Client Secret"
              variant="outlined"
              fullWidth
              required
              name="client_secret"
              value={inputs.client_secret}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Payment Mode *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="payment_mode"
                value={inputs.payment_mode}
                label="Payment Mode *"
                onChange={handleChange}
              >
                <MenuItem value="sandBox">SandBox</MenuItem>
                <MenuItem value="live">Live</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
