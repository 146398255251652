import { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import Chart from "react-apexcharts";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import AnalyticsFilter from "./AnalyticsFilter";
import FilteredChip from "src/components/FilteredChip";
import { get_browser_base_report } from "src/DAL/Analytics/Analytics";
import moment from "moment";
import { dd_date_format } from "src/utils/constant";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 44,
  color: "#fff",
  border: "1px solid #e4c073",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    boxShadow: theme.customShadows.z8,
    border: "1px solid #e4c073",
    width: 280,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const EMPTY_FILTER = {
  start_date: new Date(new Date().setDate(new Date().getDate() - 6)),
  end_date: new Date(),
};

const TechDetail = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [dataList, setDataList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [openFilter, setOpenfilter] = useState(false);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "line",
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: "#ffffff",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#ffffff",
          },
        },
      },
      tooltip: {
        theme: "dark",
      },
      grid: {
        borderColor: "#ffffff",
      },
    },
  });

  const formatDate = (dateStr) => {
    const date = new Date(
      `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(6, 8)}`
    );
    return format(date, "MMM dd");
  };

  const getCategoriesList = async (filterData) => {
    let postData = { ...filterData };
    if (postData.start_date) {
      postData.start_date = moment(postData.start_date).format("YYYY-MM-DD");
    }
    if (postData.end_date) {
      postData.end_date = moment(postData.end_date).format("YYYY-MM-DD");
    }
    if (searchText) {
      postData.search = searchText;
    }
    setIsLoading(true);
    const result = await get_browser_base_report(postData);
    if (result.code == 200) {
      let chipData = { ...filterData };
      if (chipData.start_date) {
        let date = `Start Date: ${dd_date_format(
          chipData.start_date
        )} End Date:  ${dd_date_format(chipData.end_date)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chipData.start_date;
        delete chipData.end_date;
      }
      delete chipData.metrics;
      delete chipData.dimensions;
      const graphical_data = result.graphical_report_states;
      const uniqueBrowsers = [
        ...new Set(graphical_data.map((item) => item.browser)),
      ];

      const formetDates = [
        ...new Set(graphical_data.map((item) => formatDate(item.date))),
      ];
      const dates = [...new Set(graphical_data.map((item) => item.date))];

      const seriesData = uniqueBrowsers.map((browser) => {
        const data = dates.map((date) => {
          const browserData = graphical_data.find(
            (item) => item.date === date && item.browser === browser
          );
          return browserData ? parseInt(browserData.activeUsers) : 0;
        });

        return {
          name: browser,
          data,
          color:
            browser === "Chrome"
              ? "#4285F4"
              : browser === "Edge"
              ? "#0078D7"
              : browser === "Firefox"
              ? "#FF7139"
              : "#00ACED",
        };
      });
      setChartData({
        series: seriesData,
        options: {
          chart: {
            type: "line",
          },
          xaxis: {
            categories: formetDates,
            labels: {
              style: {
                colors: "#ffffff",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: "#ffffff",
              },
            },
          },
          tooltip: {
            theme: "dark",
          },
          grid: {
            borderColor: "#ffffff",
          },
        },
      });
      setDataList(result?.report);
      setUpdateFilterData(chipData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClearFilter = () => {
    getCategoriesList(EMPTY_FILTER);
    setUpdateFilterData(EMPTY_FILTER);
    setFilterData(EMPTY_FILTER);
    localStorage.removeItem("analytics_filter_country");
    setOpenfilter(false);
  };

  const searchFunction = () => {
    getCategoriesList(filterData);
    setOpenfilter(false);
    localStorage.setItem(
      "analytics_filter_country",
      JSON.stringify(filterData)
    );
  };

  const handleDeleteChip = (data) => {
    if (!data.date) {
      data.start_date = new Date(new Date().setDate(new Date().getDate() - 6));
      data.end_date = new Date();
    }
    getCategoriesList(data);
    localStorage.setItem("analytics_filter_country", JSON.stringify(data));
    setFilterData(data);
  };

  const handleOpenDawer = () => {
    setOpenfilter(true);
  };

  const handleSearchText = (event) => {
    setSearchText(event.target.value);
    localStorage.setItem("search_text_event", event.target.value);
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("analytics_filter_country");
    let find_search_text = localStorage.getItem("search_text_event");
    if (find_search_text) {
      setSearchText(find_search_text);
    }
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    getCategoriesList(filter_data);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const timeoutId = setTimeout(() => {
        getCategoriesList(filterData);
      }, 800);
      return () => clearTimeout(timeoutId);
    }
  }, [searchText]);

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "browser", label: "Browser" },
    { id: "activeUsers", label: "Active User" },
    { id: "newUsers", label: "New User" },
    { id: "engagedSessions", label: "Engaged Sessions" },
    {
      id: "engagementRate",
      label: "Engagement Rate",
      renderData: (row) => {
        const formattedRate = Number(row.engagementRate).toFixed(2);
        return <>{formattedRate}%</>;
      },
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col-lg-4">
          <h2>Platform Details</h2>
        </div>
        <div className="col-lg-8 text-end">
          <SearchStyle
            className="ms-auto"
            value={searchText}
            onChange={handleSearchText}
            placeholder="Search Url"
            startAdornment={
              <InputAdornment position="start">
                <Box
                  component={Icon}
                  icon={searchFill}
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            }
          />
          <button
            onClick={handleOpenDawer}
            className="small-contained-button ms-2"
          >
            Filter
          </button>
        </div>
      </div>
      {!isLoading && (
        <FilteredChip
          data={updateFilterData}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
      )}

      {isLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <>
          <div className="card mb-3">
            <Chart
              options={chartData.options}
              series={chartData.series}
              type="line"
              height="350"
            />
          </div>
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={dataList}
            className="card-with-background"
            is_hide={true}
            hide_footer_pagination={true}
          />
        </>
      )}
      <GeneralPopUpModel
        open={openFilter}
        setOpen={setOpenfilter}
        title={"Filter"}
        componentToPassDown={
          <AnalyticsFilter
            filterData={filterData}
            handleSelectOther={handleSelectOther}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </div>
  );
};

export default TechDetail;
