import { Autocomplete, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useSnackbar } from "notistack";
import { _get_attitude_accessment_setting } from "src/DAL/AttitudeAccessmentQuestions/AttitudeAccessmentSetting";
import { makeStyles } from "@mui/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { _update_membership_levels_mission } from "src/DAL/MissionApi/MissionApiNew";
import { _update_membership_level_coins_configration } from "src/DAL/challenges/challenges";
import ManageNavItemsNew from "./ManageNavItemsNew";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const CoinConfigurationNew = ({ level, setMembershipLevelList }) => {
  const [configArray, setConfigArray] = useState([
    { reward: "", nav_items: [] },
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const itemsRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isReload, setIsReload] = useState(false);
  console.log(level, "setMembershipLevelListsetMembershipLevelList");

  console.log(configArray, "configArrayconfigArray");
  const handleSubmit = async (e) => {
    e.preventDefault();

    let postData = {
      membership_levels_coin_configuration: {
        _id: level?._id,
        title: level?.title,
        nav_items: configArray,
      },
    };

    console.log("postData  __postData", postData);
    setIsLoading(true);
    const result = await _update_membership_level_coins_configration(postData);

    if (result.code == 200) {
      setMembershipLevelList(result?.membership_levels_coin_configuration);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClickItem = (e, index, data, selectedIndex) => {
    e.preventDefault();

    if (index === selectedIndex) {
      setSelectedItem(null);
      setSelectedIndex(null);
    } else {
      setSelectedItem(data);
      setSelectedIndex(index);
      setTimeout(() => {
        itemsRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  useEffect(() => {
    if (level?.nav_items && level?.nav_items.length > 0) {
      setConfigArray(level?.nav_items);
    } else {
      setConfigArray([]);
    }
  }, [level]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row mt-3"></div>

        <div className="text-end mt-4">
          <button className="small-contained-button fix-button">
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </div>
        <div ref={itemsRef} className={level?.type == "free" ? "mt-4" : ""}>
          <ManageNavItemsNew
            selected_items={level?.nav_items}
            index={selectedIndex}
            config_array={configArray}
            setConfigArray={setConfigArray}
            isReaload={isReload}
            setIsReaload={setIsReload}
          />
        </div>
      </form>
    </div>
  );
};
export default CoinConfigurationNew;
