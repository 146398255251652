import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useSnackbar } from "notistack";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  _auto_responded_message,
  delete_auto_responded_message,
} from "src/DAL/AutoRespondedMessage/AutoRespondedMessage";

// import {
//   DeleteLeadStatusHistory,
//   LeadStatusListing,
// } from "src/DAL/member/Member";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { dd_date_format } from "src/utils/constant";
import { historyListingApi } from "src/DAL/member/member";
import { s3baseUrl } from "src/config/config";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Levels({ levelsArray }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [first, setfirst] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [leadStatusHistory, setleadStatusHistory] = useState([]);

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "icon",
      label: "Icon",
      renderData: (row) => {
        return (
          <>
            <span>
              <Avatar
                src={s3baseUrl + row?.icon?.thumbnail_1}
                alt={row.title}
              />
            </span>
          </>
        );
      },
    },
    {
      id: "title",
      label: "Title",
    },
  ];

  // if (isLoading == true) {
  //   return <CircularProgress className={classes.loading} color="primary" />;
  // }

  return (
    <div style={{ zIndex: "0" }}>
      <div className="container" style={{ zIndex: "0" }}>
        <div class="row ">
          <div class="col-12 ">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={levelsArray}
              is_hide={true}
              className="card-with-background mt-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
