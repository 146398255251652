import React, { useEffect, useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { convertCurrencyToSign, show_proper_words } from "src/utils/constant";
import TransactionsCommissionCard from "./TransactionsCommissionCard";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { get_commission_report_for_transection_api } from "src/DAL/Transaction/transactionApi";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "10%",
    marginBottom: "10%",
  },
}));

const CommissionUser = ({ transection_id }) => {
  const classes = useStyles();
  const [listUser, setListData] = useState([]);
  const [listDetail, setListDetail] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const get_commission_list = async () => {
    const result = await get_commission_report_for_transection_api(
      transection_id
    );
    if (result.code == 200) {
      setListData(result.data);
      setListDetail(result);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    get_commission_list();
  }, []);

  const TABLE_HEAD_1 = [
    { id: "number", label: "#", type: "number" },
    {
      id: "user_info",
      label: "User Info",
      renderData: (row) => {
        let user_info = row?.user_info?.affiliate_user_info;
        console.log(row, "rowrowrow");
        return (
          <>
            <div>
              <span>
                {`${user_info.first_name} ${user_info.last_name} `}
                <span style={{ color: "#e4c073" }}>
                  {` (${row?.user_info?.affiliate_url_name})`}
                </span>
                {` | ${show_proper_words(
                  user_info.affiliate_user_created_for == "consultant"
                    ? "Delegate User"
                    : "Public User"
                )}`}
              </span>
            </div>
          </>
        );
      },
    },
    {
      id: "commission",
      label: "Commission Amount",
      renderData: (row) => {
        return (
          <>{convertCurrencyToSign(listDetail.currency) + row?.commission}</>
        );
      },
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div style={{ zIndex: "0" }}>
        <div className="container-fluid" style={{ zIndex: "0" }}>
          <div className="row mt-4">
            <div className="col-12 d-flex">
              <div className="set-heading">
                <h5 style={{ color: "#e4c073" }}>Commission Detail</h5>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <TransactionsCommissionCard
                count={listDetail.total_commission}
                currency={listDetail.currency ? listDetail.currency : "gbp"}
                title="Total Commission"
              />
            </div>
            <div className="col-12 col-md-6 ">
              <TransactionsCommissionCard
                count={listDetail.total_dynamite_commission}
                currency={listDetail.currency ? listDetail.currency : "gbp"}
                title="Team Diego Commission"
              />
            </div>
            <div class="col-12">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD_1}
                data={listUser}
                className="card-with-background mt-3"
                is_hide={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommissionUser;
