import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useSnackbar } from "notistack";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  _auto_responded_message,
  delete_auto_responded_message,
} from "src/DAL/AutoRespondedMessage/AutoRespondedMessage";

// import {
//   DeleteLeadStatusHistory,
//   LeadStatusListing,
// } from "src/DAL/member/Member";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { dd_date_format } from "src/utils/constant";
import { historyListingApi } from "src/DAL/member/member";
import { s3baseUrl } from "src/config/config";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function PackagePlanList({ PlanArray }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [first, setfirst] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [leadStatusHistory, setleadStatusHistory] = useState(PlanArray);

  const TABLE_HEAD = [
    {
      id: "Plan ",
      label: (
        <div className="d-flex">
          <span className="tableheadng">Plan</span>
        </div>
      ),
      renderData: (row) => {
        let plan_title;
        if (row.is_plan_free === false) {
          plan_title =
            row.plan_title + "(" + "Paid" + " /" + row.payment_access + ")";
        } else {
          plan_title = row.plan_title + "(" + "free" + ")";
        }
        return (
          <>
            <span>{plan_title}</span>
          </>
        );
      },
    },
    {
      id: "Plan ",
      label: (
        <div>
          <span className="tableheadng">Default Plan</span>
        </div>
      ),

      renderData: (row) => {
        return (
          <>
            <span>{row.is_default ? "Yes" : "No"}</span>
          </>
        );
      },
    },
  ];

  return (
    <div style={{ zIndex: "0" }}>
      <div className="container-fluid" style={{ zIndex: "0" }}>
        <div className="row mt-4">
          <div className="col-12 d-flex">
            <div className="set-heading">
              <h4 style={{ color: "#e4c073" }}>{first ? first : " "}</h4>
            </div>
          </div>
        </div>
        <div class="row ">
          <div class="col-12 ">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={leadStatusHistory}
              is_hide={true}
              className="card-with-background mt-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
