import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress, Autocomplete } from "@mui/material";
import { activeGroupListingApi } from "src/DAL/group/group";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";

import {
  _create_meeting_content,
  _detail_meeting_content,
  _update_meeting_content,
} from "src/DAL/MeetingContent/meetingContent";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateMeetingContent() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [audioFile, setAudioFile] = useState({});
  const [uploadAudio, setUploadAudio] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [oldAudio, setOldAudio] = useState("");
  const [audioUrl, setAudioUrl] = useState(null);
  const [inputs, setInputs] = useState({
    title: "",
    status: "true",
    content_type: "video",
    url: "",
    metadata: "",
    description: "",
    order: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const audioFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setAudioUrl(url);
      setAudioFile(file);
      setUploadAudio(true);
    }
  };

  const handleFormatData = (data) => {
    // setInputs(data);
    setInputs((values) => ({ ...values, ...data }));
    if (data?.content_type == "audio") {
      setOldAudio(data?.url);
    }

    // const name = event.target.name;
    // const value = event.target.value;
    // setInputs((values) => ({ ...values, [name]: value }));
  };

  const getMeetingContentDetail = async () => {
    setIsLoading(true);
    const result = await activeGroupListingApi();
    if (result.code === 200) {
      const result = await _detail_meeting_content(params?.id);
      if (result.code == 200) {
        handleFormatData(result?.meeting_content);
        // setInputs(result?.meeting_content);
        // if (result?.meeting_content?.content_type == "audio") {
        //   setOldAudio(result?.meeting_content?.url);
        // }

        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.content_type == "other" && inputs.metadata == "") {
      enqueueSnackbar("Other Content Description is required!", {
        variant: "error",
      });
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("description", inputs.description);
    formData.append("status", inputs.status);
    formData.append("content_type", inputs.content_type);

    if (params.id) {
      formData.append("order", inputs.order);
    }

    if (inputs.content_type == "audio") {
      if (uploadAudio) {
        formData.append("audio", audioFile);
      }
    } else if (inputs.content_type == "other") {
      formData.append("metadata", inputs.metadata);
    } else {
      formData.append("url", inputs.url);
    }

    const result = params.id
      ? await _update_meeting_content(formData, params.id)
      : await _create_meeting_content(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/meeting-content`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (params?.id) {
      if (state) {
        setIsLoading(false);
        handleFormatData(state);
      } else {
        setIsLoading(true);
        getMeetingContentDetail();
      }
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="d-flex align-items-center">
          <div>
            <IconButton
              className="back-screen-button  "
              onClick={() => navigate(`/meeting-content`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="ms-2 pb-1">
            <h2>
              {params?.id ? "Edit Meeting Content" : "Add Meeting Content"}
            </h2>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                fullWidth
                required
                name="title"
                value={inputs.title}
                onChange={handleChange}
              />
            </div>

            {params?.id && (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Order"
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                  name="order"
                  value={inputs.order}
                  onChange={handleChange}
                />
              </div>
            )}

            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  label="Status"
                  onChange={handleChange}
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Content Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="content_type"
                  value={inputs.content_type}
                  label="Content Type"
                  onChange={handleChange}
                >
                  <MenuItem value="video">Video</MenuItem>
                  <MenuItem value="audio">Audio</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </div>

            {inputs.content_type == "video" ? (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Content Video Url"
                  variant="outlined"
                  fullWidth
                  required
                  type="url"
                  name="url"
                  value={inputs.url}
                  onChange={handleChange}
                />
              </div>
            ) : inputs.content_type == "audio" ? (
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div className="row w-100 div-style ms-0 pt-0 ">
                  <div className="col-lg-8 col-md-3 col-sm-12">
                    <p className="">Upload Audio * </p>
                    <FormHelperText className="pt-0">
                      Audio mp3 (max 100mb)
                    </FormHelperText>
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12 text-end pt-2 ps-0">
                    <label htmlFor="audio">
                      <Input
                        accept="audio/mp3,audio/*;capture=microphone"
                        id="audio"
                        multiple
                        name="audio"
                        type="file"
                        onChange={audioFileChange}
                      />
                      <Button
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
                <div className="col-12 mt-1">
                  {audioUrl && (
                    <audio className="w-100" src={audioUrl} controls />
                  )}

                  {oldAudio && !audioUrl && (
                    <audio
                      className="w-100"
                      src={s3baseUrl + oldAudio}
                      controls
                    />
                  )}
                </div>
                <p className="text-secondary">{audioFile && audioFile.name}</p>
              </div>
            ) : null}

            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Short Description"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
                name="description"
                value={inputs.description}
                onChange={handleChange}
              />
            </div>

            {inputs.content_type == "other" && (
              <>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                  <h4>Other Content Description * </h4>
                  <GeneralCkeditor
                    setInputs={setInputs}
                    inputs={inputs}
                    name="metadata"
                    editorHeight={320}
                  />
                </div>
              </>
            )}

            <div className="text-end mt-4">
              <button className="small-contained-button">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
