import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import MyReward from "./Components/MyReward";
import ManageReward from "./Components/ManageReward";
import { s3baseUrl } from "src/config/config";

function BadgesHistory({ tabData }) {
  const [totalBadges, setTotalBadges] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [badges, setBadges] = useState([]);

  const TABS_OPTIONS = [
    {
      title: "My Reward",
      component: (
        <div className="col-12 mt-3">
          <MyReward
            badges={badges}
            setBadges={setBadges}
            tabData={tabData}
            totalBadges={totalBadges}
            setTotalBadges={setTotalBadges}
          />
        </div>
      ),
    },
    {
      title: "Reward History",
      component: (
        <div className="col-12 mt-3">
          <ManageReward totalBadges={totalBadges} />
        </div>
      ),
    },
  ];

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const get_badges_list = async () => {
    let tab_data = [];
    if (tabData?.badge_history_list && tabData?.badge_history_list.length > 0) {
      tab_data = tabData?.badge_history_list.map((data) => {
        return {
          ...data,
          title: data.challange_info?.challange_title,
          completed_challenges: data.badge_configration?.number_of_challenges,
        };
      });
    }
    setTotalBadges(tab_data);
  };

  const get_selected_badges_list = async () => {
    if (
      tabData &&
      tabData?.selected_badges &&
      tabData?.selected_badges.length > 0
    ) {
      const data = tabData?.selected_badges.map((badge) => {
        return {
          ...badge,
          table_avatar: {
            src: s3baseUrl + badge?.after_earned_icon?.thumbnail_1,
            alt: badge.title,
          },
        };
      });

      setBadges(data);
    }
  };

  useEffect(() => {
    get_badges_list();
    get_selected_badges_list();
  }, [tabData]);

  return (
    <>
      <div className="container-fluid">
        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
      </div>
    </>
  );
}

export default BadgesHistory;
