import { invokeApi } from "../../bl_libs/invokeApi";

export const _add_Bonus_road_map = async (data) => {
  const requestObj = {
    path: `/api/bonus_road_map`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _edit_bonus_Api = async (postData, _id) => {
  const requestObj = {
    path: `/api/bonus_road_map/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _bonus_listApi = async (data) => {
  const requestObj = {
    path: `/api/bonus_road_map`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const road_Delete_Api = async (id) => {
  const requestObj = {
    path: `/api/bonus_road_map/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_membership_level_pyramid = async (data) => {
  const requestObj = {
    path: `/api/membership_level_pyramid/add`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _membership_level_pyramid_list = async (id) => {
  const requestObj = {
    path: `/api/membership_level_pyramid/list/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const membership_level_pyramid_delete = async (id) => {
  const requestObj = {
    path: `/api/membership_level_pyramid/delete/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const membership_level_pyramid_detail = async (id) => {
  const requestObj = {
    path: `/api/membership_level_pyramid/detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _edit_membership_level_pyramid = async (postData, _id) => {
  const requestObj = {
    path: `/api/membership_level_pyramid/update/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
