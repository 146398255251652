import { useRef, useState, useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Icon } from "@iconify/react";
import MenuPopover from "../MenuPopover";
import { get_root_value } from "src/utils/domUtils";
import CustomPopoverSectionItems from "./CustomPopoverSectionItems";

export default function CustomPopoverSection(props) {
  const { menu, data } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [searchInput, setSearchInput] = useState("");
  const [popoverWidth, setPopoverWidth] = useState(0);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function calculateMaxLabelLength(data) {
    let maxLetters = 0;
    data.forEach((item) => {
      const labelLength = item.label.length;
      if (labelLength > maxLetters) {
        maxLetters = labelLength;
      }
    });
    return maxLetters;
  }

  useEffect(() => {
    if (menu.length > 8) {
      const maxLabelLength = calculateMaxLabelLength(menu);
      setPopoverWidth(maxLabelLength * 9.2 + 70);
    }
  }, []);
  console.log(popoverWidth, "menu");
  return (
    <div
      style={{ cursor: "pointer" }}
      className="remove-border menu-background-circle"
    >
      <MoreVertIcon
        style={{
          cursor: "pointer",
          fontSize: 20,
          color: get_root_value("--portal-theme-primary"),
        }}
        className="pointer custom-popover-icon"
        ref={anchorRef}
        onClick={handleOpen}
      />
      <MenuPopover
        className="custom-popover"
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          marginLeft: 1.8,
          maxHeight: 300,
          minWidth: 250,
          width: popoverWidth ? popoverWidth : "auto",
        }}
      >
        {menu.length > 8 && (
          <div
            className="menu-search-box"
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <Icon
              fontSize={20}
              className="menu-search-icon"
              icon="akar-icons:search"
            />
            <input
              className="menu-search-input"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        )}
        {menu
          .filter((option) =>
            option.label.toLowerCase().includes(searchInput.toLowerCase())
          )
          .map((option) => (
            <CustomPopoverSectionItems
              key={option.id} // Don't forget to add a key prop
              item={option}
              data={data}
              setOpen={setOpen}
            />
          ))}
      </MenuPopover>
    </div>
  );
}
