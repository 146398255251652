import * as React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress, Autocomplete } from "@mui/material";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  _get_membership_levels_mission_detail,
  _update_membership_levels_mission,
} from "src/DAL/MissionApi/MissionApiNew";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const EMPTY_OBJECT = {
  name: "",
  badges: [],
};

export default function ManageMissionMembershipLevels() {
  const navigate = useNavigate();
  const { mission_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [membershipLevelList, setMembershipLevelList] = useState([]);
  const [selectedMembershipLevel, setSelectedMembershipLevel] = useState([]);
  const [inputList, setInputList] = useState([{ ...EMPTY_OBJECT }]);
  const [missionResult, setMissionResult] = useState({});

  const handleAddClick = (index) => {
    setInputList([
      ...inputList.slice(0, index),
      { ...EMPTY_OBJECT },
      ...inputList.slice(index),
    ]);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleChange = (value, name, index) => {
    const list = [...inputList];
    list[index][name] = value;
    if (name == "name") {
      list[index]["badges"] = [];
    }
    setInputList(list);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let error = false;
    const formattedData = inputList.map((item) => {
      const selectedEvent = membershipLevelList.find(
        (event) => event.title === item.name
      );

      return {
        _id: selectedEvent?._id || "",
        title: item.name || "",
        icon: {
          thumbnail_1: selectedEvent?.icon?.thumbnail_1 || "",
        },
        membership_level_badges: (item.badges || []).map((badge) => ({
          _id: badge._id || "",
          title: badge.title || "",
          icon: {
            thumbnail_1: badge.icon?.thumbnail_1 || "",
          },
        })),
      };
    });

    let postData = {
      membership_levels: formattedData,
    };

    if (!error) {
      const result = await _update_membership_levels_mission(
        postData,
        mission_id
      );

      if (result.code == 200) {
        navigate(-1);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const getMissionDetail = async () => {
    setIsLoading(true);
    const result = await _get_membership_levels_mission_detail(mission_id);
    if (result.code === 200) {
      setMembershipLevelList(result?.membership_levels);
      setSelectedMembershipLevel(result?.mission?.membership_levels || []);
      setMissionResult(result?.mission);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  let breadCrumbMenu = [
    // {
    //   title: `Mission Level (${missionResult?.mission_lavel_info?.title})`,
    //   navigation: `/mission`,
    //   active: false,
    // },
    {
      title: `Mission (${missionResult?.title})`,
      // navigation: `/mission/challenge/${missionResult?.mission_lavel_info?._id}`,
      navigation: `/mission`,
      active: false,
    },
    {
      title: "Manage Membership Level",
      active: true,
    },
  ];

  useEffect(() => {
    getMissionDetail();
  }, []);

  useEffect(() => {
    if (selectedMembershipLevel && selectedMembershipLevel.length > 0) {
      const updatedInputList = selectedMembershipLevel.map((membership) => ({
        name: membership?.title,
        badges: membership?.membership_level_badges || [],
      }));

      setInputList(updatedInputList);
    }
  }, [selectedMembershipLevel]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>

      <form className="row mt-3" onSubmit={handleSubmit}>
        <div className="px-4">
          {inputList.map((inputs, index) => {
            const selectedEvent = membershipLevelList.find(
              (event) => event.title === inputs.name
            );

            return (
              <div key={index} className="row pb-3 mb-3 manage-event-pixel-bg">
                <div className="col-11 row">
                  <div className="col-12 col-md-6 mt-3">
                    <Autocomplete
                      fullWidth
                      options={membershipLevelList.filter(
                        (standardEvent) =>
                          !inputList.some(
                            (input, inputIndex) =>
                              inputIndex !== index &&
                              input.name === standardEvent.title
                          )
                      )}
                      getOptionLabel={(option) => option.title || ""}
                      value={selectedEvent || null}
                      onChange={(event, newValue) =>
                        handleChange(
                          newValue ? newValue.title : "",
                          "name",
                          index
                        )
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.title === value.title
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Membership Level"
                          required
                        />
                      )}
                      noOptionsText="No Option Available"
                    />
                  </div>

                  {selectedEvent && (
                    <div className="col-12 col-md-6 mt-3">
                      <Autocomplete
                        multiple
                        options={selectedEvent.membership_level_badges || []}
                        getOptionLabel={(badge) => badge.title || ""}
                        value={inputs.badges || []}
                        onChange={(event, newBadges) =>
                          handleChange(newBadges, "badges", index)
                        }
                        isOptionEqualToValue={(option, value) =>
                          option._id === value._id
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>{option.title}</li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Badge Level"
                            placeholder="Badge Level"
                          />
                        )}
                        noOptionsText="No Badge Level Available"
                      />
                    </div>
                  )}
                </div>
                <div className="col-1">
                  <div className="row mt-3 pt-2">
                    <div className="col-6">
                      {membershipLevelList.length > 0 &&
                        membershipLevelList.length > inputList.length && (
                          <IconButton onClick={() => handleAddClick(index + 1)}>
                            <AddCircleOutlineIcon />
                          </IconButton>
                        )}
                    </div>
                    <div className="col-6">
                      {inputList.length > 1 && (
                        <IconButton
                          color="error"
                          onClick={() => handleRemoveClick(index)}
                        >
                          <RemoveCircleOutlineIcon className="membership-program-cross-button" />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="text-end" id="fixedbutton">
          <button className="small-contained-button">Submit</button>
        </div>
      </form>
    </div>
  );
}
