import { Icon } from "@iconify/react";
import BarChart from "./BarChart";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import { useEffect, useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import AnalyticFilter from "./AnalyticFilter";
import { Ga4_stream_analytics_list } from "src/DAL/Transaction/transactionApi";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import searchFill from "@iconify/icons-eva/search-fill";

import { format } from "date-fns";
import {
  Box,
  Chip,
  CircularProgress,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import FilteredChip from "src/components/FilteredChip";
import { useSnackbar } from "notistack";
import { dd_date_format } from "src/utils/constant";
import CopyToClipboard from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import AnalaticsalReportDetail from "./AnalaticsalReportDetail";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const formatDate = (dateStr) => {
  const date = new Date(
    `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(6, 8)}`
  );
  return format(date, "MMM dd");
};
const getRandomColor = () => {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};
const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 44,
  color: "#fff",
  border: "1px solid #e4c073",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    boxShadow: theme.customShadows.z8,
    border: "1px solid #e4c073",
    width: 280,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));
const AnalaticsalReport = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [barData, setBarData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [showDetail, setShowDetail] = useState(false);
  const navigate = useNavigate();
  const handleSearchText = (event) => {
    setSearchText(event.target.value);
    localStorage.setItem("search_text_country", event.target.value);
  };

  const currentDate = new Date();
  const oneWeekAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 7
  );
  const EMPTY_FILTER = {
    date_from: oneWeekAgo,
    date_to: currentDate,
    stream_id: null,
  };
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);
  const [openFilter, setOpenfilter] = useState(false);
  const [filterDataPopup, setFiltersdataPopup] = useState(null);
  const [lineChartOptions, setLineChartOptions] = useState({
    options: {
      chart: {
        type: "line",
      },
      grid: {
        show: true,
        borderColor: "gray",
        strokeDashArray: 4,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
        },
        axisBorder: {
          show: true,
          color: "gray",
          dashArray: 4,
        },
      },
      xaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
          axisTicks: {
            show: false,
          },
        },
        categories: [],
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      markers: {
        size: 0,
      },
      fill: {
        colors: ["#e4c073"],
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          if (val === 0) {
            return "";
          }
          return val;
        },
      },
      legend: {
        show: false, // Hide the legends
      },
    },
    series: [],
  });

  const handleOPenDawer = () => {
    setOpenfilter(true);
  };

  const handleChaneOthers = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setPageCount(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    get_listing(filterData);
    localStorage.setItem("funnal_filter", JSON.stringify(filterData));
    setOpenfilter(false);
  };

  const get_listing = async (filterData) => {
    setIsLoading(true);
    let data = { ...filterData };
    let PostData = {
      start_date: moment(data.date_from).format("YYYY-MM-DD"),
      end_date: moment(data.date_to).format("YYYY-MM-DD"),
      // stream_id: data.stream_id.id,
      search: searchText,
    };
    let result = await Ga4_stream_analytics_list(PostData, page, rowsPerPage);
    if (result.code === 200) {
      setBarData(result.page_view_stats);
      setTableData(result.page_location_data);
      setTotalCount(result.count);
      setTotalPages(result.total_pages);
      let categories = [];
      let series = [];

      if (result.graph.length > 0) {
        categories = result.graph[0].dataPoints.map((point) =>
          formatDate(point.date)
        );
      }

      // Process each graph object to generate series data
      series = result.graph.map((graph) => ({
        name: graph.title, // Use fullPath as the series name
        data: graph.dataPoints.map((point) => point.views), // Use views for data
        color: getRandomColor(), // Default color; you can assign different colors for each series if needed
      }));

      // Update chart options with the new series and categories
      setLineChartOptions((prev) => ({
        ...prev,
        options: {
          ...prev.options,
          xaxis: {
            ...prev.options.xaxis,
            categories: categories,
          },
        },
        series: series,
      }));

      setIsLoading(false);
      let chipData = { ...filterData };
      if (chipData.date_from) {
        let date = `Start Date : ${dd_date_format(
          chipData.date_from
        )} End Date :  ${dd_date_format(chipData.date_to)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chipData.date_to;
        delete chipData.date_from;
      }
      console.log(chipData, "chipDatachipDatachipData");
      setUpdateFilterData(chipData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleCopiedUrl = () => {
    enqueueSnackbar("Page Url copied to clipboard", { variant: "success" });
  };

  const handleClearFilter = () => {
    setPage(0);
    setPageCount(1);
    setUpdateFilterData(EMPTY_FILTER);
    setFilterData(EMPTY_FILTER);
    get_listing(EMPTY_FILTER);
    setOpenfilter(false);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    if (!data.date) {
      data.date_from = oneWeekAgo;
      data.date_to = currentDate;
    }
    setFilterData(data);
    get_listing(data);
  };
  const handlePageDetail = (row) => {
    let data = {
      page_title: row.pageTitle,
      start_date: filterData.date_from,
      end_date: filterData.date_to,
    };
    setFiltersdataPopup(data);
    setShowDetail(true);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },

    {
      id: "pageTitle",
      label: "Page Title",
      renderData: (row) => {
        return (
          <p className="mb-0" onClick={() => handlePageDetail(row)}>
            <span className="me-2" style={{ cursor: "pointer" }}>
              <Tooltip title={row.pageLocation}>
                <span>{row.pageTitle}</span>
              </Tooltip>
            </span>
          </p>
        );
      },
    },
    { id: "activeUsers", label: "Active Users" },
    { id: "newUsers", label: "New Users" },
    { id: "eventCount", label: "Event Count" },
    { id: "screenPageViews", label: " Page Views" },
    {
      id: "pageLocation",
      label: "Copy Page Location",
      renderData: (row) => {
        return (
          <CopyToClipboard
            text={row.pageLocation}
            onCopy={() => handleCopiedUrl(true)}
          >
            <Tooltip title="Click to Copy URL">
              <Chip
                label="Copy Page URL"
                // size="small"
                color="primary"
                variant="outlined"
                style={{
                  fontSize: "12px",
                }}
              />
            </Tooltip>
          </CopyToClipboard>
        );
      },
    },
  ];
  useEffect(() => {
    let find_filter = localStorage.getItem(`funnal_filter`);
    if (find_filter) {
      setFilterData(JSON.parse(find_filter));
      setUpdateFilterData(JSON.parse(find_filter));
      get_listing(JSON.parse(find_filter));
    } else {
      setFilterData(EMPTY_FILTER);
      setUpdateFilterData(EMPTY_FILTER);
      get_listing(filterData);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      get_listing(filterData);
    }, 800);

    return () => clearTimeout(timeoutId);
  }, [searchText]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="contaier-fluid">
        <div className="row mt-2">
          <div className="col-6">
            <h2>Detailed Analytics Report </h2>
          </div>
          <div className="col-lg-6 text-end">
            <SearchStyle
              className="ms-auto"
              value={searchText}
              onChange={handleSearchText}
              placeholder="Search Url"
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              }
            />
            <button
              onClick={handleOPenDawer}
              className="small-contained-button ms-2"
            >
              Filter
            </button>
          </div>
          <div className="col-lg-12 col-sm-12 mt-3">
            <FilteredChip
              data={updateFilterData}
              tempState={filterData}
              EMPTY_FILTER={EMPTY_FILTER}
              onDeleteChip={handleDeleteChip}
              onClear={handleClearFilter}
            />
          </div>
          {/* <div className="col-7">
            <div className="card">
              <h3 className="straem_heading">Views By Stream Over time</h3>
              {lineChartOptions.series.length > 0 &&
                lineChartOptions.options.length > 0 && (
                  <Chart
                    options={lineChartOptions.options}
                    series={lineChartOptions.series}
                    type="line"
                    height={440}
                  />
                )}
            </div>
          </div>{" "} */}
          <div className="col-12">
            <div className="card">
              <h3 className="straem_heading">Analytics Graph </h3>
              <BarChart barData={barData} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={tableData}
          className="card-with-background"
          table_title={"Detailed Analytic Report"}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          is_hide={true}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
      </div>
      <GeneralPopUpModel
        open={openFilter}
        setOpen={setOpenfilter}
        title={"Filter  Analytics Report"}
        componentToPassDown={
          <AnalyticFilter
            handleChaneOthers={handleChaneOthers}
            filterData={filterData}
            searchFunction={searchFunction}
            handleSelectOther={handleSelectOther}
          />
        }
      />
      <WhatsappPopUpModel
        open={showDetail}
        setOpen={setShowDetail}
        title={"Traffic By Page Title"}
        show_date_and_income={true}
        componentToPassDown={
          <AnalaticsalReportDetail filterDataPopup={filterDataPopup} />
        }
      />
    </>
  );
};

export default AnalaticsalReport;
