import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { update_language_content } from "src/DAL/Programme/Programme";
import { useEffect } from "react";
import { multi_language_data } from "src/utils/constant";
import { lesson_recording_detail_api } from "src/DAL/Programme/Lessons/LessonRecordings/LessonRecordings";
import { lesson_document_detail_api } from "src/DAL/Programme/Lessons/LessonDocument/LessonDocument";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function UpdateProgramDocumentMultiLanguageContent() {
  const navigate = useNavigate();
  const { document_slug, programme_slug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [formType, setFormType] = useState("ADD");
  const [language, setLanguage] = useState([]);
  const [multi_language_content, set_multi_language_content] = useState(null);
  const [spacificLanguage, setSpacificLanguage] = useState({});
  const [rerander_editor, set_rerander_object] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let data = {
      title: spacificLanguage.title,
      detailed_description: spacificLanguage.detailed_description,
    };
    const postData = {
      content: data,
      language: spacificLanguage.content_languages,
      module_id: document_slug,
      module_type: "program_document",
      module_key: "slug",
    };
    const result = await update_language_content(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      if (formType !== "ADD") {
        handleNavigate();
      } else {
        handleNavigate();
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setSpacificLanguage((values) => ({ ...values, [name]: value }));
    if (name == "content_languages") {
      setSpacificLanguage({
        ...multi_language_content[value],
        ["content_languages"]: value,
      });
      set_rerander_object(false);
    }
  };
  const handleFormat = (data) => {
    let en = {
      title: data.title,
      detailed_description: data.detailed_description,
    };
    let object = {
      en: en,
    };

    set_multi_language_content({ ...object, ...data.multi_language_content });
    setSpacificLanguage({
      title: data.title,
      detailed_description: data.detailed_description,
      content_languages: "en",
    });
    setFormType("EDIT");
    const multi_language = [];
    if (data.content_languages && data.content_languages.length > 0) {
      const content_languages = data.content_languages.map((item) => {
        const find_language = multi_language_data.find(
          (language) => language.code == item
        );
        if (find_language) {
          multi_language.push(find_language);
        }
      });
    }
    setLanguage(multi_language);
    setIsLoading(false);
  };

  const handleNavigate = () => {
    navigate(`/programmes/programm-document/${programme_slug}`);
  };
  const get_document_detail = async () => {
    setIsLoading(true);
    const result = await programme_document_detail_api(document_slug);
    if (result.code == 200) {
      handleFormat(result.program_document);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (document_slug) {
      if (state) {
        handleFormat(state);
      } else {
        get_document_detail();
      }
    }
  }, []);

  useEffect(() => {
    set_rerander_object(true);
  }, [spacificLanguage]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex align-items-center">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => handleNavigate()}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-2 mb-4">Document Content</h2>
        </div>
      </div>

      <form className="row" onSubmit={handleSubmit}>
        <div className="row partition-border ms-2 pt-4 pb-4">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Content Languages
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="content_languages"
                value={spacificLanguage.content_languages ?? "en"}
                onChange={handleChange}
                label="Content Languages"
              >
                <MenuItem value="en">English</MenuItem>
                {language.map((item, index) => (
                  <MenuItem value={item.code}>{item.language}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              name="title"
              value={spacificLanguage.title}
              onChange={handleChange}
              required
            />
          </div>

          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description"
                multiline
                rows={6}
                required
                name="detailed_description"
                value={spacificLanguage.detailed_description}
                onChange={handleChange}
              />
              <FormHelperText>Maximum limit 500 characters</FormHelperText>
            </FormControl>
          </div>
        </div>

        <div className="text-end mt-4">
          <button className="small-contained-button">
            {formType == "ADD" ? "Submit" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
