import * as React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Autocomplete, CircularProgress } from "@mui/material";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import {
  _get_membership_levels_program_detail,
  _update_membership_levels_program,
} from "src/DAL/Programme/Programme";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ManageMembershipLevels() {
  const navigate = useNavigate();
  const { programme_slug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [membershipLevelList, setMembershipLevelList] = useState([]);
  const [selectedMembershipLevel, setSelectedMembershipLevel] = useState(null);
  const [inputData, setInputData] = useState({
    name: "",
    badges: {},
  });

  const handleChange = (value, name) => {
    const list = { ...inputData };
    console.log("list  __list", list);
    list[name] = value;
    if (name == "name") {
      list["badges"] = null;
    }
    setInputData(list);
    console.log("list  __list", list);

    // setInputData((prev) => ({
    //   ...prev,
    //   [name]: value,
    // }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedEvent = membershipLevelList.find(
      (event) => event.title === inputData.name
    );

    const postData = {
      membership_levels: {
        _id: selectedEvent?._id || "",
        title: inputData.name || "",
        membership_level_badges: {
          _id: inputData.badges._id || "",
          title: inputData.badges.title || "",
        },
      },
    };

    console.log("postData  ___postData", postData);
    setIsLoading(true);
    const result = await _update_membership_levels_program(
      postData,
      programme_slug
    );

    if (result.code === 200) {
      navigate(-1);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getProgrammeDetail = async () => {
    setIsLoading(true);
    const result = await _get_membership_levels_program_detail(programme_slug);
    if (result.code === 200) {
      setMembershipLevelList(result?.membership_levels);
      setSelectedMembershipLevel(result?.program?.membership_levels || null);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProgrammeDetail();
  }, []);

  useEffect(() => {
    if (selectedMembershipLevel) {
      const updatedInputList = {
        name: selectedMembershipLevel?.title,
        badges: selectedMembershipLevel?.membership_level_badges || {},
      };

      // const [inputData, setInputData] = useState({
      //   name: "",
      //   badges: {},
      // });

      setInputData(updatedInputList);
    }
    console.log(
      "selectedMembershipLevel  __selectedMembershipLevel",
      selectedMembershipLevel
    );
  }, [selectedMembershipLevel]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <ActiveLastBreadcrumb
            breadCrumbMenu={[
              { title: "Programmes", navigation: `/programmes`, active: false },
              {
                title: state?.title || "Program Details",
                navigation: `/programmes/programmes-detail/${programme_slug}`,
                active: false,
              },
              { title: "Manage Membership Level", active: true },
            ]}
          />
        </div>
      </div>

      <form className="row mt-3" onSubmit={handleSubmit}>
        <div className="px-4">
          <div className="row pb-3 mb-3 manage-event-pixel-bg">
            <div className="col-12 col-md-6 mt-3">
              <Autocomplete
                fullWidth
                options={membershipLevelList}
                getOptionLabel={(option) => option.title || ""}
                value={
                  membershipLevelList.find(
                    (event) => event.title === inputData.name
                  ) || null
                }
                onChange={(event, newValue) =>
                  handleChange(newValue ? newValue.title : "", "name")
                }
                isOptionEqualToValue={(option, value) =>
                  option.title === value.title
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Membership Level List"
                    required
                  />
                )}
                noOptionsText="No Option Available"
              />
            </div>

            {inputData.name && (
              <div className="col-12 col-md-6 mt-3">
                <Autocomplete
                  options={
                    membershipLevelList.find(
                      (event) => event.title === inputData.name
                    )?.membership_level_badges || []
                  }
                  getOptionLabel={(badge) => badge.title || ""}
                  value={inputData.badges || null}
                  onChange={(event, newValue) =>
                    handleChange(newValue || {}, "badges")
                  }
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Badge"
                      placeholder="Badge"
                    />
                  )}
                  noOptionsText="No Badge Available"
                />
              </div>
            )}
          </div>
        </div>

        <div className="text-end" id="fixedbutton">
          <button className="small-contained-button">Submit</button>
        </div>
      </form>
    </div>
  );
}
