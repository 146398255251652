import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { s3baseUrl } from "src/config/config";
import ImageBox from "src/components/ModelBox/ImageBox";
import { delete_card_api } from "src/DAL/HelpVideos/Categories";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  VersionDeleteApi,
  VersionHistoryList,
} from "src/DAL/VesionHistoryApi/VersionHistory";
import { dd_date_format } from "src/utils/constant";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import ShopOrderDetail from "../Shop/ShopOrderDetail";
import VersionHistoryDetails from "./VersionHistoryDetails";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function VersionHistory({ type }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [data, setData] = useState(null);
  const [categoriesData, setCategoriesData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const getCategoriesListing = async (text) => {
    setIsLoading(true);
    const result = await VersionHistoryList(page, rowsPerPage, text);
    if (result.code == 200) {
      console.log(result, "resultresult");
      const categories = result.version_histories.map((category) => {
        return {
          ...category,
        };
      });
      setCategoriesData(categories);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_cards_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await VersionDeleteApi(deleteDoc._id);
    if (result.code === 200) {
      setCategoriesData((categoriesData) => {
        return categoriesData.filter((data) => data._id !== deleteDoc._id);
      });
      // getCategoriesListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleEdit = (value) => {
    navigate(`/versions/add/${value._id}`, {
      state: value,
    });
  };

  const handleNavigate = () => {
    navigate(`/versions/add`);
  };
  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    localStorage.setItem("card_search", searchText);

    getCategoriesListing(searchText);
  };

  const handleOPenPopUp = (data, name) => {
    let PostData = {
      Title: name,
      data: data,
    };
    setData(PostData);
    setOpen(true);
  };
  const handleClosePopUp = () => {
    setData(null);
    setOpen(false);
  };

  useEffect(() => {
    let search_text = localStorage.getItem("card_search");
    if (search_text) {
      setSearchText(search_text);
      getCategoriesListing(search_text);
    } else {
      setSearchText("");
      getCategoriesListing("");
    }
  }, [page, rowsPerPage]);

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "platform_type",
      label: "Platform",
      renderData: (row) => {
        return (
          <>
            <span
              className="detail-build-button"
              onClick={() => handleOPenPopUp(row)}
              style={{ color: "white", textDecoration: "none" }}
            >
              {row.platform_type == "both"
                ? "IOS/Android"
                : row.platform_type == "ios"
                ? "IOS"
                : row.platform_type}
            </span>
          </>
        );
      },
    },
    {
      id: "platform_type",
      label: "View Details",
      renderData: (row) => {
        return (
          <>
            <span
              className="detail-build-button"
              onClick={() => handleOPenPopUp(row)}
            >
              View Details
            </span>
          </>
        );
      },
    },

    {
      id: "platform_type",
      label: "Version Updated Date",
      renderData: (row) => {
        return (
          <>
            <span>{dd_date_format(row.createdAt)}</span>{" "}
          </>
        );
      },
    },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Versions History</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Version History
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={categoriesData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          is_hide={true}
          // custom_pagination={{
          //   total_count: totalCount,
          //   rows_per_page: rowsPerPage,
          //   page: page,
          //   handleChangePage: handleChangePage,
          //   onRowsPerPageChange: handleChangeRowsPerPage,
          // }}
          // custom_search={{
          //   searchText: searchText,
          //   setSearchText: setSearchText,
          //   handleSubmit: searchFunction,
          // }}
          // pagePagination={true}
          // pageCount={pageCount}
          // totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
      </div>
      {open && (
        <WhatsappPopUpModel
          open={open}
          setOpen={handleClosePopUp}
          title={"Version Detail"}
          show_date_and_income={true}
          componentToPassDown={<VersionHistoryDetails data={data.data} />}
        />
      )}
    </>
  );
}
