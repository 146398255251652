import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { feedSettingApi, updateSettingApi } from "src/DAL/feeds/feeds";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { s3baseUrl } from "src/config/config";
import { COMMUNITY_LEVELS } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function FeedSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  let initial_levels_description = {};

  COMMUNITY_LEVELS.map((level) => {
    let level_name = `${level.name}_level`;
    initial_levels_description[level_name] = "";
  });

  const [nextLevelDescriptionCk, setNextLevelDescriptionCk] = useState({
    ...initial_levels_description,
    executive_level: "",
  });

  let initial_levels_state = {};

  COMMUNITY_LEVELS.map((level) => {
    let level_name = `${level.name}_level`;
    let badge_name = `${level.name}_badge`;
    initial_levels_state[level_name] = {
      like_point: "",
      tip_point: "",
      stick_point: "",
      coins_count: "",
      coins_count_for_next_level: 0,
    };
    initial_levels_state[badge_name] = "";
  });

  const [inputs, setInputs] = useState({
    ...initial_levels_state,
    executive_level: {
      like_point: "",
      tip_point: "",
      stick_point: "",
      coins_count: "",
      coins_count_for_next_level: 0,
    },
    bonus_count: "",
    maximum_like_count: "",
    coin_description: "",
    like_description: "",
    stick_description: "",
    gratitude_description: "",
    notice_board: "",
    poll_winner_description: "",
    win_title: "",
    monthly_leaderboard_heading: "",
    yearly_leaderboard_heading: "",
    life_time_leaderboard_heading: "",
    weekly_leaderboard_heading: "",
    show_level_filter: true,
    enable_mention_user: false,
    enable_poll_feed: false,
    enable_survey_feed: false,
  });

  const getSetting = async () => {
    setIsLoading(true);
    const result = await feedSettingApi();
    if (result.code == 200) {
      result.feed_setting.map((setting) => {
        delete setting._id;
        setInputs(setting);
        let community_levels = {};
        COMMUNITY_LEVELS.map((level) => {
          let level_name = `${level.name}_level`;
          community_levels[level_name] =
            setting[level_name]?.coins_count_for_next_level_description;
        });
        setNextLevelDescriptionCk({
          ...community_levels,
          executive_level:
            setting.executive_level.coins_count_for_next_level_description,
        });
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    COMMUNITY_LEVELS.map((level) => {
      inputs[`${level.name}_level`].coins_count_for_next_level_description =
        nextLevelDescriptionCk[`${level.name}_level`];
    });

    inputs.executive_level.coins_count_for_next_level_description =
      nextLevelDescriptionCk.executive_level;

    const result = await updateSettingApi(inputs);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeLevel = (event, level) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((values) => ({
      ...values,
      [level]: {
        ...values[level],
        [name]: value,
      },
    }));
  };

  const dynamiteFileChangedHandler = async (e) => {
    const { name, files } = e.target;
    const formData = new FormData();
    formData.append("image", files[0]);
    formData.append("width", "300");
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        [name]: imageUpload.image_path,
      });
    }
  };

  useEffect(() => {
    getSetting();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin">
        <div className="col-6">
          <h2>Feed Setting</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          {COMMUNITY_LEVELS.map((level) => {
            return (
              <>
                <h3 className="mt-4">{level.title} Level</h3>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                  <TextField
                    id="outlined-basic"
                    label="Like Coins"
                    variant="outlined"
                    fullWidth
                    required
                    name="like_point"
                    value={inputs[`${level.name}_level`]?.like_point}
                    onChange={(e) => {
                      handleChangeLevel(e, `${level.name}_level`);
                    }}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                  <TextField
                    id="outlined-basic"
                    label="Gratitude Coins"
                    variant="outlined"
                    fullWidth
                    required
                    name="tip_point"
                    value={inputs[`${level.name}_level`]?.tip_point}
                    onChange={(e) => {
                      handleChangeLevel(e, `${level.name}_level`);
                    }}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Coins Count"
                    variant="outlined"
                    fullWidth
                    required
                    name="coins_count"
                    value={inputs[`${level.name}_level`]?.coins_count}
                    onChange={(e) => {
                      handleChangeLevel(e, `${level.name}_level`);
                    }}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Coins Count For Next Level"
                    variant="outlined"
                    type="number"
                    fullWidth
                    name="coins_count_for_next_level"
                    value={
                      inputs[`${level.name}_level`]?.coins_count_for_next_level
                    }
                    onChange={(e) => {
                      handleChangeLevel(e, `${level.name}_level`);
                    }}
                  />
                </div>
                <div className="col-12 mt-4">
                  <h4>Coins Count For Next Level Description</h4>
                  <GeneralCkeditor
                    setInputs={setNextLevelDescriptionCk}
                    inputs={nextLevelDescriptionCk}
                    name={`${level.name}_level`}
                    editorHeight={320}
                  />
                </div>
              </>
            );
          })}
          <h3 className="mt-5">Executive Level</h3>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Like Coins"
              variant="outlined"
              fullWidth
              required
              name="like_point"
              value={inputs.executive_level.like_point}
              onChange={(e) => {
                handleChangeLevel(e, "executive_level");
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Gratitude Coins"
              variant="outlined"
              fullWidth
              required
              name="tip_point"
              value={inputs.executive_level.tip_point}
              onChange={(e) => {
                handleChangeLevel(e, "executive_level");
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Coins Count"
              variant="outlined"
              fullWidth
              required
              name="coins_count"
              value={inputs.executive_level.coins_count}
              onChange={(e) => {
                handleChangeLevel(e, "executive_level");
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Coins Count For Next Level"
              variant="outlined"
              type="number"
              fullWidth
              name="coins_count_for_next_level"
              value={inputs.executive_level.coins_count_for_next_level}
              onChange={(e) => {
                handleChangeLevel(e, "executive_level");
              }}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Coins Count For Next Level Description</h4>
            <GeneralCkeditor
              setInputs={setNextLevelDescriptionCk}
              inputs={nextLevelDescriptionCk}
              name="executive_level"
              editorHeight={320}
            />
          </div>
          <h3 className="mt-5">General Settings</h3>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Maximum Like Count"
              variant="outlined"
              fullWidth
              required
              name="maximum_like_count"
              value={inputs.maximum_like_count}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Bonus Coins"
              variant="outlined"
              fullWidth
              required
              name="bonus_count"
              value={inputs.bonus_count}
              onChange={handleChange}
            />
          </div>

          {COMMUNITY_LEVELS.map((level) => {
            return (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label={`${level.title} Badge Title`}
                  variant="outlined"
                  fullWidth
                  required
                  name={`${level.name}_title`}
                  value={inputs[`${level.name}_title`]}
                  onChange={handleChange}
                />
              </div>
            );
          })}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Win Badge Title"
              variant="outlined"
              fullWidth
              required
              name="win_title"
              value={inputs.win_title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show Level Filter
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="show_level_filter"
                required
                value={inputs.show_level_filter}
                label="Show Level Filter"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Enable mention users in feeds and comments
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="enable_mention_user"
                required
                value={inputs.enable_mention_user}
                label="Enable mention users in feeds and comments"
                onChange={handleChange}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label-21">
                Enable Poll in Feeds
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-21"
                id="demo-simple-select-21"
                name="enable_poll_feed"
                required
                value={inputs.enable_poll_feed}
                label="Enable Poll in Feeds"
                onChange={handleChange}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label-21">
                Enable Survey in Feeds
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-21"
                id="demo-simple-select-21"
                name="enable_survey_feed"
                value={inputs.enable_survey_feed}
                label="Enable Survey in Feeds"
                onChange={handleChange}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </div>
          {COMMUNITY_LEVELS.map((level, index) => {
            return (
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div className="row w-100 div-style ms-0 pt-0">
                  <div className="col-5">
                    <p className="">{level.title} Badge</p>
                    <FormHelperText className="pt-0">
                      Image Size(90 X 90) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-2">
                    {inputs[`${level.name}_badge`] && (
                      <img
                        src={s3baseUrl + inputs[`${level.name}_badge`]}
                        height="50"
                      />
                    )}
                  </div>
                  <div className="col-5 text-end pt-2">
                    <label htmlFor={`contained-button-file${index}0`}>
                      <Input
                        accept="image/*"
                        id={`contained-button-file${index}0`}
                        type="file"
                        name={`${level.name}_badge`}
                        onChange={dynamiteFileChangedHandler}
                      />

                      <Button
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
                {inputs[`${level.name}_badge`] == "" ? (
                  ""
                ) : (
                  <p className="text-secondary">
                    {inputs[`${level.name}_badge`]}
                  </p>
                )}
              </div>
            );
          })}

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Weekly Leaderboard Heading"
              variant="outlined"
              fullWidth
              required
              name="weekly_leaderboard_heading"
              value={inputs.weekly_leaderboard_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Monthly Leaderboard Heading"
              variant="outlined"
              fullWidth
              required
              name="monthly_leaderboard_heading"
              value={inputs.monthly_leaderboard_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Yearly Leaderboard Heading"
              variant="outlined"
              fullWidth
              required
              name="yearly_leaderboard_heading"
              value={inputs.yearly_leaderboard_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Life Time Leaderboard Heading"
              variant="outlined"
              fullWidth
              required
              name="life_time_leaderboard_heading"
              value={inputs.life_time_leaderboard_heading}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Coin Description"
              variant="outlined"
              fullWidth
              required
              multiline
              rows={4}
              name="coin_description"
              value={inputs.coin_description}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Like Description"
              variant="outlined"
              fullWidth
              required
              multiline
              rows={4}
              name="like_description"
              value={inputs.like_description}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Stick Description"
              variant="outlined"
              fullWidth
              required
              multiline
              rows={4}
              name="stick_description"
              value={inputs.stick_description}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Gratitude Description"
              variant="outlined"
              fullWidth
              required
              multiline
              rows={4}
              name="gratitude_description"
              value={inputs.gratitude_description}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <h4>Notice Board</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="notice_board"
              editorHeight={320}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <h4>Poll Winner Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="poll_winner_description"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
