import React, { useEffect, useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Box,
} from "@mui/material";
import { _update_member_badge_count } from "src/DAL/member/member";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import { LoadingButton } from "@mui/lab";

const AddOrUpdateManageRewards = ({
  tabData,
  data,
  setBadges,
  setOpenManageRewards,
  totalBadges,
  setTotalBadges,
}) => {
  const member_id = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedBadges, setSelectedBadges] = useState([]);
  const [badgeCounts, setBadgeCounts] = useState({});
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const SelectedIds = data.map((item) => item?._id);

  const handleBadgeChange = (id) => {
    setSelectedBadges((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((badgeId) => badgeId !== id)
        : [...prevSelected, id]
    );
  };

  const handleBadgeCountChange = (id, value) => {
    setBadgeCounts((prevCounts) => ({
      ...prevCounts,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoadingButton(true);
    const formattedData = {
      member_id: member_id?.id,
      badges: tabData?.badges
        .filter(
          (badge) =>
            selectedBadges.includes(badge._id) &&
            parseInt(badgeCounts[badge._id], 10) > 0
        )
        .map((badge) => {
          return {
            _id: badge?._id,
            title: badge?.title,
            after_earned_icon: badge?.after_earned_icon,
            icon: badge?.icon,
            no_of_badges: parseInt(badgeCounts[badge._id], 10),
          };
        }),
    };

    const result = await _update_member_badge_count(formattedData);
    if (result.code === 200) {
      let historyData = [result?.history, ...totalBadges];
      const newData = result?.badges.map((badge) => {
        return {
          ...badge,
          table_avatar: {
            src: s3baseUrl + badge?.after_earned_icon?.thumbnail_1,
            alt: badge.title,
          },
        };
      });

      setTotalBadges(historyData);
      setBadges(newData ?? []);
      setOpenManageRewards(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingButton(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    const initialSelected = tabData?.badges
      ?.filter((badge) => data.some((d) => d._id === badge._id))
      .map((badge) => badge._id);

    const initialCounts = tabData?.badges.reduce((acc, badge) => {
      const matchingBadge = data.find((d) => d._id === badge._id);
      acc[badge._id] = matchingBadge ? matchingBadge.no_of_badges : 0;
      return acc;
    }, {});

    setSelectedBadges(initialSelected || []);
    setBadgeCounts(initialCounts || {});
  }, [tabData, data]);

  return (
    <Box sx={{ p: 2, color: "#fff" }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {tabData?.badges.map((badge) => (
            <Grid item xs={12} key={badge._id}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={2}
              >
                <Box display="flex" alignItems="center" gap={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedBadges.includes(badge._id)}
                        onChange={() => handleBadgeChange(badge._id)}
                        disabled={SelectedIds.includes(badge._id)}
                      />
                    }
                    label={badge.title}
                  />
                </Box>

                <div className="mt-1">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Number of Rewards"
                    type="number"
                    name="reviews_tab_title"
                    size="small"
                    className="number_of_rewards_input"
                    value={badgeCounts[badge._id]}
                    onChange={(e) =>
                      handleBadgeCountChange(badge._id, e.target.value)
                    }
                    inputProps={{
                      min: 1,
                    }}
                    disabled={!selectedBadges.includes(badge._id)}
                    required
                  />
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box mt={4} textAlign="right">
          <LoadingButton
            size="small"
            type="submit"
            variant="contained"
            className="small-contained-button"
            loading={isLoadingButton}
            sx={{ boxShadow: "none" }}
            disabled={selectedBadges.length === 0}
          >
            Update
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

export default AddOrUpdateManageRewards;
