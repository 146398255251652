import { Dialog } from "@mui/material";
import React from "react";

export default function GeneralPopUpModel({
  open,
  title,
  setOpen,
  componentToPassDown,
  paperClass,
}) {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        disableEnforceFocus={true}
        PaperProps={{
          className: `p-3 general-popup-model ${paperClass || ""}`,
        }}
      >
        <div class="cross-icon" onClick={() => setOpen(false)}>
          x
        </div>
        <div className="popup-title">
          <h2 className="text-capitalize">{title}</h2>
        </div>
        <hr />
        <div className="responce-messages">{componentToPassDown}</div>
      </Dialog>
    </>
  );
}
