import { invokeApi } from "../../bl_libs/invokeApi";

export const _list_membership_level = async (page, limit, data) => {
  const requestObj = {
    path: `/api/membership_level/list_membership_level?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _create_membership_level = async (data) => {
  const requestObj = {
    path: `/api/membership_level/create_membership_level`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_membership_level = async (data, id) => {
  const requestObj = {
    path: `/api/membership_level/update_membership_level/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _detail_membership_level = async (id) => {
  const requestObj = {
    path: `/api/membership_level/detail_membership_level/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_membership_level = async (id) => {
  const requestObj = {
    path: `/api/membership_level/delete_membership_level/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
