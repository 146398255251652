import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  TextField,
  Autocomplete,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { programmeListing } from "src/DAL/Programme/Programme";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { useEffect } from "react";
import { consultantSearchListApi } from "src/DAL/booking/bookingApi";
import { sale_page_list_with_plans_api } from "src/DAL/WebsitePages/WebsitePages";
import MUIAutocomplete from "../GeneralComponents/MUIAutocomplete";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function StatisticsFilter({
  filterData,
  handleChange,
  handleIsDateRange,
  handleChangeDates,
  searchSubmitFilter,
  handleClearFilter,
  setSelectedDelegate,
  selectedDelegate,
}) {
  const [programsList, setProgramsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [personName, setPersonName] = useState([]);
  const [serchText, setSerchText] = useState("");
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [pagesList, setPagesList] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const getPagesList = async () => {
    const result = await sale_page_list_with_plans_api();
    if (result.code === 200) {
      let page_list = result.sale_pages.map((page) => {
        let page_title = page.sale_page_title;
        if (page.type == "template") {
          page_title = page.sale_page_title + " (Template)";
        }
        return {
          ...page,
          page_title: page_title,
        };
      });
      if (selectedPage) {
        setPlansList(selectedPage.payment_plans);
        setSelectedPlan(selectedPlan);
      }
      console.log(page_list,"page_listpage_listpage_list")
      setPagesList(page_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSelectPage = (value) => {
    setSelectedPage(value);
    setPlansList(value.payment_plans);
    setSelectedPlan(null);
  };

  const getProgrammesList = async () => {
    let result = await programmeListing();
    if (result.code == 200) {
      setProgramsList(result.program);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };
  const handleSubmit = async (new_value) => {
    if (new_value === "filter") {
      setPage(0);
      setPageCount(0);
      reload("filter");
    }
    onCloseDrawer();
  };
  const filterFunctionRemove = () => {
    setPage(0);
    reload("clear");
    onCloseDrawer();
  };
  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };
  const getSearchGroupsAndMembers = async () => {
    const result = await consultantSearchListApi(serchText);
    if (result.code === 200) {
      setPersonName(result.consultant_list);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const getConsultantsOnsearch = async () => {
    if (serchText.length > 2) {
      const result = await consultantSearchListApi(serchText);
      if (result.code === 200) {
        setPersonName(result.consultant_list);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };
  const goalsLevel = [
    {
      name: "None",
      value: "",
    },
    {
      name: "Complete",
      value: "complete",
    },
    {
      name: "Incomplete",
      value: "incomplete",
    },
    {
      name: "Responded",
      value: "responded",
    },
  ];

  useEffect(() => {
    getProgrammesList();
    getSearchGroupsAndMembers();
  }, []);
  useEffect(() => {
    getConsultantsOnsearch();
  }, [serchText]);
  useEffect(() => {
    getPagesList();
  }, []);

  return (
    <>
      <div className="container-fluid new-memories">
        <div className="col-12 mt-3">
          <MUIAutocomplete
            inputLabel="Sale Page"
            selectedOption={selectedPage}
            setSelectedOption={handleSelectPage}
            optionsList={pagesList}
            autoComplete="new-password"
            name="page_title"
          />
        </div>
        <div className="col-12 mt-3 mb-3">
          <MUIAutocomplete
            inputLabel="Choose Plan"
            selectedOption={selectedPlan}
            setSelectedOption={setSelectedPlan}
            optionsList={plansList}
            autoComplete="new-password"
            name="plan_title"
          />
        </div>

        {/* <FormControl fullWidth className="mt-3">
          <InputLabel id="demo-simple-select-label">Member Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterData.status}
            name="status"
            label="Member Status"
            onChange={handleChange}
          >
            <MenuItem value="">None</MenuItem>;
            <MenuItem value={true}>Active</MenuItem>;
            <MenuItem value={false}>Inactive</MenuItem>;
          </Select>
        </FormControl> */}
        <Autocomplete
          // multiple
          id="tags-outlined"
          options={personName}
          getOptionLabel={(option) =>
            option.first_name +
            " " +
            option.last_name +
            " (" +
            option.email +
            ")"
          }
          filterSelectedOptions
          value={selectedDelegate}
          onChange={(event, newValue) => {
            setSelectedDelegate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Consultant"
              placeholder="Consultant"
              onChange={handleSearch}
            />
          )}
        />
        <FormControlLabel
          className="mt-2"
          control={
            <Checkbox
              defaultChecked
              checked={filterData.is_date_range}
              onChange={handleIsDateRange}
            />
          }
          label="Search By Date Range"
        />
        {filterData.is_date_range && (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                name="from_date"
                value={filterData.from_date}
                format="YYYY-MM-DD"
                inputFormat="dd-MM-yyyy"
                onChange={(e) => handleChangeDates(e, "from_date")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="mt-3 inputs-fields"
                    required={true}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                name="to_date"
                value={filterData.to_date}
                format="YYYY-MM-DD"
                inputFormat="dd-MM-yyyy"
                onChange={(e) => handleChangeDates(e, "to_date")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="mt-3 inputs-fields"
                    required={true}
                  />
                )}
              />
            </LocalizationProvider>
          </>
        )}
        <Box sx={{ py: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={searchSubmitFilter}
            startIcon={
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: 5 }}
              >
                <path
                  d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                  fill={get_root_value("--portal-theme-primary")}
                />
              </svg>
            }
          >
            Filter
          </Button>
        </Box>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          className="mb-3"
          onClick={handleClearFilter}
          startIcon={<Icon icon={roundClearAll} />}
        >
          Clear All
        </Button>
      </div>
    </>
  );
}
