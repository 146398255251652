import * as React from "react";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { _import_subscriber_from_csv } from "src/DAL/WebsitePages/WebsitePages";

export default function ImportCSV({
  setImportExportcsvFile,
  getMemberListing,
}) {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [csvFile, setCsvFile] = useState();

  const fileChangedHandler = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!csvFile) {
      enqueueSnackbar("Please upload csv file", { variant: "error" });
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("type", "subscriber");
    formData.append("csv", csvFile);
    formData.append("plan_id", params.plan_id);

    const result = await _import_subscriber_from_csv(formData);
    if (result.code == 200) {
      setIsLoading(false);
      setImportExportcsvFile(false);
      getMemberListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="col-12">
          <form method="post" action="#" id="#">
            <div className="form-group files">
              <input
                onChange={fileChangedHandler}
                type="file"
                className="form-control"
                multiple
                accept=".csv"
              />
            </div>
          </form>
        </div>
      </div>
      <div className="ms-auto text-end">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </>
  );
}
