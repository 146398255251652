import { invokeApi } from "../../bl_libs/invokeApi";

export const GroupAutomatedListingApi = async (data) => {
  const requestObj = {
    path: `/api/group/active_automated_group_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const GroupListingApi = async (data) => {
  const requestObj = {
    path: `/api/group`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _group_filter_listing = async (page, limit, data) => {
  const requestObj = {
    path: `/api/group/group_filter?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const activeGroupListingApi = async (data) => {
  const requestObj = {
    path: `/api/group/active_group_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddGroupApi = async (data) => {
  const requestObj = {
    // path: `/api/group`,
    path: `/api/group/add_group_v1`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditGroupApi = async (data, slug) => {
  const requestObj = {
    // path: `/api/group/${slug}`,
    path: `/api/group/update_group_v1/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteGroupApi = async (slug) => {
  const requestObj = {
    path: `/api/group/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const group_detail_api = async (slug) => {
  const requestObj = {
    path: `/api/group/detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
