import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useSnackbar } from "notistack";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  _auto_responded_message,
  delete_auto_responded_message,
} from "src/DAL/AutoRespondedMessage/AutoRespondedMessage";
import { dd_date_format } from "src/utils/constant";
import { LeadStatusListing } from "src/DAL/member/member";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function LeadStatusHistory({ leadStatusData }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [first, setfirst] = useState("");
  const [query, setQuery] = useState("");
  const [deleteDoc, setDeleteDoc] = useState("");
  const [changeStatus, setChangeStatus] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [messagesData, setMessagesData] = useState([]);
  const [leadStatusHistory, setleadStatusHistory] = useState([]);
  const [selectedMember, setSelectedMember] = useState({});
  const member = window.location.pathname.includes("/members");
  const allmember = window.location.pathname.includes("/all-members");
  const Nurture = window.location.pathname.includes("/nurture-members");
  let pageName = "";
  if (member) {
    pageName = "Member";
  } else if (allmember) {
    pageName = "all Member";
  } else if (Nurture) {
    pageName = "Nurture Member";
  }
  const change_lead_status = (value) => {
    setSelectedMember(value);
    setChangeStatus(true);
  };

  const handleOpenLeadStatus = () => {
    setChangeStatus(true);
  };
  function removeHyphensAndReplaceWithSpace(inputString) {
    // Use the replace() method with a regular expression to remove hyphens and replace them with spaces
    var modifiedString = inputString.replace(/_/g, " ");
    return modifiedString;
  }
  const handleCloseLeadStatus = () => {
    setChangeStatus(false);
  };
  const getFilteredData = (query) => {
    let dataToFilter = [...leadStatusHistory];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.lead_status.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };
  const LeadStatusList = async () => {
    setIsLoading(true);
    const result = await LeadStatusListing(leadStatusData._id);
    if (result.code == 200) {
      setfirst(
        result.member.first_name +
          " " +
          result.member.last_name +
          " (" +
          result.member?.email +
          ") "
      );
      handleFormat(result.member.lead_status_history);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    setOpenDelete(false);
    let postData = {
      member_id: leadStatusData._id,
      lead_status: deleteDoc.lead_status._id,
      id: deleteDoc._id,
    };
    const result = await DeleteLeadStatusHistory(postData);
    if (result.code === 200) {
      LeadStatusList();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    navigate(`/auto-responded-message/edit-message/${value._id}`, {
      state: value,
    });
  };

  const handleNavigate = () => {
    navigate(`/auto-responded-message/add-message`);
  };
  const handleFormat = (data) => {
    setleadStatusHistory(data);
    setIsLoading(false);
  };
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
  };
  useEffect(() => {
    LeadStatusList();
  }, []);

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "lead_status",
      label: "Lead Status",
      renderData: (row) => {
        let lead_status = row.lead_status;
        if (lead_status) {
          return (
            <div className="position-relative">
              <Chip
                label={lead_status.title}
                style={{
                  backgroundColor: lead_status.background_color,
                  color: lead_status.text_color,
                  height: "25px",
                }}
              />
            </div>
          );
        }
      },
    },
    {
      id: "income_value",
      label: "Income Value",
      renderData: (row) => {
        return (
          <div>
            <p className="mb-0">£{row.income_value}</p>
          </div>
        );
      },
    },
    {
      id: "action_info",
      label: "Action Info",
      renderData: (row) => {
        let action_info = row.action_info;
        return (
          <div>
            {action_info.name ? (
              <p className="mb-0">
                {row.action_info.name +
                  " " +
                  "(" +
                  removeHyphensAndReplaceWithSpace(
                    row.action_info.action_user_type
                  ) +
                  ")"}
              </p>
            ) : (
              <p className="mb-0">N/A</p>
            )}
          </div>
        );
      },
    },
    {
      id: "date",
      label: "Date",
      renderData: (row) => {
        return (
          <div>
            <p className="mb-0">{dd_date_format(row.changed_date_time)}</p>
          </div>
        );
      },
    },
  ];
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: change_lead_status,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  let breadCrumbMenu = [
    {
      title: pageName,
      navigation: -1,
      active: false,
    },

    {
      title: "Lead Status History",
      navigation: ``,
      active: true,
    },
  ];
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div style={{ zIndex: "0" }}>
      <div className="container-fluid" style={{ zIndex: "0" }}>
        <div className="row mt-4">
          <div className="col-12 d-flex">
            {/* <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} /> */}
            {/* <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton> */}
            <div className="mb-3 set-heading">
              <h4 style={{ color: "#e4c073" }}>{first ? first : " "}</h4>
            </div>
          </div>
          <div className="row mb-3 justify-content-between">
            <div className="col-lg-6 col-sm-12 mt-3">
              <h2 style={{ color: "white" }}>Lead Status History</h2>
            </div>
            <div className="col-lg-4 col-sm-12 text-end">
              <FormControl variant="outlined" style={{ marginTop: "2px" }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  variant="small"
                  onChange={handleChangeSearch}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 mt-3">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={getFilteredData(query)}
              className="card-with-background mt-3"
              is_hide={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
