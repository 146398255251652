import { InputAdornment, TextField } from "@mui/material";
import React, { useRef } from "react";
import EmojiPickerPopup from "src/components/EmojiPicker/EmojiPickerPopup";

export default function TextFieldRef({
  x,
  handleInputChange,
  inputList,
  setInputList,
  i,
}) {
  const inputRef = useRef(null);
  return (
    <TextField
      label="Enter an answer choice"
      variant="outlined"
      fullWidth
      value={x.option}
      name="option"
      onChange={(e) => handleInputChange(e, i)}
      inputRef={inputRef}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <EmojiPickerPopup
              setInput={(newValue) => {
                const list = [...inputList];
                list[i].option = newValue;
                setInputList(list);
              }}
              inputRef={inputRef}
            />
          </InputAdornment>
        ),
      }}
    />
  );
}
