import React, { useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import { dd_date_format, show_proper_words } from "src/utils/constant";
import { s3baseUrl } from "src/config/config";
import { Avatar } from "@mui/material";

const ManageReward = ({ totalBadges }) => {
  const [openManageRewards, setOpenManageRewards] = useState(false);
  const [badgesData, setBadgesData] = useState([]);

  console.log("totalBadges  ___totalBadges", totalBadges);

  let TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "type",
      label: "History Type",
      renderData: (row) => {
        return <div>{row.type ? show_proper_words(row?.type) : "N/A"}</div>;
      },
    },
    {
      id: "action_info",
      label: "Action Info",
      renderData: (row) => {
        let action_info = row?.action_info?.name ?? "N/A";
        if (row?.type == "mission_completed") {
          action_info = row?.module_info?.title ?? "N/A";
        }

        return (
          <div>
            {action_info}
            {row?.type && ` (${show_proper_words(row?.type)})`}

            {row?.history_type === "remove" && (
              <>
                <span className="history_delete_color"> - Deleted </span>
              </>
            )}
          </div>
        );
      },
    },
    {
      id: "selected_badges",
      label: "Rewards Assigned",
      renderData: (row) => {
        return (
          <div className="d-flex">
            <p
              className="mb-1 mt-1 ms-4 view-more"
              aria-describedby={"id"}
              variant="contained"
              onClick={() => openManageRewardsPopup(row)}
            >
              View Rewards
            </p>
          </div>
        );
      },
    },

    {
      id: "created_at",
      label: "Reward Date ",
      renderData: (row) => {
        return (
          <div>{row?.createdAt ? dd_date_format(row?.createdAt) : "N/A"}</div>
        );
      },
    },
  ];

  const openManageRewardsPopup = (value) => {
    setOpenManageRewards(true);
    setBadgesData(value);
  };

  return (
    <>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={totalBadges}
        className="card-with-background"
        hide_search={true}
      />

      <WhatsappPopUpModel
        open={openManageRewards}
        setOpen={setOpenManageRewards}
        title={"Rewards"}
        className="rewards_popup_view"
        titleClassName="reward-title"
        componentToPassDown={
          <>
            <div className="d-flex flex-column color_white container">
              {badgesData &&
              badgesData?.badges &&
              badgesData?.badges.length > 0 ? (
                badgesData?.badges.map((badge, index) => (
                  <div
                    key={index}
                    className={`d-flex justify-content-between align-items-center ${
                      badgesData?.history_type == "remove"
                        ? "badge-card-remove"
                        : "badge-card"
                    }  ${index == 0 ? "mt-2" : "mt-3"}`}
                  >
                    <div className="d-flex align-items-center">
                      <Avatar
                        src={s3baseUrl + badge?.after_earned_icon?.thumbnail_2}
                        alt=""
                        className="me-2 badge-image"
                      />
                      <span className="badge-title">{badge.title}</span>
                    </div>
                    <span className="badges-count">{badge?.no_of_badges}x</span>
                  </div>
                ))
              ) : (
                <p className="mt-3 text-center color_white">Data Not Found!</p>
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default ManageReward;
