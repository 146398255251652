import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { memberAndGroupsListApi } from "../../DAL/member/member";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress, Autocomplete } from "@mui/material";
import { activeGroupListingApi } from "src/DAL/group/group";
import { useEffect } from "react";
import {
  AddChallengeApi,
  challengesDetailApi,
  EditChallengeApi,
} from "src/DAL/challenges/challenges";
import { s3baseUrl } from "src/config/config";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";

import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import ConfirmationWithHtml from "src/components/ModalPopover/ConfirmationWithHtml";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddChallenge() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { state, search } = useLocation();
  const params = useParams();
  const [personName, setPersonName] = useState([]);
  const [member, setMember] = useState([]);
  const [selectedExclude, setSelectedExclude] = useState([]);
  const [excludeMembers, setExcludeMembers] = useState([]);
  const [audioFile, setAudioFile] = useState({});
  const [oldData, setOldData] = useState({});
  const [uploadAudio, setUploadAudio] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [groupListing, setGroupListing] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [image, setImage] = useState({});
  const [oldImage, setOldImage] = useState();
  const [serchText, setSerchText] = useState("");
  const [excludeText, setExcludeText] = useState("");
  const [searchType, setSearchType] = useState("");
  const [oldAudio, setOldAudio] = useState("");
  const [updateFor, setUpdateFor] = useState("for_incomplete_users");
  const [badgeLevels, setBadgeLevels] = useState([]);
  const [selectedBadgeLevels, setSelectedBadgeLevels] = useState([]);
  const type = new URLSearchParams(search).get("type");

  const [inputs, setInputs] = useState({
    title: "",
    status: "true",
    image: {},
    detailed_description: "",
    number_of_days: "",
    button_text: "",
    image_statement: "",
    challange_intro_video_url: "",
    challange_file_type: "video",
    challange_intro_video_description: "",
    challenge_completion_message: "",
    challange_video_url: "",
    short_description: "",
    challange_type: type,
    daily_challange_reward_coins: "",
    completed_challange_reward_coins: "",
    order: "",
    challenge_enable_coins_count: "",
    intentions_heading: "",
    is_show_intentions: false,
  });

  const fileChangedHandler = (e) => {
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const gethallengeInfo = async () => {
    setIsLoading(true);
    const result = await activeGroupListingApi();

    if (result.code === 200) {
      const result = await challengesDetailApi(params.challenge_id);
      if (result.code == 200) {
        setSelectedBadgeLevels(result?.challenge?.badge_levels ?? []);
        setInputs(result?.challenge);
        setOldData(result?.challenge);
        setOldImage(result?.challenge?.challange_image?.thumbnail_1);
        setOldAudio(result?.challenge?.challange_audio_url);
        let groups_slug = result?.challenge_groups;
        let selected_group = [];
        groups_slug.map((group) => {
          selected_group.push(group);
        });
        let member_id = result?.challenge_members;
        let member_object = [];
        member_id?.map((member) => {
          let memberSelected = result.members?.filter(
            (value) => value._id == member._id
          )[0];

          member_object.push(memberSelected);
        });

        let exclude_members_object = [];
        result?.challenge.exclude_members?.map((member) => {
          exclude_members_object.push(member._id);
        });
        setMember(result?.challenge_members);
        setSelectedExclude(exclude_members_object);
        setGroupsName(selected_group);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };
  const audioFileChange = (e) => {
    setAudioFile(e.target.files[0]);
    setUploadAudio(true);
  };

  const getGroupsAndMembers = async () => {
    const result = await memberAndGroupsListApi();
    if (result.code === 200) {
      setBadgeLevels(result?.badge_levels ?? []);
      setGroupListing(result.group);
      setPersonName(result.members);
      setExcludeMembers(result.members);

      let member_id = state?.member;
      let member_object = [];
      member_id?.map((member) => {
        let memberSelected = result.members?.filter(
          (value) => value._id == member._id
        )[0];
        member_object.push(memberSelected);
      });
      setMember(member_object);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChangeGroup = (value) => {
    setGroupsName(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!params.challenge_id) {
      handleSubmitFinal();
      return;
    }
    if (+inputs.number_of_days !== +oldData.number_of_days) {
      setOpenConfirmation(true);
    } else {
      handleSubmitFinal();
    }
  };

  const handleSubmitFinal = async () => {
    setOpenConfirmation(false);
    setIsLoading(true);

    if (selectedBadgeLevels?.length == 0) {
      enqueueSnackbar("Badge Levels is required!", { variant: "error" });
      setIsLoading(false);
      return;
    }

    let group_array = [];
    if (groupsName.length > 0) {
      group_array = groupsName.map((group) => {
        return {
          _id: group._id,
        };
      });
    }

    let selected_member_array = [];
    let selected_member_object = {};
    member.map((member) => {
      selected_member_object = {
        _id: member._id,
      };
      selected_member_array.push(selected_member_object);
    });

    let exclude_member_array = [];
    let exclude_member_object = {};
    selectedExclude?.map((member) => {
      exclude_member_object = {
        _id: member._id,
      };
      exclude_member_array.push(exclude_member_object);
    });

    let badge_levels = selectedBadgeLevels.map((level) => ({
      _id: level?._id,
      title: level?.title,
    }));

    const formData = new FormData();
    formData.append("title", inputs.title);

    if (image.length > 0) {
      formData.append("image", inputs.image);
    }
    formData.append("status", inputs.status);
    formData.append("intentions_heading", inputs.intentions_heading);
    formData.append("is_show_intentions", Boolean(inputs.is_show_intentions));
    formData.append(
      "challenge_enable_coins_count",
      inputs.challenge_enable_coins_count
    );
    formData.append("member", JSON.stringify(selected_member_array));
    formData.append("exclude_members", JSON.stringify(exclude_member_array));
    formData.append("group", JSON.stringify(group_array));

    formData.append(
      "challange_intro_video_url",
      inputs.challange_intro_video_url
    );
    if (params.challenge_id) {
      formData.append("order", inputs.order);
      if (+inputs.number_of_days !== +oldData.number_of_days) {
        formData.append("update_number_of_days_for_all", updateFor);
      }
    }
    formData.append("number_of_days", inputs.number_of_days);
    formData.append(
      "challange_intro_video_description",
      inputs.challange_intro_video_description
    );
    formData.append(
      "challenge_completion_message",
      inputs.challenge_completion_message
    );
    formData.append("challange_type", inputs.challange_type);
    formData.append("challange_file_type", inputs.challange_file_type);
    if (inputs.challange_file_type == "audio") {
      if (uploadAudio) {
        formData.append("audio", audioFile);
      }
    } else {
      formData.append("challange_video_url", inputs.challange_video_url);
    }
    formData.append("short_description", inputs.short_description);
    formData.append(
      "daily_challange_reward_coins",
      inputs.daily_challange_reward_coins
    );
    formData.append(
      "completed_challange_reward_coins",
      inputs.completed_challange_reward_coins
    );
    formData.append("badge_levels", JSON.stringify(badge_levels));

    const result = params.challenge_id
      ? await EditChallengeApi(formData, params.challenge_id)
      : await AddChallengeApi(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/challenge?type=${inputs.challange_type}`, {
        state: state,
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const getSearchGroupsAndMembers = async () => {
    const result = await memberAndGroupsListApi(serchText);
    if (result.code === 200) {
      setPersonName(result.members);
    }
  };

  const getSearchExcludeAndMembers = async () => {
    const result = await memberAndGroupsListApi(excludeText);
    if (result.code === 200) {
      setExcludeMembers(result.members);
    }
  };

  const handleExcludeSearch = (event, type) => {
    event.preventDefault();
    const value = event.target.value;
    if (type == "exclude") {
      setSearchType("exclude");
      setExcludeText(value);
    } else {
      setSearchType("member");
      setSerchText(value);
    }
  };

  useEffect(() => {
    if (searchType == "member") {
      getSearchGroupsAndMembers();
    }
  }, [serchText]);

  useEffect(() => {
    if (searchType == "exclude") {
      getSearchExcludeAndMembers();
    }
  }, [excludeText]);

  useEffect(() => {
    setSerchText("");
  }, [member]);

  useEffect(() => {
    setExcludeText("");
  }, [selectedExclude]);

  useEffect(() => {
    getGroupsAndMembers();
  }, []);

  useEffect(() => {
    if (params?.challenge_id) {
      setIsLoading(true);
      gethallengeInfo();
    }
  }, []);

  let finalArray = [];
  for (let i = 0; i < personName.length; i++) {
    let count = 0;
    for (let j = 0; j < member?.length; j++) {
      if (personName[i]?._id == member[j]?._id) {
        count++;
      }
    }
    if (count == 0) {
      finalArray.push(personName[i]);
    }
  }

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const RADIO_ACTIONS = [
    {
      label: "For new and old users they haven't completed the challenge",
      value: "for_incomplete_users",
    },
    {
      label: "For all users even they have completed the challenge",
      value: "for_all_users",
    },
  ];

  return (
    <>
      <ConfirmationWithHtml
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        componentToPassDown={
          <div className="confirmation-popup-title">
            <h2>Where do want to apply change in number of days</h2>
            <div className="mt-3">
              {RADIO_ACTIONS.map((radio) => {
                return (
                  <div
                    className="d-flex cursor-pointer mt-2"
                    onClick={() => {
                      setUpdateFor(radio.value);
                    }}
                  >
                    <div className="custome-radio-box me-2">
                      {radio.value === updateFor && (
                        <div className="selected"></div>
                      )}
                    </div>
                    <div className="custome-radio-label">{radio.label}</div>
                  </div>
                );
              })}
              <div className="text-end">
                <button
                  onClick={handleSubmitFinal}
                  className="small-contained-button mt-3 py-1 px-4"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        }
      />
      <div className="container-fluid">
        <div className="row mobile-margin display-flex">
          <div className="col-12 d-flex">
            <span>
              <IconButton
                className="back-screen-button mb-2"
                onClick={() =>
                  navigate(`/challenge?type=${type}`, {
                    state: state,
                  })
                }
              >
                <ArrowBackIcon />
              </IconButton>
            </span>
            <h2>{params?.challenge_id ? "Edit Challenge" : "Add Challenge"}</h2>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                fullWidth
                required
                name="title"
                value={inputs.title}
                onChange={handleChange}
              />
            </div>

            {params?.challenge_id && (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Order"
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                  name="order"
                  value={inputs.order}
                  onChange={handleChange}
                />
              </div>
            )}

            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  label="Status"
                  onChange={handleChange}
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Challenge Enable Coins Count"
                variant="outlined"
                fullWidth
                required
                name="challenge_enable_coins_count"
                value={inputs.challenge_enable_coins_count}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Challenge Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="challange_type"
                  value={inputs.challange_type}
                  label="Challenge Type"
                  onChange={handleChange}
                >
                  <MenuItem value="visioneering">Visioneering</MenuItem>
                  <MenuItem value="mediation">Meditation</MenuItem>
                  <MenuItem value="lifeScript">Script Writing</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Number Of Challenge Days"
                variant="outlined"
                fullWidth
                type="number"
                required
                name="number_of_days"
                value={inputs.number_of_days}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Daily Challange Reward Coins"
                variant="outlined"
                fullWidth
                type="number"
                required
                name="daily_challange_reward_coins"
                value={inputs.daily_challange_reward_coins}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Completed Challange Reward Coins"
                variant="outlined"
                fullWidth
                type="number"
                required
                name="completed_challange_reward_coins"
                value={inputs.completed_challange_reward_coins}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Challange Intro Video Url"
                variant="outlined"
                fullWidth
                type="url"
                name="challange_intro_video_url"
                value={inputs.challange_intro_video_url}
                onChange={handleChange}
              />
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <MUIAutocomplete
                inputLabel="Badge Levels *"
                selectedOption={selectedBadgeLevels}
                setSelectedOption={setSelectedBadgeLevels}
                optionsList={badgeLevels}
                limitTags={2}
                multiple
                name="title"
              />
            </div>

            {inputs.challange_type == "lifeScript" && (
              <>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label">
                      Is Show Intentions
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="is_show_intentions"
                      value={inputs.is_show_intentions ?? false}
                      label="Is Show Intentions"
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {inputs.is_show_intentions && (
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Intentions Heading"
                      variant="outlined"
                      fullWidth
                      type="text"
                      required
                      name="intentions_heading"
                      value={inputs.intentions_heading}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </>
            )}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Challenge File Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="challange_file_type"
                  value={inputs.challange_file_type}
                  label=" Challenge File Type"
                  onChange={handleChange}
                >
                  <MenuItem value="video">Video</MenuItem>
                  <MenuItem value="audio">Audio</MenuItem>
                </Select>
              </FormControl>
            </div>

            {inputs.challange_file_type == "video" ? (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Challange Video Url"
                  variant="outlined"
                  fullWidth
                  required
                  type="url"
                  name="challange_video_url"
                  value={inputs.challange_video_url}
                  onChange={handleChange}
                />
              </div>
            ) : (
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div className="row w-100 div-style ms-0 pt-0 ">
                  <div className="col-lg-8 col-md-3 col-sm-12">
                    <p className="">Upload Audio * </p>
                    <FormHelperText className="pt-0">
                      Audio mp3 (max 100mb)
                    </FormHelperText>
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12 text-end pt-2 ps-0">
                    <label htmlFor="audio">
                      <Input
                        accept="audio/mp3,audio/*;capture=microphone"
                        id="audio"
                        multiple
                        name="audio"
                        type="file"
                        onChange={audioFileChange}
                      />
                      <Button
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
                <div className="col-12 mt-1">
                  {oldAudio && (
                    <audio
                      className="w-100"
                      src={s3baseUrl + oldAudio}
                      controls
                    />
                  )}
                </div>
                <p className="text-secondary">{audioFile && audioFile.name}</p>
              </div>
            )}

            <>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <MUIAutocomplete
                  inputLabel="Groups"
                  selectedOption={groupsName}
                  setSelectedOption={handleChangeGroup}
                  optionsList={groupListing}
                  multiple
                  name="title"
                />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={finalArray}
                  getOptionLabel={(option) =>
                    option?.first_name + " (" + option?.email + ")"
                  }
                  filterSelectedOptions
                  value={member}
                  onChange={(event, newValue) => {
                    setMember(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Members"
                      placeholder="Members"
                      onChange={(e) => {
                        handleExcludeSearch(e, "member");
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={excludeMembers}
                  getOptionLabel={(option) =>
                    option.first_name + " (" + option.email + ")"
                  }
                  filterSelectedOptions
                  value={selectedExclude}
                  onChange={(event, newValue) => {
                    setSelectedExclude(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Exclude Members"
                      placeholder="Exclude Members"
                      onChange={(e) => {
                        handleExcludeSearch(e, "exclude");
                      }}
                    />
                  )}
                />
              </div>
            </>
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <div className="row w-100 div-style ms-0 pt-0">
                <div className="col-5">
                  <p className="">Upload Challenge Image *</p>
                  <FormHelperText className="pt-0">
                    Image Size(350 X 100) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText>
                </div>
                <div className="col-2">
                  {image.length > 0 ? (
                    <img src={image} height="50" />
                  ) : (
                    oldImage && <img src={s3baseUrl + oldImage} height="50" />
                  )}
                </div>
                <div className="col-5 text-end pt-2">
                  <label htmlFor="contained-button-file-logo">
                    <Input
                      accept="image/*"
                      id="contained-button-file-logo"
                      multiple
                      type="file"
                      name="logo"
                      hidden
                      onChange={fileChangedHandler}
                    />

                    <Button
                      className="small-contained-button"
                      startIcon={<FileUploadIcon />}
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </div>
              {inputs?.image?.name == "" ? (
                ""
              ) : (
                <p className="text-secondary">{inputs?.image?.name}</p>
              )}
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Short Description"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 mt-4">
              <h4>Challange Intro Video Description </h4>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                name="challange_intro_video_description"
                editorHeight={320}
              />
            </div>
            <div className="col-12 mt-4">
              <h4>Challenge Completion Message</h4>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                name="challenge_completion_message"
                editorHeight={320}
              />
            </div>
            <div className="text-end mt-4">
              <button className="small-contained-button">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
