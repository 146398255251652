import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import Label from "src/components/Label";
import { self_image_responded_users } from "src/DAL/goalstatement/goalStatement";
import { dd_date_format } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const EMPTY_FILTER = {
  page: 0,
  rowsPerPage: 50,
  searchText: "",
};

export default function SelfImageResponded() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [membersList, setMembersList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);

  const getMembersList = async (postData) => {
    setIsLoading(true);

    const result = await self_image_responded_users(
      postData.page,
      postData.rowsPerPage,
      postData.searchText
    );

    if (result.code === 200) {
      const data = result.history.map((history) => {
        const member = history.user_info;
        return {
          ...history,
          name: member.first_name + " " + member.last_name,
          completed_on: dd_date_format(history.completed_date),
          email: member.email,
          table_avatar: {
            src: s3baseUrl + member.profile_image,
            alt: member.first_name,
          },
        };
      });
      setMembersList(data);
      setTotalCount(result.total_count);
      setTotalPages(result.total_page);
      localStorage.setItem("responded_filter_data", JSON.stringify(postData));
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
    let filter_data = { ...filterData };
    filter_data.page = newPage;
    setFilterData(filter_data);
    getMembersList(filter_data);
  };

  const handleChangeRowsPerPage = (event) => {
    let rowsPerPage = parseInt(event.target.value, 10);
    setPageCount(1);
    let filter_data = { ...filterData };
    filter_data.rowsPerPage = rowsPerPage;
    setFilterData(filter_data);
    getMembersList(filter_data);
  };

  const handleChangePages = (event, newPage) => {
    let page_number = 0;
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      page_number = newPage - 1;
      setPageCount(newPage);
    }
    let filter_data = { ...filterData };
    filter_data.page = page_number;
    setFilterData(filter_data);
    getMembersList(filter_data);
  };

  const handleDetail = (value) => {
    navigate(
      `${window.location.pathname}/answers-detail?created_for_id=${
        value._id
      }&member_id=${
        value.user_info._id
      }&created_for=self_image&check_user=${true}`,
      {
        state: value,
      }
    );
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    {
      id: "number",
      label: "#",
      type: "number",
      className: "cursor-pointer",
    },
    {
      id: "name",
      label: "Name",
      handleClick: handleDetail,
      className: "cursor-pointer",
    },
    {
      id: "email",
      label: "Email",
      handleClick: handleDetail,
      className: "cursor-pointer",
    },
    { id: "table_avatar", label: "Profile Image", type: "thumbnail" },
    {
      id: "status",
      label: "Status",
      renderData: () => (
        <Label variant="ghost" color={"success"}>
          Completed
        </Label>
      ),
    },
    { id: "completed_on", label: "Completed Date" },
  ];

  const MENU_OPTIONS = [
    {
      label: "Detail",
      icon: "akar-icons:edit",
      handleClick: handleDetail,
    },
  ];

  const searchFunction = () => {
    setFilterData((old) => ({ ...old, page: 0 }));
    setPageCount(1);
    getMembersList(filterData);
  };

  const changeSearch = (value) => {
    setFilterData((old) => ({ ...old, searchText: value }));
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("responded_filter_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      setFilterData(filter_data);
    }
    getMembersList(filter_data);
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mt-4">
          <div className="col-12">
            <h2>Self Image Responded Members</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-4">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={membersList}
              selected={selected}
              MENU_OPTIONS={MENU_OPTIONS}
              setSelected={setSelected}
              className="card-with-background"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: filterData.rowsPerPage,
                page: filterData.page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: filterData.searchText,
                setSearchText: changeSearch,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
              pagePagination={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
