import React, { useEffect, useState } from "react";
import { List, Collapse, ListItemText, ListItemButton } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  matchPath,
  useLocation,
  NavLink as RouterLink,
} from "react-router-dom";
import { s3baseUrl } from "src/config/config";

export default function NavSectionItem({ data, searchInput }) {
  const { pathname } = useLocation();
  const [openDropdown, setOpenDropdown] = useState(false);

  const match = ({ path, matches }) => {
    if (matches) {
      let is_active = false;
      matches.forEach((match_path) => {
        const match = match_path
          ? !!matchPath({ path: match_path, end: false }, pathname)
          : false;

        if (match) {
          is_active = true;
        }
      });
      return is_active;
    }
    return path ? !!matchPath({ path, end: false }, pathname) : false;
  };

  const handleClickDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handle_open_link = (data) => {
    if (data.is_open_new_tab) {
      window.open(data.path);
    } else {
      window.open(data.path, "_self");
    }
  };

  useEffect(() => {
    //On reload dropdown should not close
    data.child_options?.map((child_option) => {
      if (child_option.matches) {
        child_option.matches.map((match) => {
          const is_match_path = window.location.pathname.includes(match);
          if (is_match_path || searchInput) {
            setOpenDropdown(true);
          }
        });
      }
      const is_path = window.location.pathname.includes(child_option.path);
      if (is_path || searchInput) {
        setOpenDropdown(true);
      }
    });
  }, [searchInput]);

  return (
    <>
      <ListItemButton
        component={data.is_link ? "" : data.path ? RouterLink : "span"}
        target={data.is_open_new_tab ? "_blank" : undefined}
        to={data.is_link ? "" : data.path ? data.path : ""}
        sx={{ pl: 5, fontSize: "14px" }}
        onClick={() => {
          if (data.is_link) {
            handle_open_link(data);
          } else {
            handleClickDropdown();
          }
        }}
        className={
          match({ path: data.path, matches: data.matches })
            ? "menuActive menus-list"
            : "menus-list"
        }
      >
        {data.icon && (
          <img
            src={s3baseUrl + data.icon}
            alt="icon"
            style={{ width: "20px", MaxHeight: "30px", marginRight: "10px" }}
          />
        )}

        <ListItemText
          primaryTypographyProps={{ fontSize: "14px" }}
          primary={data.title}
        />

        {/* This will only work in case of dropdown*/}
        {data.child_options && (openDropdown ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>

      {/* This will only work in case of dropdown*/}
      {data.child_options && (
        <Collapse in={openDropdown} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {data.child_options.map((child_option, index) => {
              return (
                <ListItemButton
                  component={
                    child_option.is_link
                      ? ""
                      : child_option.path
                      ? RouterLink
                      : "span"
                  }
                  target={child_option.is_open_new_tab ? "_blank" : undefined}
                  to={child_option.is_link ? "" : child_option.path}
                  onClick={() => {
                    if (child_option.is_link) {
                      handle_open_link(child_option);
                    }
                  }}
                  key={index}
                  sx={{
                    pl: 8,
                    color: "black",
                    fontSize: "10px",
                  }}
                  className={
                    match({
                      path: child_option.path,
                      matches: child_option.matches,
                    })
                      ? "menuActive menus-list child-menu"
                      : "menus-list child-menu"
                  }
                >
                  {data.icon && (
                    <img
                      src={s3baseUrl + data.icon}
                      alt="icon"
                      style={{
                        width: "20px",
                        MaxHeight: "30px",
                        marginRight: "10px",
                      }}
                    />
                  )}
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "14px" }}
                    primary={child_option.title}
                  />
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      )}
    </>
  );
}
