import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import { s3baseUrl } from "src/config/config";
import ReactVideoPlayer from "../../components/ReactVideoPlayer/ReactVideoPlayer";
import { deleteProgramme, programmeDetail } from "src/DAL/Programme/Programme";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function MultiLagnuageContent() {
  const params = useParams();
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [programmeDetailInfo, setProgrammeDetailInfo] = useState();
  const [languageDetail, setLanguageDetail] = useState();
  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },

    {
      title: languageDetail?.title,
      navigation: ``,
      active: true,
    },
  ];
  if (programmeDetailInfo?.title) {
    localStorage.setItem("program_title", programmeDetailInfo?.title);
    localStorage.setItem("program_slug", programmeDetailInfo?.program_slug);
  }
  const handleDetail = async () => {
    setIsLoading(true);
    let result = await programmeDetail(params.programme_slug);
    if (result.code === 200) {
      const program_detail = result.program;
      const language_detail =
        program_detail.multi_language_content[params.language];
      console.log(language_detail, "language_detail");
      setLanguageDetail(language_detail);
      setProgrammeDetailInfo(result.program);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    handleDetail();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          {languageDetail && (
            <span className="">
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          )}
        </div>
      </div>
      <div className="row section-space">
        <div className="col-12 d-flex justify-content-between">
          <h2>{languageDetail?.title}</h2>
        </div>
      </div>

      <div className="row media-margin">
        <div className="col-12">
          {programmeDetailInfo?.video_url == "" ? (
            <img
              src={
                programmeDetailInfo?.program_images
                  ? s3baseUrl + programmeDetailInfo.program_images.thumbnail_1
                  : ""
              }
            />
          ) : (
            <ReactVideoPlayer url={programmeDetailInfo?.video_url} />
          )}
        </div>
        <div className="col-12 section-space">
          {programmeDetailInfo?.audio_file == "" ? (
            ""
          ) : (
            <audio
              className="w-100"
              src={
                programmeDetailInfo?.audio_file
                  ? s3baseUrl + programmeDetailInfo?.audio_file
                  : ""
              }
              controls
            />
          )}
        </div>

        <div className="col-12 section-space img_width_100">
          {languageDetail?.short_description && (
            <p>{languageDetail?.short_description}</p>
          )}
          <p>
            <div
              dangerouslySetInnerHTML={{
                __html: languageDetail?.detailed_description,
              }}
            ></div>
          </p>
        </div>
      </div>
    </div>
  );
}

export default MultiLagnuageContent;
