import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  road_Delete_Api,
  _bonus_listApi,
} from "src/DAL/BonusRoadMAp/BonusRoadMApApi";
import { s3baseUrl } from "src/config/config";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { COMMUNITY_LEVELS } from "src/utils/constant";

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },
  {
    id: "icon",
    label: "Icon",
    alignRight: false,
    type: "thumbnail",
  },
  {
    id: "table_avatar",
    label: "Reached Status",
    alignRight: false,
    type: "thumbnail",
  },
  {
    id: "without_border_icon",
    label: "Before Status",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "coins", label: "Coins", alignRight: false },
  { id: "order", label: "order", alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const LevelParamid = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [levelsList, setLevelsList] = useState([
    {
      _id: "64db3c087313ab2ab1d4ce38",
      title: "Dynamite",
      coins: "10000",
      status: true,
      order: 1,
      images: {
        thumbnail_1: "bonus_road_map/33636a66-9440-42f4-8bf6-e7aaf5f4743e.jpeg",
      },
      without_border_icon: {
        src: "https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/bonus_road_map/3024db36-0474-4b6b-bfcf-f6a777696a76.jpeg",
        alt: "Level No.2 - PTA",
      },
      icon: {
        src: "https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/badge/eece5262-0cd9-46a4-8861-da0285c3a1ef.jpg",
        alt: "Level No.2 - PTA",
      },
      icon_title: "PTA Level ",
      notes_for_admin: "",
      community_level: "dynamite",
      createdAt: "2023-08-15T08:49:12.501Z",
      updatedAt: "2023-08-23T13:36:13.657Z",
      table_avatar: {
        src: "https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/bonus_road_map/33636a66-9440-42f4-8bf6-e7aaf5f4743e.jpeg",
        alt: "Level No.2 - PTA",
      },
    },
    {
      _id: "64db3c827313ab2ab1d4cfd5",
      title: "Rocket",
      coins: "50000",
      status: true,
      order: 2,
      images: {
        thumbnail_1: "bonus_road_map/ced8e47d-617d-4f61-a294-5d1e2f485f53.jpeg",
      },
      without_border_icon: {
        src: "https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/bonus_road_map/4ab1bde6-bf8a-451b-b79d-f2d048a2824a.jpeg",
        alt: "Ignite Mentorship",
      },
      icon_title: "Ignite Mentorship ",
      notes_for_admin: "",
      community_level: "dynamite",
      createdAt: "2023-08-15T08:51:14.526Z",
      updatedAt: "2023-08-23T13:36:13.657Z",
      icon: {
        src: "https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/badge/42462ca6-ce55-415f-b551-eed3f011ea52.png",
        alt: "Level No.2 - PTA",
      },
      table_avatar: {
        src: "https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/bonus_road_map/ced8e47d-617d-4f61-a294-5d1e2f485f53.jpeg",
        alt: "Ignite Mentorship",
      },
    },
    {
      _id: "64db48b8aacb5c311db5fc30",
      title: "Star",
      coins: "75000",
      status: true,
      order: 3,
      images: {
        thumbnail_1: "bonus_road_map/c117dd57-2b01-4b72-94c2-b7a2031ec6c5.jpeg",
      },
      without_border_icon: {
        src: "https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/bonus_road_map/d71d6696-a76b-49af-ae03-4e5498071898.jpeg",
        alt: "Mastery",
      },
      icon: {
        src: "https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/badge/4cf07bf7-d76f-466d-a4f0-22d7bd200c89.jpg",
        alt: "Level No.2 - PTA",
      },
      icon_title: "Mastery ",
      notes_for_admin: "",
      community_level: "dynamite",
      createdAt: "2023-08-15T09:43:20.184Z",
      updatedAt: "2023-08-23T16:12:14.902Z",
      table_avatar: {
        src: "https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/bonus_road_map/c117dd57-2b01-4b72-94c2-b7a2031ec6c5.jpeg",
        alt: "Mastery",
      },
    },
  ]);
  const classes = useStyles();
  let selected_index = 0;
  if (search) {
    const level = new URLSearchParams(search).get("level");
    selected_index = COMMUNITY_LEVELS.findIndex(
      (community) => community.name == level
    );
  }
  const [tabValue, setTabValue] = useState(selected_index);

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleEdit = (value) => {
    let find_leve = COMMUNITY_LEVELS[tabValue];
    navigate(`/membership-levels/pyramid/edit`, {
      state: { editValues: value },
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteValue(value._id);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    // const result = await road_Delete_Api(deleteValue);
    // if (result.code === 200) {
    //   setIsLoading(false);

    //   enqueueSnackbar(result.message, { variant: "success" });
    // } else {
    //   setIsLoading(false);
    //   enqueueSnackbar(result.message, { variant: "error" });
    // }
  };

  const handleNavigate = () => {
    navigate(`/membership-levels/pyramid/add`);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fulid">
        <div className="row">
          <div className="col-lg-8 display-flex mb-4">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate("/membership-levels")}
            >
              <ArrowBackIcon />
            </IconButton>
            <span className="sale-page-title">Membership Level pyramid</span>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Membership Level pyramid
            </button>
          </div>
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={levelsList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background mt-3"
          pagePagination={true}
        />
      </div>
    </>
  );
};

export default LevelParamid;
