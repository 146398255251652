import * as React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import { TimeZones } from "src/utils/constant";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { consultantListing } from "src/DAL/consultant/consultant";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import { Icon } from "@iconify/react";
import { countries } from "src/utils/country";
import PhoneInput from "react-phone-input-2";
import {
  IconButton,
  Divider,
  CircularProgress,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import {
  AddMemberApi,
  EditMemberApi,
  affiliateSearchInEditMember,
  memberDetailApi,
} from "src/DAL/member/member";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { usePGIMode } from "src/Hooks/PGIModeContext";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

const handle_format_users = (data) => {
  let users = data.map((user) => {
    let full_name = "";
    if (user.affiliate_user_info) {
      let affiliate = user.affiliate_user_info;
      full_name =
        affiliate.first_name +
        " " +
        affiliate.last_name +
        " (" +
        affiliate.email +
        ")";
      if (affiliate.affiliate_user_created_for == "memberuser") {
        full_name += " | Member";
      } else {
        full_name += " | Consultant";
      }
    }
    return { ...user, full_name };
  });
  return users;
};

export default function AddOrUpdateMember() {
  const navigate = useNavigate();
  const { badgeLevels } = usePGIMode();
  const classes = useStyles();
  const { member_id } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState();
  const [oldImage, setOldImage] = useState("");

  const [firstPaymentDate, setFirstPaymentDate] = useState(null);
  const [membership_purchase_expiry, setMembership_purchase_expiry] =
    useState(null);
  const [consultantValue, setConsultantValue] = useState([]);
  const [subTeamList, setSubTeamList] = useState([]);
  const [timeZoneValue, setTimeZoneValue] = useState("Europe/Dublin");
  const [inputValue, setInputValue] = useState("");
  const [phoneData, setPhoneData] = useState();
  const [checked, setChecked] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [salesDelegate, setSalesDelegate] = useState(null);
  const [nurture, setnurture] = useState(null);
  const [marketingAffliliate, setMarketingAffliliate] = useState(null);
  const [formType, setFormType] = useState("ADD");
  const [affiliatesList, setAffiliatesList] = useState([]);
  const [selectedAffiliate, setSelectedAffiliate] = useState(null);
  const done_member = window.location.pathname.includes("/done-listing");

  const [country, setCountry] = useState({
    code: "GB",
    label: "United Kingdom",
  });
  const [postalCountry, setPostalCountry] = useState({
    code: "IE",
    label: "Ireland",
    phone: "353",
  });
  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    contact_number: "",
    goal_statement_status: true,
    status: true,
    street: "",
    street2: "",
    city: "",
    zip_code: "",
    vat_number: "",
    state: "",
    consultant_id: "",
    first_payment_date: new Date(),
    member_biography: "",
    image: {},
    member_biography: "",
    time_zone: "",
    coins_count: 0,
    // is_membership_purchase: false,
    is_executive: false,
    is_super_executive: false,
    is_wheel_of_life: false,
    is_chat_allow: false,
    is_wheel_of_life_enable: false,
    control_by: "self",
    badge_level_id: "",
  });
  const [postalInputs, setPostalInputs] = useState({
    street: "",
    street2: "",
    zip_code: "",
    city: "",
    state: "",
  });

  const handleChangeIsPostalAddress = (event) => {
    setChecked(event.target.checked);
  };

  const consultantListData = async () => {
    setIsLoading(true);
    let result = await consultantListing();
    if (result.code == 200) {
      setConsultantValue(result.consultant_list);
      getAffiliateListOnSearch();
      if (state) {
        setIsLoading(false);
      } else {
        if (member_id) {
          get_member_detail();
        }
      }
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChangeDate = (newValue) => {
    setFirstPaymentDate(newValue);
  };
  const handleChangeExpireDate = (newValue) => {
    setMembership_purchase_expiry(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let postalData = {};
    if (checked == false || checked == "false") {
      postalData = {
        street: postalInputs.street,
        city: postalInputs.city,
        zip_code: postalInputs.zip_code,
        state: postalInputs.state,
        country: postalCountry ? postalCountry.code : "",
        street2: postalInputs.street2,
      };
    }

    const formData = new FormData();
    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    formData.append("member_biography", inputs.member_biography);
    formData.append("email", inputs.email);
    formData.append("contact_number", inputs.contact_number);
    formData.append("status", inputs.status);
    formData.append("street", inputs.street);
    formData.append("city", inputs.city);
    formData.append("zip_code", inputs.zip_code);
    formData.append("vat_number", inputs.vat_number);
    formData.append("badge_level_id", inputs?.badge_level_id);
    if (marketingAffliliate) {
      formData.append("marketing_affliliate", marketingAffliliate._id);
    }
    formData.append("is_wheel_of_life_enable", inputs.is_wheel_of_life_enable);
    if (formType !== "ADD") {
      formData.append("control_by", inputs.control_by);
    }
    formData.append("state", inputs.state);
    formData.append("country", country ? country.code : "");
    formData.append("consultant_id", salesDelegate ? salesDelegate?._id : "");
    formData.append("nurture", nurture ? nurture?._id : "");
    formData.append(
      "first_payment_date",
      firstPaymentDate ? moment(firstPaymentDate).format("YYYY-MM-DD") : ""
    );
    formData.append(
      "membership_purchase_expiry",
      membership_purchase_expiry
        ? moment(membership_purchase_expiry).format("YYYY-MM-DD")
        : ""
    );
    formData.append("goal_statement_status", inputs.goal_statement_status);
    formData.append("time_zone", timeZoneValue);
    // formData.append("is_membership_purchase", inputs.is_membership_purchase);
    formData.append("is_executive", inputs.is_executive);
    formData.append("is_super_executive", inputs.is_super_executive);
    formData.append("is_postal_address_same", checked);
    formData.append("street2", inputs.street2);
    formData.append("postal_addess_info", JSON.stringify(postalData));
    formData.append("is_chat_allow", inputs.is_chat_allow);

    if (formType == "ADD") {
      formData.append("password", inputs.password);
    } else {
      formData.append("coins_count", inputs.coins_count);
      formData.append("is_wheel_of_life", inputs.is_wheel_of_life);
      if (selectedAffiliate) {
        formData.append("affiliate", selectedAffiliate._id);
      }
    }
    if (file) {
      formData.append("profile_image", inputs.image);
    }

    const result =
      formType == "ADD"
        ? await AddMemberApi(formData)
        : await EditMemberApi(formData, member_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      if (done_member) {
        navigate(`/done-listing`);
      } else {
        navigate(-1);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangePostal = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setPostalInputs((values) => ({ ...values, [name]: value }));
  };

  const get_member_detail = async () => {
    setIsLoading(true);
    const result = await memberDetailApi(member_id);
    if (result.code == 200) {
      handleFormat(result.member);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getAffiliateListOnSearch = async (search = "", type = "") => {
    let result = await affiliateSearchInEditMember(search, type);
    if (result.code == 200) {
      if (type == "affiliate") {
        setAffiliatesList(handle_format_users(result.affiliate));
        return;
      } else if (type == "marketing_affiliate") {
        setSubTeamList(handle_format_users(result.marketing_affiliate));
        return;
      }
      setSubTeamList(handle_format_users(result.marketing_affiliate));
      setAffiliatesList(handle_format_users(result.affiliate));
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSearchMarketingAffliliate = (search) => {
    getAffiliateListOnSearch(search, "marketing_affiliate");
  };

  const handleSearch = (search) => {
    getAffiliateListOnSearch(search, "affiliate");
  };

  const handleFormat = (data) => {
    setPhoneData(data.contact_number);

    let findCountry = countries.find((item) => {
      if (String(item.code) === String(data.country).toUpperCase()) {
        return item;
      } else null;
    });
    setFormType("EDIT");
    setInputs((values) => ({
      ...data,
      badge_level_id:
        data?.membership_level_badge_info?.membership_level_badge_id,
    }));

    setCountry(findCountry);
    setChecked(data.is_postal_address_same);

    let selectedNavArray = [];
    data?.nav_items?.map((nav, i) => {
      selectedNavArray?.push(nav?.option_value);
    });
    let selectedNavExcludeArray = [];
    data?.nav_items_exluded?.map((nav, i) => {
      selectedNavExcludeArray?.push(nav?.option_value);
    });
    if (data.first_payment_date) {
      setFirstPaymentDate(data.first_payment_date);
    }
    if (data.membership_purchase_expiry) {
      const new_date = moment(
        data.membership_purchase_expiry,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      setMembership_purchase_expiry(new_date);
    }
    if (data.consultant?.first_name) {
      setSalesDelegate(data.consultant);
    }
    if (data.completeNurture?.first_name) {
      setnurture(data.completeNurture);
    } else {
      if (data.nurture?.first_name) {
        setnurture(data.nurture);
      }
    }
    if (data.time_zone) {
      setTimeZoneValue(data.time_zone);
    }
    if (data.profile_image) {
      setOldImage(data.profile_image);
    }
    if (data.affliliate?.affiliate_url_name) {
      let affiliate = handle_format_users([data.affliliate])[0];
      setSelectedAffiliate(affiliate);
    }
    if (data.marketing_affliliate?.affiliate_url_name) {
      let affiliate = handle_format_users([data.marketing_affliliate])[0];
      setMarketingAffliliate(affiliate);
    }
    if (data.is_postal_address_same == false) {
      let findPostalCountry = countries.find((item) => {
        if (String(item.code) === String(data.postal_addess_info.country)) {
          return item;
        }
      });
      setPostalCountry(findPostalCountry);
      setPostalInputs(data.postal_addess_info);
    }
    setIsLoading(false);
  };

  const handleChangePhone = (value, selected) => {
    setInputs((values) => ({
      ...values,
      contact_number: value,
      country: selected.countryCode.toUpperCase(),
    }));
    if (!country) {
      setCountry({
        code: selected.countryCode.toUpperCase(),
        label: selected.name,
      });
    }
  };
  useEffect(() => {
    if (member_id) {
      if (state) {
        handleFormat(state);
      }
    }
    consultantListData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="ms-1">{`${
            formType == "ADD" ? "Add" : "Edit"
          } Member`}</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              fullWidth
              required
              name="first_name"
              value={inputs.first_name}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic1"
              label="Last Name"
              variant="outlined"
              fullWidth
              required
              name="last_name"
              value={inputs.last_name}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic2"
              label="Email"
              type="email"
              variant="outlined"
              defaultValue="Hello World"
              fullWidth
              required
              name="email"
              value={inputs.email}
              onChange={handleChange}
            />
          </div>
          {formType == "ADD" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic3"
                label="Password"
                variant="outlined"
                fullWidth
                type={showPassword ? "text" : "password"}
                required
                name="password"
                value={inputs.password}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4 table-phone-input">
            <PhoneInput
              country={"gb"}
              inputClass="react-phone-input"
              dropdownClass="flag-input"
              inputProps={{
                name: "contact_number",
                autoComplete: "off",
              }}
              value={phoneData}
              enableSearch
              disableSearchIcon
              countryCodeEditable={false}
              buttonStyle={{
                border: "none",
                backgroundColor: "transparent",
              }}
              onChange={(value, data) => {
                handleChangePhone(value, data);
              }}
            />
          </div>

          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="is_membership_purchase">
                Is Membership Purchase?
              </InputLabel>
              <Select
                labelId="is_membership_purchase"
                id="demo-simple-select"
                name="is_membership_purchase"
                value={inputs.is_membership_purchase}
                label="Is Membership Purchase? "
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div> */}

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Membership Expire Date"
                inputFormat="dd-MM-yyyy"
                value={membership_purchase_expiry}
                onChange={handleChangeExpireDate}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </div>

          {formType == "EDIT" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic5"
                label="Coins Count"
                variant="outlined"
                fullWidth
                name="coins_count"
                value={inputs.coins_count}
                onChange={handleChange}
                required
              />
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Member Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Member Status "
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Is Executive ?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_executive"
                value={inputs.is_executive}
                label="Is Executive ?"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Is Super Executive?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_super_executive"
                value={inputs.is_super_executive}
                label="Is Super Executive?"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Is Wheel Of Life Completed ?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_wheel_of_life"
                value={inputs.is_wheel_of_life}
                label="Is Wheel Of Life Completed ?"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="plan_for">Is Wheel Of Life Enable ?</InputLabel>
              <Select
                labelId="plan_for"
                id="demo-simple-select"
                name="is_wheel_of_life_enable"
                value={inputs.is_wheel_of_life_enable}
                label="Is Wheel Of Life Enable ?"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {(inputs.is_executive == true ||
            inputs.is_super_executive == true) && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Is Chat Allow ?
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="is_chat_allow"
                  value={inputs.is_chat_allow}
                  label="Is Chat Allow ?"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          {formType == "EDIT" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="is_membership_purchase">Control By</InputLabel>
                <Select
                  labelId="control_by"
                  id="demo-simple-select"
                  name="control_by"
                  value={inputs.control_by}
                  label="Control By"
                  onChange={handleChange}
                >
                  <MenuItem value="self">Self</MenuItem>
                  <MenuItem value="dev_team">Dev Team</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Badge Level</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="badge_level_id"
                value={inputs?.badge_level_id}
                label="Badge Level"
                onChange={handleChange}
              >
                {badgeLevels.map((level, index) => (
                  <MenuItem key={index} value={level?._id}>
                    {level?.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Image </p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {file ? (
                  <img src={file} height="50" />
                ) : oldImage ? (
                  <img src={s3baseUrl + oldImage} height="50" />
                ) : (
                  ""
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image?.name}</p>
            )}
          </div>

          <div className="col-12">
            <h3>Address Details</h3>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <Autocomplete
              id="country-select-demo"
              options={countries}
              autoHighlight
              value={country ? country : null}
              onChange={(event, newValue) => {
                setCountry(newValue);
              }}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.label} ({option.code})
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a country"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic6"
              label="City"
              variant="outlined"
              fullWidth
              name="city"
              value={inputs.city}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic7"
              label="State"
              variant="outlined"
              fullWidth
              name="state"
              value={inputs.state}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic8"
              label="Street Address 1"
              variant="outlined"
              fullWidth
              name="street"
              value={inputs.street}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic9"
              label="Street Address 2"
              variant="outlined"
              fullWidth
              name="street2"
              value={inputs.street2}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic10"
              label="Zip Code"
              variant="outlined"
              fullWidth
              type="text"
              name="zip_code"
              value={inputs.zip_code}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic10"
              label="Vat Number"
              variant="outlined"
              fullWidth
              type="text"
              name="vat_number"
              value={inputs.vat_number}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-4 d-flex">
            <Checkbox
              checked={checked}
              onChange={handleChangeIsPostalAddress}
              inputProps={{ "aria-label": "controlled" }}
            />
            <h3 className="pt-2">Default Postal Address</h3>
          </div>
          {checked == false && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <Autocomplete
                  id="country-select-demo"
                  options={countries}
                  autoHighlight
                  value={postalCountry}
                  onChange={(event, newValue) => {
                    setPostalCountry(newValue);
                  }}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic11"
                  label="City"
                  variant="outlined"
                  fullWidth
                  name="city"
                  value={postalInputs.city}
                  onChange={handleChangePostal}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic12"
                  label="State"
                  variant="outlined"
                  fullWidth
                  name="state"
                  value={postalInputs.state}
                  onChange={handleChangePostal}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic13"
                  label="Street Address 1"
                  variant="outlined"
                  fullWidth
                  name="street"
                  value={postalInputs.street}
                  onChange={handleChangePostal}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic14"
                  label="Street Address 2"
                  variant="outlined"
                  fullWidth
                  name="street2"
                  value={postalInputs.street2}
                  onChange={handleChangePostal}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic15"
                  label="Zip Code"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="zip_code"
                  value={postalInputs.zip_code}
                  onChange={handleChangePostal}
                />
              </div>
            </>
          )}

          <Divider className="my-4" />
          <h3>Configurations</h3>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date of first payment"
                inputFormat="dd-MM-yyyy"
                value={firstPaymentDate}
                onChange={handleChangeDate}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <Autocomplete
              id="tags-outlined"
              options={consultantValue}
              getOptionLabel={(option) =>
                option.first_name +
                " " +
                option.last_name +
                " (" +
                option.email +
                ")"
              }
              value={salesDelegate}
              onChange={(event, newValue) => {
                setSalesDelegate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sales Delegate"
                  placeholder="Sales Delegate"
                />
              )}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <Autocomplete
              id="tags-outlined1"
              options={consultantValue}
              getOptionLabel={(option) =>
                option.first_name +
                " " +
                option.last_name +
                " (" +
                option.email +
                ")"
              }
              value={nurture}
              onChange={(event, newValue) => {
                setnurture(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Nurture"
                  placeholder="Nurture"
                />
              )}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel required id="demo-simple-select-label">
                Goal Statement Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="goal_statement_status"
                value={inputs.goal_statement_status}
                label="Goal Statement Status"
                onChange={handleChange}
              >
                <MenuItem value={false}>Lock</MenuItem>
                <MenuItem value={true}>Unlock</MenuItem>
              </Select>
            </FormControl>
          </div>
          {formType == "EDIT" && (
            <>
              <div className="col-md-6 mt-4">
                <MUIAutocomplete
                  inputLabel="Affliliate"
                  selectedOption={selectedAffiliate}
                  setSelectedOption={setSelectedAffiliate}
                  optionsList={affiliatesList}
                  autoComplete="new-password"
                  name="full_name"
                  setCustomSearch={handleSearch}
                />
              </div>
              <div className="col-md-6 mt-4">
                <MUIAutocomplete
                  inputLabel="Marketing Affliliate"
                  selectedOption={marketingAffliliate}
                  setSelectedOption={setMarketingAffliliate}
                  optionsList={subTeamList}
                  autoComplete="new-password"
                  name="full_name"
                  setCustomSearch={handleSearchMarketingAffliliate}
                />
              </div>
            </>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <Autocomplete
              value={timeZoneValue}
              onChange={(event, newValue) => {
                setTimeZoneValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="controllable-states-demo"
              options={TimeZones}
              renderInput={(params) => (
                <TextField {...params} label="Time Zone *" multiple />
              )}
            />
          </div>

          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description "
                multiline
                rows={6}
                name="member_biography"
                value={inputs.member_biography}
                onChange={handleChange}
              />
              <FormHelperText>
                Member Biography (Maximum limit 500 characters)
              </FormHelperText>
            </FormControl>
          </div>

          <div className="text-end mt-4">
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
