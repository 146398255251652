import { useEffect, useState } from "react";
import {
  Card,
  Chip,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import jsPDF from "jspdf";
import Label from "src/components/Label";
import ReactDOMServer from "react-dom/server";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { orderDetailList } from "src/DAL/Transaction/transactionApi";
import { useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { Icon } from "@iconify/react";
import {
  convertCurrencyToSign,
  dd_date_format,
  replace_created_for,
} from "src/utils/constant";
import moment from "moment";
import { download_csv_file } from "src/utils/convertHtml";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { orderShopDetailList } from "src/DAL/ShopProductCategoryList/ProductCategory";

// ----------------------------------------------------------------------

const headers = [
  { label: "Title", key: "title" },
  { label: "Quantity", key: "quantity" },
  { label: "Price", key: "price" },
];
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function VersionHistoryDetails({ data }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const [ticketsList, setTicketsList] = useState([]);
  const [ProductList, setProductList] = useState([]);
  const [ProductListDetail, setProductListDetail] = useState([]);
  const [orderDeatail, setOrderDeatail] = useState();

  const [isDonwloadingPdf, setIsDonwloadingPdf] = useState(false);

  const handleExportClick = () => {
    download_csv_file(headers, ProductList);
  };

  const order_status = (status) => {
    let chipColor;
    let chipTextcolor;
    switch (status) {
      case "pending":
        chipColor = "#ff9800";
        chipTextcolor = "#fff";
        break;

      case "approved":
        chipColor = "#4caf50";
        chipTextcolor = "#fff";
        break;

      case "rejected":
        chipColor = "rgb(211, 47, 47)";
        chipTextcolor = "#fff";

        break;
      default:
        chipColor = "#ff9800";
        chipTextcolor = "#fff";

        break;
    }
    return (
      <Chip
        label={status}
        style={{
          backgroundColor: chipColor,
          color: "white",
          textTransform: "capitalize",
        }}
      ></Chip>
    );
  };

  const referralName = (refferal_name, affiliate_url_name) => {
    return (
      <div>
        {`${refferal_name} `}
        {affiliate_url_name && (
          <span className="affiliate_url_name">({affiliate_url_name})</span>
        )}
      </div>
    );
  };

  console.log(data, "datadata");

  // if (isLoading == true) {
  //   return <CircularProgress className={classes.loading} color="primary" />;
  // }

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3"></div>
        {(data?.platform_type == "both" || data?.platform_type == "ios") && (
          <Card className="payment-card mb-3">
            <div className="row p-3">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 mt-5 mt-lg-0 request-type text-center ">
                    <h3 className="Sub-heading mb-4">IOS Update Info</h3>
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                    <p className="finalPrice">Version No:</p>
                  </div>
                  <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 text-muted">
                    {data?.ios_update_info?.version_no}
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                    <p className="">Build Status:</p>
                  </div>
                  <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 text-muted">
                    {order_status(data?.ios_update_info?.build_status)}
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                    Forcefully Update:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                    <span className="ms-1 mt-1">
                      {data?.ios_update_info?.force_update ? "Yes" : "No"}
                    </span>
                  </div>

                  <div className="col-5 col-md-6 col-lg-3 mt-5  mb-3 mt-lg-0 request-type">
                    Submited Date:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted text-capitalize">
                    <Icon icon="uiw:date" className="finalPrice" />
                    <span className="ms-1 mt-1">
                      {data?.ios_update_info?.submited_date
                        ? dd_date_format(data?.ios_update_info?.submited_date)
                        : "N/A"}
                    </span>
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mt-5  mb-3 mt-lg-0 request-type">
                    Released Date:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted text-capitalize">
                    <Icon icon="uiw:date" className="finalPrice" />
                    <span className="ms-1 mt-1">
                      {" "}
                      {data?.ios_update_info?.released_date
                        ? dd_date_format(data?.ios_update_info?.released_date)
                        : "N/A"}
                    </span>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 mt-5  mb-3 mt-lg-0 request-type">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.ios_update_info?.description,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        )}
        {(data.platform_type == "both" || data?.platform_type == "android") && (
          <Card className="payment-card mb-3">
            <div className="row p-3">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 mt-5 mt-lg-0 request-type text-center ">
                    <h3 className="Sub-heading mb-4">Android Update Info</h3>
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                    <p className="finalPrice">Version No:</p>
                  </div>
                  <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 text-muted">
                    {data?.android_update_info?.version_no}
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                    <p className="">Build Status:</p>
                  </div>
                  <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 text-muted">
                    {order_status(data?.android_update_info?.build_status)}
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                    Forcefully Update:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                    <span className="ms-1 mt-1">
                      {data?.ios_update_info?.android_update_info
                        ? "Yes"
                        : "No"}
                    </span>
                  </div>

                  <div className="col-5 col-md-6 col-lg-3 mt-5  mb-3 mt-lg-0 request-type">
                    Submited Date:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted text-capitalize">
                    <Icon icon="uiw:date" className="finalPrice" />
                    <span className="ms-1 mt-1">
                      {data?.android_update_info?.submited_date
                        ? dd_date_format(
                            data?.android_update_info?.submited_date
                          )
                        : "N/A"}
                    </span>
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mt-5  mb-3 mt-lg-0 request-type">
                    Released Date:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted text-capitalize">
                    <Icon icon="uiw:date" className="finalPrice" />
                    <span className="ms-1 mt-1">
                      {data?.android_update_info?.released_date
                        ? dd_date_format(
                            data?.android_update_info?.released_date
                          )
                        : "N/A"}
                    </span>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 mt-5  mb-3 mt-lg-0 request-type">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.android_update_info?.description,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        )}
      </div>
    </>
  );
}
