import { invokeApi } from "../../bl_libs/invokeApi";

export const add_gaps_video_api = async (data) => {
  const requestObj = {
    path: `/api/gaps_video/add`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const list_gaps_video_api = async () => {
  const requestObj = {
    path: `/api/gaps_video/list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const detail_gaps_video_api = async (id) => {
  const requestObj = {
    path: `/api/gaps_video/get/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delete_gaps_video_api = async (id) => {
  const requestObj = {
    path: `/api/gaps_video/delete/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_gaps_video_api = async (data, id) => {
  const requestObj = {
    path: `/api/gaps_video/update/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
