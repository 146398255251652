import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import {
  IconButton,
  Box,
  CircularProgress,
  InputAdornment,
  OutlinedInput,
  Tabs,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import { styled } from "@mui/material/styles";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import AnalyticsFilter from "./AnalyticsFilter";
import FilteredChip from "src/components/FilteredChip";
import { useSnackbar } from "notistack";
import moment from "moment";
import {
  ga4_dimension_details,
  google_analytics_report_country,
} from "src/DAL/Analytics/Analytics";
import { dd_date_format } from "src/utils/constant";
import AnalyticsChart from "./AnalyticsChart";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 44,
  color: "#fff",
  border: "1px solid #e4c073",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    boxShadow: theme.customShadows.z8,
    border: "1px solid #e4c073",
    width: 280,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const formatDuration = (seconds) => {
  if (!seconds || seconds <= 0) {
    return `0m 0s`;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}m ${remainingSeconds}s`;
};

const EMPTY_FILTER = {
  start_date: new Date(new Date().setDate(new Date().getDate() - 6)),
  end_date: new Date(),
};

const AnalyticsEventDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [value, setValue] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState({
    eventName: params.event_name,
  });
  const [eventList, setEevetList] = useState([]);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [reportList, setReportList] = useState({});
  const [openFilter, setOpenfilter] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [tableList, setTableList] = useState([
    { title: "Professional Website", percentage: "31.1%", avgTime: "3s" },
    { title: "Thank You", percentage: "17.5%", avgTime: "3s" },
    { title: "Thank You!", percentage: "12.1%", avgTime: "7s" },
    { title: "Beautiful Payment Page", percentage: "10.4%", avgTime: "4s" },
    { title: "VSL Sale Page Funnel", percentage: "10.0%", avgTime: "9s" },
    { title: "Source", percentage: "4.1%", avgTime: "4s" },
    {
      title: "Payment For Inception Funnel",
      percentage: "3.7%",
      avgTime: "17s",
    },
    { title: "VSL Page Book Call Funnel", percentage: "2.2%", avgTime: "2s" },
    {
      title: "VSL Page Book Call Funnel | Appointment",
      percentage: "1.7%",
      avgTime: "8s",
    },
  ]);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const getEventList = async (filterData) => {
    let postData = { ...filterData };
    if (postData.start_date) {
      postData.start_date = moment(postData.start_date).format("YYYY-MM-DD");
    }
    if (postData.end_date) {
      postData.end_date = moment(postData.end_date).format("YYYY-MM-DD");
    }
    postData.dimensions = ["eventName"];
    postData.metrics = [
      "newUsers",
      "totalUsers",
      "engagedSessions",
      "engagementRate",
      "averageSessionDuration",
    ];
    const result = await google_analytics_report_country(postData);
    if (result.code == 200) {
      setEevetList(result?.report);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectEvent = (value) => {
    setSelectedEvent(value);
  };

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const getCategoriesList = async (filterData) => {
    let postData = { ...filterData };
    if (postData.start_date) {
      postData.start_date = moment(postData.start_date).format("YYYY-MM-DD");
    }
    if (postData.end_date) {
      postData.end_date = moment(postData.end_date).format("YYYY-MM-DD");
    }

    if (searchText) {
      postData.search = searchText;
    }

    postData.dimension = "eventName";
    postData.dimension_value = selectedEvent.eventName;
    postData.metrics = [
      "activeUsers",
      "newUsers",
      "totalUsers",
      "engagedSessions",
      "engagementRate",
      "averageSessionDuration",
    ];
    setIsLoading(true);
    const result = await ga4_dimension_details(postData);
    if (result.code == 200) {
      let chipData = { ...filterData };
      if (chipData.start_date) {
        let date = `Start Date: ${dd_date_format(
          chipData.start_date
        )} End Date:  ${dd_date_format(chipData.end_date)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chipData.start_date;
        delete chipData.end_date;
      }
      delete chipData.dimension;
      delete chipData.dimension_value;
      delete chipData.metrics;

      setDataList(result?.graphical_report);
      setReportList(result?.report);
      setTableList(result?.page_report);
      setUpdateFilterData(chipData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClearFilter = () => {
    getCategoriesList(EMPTY_FILTER);
    setUpdateFilterData(EMPTY_FILTER);
    setFilterData(EMPTY_FILTER);
    localStorage.removeItem("analytics_filter_detail");
    setOpenfilter(false);
  };

  const searchFunction = () => {
    getCategoriesList(filterData);
    setOpenfilter(false);
    localStorage.setItem("analytics_filter_detail", JSON.stringify(filterData));
  };

  const handleDeleteChip = (data) => {
    getCategoriesList(data);
    localStorage.setItem("analytics_filter_detail", JSON.stringify(data));
    setFilterData(data);
  };

  const handleOpenDawer = () => {
    setOpenfilter(true);
  };

  const handleSearchText = (event) => {
    setSearchText(event.target.value);
    localStorage.setItem("search_text_detail", event.target.value);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "pageTitle", label: "Title" },
    { id: "totalUsers", label: "Total User" },
    {
      id: "engagementRate",
      label: "Engagement Rate",
      renderData: (row) => {
        const formattedRate = Number(row.engagementRate).toFixed(2);
        return <>{formattedRate}%</>;
      },
    },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("analytics_filter_detail");
    let find_search_text = localStorage.getItem("search_text_detail");
    if (find_search_text) {
      setSearchText(find_search_text);
    }
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    getCategoriesList(filter_data);
  }, [selectedEvent]);

  useEffect(() => {
    getEventList(filterData);
    if (selectedEvent) {
      const newUrl = `/analytics-event/event-detail/${selectedEvent.eventName}`;
      window.history.pushState(null, "", newUrl);
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (!isLoading) {
      const timeoutId = setTimeout(() => {
        getCategoriesList(filterData);
      }, 800);

      return () => clearTimeout(timeoutId);
    }
  }, [searchText]);

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-6 d-flex">
            <span>
              <IconButton
                className="back-screen-button mb-4"
                onClick={() => navigate(`/analytics-event`)}
              >
                <ArrowBackIcon />
              </IconButton>
            </span>
            <h2 className="ms-2">Event Detail</h2>
          </div>
          <div className="col-md-6 text-end">
            {/* <SearchStyle
              className="ms-auto"
              value={searchText}
              onChange={handleSearchText}
              placeholder="Search Url"
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              }
            /> */}
            <button
              onClick={handleOpenDawer}
              className="small-contained-button ms-2"
            >
              Filter
            </button>
          </div>
          <div className="col-md-4">
            <MUIAutocomplete
              inputLabel="Event List"
              selectedOption={selectedEvent}
              setSelectedOption={handleSelectEvent}
              optionsList={eventList}
              autoComplete="new-password"
              name="eventName"
              required
            />
          </div>
        </div>
        {!isLoading && (
          <FilteredChip
            data={updateFilterData}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
        )}
        {isLoading ? (
          <CircularProgress className={classes.loading} color="primary" />
        ) : (
          <>
            <div className="col-12 mt-3">
              <div className="card b_radius_16">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      className="pb-0"
                      label={
                        <>
                          Total User
                          <br />
                          <h3 className="me-auto">
                            {reportList?.totalUsers ?? 0}
                          </h3>
                        </>
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      className="pb-0"
                      label={
                        <>
                          Active User
                          <br />{" "}
                          <h3 className="me-auto">
                            {reportList?.activeUsers ?? 0}
                          </h3>
                        </>
                      }
                      {...a11yProps(1)}
                    />
                    <Tab
                      className="pb-0"
                      label={
                        <>
                          New User
                          <br />{" "}
                          <h3 className="me-auto">
                            {reportList?.newUsers ?? 0}
                          </h3>
                        </>
                      }
                      {...a11yProps(2)}
                    />
                    <Tab
                      className="pb-0"
                      label={
                        <>
                          Average Engagement Time
                          <br />{" "}
                          <h3 className="me-auto">
                            {formatDuration(reportList?.averageSessionDuration)}
                          </h3>
                        </>
                      }
                      {...a11yProps(3)}
                    />
                  </Tabs>
                </Box>
                {dataList.length > 0 && (
                  <>
                    <CustomTabPanel value={value} index={0}>
                      <AnalyticsChart dataList={dataList} metric="Users" />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      <AnalyticsChart
                        dataList={dataList}
                        metric="Active Users"
                      />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                      <AnalyticsChart dataList={dataList} metric="New Users" />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                      <AnalyticsChart
                        dataList={dataList}
                        metric="Sessions Per User"
                      />
                    </CustomTabPanel>
                  </>
                )}
              </div>
            </div>
            <div className="col-12 mt-3">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={tableList}
                className="card-with-background"
                is_hide={true}
              />
            </div>
          </>
        )}
      </div>
      <GeneralPopUpModel
        open={openFilter}
        setOpen={setOpenfilter}
        title={"Filter"}
        componentToPassDown={
          <AnalyticsFilter
            filterData={filterData}
            handleSelectOther={handleSelectOther}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
};

export default AnalyticsEventDetail;
