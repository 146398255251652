import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  addPageApi,
  detailPageApi,
  editPageApi,
  get_data_for_sale_page_api,
} from "src/DAL/WebsitePages/WebsitePages";
import { s3baseUrl } from "src/config/config";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { TextareaAutosize } from "@mui/base";
import { PAYMENT_METHODS } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

let data_lists = {
  menu: [],
  template_pages: [],
  thanks_template_array: [],
  payment_template_array: [],
  call_types: [],
  lead_status: [],
};

export default function AddOrUpdatePage() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [dataLists, setDataLists] = useState(data_lists);
  const [formType, setFormType] = useState("ADD");
  const is_website_pages = window.location.pathname.includes("website-pages");
  const [images, setImages] = useState({
    brand_favicon: "",
    brand_logo: "",
    footer_logo: "",
  });

  const [oldImages, setOldImages] = useState({
    brand_favicon: "",
    brand_logo: "",
    footer_logo: "",
  });

  const [inputs, setInputs] = useState({
    brand_favicon: {},
    brand_logo: {},
    footer_logo: {},
    meta_title: "",
    meta_keywords: "",
    payment_mode: "sandBox",
    active_campaign_list_id: 0,
    active_campaign_tag_id: 0,
    default_commission_percentage: 0,
    redirect_user: "thanks",
    type_of_page: "sale_page",
    thanks_template: "",
    payment_template: "",
    meta_description: "",
    sale_page_title: "",
    pixel_code_header: "",
    pixel_code_body: "",
    google_tracking_code_header: "",
    google_tracking_code_body: "",
    website_portal_css_code: "",
    website_portal_js_code: "",
    template: null,
    is_general_brand_logo: true,
    is_default_commission: false,
    is_general_social_links: true,
    custom_link: "",
    facebook_link: "",
    pinterest_link: "",
    instagram_link: "",
    youtube_link: "",
    mailbox_link: "",
    snap_chat_link: "",
    twitter_link: "",
    linkedin_link: "",
    message: "",
    tiktok_link: "",
    thankyou_page_content: "",
    header_menu: "",
    footer_menu: "",
    book_a_call_type: null,
    is_publically_accessible: false,
    is_paid: false,
    booking_tracking: true,
    is_password_auto_generated: false,
    book_a_call_custom_url: "",
    page_alias_url: "",
    show_free_plan_in_popup: false,
    show_free_plans_in_list: true,
    is_show_address: true,
    lead_status: null,
    is_use_plan_package: false,
    use_comparison_chart: false,
    show_slotes: "all_time",
    show_slotes_duration: "",
    allow_coupon_code: false,
    allowed_payment_methods: [
      {
        value: "credit_card",
        label: "Credit Card",
      },
    ],
  });

  const fileChangedHandler = (e) => {
    const { name, files } = e.target;
    setInputs({
      ...inputs,
      [name]: files[0],
    });
    setImages({
      ...images,
      [name]: URL.createObjectURL(files[0]),
    });
  };

  const formatData = (data) => {
    setFormType("EDIT");
    const { brand_logo, brand_favicon, footer_logo } = data;
    let allowed_payment_methods = data.allowed_payment_methods || [];

    if (allowed_payment_methods?.length > 0) {
      allowed_payment_methods = allowed_payment_methods.map((method) =>
        PAYMENT_METHODS.find((m) => m.value === method)
      );
    }

    setInputs({
      ...data,
      allowed_payment_methods: allowed_payment_methods,
      template: data.template_page?.sale_page_title,
      ["footer_menu"]: data.footer_menu ? data.footer_menu : "none",
      ["header_menu"]: data.header_menu ? data.header_menu : "none",
      ["allow_coupon_code"]: data.allow_coupon_code
        ? data.allow_coupon_code
        : false,
      ["thanks_template"]: data.thanks_template ? data.thanks_template : "none",
      ["is_use_plan_package"]: data.is_use_plan_package
        ? data.is_use_plan_package
        : false,
      ["use_comparison_chart"]: data.use_comparison_chart
        ? data.use_comparison_chart
        : false,
    });
    setOldImages({
      brand_logo,
      brand_favicon,
      footer_logo,
    });
    setIsLoading(false);
  };

  const getPageDetail = async () => {
    setIsLoading(true);
    const result = await detailPageApi(params.page_slug);
    if (result.code == 200) {
      formatData(result.sale_page);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const templatesListData = async () => {
    setIsLoading(true);
    let result = await get_data_for_sale_page_api();
    if (result.code == 200) {
      setDataLists(result);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e, exist) => {
    if (e) {
      e.preventDefault();
    }
    setIsLoading(true);
    let allowed_payment_methods = inputs.allowed_payment_methods.map(
      (method) => method.value
    );
    const formData = new FormData();
    formData.append("meta_title", inputs.meta_title);
    formData.append("page_alias_url", inputs.page_alias_url);
    formData.append("show_free_plan_in_popup", inputs.show_free_plan_in_popup);
    formData.append("show_free_plans_in_list", inputs.show_free_plans_in_list);
    formData.append("is_use_plan_package", inputs.is_use_plan_package);
    formData.append("use_comparison_chart", inputs.use_comparison_chart);
    formData.append("meta_description", inputs.meta_description);
    formData.append("payment_mode", inputs.payment_mode);
    formData.append("meta_keywords", inputs.meta_keywords);
    formData.append("pixel_code_header", inputs.pixel_code_header);
    formData.append("allow_coupon_code", inputs.allow_coupon_code);
    formData.append(
      "allowed_payment_methods",
      JSON.stringify(allowed_payment_methods)
    );
    formData.append(
      "is_password_auto_generated",
      inputs.is_password_auto_generated
    );
    formData.append("message", inputs.message);
    formData.append(
      "google_tracking_code_header",
      inputs.google_tracking_code_header
    );
    if (inputs.type_of_page === "payment_page") {
      formData.append("is_show_address", inputs.is_show_address);
    }
    formData.append(
      "google_tracking_code_body",
      inputs.google_tracking_code_body
    );
    formData.append("website_portal_css_code", inputs.website_portal_css_code);
    formData.append("website_portal_js_code", inputs.website_portal_js_code);
    formData.append("pixel_code_body", inputs.pixel_code_body);
    formData.append("active_campaign_list_id", inputs.active_campaign_list_id);
    formData.append("active_campaign_tag_id", inputs.active_campaign_tag_id);
    formData.append("redirect_user", inputs.redirect_user);
    if (inputs.redirect_user == "other_link") {
      formData.append("custom_link", inputs.custom_link);
    }
    if (inputs.type_of_page == "book_a_call_page") {
      formData.append("is_paid", inputs.is_paid);
      formData.append("booking_tracking", inputs.booking_tracking);
      formData.append("book_a_call_custom_url", inputs.book_a_call_custom_url);
      formData.append("book_a_call_type", inputs.book_a_call_type._id);
      formData.append("show_slotes", inputs.show_slotes);
      if (inputs.show_slotes == "specific_time") {
        formData.append("show_slotes_duration", inputs.show_slotes_duration);
      }
      if (inputs.lead_status) {
        formData.append("lead_status", inputs.lead_status._id);
      }
    }
    formData.append(
      "is_publically_accessible",
      inputs.is_publically_accessible
    );
    formData.append("sale_page_title", inputs.sale_page_title);
    formData.append("thankyou_page_content", inputs.thankyou_page_content);
    formData.append("is_general_brand_logo", inputs.is_general_brand_logo);
    formData.append("is_general_social_links", inputs.is_general_social_links);
    formData.append("is_default_commission", inputs.is_default_commission);
    if (inputs.is_default_commission) {
      formData.append(
        "default_commission_percentage",
        inputs.default_commission_percentage
      );
    }

    formData.append(
      "header_menu",
      inputs.header_menu == undefined || inputs.header_menu == "none"
        ? ""
        : inputs.header_menu
    );
    formData.append(
      "footer_menu",
      inputs.footer_menu == undefined || inputs.footer_menu == "none"
        ? ""
        : inputs.footer_menu
    );
    if (inputs.type_of_page == "sale_page") {
      formData.append(
        "thanks_template",
        inputs.thanks_template == undefined || inputs.thanks_template == "none"
          ? ""
          : inputs.thanks_template
      );
      formData.append("payment_template", inputs.payment_template);
    }
    if (formType === "ADD" && inputs.template) {
      formData.append("template", inputs.template.template);
    }
    if (inputs.is_general_social_links == false) {
      formData.append("facebook_link", inputs.facebook_link);
      formData.append("pinterest_link", inputs.pinterest_link);
      formData.append("youtube_link", inputs.youtube_link);
      formData.append("instagram_link", inputs.instagram_link);
      formData.append("mailbox_link", inputs.mailbox_link);
      // added by Zubair
      formData.append("snap_chat_link", inputs.snap_chat_link);
      formData.append("twitter_link", inputs.twitter_link);
      formData.append("linkedin_link", inputs.linkedin_link);
      formData.append("tiktok_link", inputs.tiktok_link);
    }
    if (inputs.is_general_brand_logo == false) {
      formData.append("brand_logo", images.brand_logo ? inputs.brand_logo : "");
      formData.append(
        "brand_favicon",
        images.brand_favicon ? inputs.brand_favicon : ""
      );
      formData.append(
        "footer_logo",
        images.footer_logo ? inputs.footer_logo : ""
      );
    }
    console.log(...formData, "formDataformData");
    const result =
      formType === "ADD"
        ? await addPageApi(formData)
        : await editPageApi(formData, params.page_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      if (exist == "exist") {
        navigate(-1);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeSlot = (event) => {
    const value = event.target.value;
    setInputs((values) => ({
      ...values,
      show_slotes_duration: "",
      show_slotes: value,
    }));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name == "template") {
      let template = dataLists.template_pages.find(
        (template) => template._id == value
      );
      setInputs({ ...template, [name]: value });
    } else {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  const handleRemoveImage = (name) => {
    setInputs((values) => ({ ...values, [name]: {} }));
    setImages((values) => ({ ...values, [name]: "" }));
    setOldImages((values) => ({ ...values, [name]: "" }));
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeMethod = (value) => {
    let find_stripe = value.find((method) => method.value === "credit_card");
    if (!find_stripe) return;
    setInputs((values) => ({ ...values, allowed_payment_methods: value }));
  };

  const handleChangePage = (name, value) => {
    let allowed_payment_methods = value.allowed_payment_methods || [];
    if (allowed_payment_methods?.length > 0) {
      allowed_payment_methods = allowed_payment_methods.map((method) =>
        PAYMENT_METHODS.find((m) => m.value === method)
      );
    }

    setInputs({
      ...value,
      [name]: value,
      allowed_payment_methods,
      ["footer_menu"]: value.footer_menu ? value.footer_menu : "none",
      ["header_menu"]: value.header_menu ? value.header_menu : "none",
      ["thanks_template"]: value.thanks_template
        ? value.thanks_template
        : "none",
    });
  };

  useEffect(() => {
    if (params.page_slug) {
      if (state) {
        formatData(state);
      } else {
        getPageDetail();
      }
    }
    templatesListData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() =>
              navigate(
                `${is_website_pages ? "/website-pages" : "/template-pages"}`
              )
            }
          >
            <ArrowBackIcon />
          </IconButton>
          <h2>{`${formType === "ADD" ? "Add" : "Edit"} Page`}</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          {inputs.type_of_page !== "thanks_page" &&
            inputs.type_of_page !== "payment_page" && (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                {formType === "ADD" ? (
                  <MUIAutocomplete
                    inputLabel="Choose Template"
                    selectedOption={inputs.template}
                    setSelectedOption={(value) => {
                      handleChangePage("template", value);
                    }}
                    optionsList={dataLists.template_pages ?? []}
                    name="sale_page_title"
                    required
                  />
                ) : (
                  <TextField
                    label="Choose Template"
                    variant="outlined"
                    fullWidth
                    disabled
                    value={inputs.template}
                  />
                )}
              </div>
            )}
          {inputs.type_of_page !== "thanks_page" &&
            inputs.type_of_page !== "payment_page" && (
              <>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <FormControl fullWidth disabled>
                    <InputLabel id="demo-simple-select-label8">
                      Type Of Page
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label8"
                      id="demo-simple-select"
                      name="type_of_page"
                      value={inputs.type_of_page}
                      label="Type Of Page"
                      onChange={handleChange}
                      required
                    >
                      <MenuItem value="sale_page">Sale Page</MenuItem>
                      <MenuItem value="thanks_page">Thanks Page</MenuItem>
                      <MenuItem value="payment_page">Payment Page</MenuItem>
                      <MenuItem value="book_a_call_page">
                        Book A Call Page
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </>
            )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Page Title"
              variant="outlined"
              fullWidth
              name="sale_page_title"
              required
              value={inputs.sale_page_title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Page Alias Title"
              variant="outlined"
              fullWidth
              name="page_alias_url"
              required
              value={inputs.page_alias_url}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Meta Title"
              variant="outlined"
              fullWidth
              name="meta_title"
              required
              value={inputs.meta_title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Meta Keyword"
              variant="outlined"
              fullWidth
              name="meta_keywords"
              required
              value={inputs.meta_keywords}
              onChange={handleChange}
            />
          </div>
          {inputs.type_of_page !== "thanks_page" &&
            inputs.type_of_page !== "payment_page" && (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label9">
                    Payment Mode
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label9"
                    id="demo-simple-select"
                    name="payment_mode"
                    required
                    value={inputs.payment_mode}
                    label="Payment Mode"
                    onChange={handleChange}
                  >
                    <MenuItem value="live">Live</MenuItem>
                    <MenuItem value="sandBox">SandBox</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
          {inputs.type_of_page === "sale_page" && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label10">
                    Select Thanks Page
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label10"
                    id="demo-simple-select"
                    name="thanks_template"
                    value={inputs.thanks_template}
                    label="Select Thanks Page"
                    onChange={handleChange}
                  >
                    <MenuItem value="none">None</MenuItem>
                    {dataLists?.thanks_template_array?.map((page, i) => {
                      return (
                        <MenuItem value={page._id} key={i}>
                          {page.template_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label11">
                    Select Payment Page
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label11"
                    id="demo-simple-select"
                    name="payment_template"
                    value={inputs.payment_template}
                    label="Select Payment Page"
                    onChange={handleChange}
                  >
                    {dataLists?.payment_template_array?.map((page, i) => {
                      return (
                        <MenuItem value={page._id} key={i}>
                          {page.template_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </>
          )}
          {inputs.type_of_page == "book_a_call_page" && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label12">
                    Is Paid
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label12"
                    id="demo-simple-select"
                    name="is_paid"
                    required
                    value={inputs.is_paid}
                    label="Is Paid"
                    onChange={handleChange}
                  >
                    <MenuItem value={false}>No</MenuItem>
                    <MenuItem value={true}>Yes</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label13">
                    Booking Tracking
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label13"
                    id="demo-simple-select"
                    name="booking_tracking"
                    required
                    value={inputs.booking_tracking}
                    label="Booking Tracking"
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Book Call Custom Url"
                  variant="outlined"
                  fullWidth
                  name="book_a_call_custom_url"
                  value={inputs.book_a_call_custom_url}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <MUIAutocomplete
                  inputLabel="Call Type"
                  selectedOption={inputs.book_a_call_type}
                  setSelectedOption={(value) => {
                    handleChangeOthers("book_a_call_type", value);
                  }}
                  optionsList={dataLists.call_types ?? []}
                  name="title"
                  required
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <MUIAutocomplete
                  inputLabel="Lead Status"
                  selectedOption={inputs.lead_status}
                  setSelectedOption={(v) => {
                    handleChangeOthers("lead_status", v);
                  }}
                  optionsList={dataLists.lead_status ?? []}
                  autoComplete="new-password"
                  name="title"
                />
              </div>
              <div className="col-10 col-md-5 mt-4 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label13">
                    Show Booking Slots
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label13"
                    id="demo-simple-select"
                    name="show_slotes"
                    value={inputs.show_slotes}
                    label="Show Booking Slots"
                    onChange={handleChangeSlot}
                  >
                    <MenuItem value="all_time">All Time</MenuItem>
                    <MenuItem value="specific_time">Specific Days</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-2 col-md-1 mt-4">
                <div className="show-free-plans-in-list-info">
                  <Tooltip
                    title={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `<p>From this dropdown menu, you can decide whether you want to display all available appointment slots on the page or only specific days.</p>
<ol>
<li>
<p><strong>Show All Days:</strong> If you select "All Days" from the dropdown, the page will display all available slots.</p>
</li>
<li>
<p><strong>Show Specific Days:</strong> If you choose "Specific Days," an additional field will appear where you can specify the number of days for which you want to display slots. Enter the number of days you want to show and only slots for the selected number of days will be displayed. The rest will be hidden and updated as time goes by.</p>
</li>
</ol>`,
                        }}
                      />
                    }
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                </div>
              </div>
              {inputs.show_slotes == "specific_time" && (
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Number Of Days"
                    variant="outlined"
                    fullWidth
                    required
                    type="number"
                    name="show_slotes_duration"
                    value={inputs.show_slotes_duration}
                    onChange={handleChange}
                  />
                </div>
              )}
            </>
          )}
          {inputs.type_of_page !== "thanks_page" &&
            inputs.type_of_page !== "payment_page" && (
              <>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label14">
                      Redirect User
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label14"
                      id="demo-simple-select"
                      name="redirect_user"
                      value={inputs.redirect_user}
                      label="Redirect User"
                      onChange={handleChange}
                    >
                      <MenuItem value="thanks">Thanks Page</MenuItem>
                      {inputs.type_of_page == "book_a_call_page" && (
                        <MenuItem value="appointment">Appointment</MenuItem>
                      )}
                      <MenuItem value="other_link">Other Link</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {inputs.redirect_user === "other_link" ? (
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Redirect Link"
                      variant="outlined"
                      fullWidth
                      required
                      name="custom_link"
                      type="url"
                      value={inputs.custom_link}
                      onChange={handleChange}
                      onWheel={(event) => {
                        event.preventDefault();
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Active Campaign List id"
                    variant="outlined"
                    fullWidth
                    name="active_campaign_list_id"
                    type="number"
                    value={inputs.active_campaign_list_id}
                    onChange={handleChange}
                    onWheel={(event) => {
                      event.preventDefault();
                    }}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Active Campaign Tag Id"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="active_campaign_tag_id"
                    value={inputs.active_campaign_tag_id}
                    onChange={handleChange}
                  />
                </div>
              </>
            )}
          {/* header and footer menu */}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label15">
                Header Menu
              </InputLabel>
              <Select
                labelId="demo-simple-select-label15"
                id="demo-simple-select"
                name="header_menu"
                value={inputs.header_menu}
                label="Header Menu"
                onChange={handleChange}
              >
                <MenuItem value="none" key={"123"}>
                  None
                </MenuItem>
                {dataLists?.menu?.map((menu, i) => {
                  return (
                    <MenuItem value={menu._id} key={i}>
                      {menu.menu_title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label16">
                Footer Menu
              </InputLabel>
              <Select
                labelId="demo-simple-select-label16"
                id="demo-simple-select"
                name="footer_menu"
                value={inputs.footer_menu}
                label="Footer Menu"
                onChange={handleChange}
              >
                <MenuItem value={"none"} key={"11sq1"}>
                  None
                </MenuItem>
                {dataLists?.menu?.map((menu, i) => {
                  return (
                    <MenuItem value={menu._id} key={i}>
                      {menu.menu_title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          {}
          {is_website_pages &&
            inputs.type_of_page !== "thanks_page" &&
            inputs.type_of_page !== "payment_page" && (
              <>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label1">
                      Do you want to use default commission
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label1"
                      id="demo-simple-select"
                      name="is_default_commission"
                      required
                      value={inputs.is_default_commission}
                      label=" Do you want to use default commission"
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {inputs.is_default_commission && (
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="default commission in %"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="default_commission_percentage"
                      value={inputs.default_commission_percentage}
                      InputProps={{ inputProps: { min: 1, max: 100 } }}
                      onChange={handleChange}
                    />
                  </div>
                )}
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label2">
                      Is Publically Accessible
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label2"
                      id="demo-simple-select"
                      name="is_publically_accessible"
                      required
                      value={inputs.is_publically_accessible}
                      label="Is Publically Accessible"
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </>
            )}
          {/* header and footer menu end */}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label3">
                Use general brand logo, footer logo and favicon?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label3"
                id="demo-simple-select"
                name="is_general_brand_logo"
                required
                value={inputs.is_general_brand_logo}
                label="Use general brand logo, footer logo and favicon?"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label4">
                Use general Social Media Links?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label4"
                id="demo-simple-select"
                name="is_general_social_links"
                required
                value={inputs.is_general_social_links}
                label="Use general Social Media Links?"
                onChange={handleChange}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label5">
                Auto Generate Password
              </InputLabel>
              <Select
                labelId="demo-simple-select-label5"
                id="demo-simple-select"
                name="is_password_auto_generated"
                required
                value={inputs.is_password_auto_generated}
                label="Auto Generate Password"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label7">
                Show Free Plan In Popup
              </InputLabel>
              <Select
                labelId="demo-simple-select-label7"
                id="demo-simple-select"
                name="show_free_plan_in_popup"
                required
                value={inputs.show_free_plan_in_popup}
                label="Show Free Plan In Popup"
                onChange={handleChange}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Allowed Payment Methods"
              selectedOption={inputs.allowed_payment_methods}
              setSelectedOption={handleChangeMethod}
              optionsList={PAYMENT_METHODS}
              name="label"
              required
              multiple={true}
            />
          </div>
          {inputs.type_of_page === "sale_page" && (
            <>
              <div className="col-10 col-md-5 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label7">
                    Show Free Plans In List
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label7"
                    id="demo-simple-select"
                    name="show_free_plans_in_list"
                    required
                    value={inputs.show_free_plans_in_list}
                    label="Show Free Plans In List"
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-2 col-md-1 mt-4">
                <div className="show-free-plans-in-list-info">
                  <Tooltip
                    title={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `<ul class="mt-3 plans-in-list-info-list"><li>This dropdown will determine whether to display the free plan in the plan's list or not.</li><li>If "yes" is selected, the free plan will be shown on both the sales page and the payment page.</li><li>If "no" is chosen, the free plan will not appear on the sales page or the payment page. It will also not be displayed on pages that are free, such as those containing only the free plan form.</li>
                      </ul>`,
                        }}
                      />
                    }
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                </div>
              </div>
            </>
          )}
          {inputs.type_of_page === "payment_page" && (
            <>
              <div className="col-10 col-md-5 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label7">
                    Is Show Address
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label7"
                    id="demo-simple-select"
                    name="is_show_address"
                    required
                    value={inputs.is_show_address}
                    label="Is Show Address"
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-2 col-md-1 mt-4">
                <div className="show-free-plans-in-list-info">
                  <Tooltip
                    title={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `<ul class="mt-3 plans-in-list-info-list"><li>
                          You can manage this through the dropdown menu whether
                          the shipping address should be displayed on the
                          payment page.
                        </li>
                        <li>
                          If you choose "NO," the shipping address fields will
                          not be shown on the payment page. However, if there
                          are products associated with that plan, the shipping
                          address will always be displayed regardless of whether
                          "YES" or "NO" is chosen in the dropdown.
                        </li>
                        <li>
                          If you select "YES," the shipping address fields will
                          be shown on the payment page.
                        </li>
                    </ul>`,
                        }}
                      />
                    }
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                </div>
              </div>
            </>
          )}
          <div className="col-10 col-md-6 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label7">
                Use Plan Package ?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label7"
                id="demo-simple-select"
                name="is_use_plan_package"
                required
                value={inputs.is_use_plan_package}
                label="Use Plan Package ?"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.type_of_page === "sale_page" && (
            <>
              <div className="col-10 col-md-5 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label7">
                    Use Comparison Chart ?
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label7"
                    id="demo-simple-select"
                    name="use_comparison_chart"
                    value={inputs.use_comparison_chart}
                    label="Use Comparison Chart ?"
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-2 col-md-1 mt-4">
                <div className="show-free-plans-in-list-info">
                  <Tooltip
                    title={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `<p class="pt-2">You can manage whether to display the comparison chart on your website page using this dropdown.</p>
<ul>
<li>If "Yes" is selected in the dropdown, the "Feature Chart Settings" option will appear in the page&rsquo;s 3-dot menu.</li>
<li>If "No" is selected, the setting will not be available in the 3-dot menu for admins, and the chart will not appear on the website page.</li>
</ul>`,
                        }}
                      />
                    }
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                </div>
              </div>
            </>
          )}

          <div className="col-10 col-md-6 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label7">
                Allow Discount Coupon Code
              </InputLabel>
              <Select
                labelId="demo-simple-select-label7"
                id="demo-simple-select"
                name="allow_coupon_code"
                required
                value={inputs.allow_coupon_code}
                label="Allow Discount Coupon Code"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>

          {(inputs.is_general_social_links == false ||
            inputs.is_general_social_links == "false") && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Facebook Link"
                  variant="outlined"
                  fullWidth
                  name="facebook_link"
                  value={inputs.facebook_link}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Pinterest Link"
                  variant="outlined"
                  fullWidth
                  name="pinterest_link"
                  value={inputs.pinterest_link}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Instagram Link"
                  variant="outlined"
                  fullWidth
                  name="instagram_link"
                  value={inputs.instagram_link}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Youtube Link"
                  variant="outlined"
                  fullWidth
                  name="youtube_link"
                  value={inputs.youtube_link}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="SnapChat Link"
                  variant="outlined"
                  fullWidth
                  name="snap_chat_link"
                  value={inputs.snap_chat_link}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Twitter Link"
                  variant="outlined"
                  fullWidth
                  name="twitter_link"
                  value={inputs.twitter_link}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Linkedin Link"
                  variant="outlined"
                  fullWidth
                  name="linkedin_link"
                  value={inputs.linkedin_link}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Tiktok Link"
                  variant="outlined"
                  fullWidth
                  name="tiktok_link"
                  value={inputs.tiktok_link}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Mailbox Link"
                  variant="outlined"
                  fullWidth
                  name="mailbox_link"
                  value={inputs.mailbox_link}
                  onChange={handleChange}
                />
              </div>
            </>
          )}
          {inputs.is_general_brand_logo == false && (
            <>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div className="row w-100 div-style ms-0 pt-0">
                  <div className="col-6">
                    <p className="">Brand Logo</p>
                    <FormHelperText className="pt-0">
                      Recommended Size (200 X 100) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-2">
                    <div className="del_img_icon">
                      {images.brand_logo ? (
                        <img
                          src={images.brand_logo}
                          height="50"
                          className="image_border"
                        />
                      ) : formType === "EDIT" && images.brand_logo ? (
                        <img
                          src={images.brand_logo}
                          height="50"
                          className="image_border"
                        />
                      ) : (
                        oldImages.brand_logo && (
                          <img
                            src={s3baseUrl + oldImages.brand_logo}
                            className="image_border"
                            height="50"
                          />
                        )
                      )}
                      {images.brand_logo || oldImages.brand_logo ? (
                        <Tooltip title="Delete image">
                          <span
                            className="pointer_cursor"
                            onClick={() => {
                              handleRemoveImage("brand_logo");
                            }}
                          >
                            X
                          </span>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-4 text-end pt-2">
                    <label htmlFor="contained-button-fileFb">
                      <Input
                        accept="image/*"
                        id="contained-button-fileFb"
                        multiple
                        type="file"
                        name="brand_logo"
                        onChange={fileChangedHandler}
                      />

                      <Button
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
                {inputs?.image?.name == "" ? (
                  ""
                ) : (
                  <p className="text-secondary">{inputs?.image?.name}</p>
                )}
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div className="row w-100 div-style ms-0 pt-0">
                  <div className="col-6">
                    <p className="">Brand Favicon</p>
                    <FormHelperText className="pt-0">
                      Recommended Size(32 X 32) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-2">
                    <div className="del_img_icon">
                      {images.brand_favicon ? (
                        <img
                          src={images.brand_favicon}
                          height="50"
                          className="image_border"
                        />
                      ) : formType === "EDIT" && images.brand_favicon ? (
                        <img
                          src={images.brand_favicon}
                          height="50"
                          className="image_border"
                        />
                      ) : (
                        oldImages.brand_favicon && (
                          <img
                            src={s3baseUrl + oldImages.brand_favicon}
                            className="image_border"
                            height="50"
                          />
                        )
                      )}
                      {images.brand_favicon || oldImages.brand_favicon ? (
                        <Tooltip title="Delete image">
                          <span
                            className="pointer_cursor"
                            onClick={() => {
                              handleRemoveImage("brand_favicon");
                            }}
                          >
                            X
                          </span>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-4 text-end pt-2">
                    <label htmlFor="contained-button-fileInstagram">
                      <Input
                        accept="image/*"
                        id="contained-button-fileInstagram"
                        multiple
                        type="file"
                        name="brand_favicon"
                        onChange={fileChangedHandler}
                      />

                      <Button
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
                {inputs?.image?.name == "" ? (
                  ""
                ) : (
                  <p className="text-secondary">{inputs?.image?.name}</p>
                )}
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div className="row w-100 div-style ms-0 pt-0">
                  <div className="col-6">
                    <p className="">Footer Logo</p>
                    <FormHelperText className="pt-0">
                      Recommended Size (200 X 100) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-2">
                    <div className="del_img_icon">
                      {images.footer_logo ? (
                        <img
                          src={images.footer_logo}
                          height="50"
                          className="image_border"
                        />
                      ) : formType === "EDIT" && images.footer_logo ? (
                        <img
                          src={images.footer_logo}
                          height="50"
                          className="image_border"
                        />
                      ) : (
                        oldImages.footer_logo && (
                          <img
                            src={s3baseUrl + oldImages.footer_logo}
                            className="image_border"
                            height="50"
                          />
                        )
                      )}
                      {images.footer_logo || oldImages.footer_logo ? (
                        <Tooltip title="Delete image">
                          <span
                            className="pointer_cursor"
                            onClick={() => {
                              handleRemoveImage("footer_logo");
                            }}
                          >
                            X
                          </span>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-4 text-end pt-2">
                    <label htmlFor="contained-button-fileTwitter">
                      <Input
                        accept="image/*"
                        id="contained-button-fileTwitter"
                        multiple
                        type="file"
                        name="footer_logo"
                        onChange={fileChangedHandler}
                      />

                      <Button
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
                {inputs?.image?.name == "" ? (
                  ""
                ) : (
                  <p className="text-secondary">{inputs?.image?.name}</p>
                )}
              </div>
            </>
          )}
          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Meta Description"
                multiline
                rows={3}
                name="meta_description"
                value={inputs.meta_description}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          {/* <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Facebook Tracking Code Header"
                multiline
                rows={3}
                name="pixel_code_header"
                value={inputs.pixel_code_header}
                onChange={handleChange}
              />
            </FormControl>
          </div> */}
          <div className="col-12 ">
            <p className="tracking_code_heading">
              Facebook Tracking Code Header
            </p>

            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              value={inputs.pixel_code_header}
              name="pixel_code_header"
              onChange={handleChange}
              placeholder="Facebook Tracking Code Header"
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "8px",
                paddingLeft: "5px",
                outline: "none",
                focus: "green",
              }}
            />
            <p className="tracking_code_heading">Facebook Tracking Code Body</p>
            <TextareaAutosize
              aria-label="empty textarea"
              value={inputs.pixel_code_body}
              name="pixel_code_body"
              onChange={handleChange}
              className="textarea-autosize"
              placeholder="Facebook Tracking Code Body"
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "8px",
                paddingLeft: "5px",
                outline: "none",
                focus: "green",
              }}
            />
            <p className="tracking_code_heading">Google Tracking Code Header</p>
            <TextareaAutosize
              aria-label="empty textarea"
              value={inputs.google_tracking_code_header}
              name="google_tracking_code_header"
              onChange={handleChange}
              className="textarea-autosize"
              placeholder="Google Tracking Code Header"
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "8px",
                paddingLeft: "5px",
                outline: "none",
              }}
            />
            <p className="tracking_code_heading">Google Tracking Code Body</p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              value={inputs.google_tracking_code_body}
              name="google_tracking_code_body"
              onChange={handleChange}
              placeholder="Google Tracking Code Body"
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "8px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
            <p className="tracking_code_heading">Website Portal Css Code</p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              value={inputs.website_portal_css_code}
              name="website_portal_css_code"
              onChange={handleChange}
              placeholder="Website Portal Css Code"
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "8px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
            <p className="tracking_code_heading">Website Portal Js Code</p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              value={inputs.website_portal_js_code}
              name="website_portal_js_code"
              onChange={handleChange}
              placeholder="Website Portal Js Code"
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "8px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />

            {/* </FormControl> */}
          </div>
          <div className="col-12 mt-4">
            <h4>QR Code Message *</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="message"
              editorHeight={320}
            />
          </div>
          {/* <div className="col-12 mt-4">
            <h4>Thank You Page Content</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="thankyou_page_content"
              editorHeight={320}
            />
          </div> */}
          <div className="text-end mt-4">
            {" "}
            <button
              className="small-contained-button me-2"
              onClick={(e) => handleSubmit(e, "exist")}
            >
              {formType == "ADD" ? "Submit & Exit" : "Update & Exit"}
            </button>
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
