import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import {
  delete_lead_status,
  lead_status_list,
} from "src/DAL/SaleSections/LeadStatus";
import moment from "moment";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import Levels from "./Levels";
import {
  delete_mission_level_list,
  get_mission_level_list,
} from "src/DAL/MissionApi/MissionApiNew";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MissionsList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [missionLevel, setMissionLevel] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [referredpop, setReferredpop] = useState(false);
  const [levelsArray, setLevelsArray] = useState(false);

  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  const searchFunction = (e) => {
    if (e) {
      e.preventDefault();
    }
    setPage(0);
    setPageCount(1);
    localStorage.setItem("mission_levels_search", searchText);

    getMissionLevels(searchText);
  };

  const getMissionLevels = async (text) => {
    setIsLoading(true);
    let postData = {
      search_text: text ? text : "",
    };
    const result = await get_mission_level_list(postData, page, rowsPerPage);
    if (result.code === 200) {
      console.log(result, "status__status");
      const mission_levels = result.mission_lavel.map((status) => {
        return {
          ...status,
          title: status.title,
          table_avatar: {
            src: s3baseUrl + status.image?.thumbnail_1,
            alt: status.title,
          },
        };
      });
      setMissionLevel(mission_levels);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_number_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    navigate(`/mission/addOrUpdate/${value._id}`, {
      state: value,
    });
  };
  // const handlelevel = (value) => {
  //   navigate(`/mission/manage-level-configuration/${value.title}`, {
  //     state: value,
  //   });
  // };
  const handleChallenges = (value) => {
    navigate(`/mission/challenge/${value._id}`);
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await delete_mission_level_list(deleteDoc._id);
    if (result.code === 200) {
      getMissionLevels();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/mission/addOrUpdate`);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
      color: "#da3030",
    },
    // {
    //   label: "Manage Level Configuration",
    //   icon: "akar-icons:edit",
    //   handleClick: handlelevel,
    // },
    {
      label: "Missions",
      icon: "akar-icons:edit",
      handleClick: handleChallenges,
    },
  ];

  const handleOPen = (value) => {
    setReferredpop(true);
    setLevelsArray(value);
  };
  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "title",
      label: "Title",
      alignRight: false,
    },
    {
      id: "table_avatar",
      label: "Image",
      alignRight: false,
      type: "thumbnail",
    },

    { id: "status", label: "Status", alignRight: false, type: "row_status" },
  ];
  useEffect(() => {
    let search_text = localStorage.getItem("mission_levels_search");
    if (search_text) {
      setSearchText(search_text);
      getMissionLevels(search_text);
    } else {
      setSearchText("");
      getMissionLevels("");
    }
  }, [page, rowsPerPage]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-8 col-sm-12">
            <h2>Mission Levels</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Mission Level
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={missionLevel}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
        <WhatsappPopUpModel
          open={referredpop}
          setOpen={setReferredpop}
          title={"Badges  "}
          show_date_and_income={true}
          componentToPassDown={<Levels levelsArray={levelsArray} />}
        />
      </div>
    </>
  );
}
