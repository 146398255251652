import React, { useEffect } from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import {
  _add_membership_level_pyramid,
  _edit_membership_level_pyramid,
  membership_level_pyramid_detail,
} from "src/DAL/BonusRoadMAp/BonusRoadMApApi";
import { s3baseUrl } from "src/config/config";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddEditMapParamid() {
  const navigate = useNavigate();
  const Search = useLocation();
  const params = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);

  let find_path = Search.pathname.includes("road-map-levels/pyramid/add");

  const [inputs, setInputs] = useState({
    title: "",
    coins: "",
    status: "true",
    order: "",
    image: {},
    before_status_image: {},
    reached_status_image: {},
    old_image: "",
    old_before_status_image: "",
    old_reached_status_image: "",
  });

  const [previews, setPreviews] = useState({
    image: "",
    before_status_image: "",
    reached_status_image: "",
  });

  const fileChangedHandler = (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      setPreviews({ ...previews, [name]: URL.createObjectURL(files[0]) });
      setInputs({ ...inputs, [name]: files[0] });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const hanldeNavigate = () => {
    navigate(-1);
  };

  const get_map_levels_List = async () => {
    const result = await membership_level_pyramid_detail(params?.map_id);
    if (result.code == 200) {
      setInputs({
        ...inputs,
        ...result.pyramid,
        old_image: result.pyramid?.icon?.thumbnail_1,
        old_before_status_image:
          result.pyramid?.before_status_image?.thumbnail_1,
        old_reached_status_image:
          result.pyramid?.reached_status_image?.thumbnail_1,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("coins", inputs.coins);
    formData.append("status", inputs.status);
    if (find_path) {
      formData.append("road_map_level", params?.map_id);
    } else {
      formData.append("order", inputs.order);
    }
    if (previews.image) {
      formData.append("image", inputs.image);
    }
    if (previews.reached_status_image) {
      formData.append("reached_status_image", inputs.reached_status_image);
    }
    if (previews.before_status_image) {
      formData.append("before_status_image", inputs.before_status_image);
    }
    const result = find_path
      ? await _add_membership_level_pyramid(formData)
      : await _edit_membership_level_pyramid(formData, params?.map_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      hanldeNavigate();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (find_path) {
      setIsLoading(false);
    } else {
      get_map_levels_List();
    }
  }, [find_path]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fulid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={hanldeNavigate}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>{find_path ? "Add" : "Edit"} Pyramid</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Coins"
              variant="outlined"
              fullWidth
              required
              type="number"
              name="coins"
              value={inputs.coins}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                required
                value={inputs.status}
                label="Status *"
                onChange={handleChange}
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          {!find_path && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                required
                name="order"
                value={inputs.order}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="row w-100 div-style ms-0 pt-4">
            <div className="col-5">
              <p className="">Icon *</p>
              <FormHelperText className="pt-0">
                Icon Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP","GIF")
              </FormHelperText>
            </div>
            <div className="col-2">
              <img
                src={
                  previews.image
                    ? previews.image
                    : inputs.old_image
                    ? s3baseUrl + inputs.old_image
                    : ""
                }
                height={50}
              />
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  onChange={fileChangedHandler}
                />

                <Button
                  id="contained-button-file"
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>{" "}
          <div className="row w-100 div-style ms-0 pt-4">
            <div className="col-5">
              <p className="">Reached Status *</p>
              <FormHelperText className="pt-0">
                Icon Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP","GIF")
              </FormHelperText>
            </div>
            <div className="col-2">
              <img
                src={
                  previews.reached_status_image
                    ? previews.reached_status_image
                    : inputs.old_reached_status_image
                    ? s3baseUrl + inputs.old_reached_status_image
                    : ""
                }
                height={50}
              />
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file-2">
                <Input
                  accept="image/*"
                  id="contained-button-file-2"
                  multiple
                  type="file"
                  name="reached_status_image"
                  onChange={fileChangedHandler}
                />

                <Button
                  id="contained-button-file-2"
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          <div className="row w-100 div-style ms-0 pt-4">
            <div className="col-5">
              <p className="">Before Status *</p>
              <FormHelperText className="pt-0">
                Icon Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP","GIF")
              </FormHelperText>
            </div>
            <div className="col-2">
              <img
                src={
                  previews.before_status_image
                    ? previews.before_status_image
                    : inputs.old_before_status_image
                    ? s3baseUrl + inputs.old_before_status_image
                    : ""
                }
                height={50}
              />
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file-1">
                <Input
                  accept="image/*"
                  id="contained-button-file-1"
                  multiple
                  type="file"
                  name="before_status_image"
                  onChange={fileChangedHandler}
                />

                <Button
                  id="contained-button-file-1"
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {find_path ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
