import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  clientSettingApi,
  updateConsultantGeneralSettingApi,
} from "src/DAL/ClientSetting/ClientSetting";
import { s3baseUrl } from "src/config/config";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function UpdateConsultantGeneralSetting() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = useState({
    meta_title: "",
    meta_description: "",
    dashboard_content: "",
    content_for_no_template_page_access: "",
    consultant_feed_label: "",
    delegate_feed_label: "",
    assessment_page_content: "",
    brandLogo: {},
    brandLogo2: {},
    brandFavIcon: {},
    theCosmosBannerImage: {},
    consultantFeedIcon: {},
    delegateFeedIcon: {},
    marketing_feed_icon: {},
    inner_circle_feed_icon: {},
    brand_name: "",
    upcoming_event_heading: "",
    current_event_heading: "",
    oldBrandLogo: "",
    oldBrandLogo2: "",
    oldbrandFavIcon: "",
    oldTheCosmosBannerImage: " ",
    oldConsultantFeedIcon: " ",
    oldDelegateFeedIcon: " ",
    number_of_support_tickets_allowed: 5,
    book_call_meeting_platform: "zoom",
    record_meeting: true,
    marketing_feed_label: "",
    inner_circle_feed_label: "",
    bank_payment_details: "",
    pixel_code_header: "",
    pixel_code_body: "",
    analytic_google_code: "",
    show_project_logs: false,
  });

  const [images, setImages] = useState({
    brandLogo: "",
    brandLogo2: "",
    brandFavIcon: "",
    theCosmosBannerImage: "",
    consultantFeedIcon: "",
    delegateFeedIcon: "",
    marketing_feed_icon: "",
    inner_circle_feed_icon: "",
  });

  const fileChangedHandler = (e) => {
    const name = e.target.name;
    const value = e.target.files[0];
    setInputs({
      ...inputs,
      [name]: value,
    });

    setImages({
      ...images,
      [name]: URL.createObjectURL(value),
    });
  };

  const clientDetail = async () => {
    const result = await clientSettingApi();
    if (result.code == 200) {
      let generalSetting = result?.content_setting?.consultant_general_setting;
      console.log(generalSetting, "---generalSetting");
      setInputs({
        ...generalSetting,
        ["brandLogo"]: generalSetting?.brand_logo,
        ["brandLogo2"]: generalSetting?.brand_logo_2,
        ["oldBrandLogo"]: generalSetting?.brand_logo,
        ["oldBrandLogo2"]: generalSetting?.brand_logo_2,
        ["brandFavIcon"]: generalSetting?.brand_favicon,
        ["oldBrandFavIcon"]: generalSetting?.brand_favicon,
        ["oldTheCosmosBannerImage"]: generalSetting?.the_cosmos_banner_image,
        ["oldConsultantFeedIcon"]: generalSetting?.consultant_feed_icon,
        ["oldDelegateFeedIcon"]: generalSetting?.delegate_feed_icon,
        ["theCosmosBannerImage"]: generalSetting?.the_cosmos_banner_image,
        ["record_meeting"]: generalSetting?.record_meeting,
        ["book_call_meeting_platform"]:
          generalSetting?.book_call_meeting_platform,
        ["number_of_support_tickets_allowed"]:
          generalSetting?.number_of_support_tickets_allowed ?? 5,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    const formData = new FormData();
    formData.append("meta_title", inputs.meta_title);
    formData.append("meta_description", inputs.meta_description);
    formData.append("brand_name", inputs.brand_name);
    formData.append(
      "number_of_support_tickets_allowed",
      inputs.number_of_support_tickets_allowed
    );
    formData.append(
      "book_call_meeting_platform",
      inputs.book_call_meeting_platform
    );
    formData.append("record_meeting", inputs.record_meeting);
    formData.append("upcoming_event_heading", inputs.upcoming_event_heading);
    formData.append("current_event_heading", inputs.current_event_heading);
    formData.append("dashboard_content", inputs.dashboard_content);
    formData.append("consultant_feed_label", inputs.consultant_feed_label);
    formData.append("delegate_feed_label", inputs.delegate_feed_label);
    formData.append("marketing_feed_label", inputs.marketing_feed_label);
    formData.append("inner_circle_feed_label", inputs.inner_circle_feed_label);
    formData.append("bank_payment_details", inputs.bank_payment_details);
    formData.append("pixel_code_header", inputs.pixel_code_header);
    formData.append("pixel_code_body", inputs.pixel_code_body);
    formData.append(
      "show_project_logs",
      inputs.show_project_logs ? true : false
    );
    formData.append("analytic_google_code", inputs.analytic_google_code);
    formData.append(
      "content_for_no_template_page_access",
      inputs.content_for_no_template_page_access
    );
    formData.append("assessment_page_content", inputs.assessment_page_content);

    if (images.brandLogo) {
      formData.append("brand_logo", inputs.brandLogo);
    }
    if (images.brandLogo2) {
      formData.append("brand_logo_2", inputs.brandLogo2);
    }
    if (images.brandFavIcon) {
      formData.append("brand_favicon", inputs.brandFavIcon);
    }
    if (images.theCosmosBannerImage) {
      formData.append("the_cosmos_banner_image", inputs.theCosmosBannerImage);
    }
    if (images.consultantFeedIcon) {
      formData.append("consultant_feed_icon", inputs.consultantFeedIcon);
    }
    if (images.delegateFeedIcon) {
      formData.append("delegate_feed_icon", inputs.delegateFeedIcon);
    }
    if (images.inner_circle_feed_icon) {
      formData.append("inner_circle_feed_icon", inputs.inner_circle_feed_icon);
    }
    if (images.marketing_feed_icon) {
      formData.append("marketing_feed_icon", inputs.marketing_feed_icon);
    }

    const result = await updateConsultantGeneralSettingApi(formData);
    if (result.code === 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    clientDetail();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <h2>General Settings</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-md-6 mt-4">
            <TextField
              id="outlined-basic"
              label="Meta title for client"
              variant="outlined"
              fullWidth
              required
              name="meta_title"
              value={inputs.meta_title}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-4">
            <TextField
              id="outlined-basic"
              label="Meta description for client"
              variant="outlined"
              fullWidth
              required
              name="meta_description"
              value={inputs.meta_description}
              onChange={handleChange}
            />
          </div>
          <h3 className="mt-3">General Information</h3>
          <div className="col-12 col-md-6 mt-2">
            <TextField
              id="outlined-basic"
              label="Brand Name"
              variant="outlined"
              fullWidth
              required
              name="brand_name"
              value={inputs.brand_name}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-4 mt-md-2">
            <TextField
              id="outlined-basic"
              label="Upcomming Event Heading"
              variant="outlined"
              fullWidth
              required
              name="upcoming_event_heading"
              value={inputs.upcoming_event_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-4">
            <TextField
              id="outlined-basic"
              label="Current Event Heading"
              variant="outlined"
              fullWidth
              required
              name="current_event_heading"
              value={inputs.current_event_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-4">
            <TextField
              id="outlined-basic"
              label="Consultant Feed Tooltip Label"
              variant="outlined"
              fullWidth
              name="consultant_feed_label"
              value={inputs.consultant_feed_label}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-4">
            <TextField
              id="outlined-basic"
              label="Delegate Feed Tooltip Label"
              variant="outlined"
              fullWidth
              name="delegate_feed_label"
              value={inputs.delegate_feed_label}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-4">
            <TextField
              id="outlined-basic"
              label="Inner Circle Feed Tooltip Label"
              variant="outlined"
              fullWidth
              name="inner_circle_feed_label"
              value={inputs.inner_circle_feed_label}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-4">
            <TextField
              id="outlined-basic"
              label="Marketing Team Feed Tooltip Label"
              variant="outlined"
              fullWidth
              name="marketing_feed_label"
              value={inputs.marketing_feed_label}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-4">
            <TextField
              id="outlined-basic"
              label="Number of Support Tickets Allowed"
              variant="outlined"
              fullWidth
              type="number"
              inputProps={{ min: "0" }}
              name="number_of_support_tickets_allowed"
              value={inputs.number_of_support_tickets_allowed}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label" required>
                Book Call Meeting Platform
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="book_call_meeting_platform"
                value={inputs.book_call_meeting_platform}
                label="Book Call Meeting Platform"
                onChange={handleChange}
                required
              >
                <MenuItem value="zoom_meeting">Zoom Meeting</MenuItem>
                <MenuItem value="dd_portal">Dynamite Lifestyle</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.book_call_meeting_platform === "dd_portal" && (
            <div className="col-12 col-md-6 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label" required>
                  Record Meeting
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="record_meeting"
                  value={inputs.record_meeting}
                  label="Record Meeting"
                  onChange={handleChange}
                  required
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required className="">
              <InputLabel id="demo-simple-select-label1">
                Show Project Logs
              </InputLabel>
              <Select
                labelId="demo-simple-select-label1"
                id="demo-simple-select1"
                value={inputs.show_project_logs ? true : false}
                name="show_project_logs"
                label="Show Project Logs"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Pixel Code Header"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              name="pixel_code_header"
              value={inputs.pixel_code_header}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Pixel Code Body"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              name="pixel_code_body"
              value={inputs.pixel_code_body}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Google Analytic Code"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              name="analytic_google_code"
              value={inputs.analytic_google_code}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Brand Logo 1</p>
                <FormHelperText className="pt-0">
                  (Recommended Size 150 X 22)
                </FormHelperText>
              </div>
              <div className="col-2">
                {images.brandLogo ? (
                  <img src={images.brandLogo} height="50" />
                ) : (
                  inputs.oldBrandLogo && (
                    <img src={s3baseUrl + inputs.oldBrandLogo} height="50" />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file1">
                  <Input
                    accept="image/*"
                    id="contained-button-file1"
                    multiple
                    type="file"
                    name="brandLogo"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.brandLogo?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.brandLogo?.name}</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Brand Logo 2</p>
                <FormHelperText className="pt-0">
                  (Recommended Size 150 X 22)
                </FormHelperText>
              </div>
              <div className="col-2">
                {images.brandLogo2 ? (
                  <img src={images.brandLogo2} height="50" />
                ) : (
                  inputs.oldBrandLogo2 && (
                    <img src={s3baseUrl + inputs.oldBrandLogo2} height="50" />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="brandLogo2"
                    onChange={fileChangedHandler}
                  />
                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.brandLogo2?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.brandLogo2?.name}</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Brand FavIcon</p>
                <FormHelperText className="pt-0">
                  (Recommended Size 32 X 32)
                </FormHelperText>
              </div>
              <div className="col-2">
                {images.brandFavIcon ? (
                  <img src={images.brandFavIcon} height="50" />
                ) : (
                  inputs.oldBrandFavIcon && (
                    <img src={s3baseUrl + inputs.oldBrandFavIcon} height="50" />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-fileFavIcon">
                  <Input
                    accept="image/*"
                    id="contained-button-fileFavIcon"
                    multiple
                    type="file"
                    name="brandFavIcon"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.brandFavIcon?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.brandFavIcon?.name}</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">The Cosmos Banner Image</p>
                <FormHelperText className="pt-0">
                  Image Size(2200) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {images.theCosmosBannerImage ? (
                  <img src={images.theCosmosBannerImage} height="50" />
                ) : (
                  inputs.oldTheCosmosBannerImage && (
                    <img
                      src={s3baseUrl + inputs.oldTheCosmosBannerImage}
                      height="50"
                    />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-the_cosmos_banner_image">
                  <Input
                    accept="image/*"
                    id="contained-button-the_cosmos_banner_image"
                    multiple
                    type="file"
                    name="theCosmosBannerImage"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.the_cosmos_banner_image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">
                {inputs?.the_cosmos_banner_image?.name}
              </p>
            )}
          </div>{" "}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Consultant Feed Icon</p>
                <FormHelperText className="pt-0">
                  Image Size(2200) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {images.consultantFeedIcon ? (
                  <img src={images.consultantFeedIcon} height="50" />
                ) : (
                  inputs.oldConsultantFeedIcon && (
                    <img
                      src={s3baseUrl + inputs.oldConsultantFeedIcon}
                      height="50"
                    />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-consultant-feed-icon">
                  <Input
                    accept="image/*"
                    id="contained-button-consultant-feed-icon"
                    multiple
                    type="file"
                    name="consultantFeedIcon"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.consultantFeedIcon?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">
                {inputs?.consultantFeedIcon?.name}
              </p>
            )}
          </div>{" "}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Delegate Feed Icon</p>
                <FormHelperText className="pt-0">
                  Image Size(2200) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {images.delegateFeedIcon ? (
                  <img src={images.delegateFeedIcon} height="50" />
                ) : (
                  inputs.oldDelegateFeedIcon && (
                    <img
                      src={s3baseUrl + inputs.oldDelegateFeedIcon}
                      height="50"
                    />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-delegate-feed-icon">
                  <Input
                    accept="image/*"
                    id="contained-button-delegate-feed-icon"
                    multiple
                    type="file"
                    name="delegateFeedIcon"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.delegateFeedIcon?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.delegateFeedIcon?.name}</p>
            )}
          </div>{" "}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Inner Circle Feed Icon</p>
                <FormHelperText className="pt-0">
                  Image Size(500) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {images.inner_circle_feed_icon ? (
                  <img src={images.inner_circle_feed_icon} height="50" />
                ) : (
                  inputs.inner_circle_feed_icon && (
                    <img
                      src={s3baseUrl + inputs.inner_circle_feed_icon}
                      height="50"
                    />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-inner-feed-icon">
                  <Input
                    accept="image/*"
                    id="contained-button-inner-feed-icon"
                    multiple
                    type="file"
                    name="inner_circle_feed_icon"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.inner_circle_feed_icon?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">
                {inputs?.inner_circle_feed_icon?.name}
              </p>
            )}
          </div>{" "}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Marketing Team Feed Icon</p>
                <FormHelperText className="pt-0">
                  Image Size(500) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {images.marketing_feed_icon ? (
                  <img src={images.marketing_feed_icon} height="50" />
                ) : (
                  inputs.marketing_feed_icon && (
                    <img
                      src={s3baseUrl + inputs.marketing_feed_icon}
                      height="50"
                    />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-marketing-feed-icon">
                  <Input
                    accept="image/*"
                    id="contained-button-marketing-feed-icon"
                    multiple
                    type="file"
                    name="marketing_feed_icon"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.marketing_feed_icon?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">
                {inputs?.marketing_feed_icon?.name}
              </p>
            )}
          </div>
          <div className="col-12 mt-4">
            <h4>Dashboard Content *</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="dashboard_content"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Content For No Template Page Access *</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="content_for_no_template_page_access"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Assessment Page Content *</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="assessment_page_content"
              editorHeight={350}
            />
          </div>{" "}
          <div className="col-12 mt-5">
            <h4>Bank Payment Details </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="bank_payment_details"
              editorHeight={350}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button" id="fixedbutton">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
