import * as React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import { Autocomplete, CircularProgress, IconButton } from "@mui/material";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import {
  _get_membership_levels_program_detail,
  _update_membership_levels_program,
} from "src/DAL/Programme/Programme";
import {
  _get_membership_levels_web_pages_detail,
  _update_membership_levels_for_web_pages,
} from "src/DAL/WebsitePages/WebsitePages";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ManageMembershipLevelsForWebPages({ type }) {
  let navigatePath = type == "template" ? "template-pages" : "website-pages";
  const navigate = useNavigate();
  const { page_id, page_slug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [membershipLevelList, setMembershipLevelList] = useState([]);
  const [selectedMembershipLevel, setSelectedMembershipLevel] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [inputData, setInputData] = useState({
    name: "",
    badges: {},
  });

  const handleChange = (value, name) => {
    const list = { ...inputData };
    list[name] = value;
    if (name == "name") {
      list["badges"] = null;
    }
    setInputData(list);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedEvent = membershipLevelList.find(
      (event) => event.title === inputData.name
    );

    const postData = {
      membership_levels: {
        _id: selectedEvent?._id || "",
        title: inputData.name || "",

        membership_level_badges: {
          _id: inputData.badges._id || "",
          title: inputData.badges.title || "",
        },
      },
    };

    setIsLoading(true);
    const result = await _update_membership_levels_for_web_pages(
      postData,
      page_slug
    );

    if (result.code === 200) {
      navigate(-1);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getProgrammeDetail = async () => {
    setIsLoading(true);
    const result = await _get_membership_levels_web_pages_detail(page_slug);
    if (result.code === 200) {
      setMembershipLevelList(result?.membership_levels);
      setSelectedMembershipLevel(result?.program?.membership_levels || null);
      setPageData(result?.program);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProgrammeDetail();
  }, []);

  useEffect(() => {
    if (selectedMembershipLevel) {
      const updatedInputList = {
        name: selectedMembershipLevel?.title,
        badges: selectedMembershipLevel?.membership_level_badges || {},
      };

      setInputData(updatedInputList);
    }
  }, [selectedMembershipLevel]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <ActiveLastBreadcrumb
            breadCrumbMenu={[
              {
                title: type == "page" ? "Pages" : "Template Pages",
                navigation: `/${navigatePath}`,
                active: false,
              },
              {
                title: pageData?.plan_title || "Payment Plan",
                navigation: `/${navigatePath}/${page_id}/payment-plans`,
                active: false,
              },
              { title: "Manage Membership Level", active: true },
            ]}
          />
        </div>
      </div>

      <form className="row mt-3" onSubmit={handleSubmit}>
        <div className="px-4">
          <div className="row pb-3 mb-3 manage-event-pixel-bg">
            <div className="col-12 col-md-6 mt-3">
              <Autocomplete
                fullWidth
                options={membershipLevelList}
                getOptionLabel={(option) => option.title || ""}
                value={
                  membershipLevelList.find(
                    (event) => event.title === inputData.name
                  ) || null
                }
                onChange={(event, newValue) =>
                  handleChange(newValue ? newValue.title : "", "name")
                }
                isOptionEqualToValue={(option, value) =>
                  option.title === value.title
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Membership Level List"
                    required
                  />
                )}
                noOptionsText="No Option Available"
              />
            </div>

            {inputData.name && (
              <div className="col-12 col-md-6 mt-3">
                <Autocomplete
                  options={
                    membershipLevelList.find(
                      (event) => event.title === inputData.name
                    )?.membership_level_badges || []
                  }
                  getOptionLabel={(badge) => badge.title || ""}
                  value={inputData.badges || null}
                  onChange={(event, newValue) =>
                    handleChange(newValue || {}, "badges")
                  }
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Badge"
                      placeholder="Badge"
                    />
                  )}
                  noOptionsText="No Badge Available"
                />
              </div>
            )}
          </div>
        </div>

        <div className="text-end" id="fixedbutton">
          <button className="small-contained-button">Submit</button>
        </div>
      </form>
    </div>
  );
}
