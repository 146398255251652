import { project_mode, project_name } from "src/config/config";
import SettingsIcon from "@mui/icons-material/Settings";
import { Icon } from "@iconify/react";
import { ListItemIcon } from "@mui/material";
import {
  AffirmationIcons,
  DashboardIcons,
  DayPlanIcons,
  GoalStatmentIcons,
  GratitudeIcons,
  meditationIcon,
  PodsIcons,
  ProfileIcons,
  ProgrammeIcons,
  QuotesIcons,
  thesourceIcons,
  VaultIcons,
} from "src/assets";
import { usePGIMode } from "src/Hooks/PGIModeContext";
// ----------------------------------------------------------------------
const getMUIIcon = (name) => {
  return <ListItemIcon className="navbar-icon">{name}</ListItemIcon>;
};

const getEvaIcon = (name) => (
  <Icon className="navbar-icon" icon={name} width={22} height={22} />
);
const getImageIcon = (name) => (
  <img className="navbar-icon" src={name} width={22} height={22} />
);
//
const sidebarConfig = (Navlist) => {
  const { userInfo } = usePGIMode();
  let sidebarMenus = Navlist;

  return sidebarMenus;
};
export default sidebarConfig;
