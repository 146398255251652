import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Chip, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { get_mission_members_list } from "src/DAL/MissionApi/MissionApiNew";
import { dd_date_format, show_proper_words } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MissionMembers() {
  const classes = useStyles();
  const { mission_id } = useParams();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [missionResult, setMissionResult] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  const searchFunction = (e) => {
    if (e) {
      e.preventDefault();
    }
    setPage(0);
    setPageCount(1);
    localStorage.setItem("mission_members", searchText);
    get_mission_members(searchText);
  };

  const get_mission_members = async (text) => {
    setIsLoading(true);
    const data = { mission_id, page, rowsPerPage, text };
    const result = await get_mission_members_list(data);
    if (result.code === 200) {
      setMissionResult(result);

      const users = result.users_list.map((user) => {
        let { first_name, last_name, email, profile_image } = user.user_info;
        let user_name = `${first_name} ${last_name} (${email})`;
        let start_date = dd_date_format(user.mission_start_date);
        return {
          ...user,
          user_name,
          start_date,
          table_avatar: {
            src: s3baseUrl + profile_image,
            alt: user_name,
          },
        };
      });
      setUserList(users);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handle_mission_status = (row) => {
    return (
      <Chip
        width="150px"
        label={show_proper_words(row.mission_status)}
        variant="contained"
        className="mission-status-chip"
        color={row.mission_status === "completed" ? "success" : "warning"}
        size="small"
      />
    );
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
    },
    {
      id: "user_name",
      label: "User Name",
    },
    {
      id: "start_date",
      label: "Start Date",
    },
    {
      id: "completed_mission_days",
      label: "Completed Days",
      renderData: (row) => <>{row?.completed_mission_days ?? 0}</>,
    },
    {
      id: "attracted_coins",
      label: "Coins Attracted",
    },
    {
      id: "target_coins",
      label: "Target Coins",
    },
    {
      id: "mission_status",
      label: "Mission Status",
      renderData: (row) => handle_mission_status(row),
    },
  ];

  let breadCrumbMenu = [
    // {
    //   title: `Mission Level (${missionResult?.mission_level?.title})`,
    //   navigation: `/mission`,
    //   active: false,
    // },
    {
      title: `Mission (${missionResult.mission?.title})`,
      // navigation: `/mission/challenge/${missionResult?.mission_level?._id}`,
      navigation: `/mission`,
      active: false,
    },
    {
      title: "Missions Members",
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    let search_text = localStorage.getItem("mission_members");
    setSearchText(search_text || "");
    get_mission_members(search_text || "");
  }, [page, rowsPerPage]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mb-4">
          <div className="col-12 mb-4">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
          <div className="col-lg-8 col-sm-12">
            <h2>Missions Members</h2>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          className="card-with-background"
          pagePagination={true}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
      </div>
    </>
  );
}
