import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  DeleteMessageApi,
  adminUserDeleteApi,
} from "src/DAL/AdminUserApi/AdminUserApi";
import moment from "moment";
import { dd_date_format } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function WellcomeReminderUser({ tabData }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const getProductCategories = async () => {
    setIsLoading(true);

    const ListData = tabData.map((items) => {
      let nurtureData = "N/A";
      if (items.nurture) {
        nurtureData =
          items?.nurture?.first_name +
          " " +
          items?.nurture?.last_name +
          " (" +
          items?.nurture?.email +
          ")";
      }
      return {
        ...items,
        MENU_OPTIONS: handleMenu(items),
        nurtureData: nurtureData,
      };
    });
    setCategoryList(ListData);

    setIsLoading(false);
  };

  const handleEdit = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/reminder-user/edit-reminder-user/${value?._id}`, {
      state: value,
    });
  };
  const handleOpenDelete = (value) => {
    setOpenDelete(true);
    setDeleteDoc(value);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteMessageApi(deleteDoc?._id);

    if (result.code === 200) {
      setIsLoading(false);
      setCategoryList((categoriesData) => {
        return categoriesData.filter((data) => data._id !== deleteDoc._id);
      });

      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleMenu = (row) => {
    let MENU_OPTIONS = [];

    MENU_OPTIONS.push(
      // {
      //   label: "Edit",
      //   icon: "akar-icons:edit",
      //   handleClick: handleEdit,
      // },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleOpenDelete,
      }
    );

    return MENU_OPTIONS;
  };
  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },

    {
      id: "nurtureData",
      label: "Nurture",
    },
    {
      id: "time",
      label: "Message  ",
      renderData: (row) => {
        return (
          <>
            <Tooltip
              title={
                <span
                  dangerouslySetInnerHTML={{
                    __html: row.description,
                  }}
                />
              }
            >
              <p
                style={{
                  cursor: "pointer",
                  color: "#e4c073",
                  textDecoration: "underline",
                }}
              >
                View Message
              </p>
            </Tooltip>
          </>
        );
      },
    },
    {
      id: "time",
      label: "Notify Date Time",
      renderData: (row) => {
        let time;
        if (row.notify_schedule.length > 0) {
          time = moment(row.notify_schedule[0].notify_time, "HH:mm");
        }
        return (
          <>
            {row.notify_schedule.length > 0 ? (
              <div className="d-flex">
                <p style={{ marginBottom: "0px" }}>
                  {dd_date_format(row.notify_schedule[0].notify_date_time)}
                </p>

                <div className="ms-2">
                  <p style={{ marginBottom: "0px" }}>{time.format("h:mm A")}</p>
                </div>
              </div>
            ) : (
              "N/A"
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getProductCategories();
  }, [tabData]);
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="">
        <div className="row">
          <div className="col-lg-8 col-sm-8 mb-2 mt-3">
            <h2>Welcome Reminders Message </h2>
          </div>
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={categoryList}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
