import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { client_Url } from "src/config/config";

const CopyLinkForAppWeb = ({
  handleGenerateCopyURL,
  selectedRowData,
  CopyUrlToClipboard,
  copyLinkLoading,
}) => {
  return (
    <>
      <Box>
        <Stack direction="column" spacing={2} justifyContent="space-around">
          <Button
            variant="outlined"
            startIcon={<ContentCopyIcon />}
            onClick={() => handleGenerateCopyURL(selectedRowData)}
          >
            <Typography>
              {copyLinkLoading ? "Please Wait ..." : "Copy link for App"}{" "}
            </Typography>
          </Button>
          <Button
            variant="outlined"
            startIcon={<ContentCopyIcon />}
            onClick={() =>
              CopyUrlToClipboard(
                selectedRowData?.page_type == "landing"
                  ? client_Url
                  : client_Url + selectedRowData?.sale_page_title_slug
              )
            }
          >
            <Typography> Copy link for Web </Typography>
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default CopyLinkForAppWeb;
