import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { clientPortalUrl, s3baseUrl } from "src/config/config";
import ChatCard from "./ChatCard";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
  dummyImage,
  dynamite,
  elite,
  mastery,
} from "src/assets";
const imageLinks = {
  docx: wordImage,
  mp3: audioImage,
  pdf: pdfImage,
  csv: csvImage,
  doc: docImage,
  xlsx: xlsxImage,
  xls: xlsxImage,
  other: otherImage,
};

import { htmlDecode } from "src/utils/convertHtml";
import { supportTicketDetailApi } from "src/DAL/supportTicket/supportTicket";
import ImageModelBox from "src/components/ModelBox/ImageModelBox";
import { member_login_api } from "src/DAL/member/member";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SupportTicketDetail() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const params = useParams();
  const [supportTicketData, setSupportTicketData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const handleOpen = (image_path) => {
    setImageUrl(image_path);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const getSupportTicketDetail = async () => {
    setIsLoading(true);
    const result = await supportTicketDetailApi(params.id);
    if (result.code === 200) {
      setSupportTicketData(result.support_ticket);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getMemberInfo = () => {
    const { member } = supportTicketData;
    const badge_level = member?.membership_level_badge_info;
    return (
      <>
        {member?.first_name
          ? member?.first_name +
            " " +
            member?.last_name +
            " (" +
            member?.email +
            ")"
          : "N/A"}
        <Tooltip title={badge_level?.membership_level_title}>
          <img
            src={s3baseUrl + badge_level?.membership_level_icon}
            alt=""
            width="30px"
            className="bagde-image"
          />
        </Tooltip>
      </>
    );
  };

  const LoginAsMember = async (value) => {
    setIsLoading(true);
    const data = {
      email: supportTicketData?.member?.email,
      user_type: "client",
    };
    const result = await member_login_api(data);
    if (result.code === 200) {
      window.open(`${clientPortalUrl}login-by-admin/${result.token}`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getCommentImage = (file) => {
    const ext = file.thumbnail_1.split(".").pop();
    if (
      ext == "jpg" ||
      ext == "JPG" ||
      ext == "png" ||
      ext == "webp" ||
      ext == "jpeg" ||
      ext == "JPEG" ||
      ext == "PNG"
    ) {
      return s3baseUrl + file.thumbnail_2;
    } else if (imageLinks[ext]) {
      return imageLinks[ext];
    } else {
      return imageLinks.other;
    }
  };

  let breadCrumbMenu = [
    {
      title: "Member Support Tickets",
      navigation: `/support-ticket`,
      active: false,
    },
    {
      title: "Detail",
      active: false,
    },
    {
      title: getMemberInfo(),
      active: true,
    },
  ];

  useEffect(() => {
    getSupportTicketDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-8 text-start mt-2 ">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-4 text-end">
          {supportTicketData.ticket_status === 1 ||
          supportTicketData.ticket_status === 2 ? (
            <button
              className="small-contained-button float-end mt-1 disabled"
              disabled="disabled"
            >
              Resolved
            </button>
          ) : (
            ""
          )}
          <button
            className="small-contained-button me-2 mt-1 mb-4 float-end"
            onClick={LoginAsMember}
          >
            Login As Member
          </button>
        </div>
      </div>
      <div className="row section-space">
        <div className="col-sm-12">
          <h1>{htmlDecode(supportTicketData.subject)}</h1>
        </div>
      </div>

      <div className="row media-margin">
        <div className="col-12">
          <p>{htmlDecode(supportTicketData.description)}</p>
        </div>
        <div className="col-sm-12 my-2">
          {supportTicketData.ticket_images &&
            supportTicketData.ticket_images.map((image, i) => {
              const ext = image.thumbnail_1.split(".").pop();
              if (
                ext == "jpg" ||
                ext == "JPG" ||
                ext == "png" ||
                ext == "webp" ||
                ext == "jpeg" ||
                ext == "JPEG" ||
                ext == "PNG"
              ) {
                return (
                  <span className="preview ticket_image_preview" key={i}>
                    <img
                      onClick={() => {
                        handleOpen(s3baseUrl + image.thumbnail_1);
                      }}
                      className="p-0"
                      src={
                        image?.thumbnail_2 === undefined ||
                        image?.thumbnail_2 === ""
                          ? dummyImage
                          : s3baseUrl + image.thumbnail_2
                      }
                    />
                  </span>
                );
              } else {
                return (
                  <span className="preview ticket_image_preview" key={i}>
                    <a
                      href={s3baseUrl + image.thumbnail_1}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={getCommentImage(image)} />
                    </a>
                  </span>
                );
              }
            })}
        </div>
        <ChatCard
          comments={supportTicketData.comment}
          ticket_id={supportTicketData._id}
          list_data={getSupportTicketDetail}
          supportTicketData={supportTicketData}
          memberName={supportTicketData?.member?.first_name}
          memberEmail={supportTicketData?.member?.email}
        />
        <ImageModelBox
          open={open}
          handleClose={handleClose}
          image_url={imageUrl}
        />
      </div>
    </div>
  );
}
