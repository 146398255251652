import * as React from "react";
import { _get_membership_level_coins_configration } from "src/DAL/challenges/challenges";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { useEffect } from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { _get_membership_levels_program_detail } from "src/DAL/Programme/Programme";
import CoinConfigurationNew from "./CoinConfigurationNew";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CoinConfigurationTabsNew() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [membershipLevelList, setMembershipLevelList] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const TABS_OPTIONS = [];

  membershipLevelList.map((level) => {
    TABS_OPTIONS.push({
      title: level?.title,
      component: (
        <CoinConfigurationNew
          level={level}
          setMembershipLevelList={setMembershipLevelList}
        />
      ),
    });
  });

  const getMembershipLevelCoinsConfigration = async () => {
    setIsLoading(true);
    const result = await _get_membership_level_coins_configration();
    if (result.code === 200) {
      setMembershipLevelList(result?.membership_levels);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMembershipLevelCoinsConfigration();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="row mb-4">
        <div className="col-lg-8 col-sm-12">
          <h2>Nav Items Configrations</h2>
        </div>
      </div>
      <MUICustomTabs
        data={TABS_OPTIONS}
        value={tabValue}
        handleChange={handleChange}
      />
    </>
  );
}
