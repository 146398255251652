import { invokeApi } from "../../bl_libs/invokeApi";

export const _list_meeting_content1 = async (data) => {
  const requestObj = {
    path: `/meeting_content/list_meeting_content`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _list_meeting_content = async (page, limit, data) => {
  const requestObj = {
    path: `/meeting_content/list_meeting_content?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _detail_meeting_content = async (id) => {
  const requestObj = {
    path: `/meeting_content/detail_meeting_content/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _update_meeting_content = async (data, id) => {
  const requestObj = {
    path: `/meeting_content/update_meeting_content/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _create_meeting_content = async (data) => {
  const requestObj = {
    path: `/meeting_content/create_meeting_content`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _delete_meeting_content = async (id) => {
  const requestObj = {
    path: `/meeting_content/delete_meeting_content/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
