import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  FormHelperText,
  Button,
  Tooltip,
} from "@mui/material";
import { addSalesApi, EditsaleApi } from "src/DAL/SaleSections/saleSection";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const Input = styled("input")({
  display: "none",
});
const levels = [
  {
    id: 1,
    name: "Dynamite",
    value: "dynamite",
  },
  {
    id: 2,
    name: "Elite",
    value: "elite",
  },
  {
    id: 3,
    name: "PTA",
    value: "pta",
  },
  {
    id: 4,
    name: "Ignite",
    value: "Ignite",
  },
];

export default function ManageLevelConfiguration() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const [formType, setFormType] = useState("ADD");
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState();
  const [oldImage, setOldImage] = useState("");
  const [addArray, setaddArray] = useState([
    {
      number_of_challenges: "",
      badges: levels,
      isExpanded: false,
      selected_badges: null,
    },
  ]);
  const [inputs, setInputs] = useState({
    title: "",
    status: "true",
    short_description: "",
    order: 0,
  });

  const handleAdd = (index) => {
    let newAddArray = addArray.slice();
    newAddArray.splice(index + 1, 0, {
      number_of_challenges: "",
      badges: levels,
      isExpanded: false,
      selected_badges: null,
    });
    setaddArray(newAddArray);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);
    setaddArray(delValue);
  };
  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const handleChangeInputs = (
    newValue,
    index,
    child_options,
    child_index,
    child_id
  ) => {
    const value = newValue.target.value;
    const name = newValue.target.name;

    const copyArray = [...addArray];
    const selectedObject = copyArray[index];
    let findedChild = selectedObject.selected_badges.find(
      (item) => item._id == child_id
    );
    if (child_options == "child_options" && findedChild) {
      findedChild.no_of_badges = value;
    } else {
      selectedObject[name] = value;
    }
    setaddArray(copyArray);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("short_description", inputs.short_description);
    formData.append("status", inputs.status);
    if (formType === "EDIT") {
      formData.append("order", inputs.order);
    }

    const result =
      formType === "ADD"
        ? await addSalesApi(formData)
        : await EditsaleApi(formData, params.sale_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/all-sales`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  console.log(params, "paramsparams");
  useEffect(() => {
    if (params && params.id) {
      if (state) {
        setFormType("EDIT");
        setInputs(state);
      } else {
        getCategoryData();
      }
    }
  }, []);
  const handleChangeOthers = (value, name, index) => {
    const copyArray = [...addArray];
    const selectedObject = copyArray[index];
    selectedObject[name] = value;
    setaddArray(copyArray);
    expandArea(index, "open");
  };

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-2">Manage Levels Configuration</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <divv className="col-12">
            {addArray &&
              addArray.map((item, index) => {
                return (
                  <div className="card mb-2 p-2">
                    <div className="row">
                      <div className="col-4 ">
                        <MUIAutocomplete
                          inputLabel="Levels"
                          limitTags={2}
                          selectedOption={item.selected_badges}
                          size="small"
                          setSelectedOption={(e) => {
                            handleChangeOthers(e, "selected_badges", index);
                          }}
                          optionsList={item.badges}
                          autoComplete="new-password"
                          name="name"
                        />
                      </div>
                      <div className="col-4">
                        <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
                          <TextField
                            className="text-muted"
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            id="outlined-basic"
                            label="Enable Coins Start"
                            variant="outlined"
                            fullWidth
                            size="small"
                            name="enable_coin_start"
                            type="number"
                            value={item.enable_coin_start}
                            onChange={(e) => handleChangeInputs(e, index)}
                            required={true}
                          />
                        </div>
                      </div>{" "}
                      <div className="col-3">
                        <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
                          <TextField
                            className="text-muted"
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            id="outlined-basic"
                            label="Enable Coins End"
                            variant="outlined"
                            fullWidth
                            size="small"
                            name="enable_coin_end"
                            type="number"
                            value={item.enable_coin_end}
                            onChange={(e) => handleChangeInputs(e, index)}
                            required={true}
                          />
                        </div>
                      </div>
                      <div
                        className="col-1 ps-0"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <span className="cross-icon">
                          {addArray.length > 1 ? (
                            <>
                              <Tooltip title="Remove">
                                <RemoveCircleOutlineIcon
                                  onClick={() => handleDelete(index)}
                                  className="diary-icon-remove"
                                />
                              </Tooltip>
                            </>
                          ) : (
                            ""
                          )}
                          <Tooltip title="Add">
                            <AddCircleOutlineIcon
                              onClick={() => handleAdd(index)}
                              className="diary-icon-add"
                            />
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className="text-end mt-4">
              <button className="small-contained-button">Submit</button>
            </div>
          </divv>
        </div>
      </form>
    </div>
  );
}
