import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import {
  add_programme_api,
  deleteAudioApi,
  update_programme_api,
  programme_detail_api,
  update_programme_content,
  update_language_content,
} from "src/DAL/Programme/Programme";
import { s3baseUrl } from "../../config/config";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";
import { multi_language_data, urlPatternValidation } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function UpdateMultiLanguageContent() {
  const navigate = useNavigate();
  const { programme_slug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [audioFile, setAudio] = useState();
  const [oldAudio, setOldAudio] = useState("");
  const [formType, setFormType] = useState("ADD");
  const [language, setLanguage] = useState([]);
  const [multi_language_content, set_multi_language_content] = useState(null);
  const [spacificLanguage, setSpacificLanguage] = useState({});
  const [rerander_editor, set_rerander_object] = useState(true);

  const [images, setImages] = useState({
    main_image: "",
    program_lock_icon: "",
  });

  const [oldImages, setOldImages] = useState({
    main_image: "",
    program_lock_icon: "",
  });
  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    is_program_show_on_list: true,
    main_image: {},
    program_access_type: "",
    video_url: "",
    delegate_first_tab_heading: "",
    short_description: "",
    detailed_description: "",
    no_of_limited_days: 0,
    no_of_start_days: 0,
    is_hidden: false,
    show_on_mission_control: false,
    is_program_module: false,
    is_show_on: "main_portal",
    order: 0,
    module_tab_name: "",
    program_lock_icon: {},
    program_lock_statement: "",
    is_membership_allowed: true,
    content_languages: "en",
  });

  const getProgrammeDetail = async () => {
    setIsLoading(true);
    const result = await programme_detail_api(programme_slug);
    if (result.code === 200) {
      handleFormat(result.program);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let data = {
      title: spacificLanguage.title,
      program_lock_statement: spacificLanguage.program_lock_statement,
      delegate_first_tab_heading: spacificLanguage.delegate_first_tab_heading,
      short_description: spacificLanguage.short_description,
      detailed_description: spacificLanguage.detailed_description,
    };
    const postData = {
      content: data,
      language: spacificLanguage.content_languages,
      module_id: programme_slug,
      module_type: "program",
      module_key: "slug",
    };

    const result = await update_language_content(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      if (formType !== "ADD") {
        handleNavigate(result?.program?.program_slug);
      } else {
        handleNavigate();
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setSpacificLanguage((values) => ({ ...values, [name]: value }));
    if (name == "content_languages") {
      setSpacificLanguage({
        ...multi_language_content[value],
        ["content_languages"]: value,
      });
      set_rerander_object(false);
    }
  };
  const handleFormat = (data) => {
    console.log(data, "statestate");

    let en = {
      title: data.title,
      program_lock_statement: data.program_lock_statement,
      delegate_first_tab_heading: data.delegate_first_tab_heading,
      short_description: data.short_description,
      detailed_description: data.detailed_description,
    };
    let object = {
      en: en,
    };

    set_multi_language_content({ ...object, ...data.multi_language_content });
    setSpacificLanguage({
      title: data.title,
      program_lock_statement: data.program_lock_statement,
      delegate_first_tab_heading: data.delegate_first_tab_heading,
      short_description: data.short_description,
      detailed_description: data.detailed_description,
      content_languages: "en",
    });

    setInputs({
      ...data,
      ["content_languages"]: "en",
    });
    setFormType("EDIT");
    setOldImages({
      ...oldImages,
      ["main_image"]: data.program_images.thumbnail_2,
      ["program_lock_icon"]: data.program_lock_icon,
    });
    setOldAudio(data.audio_file);
    const multi_language = [];
    if (data.content_languages && data.content_languages.length > 0) {
      const content_languages = data.content_languages.map((item) => {
        const find_language = multi_language_data.find(
          (language) => language.code == item
        );
        if (find_language) {
          multi_language.push(find_language);
        }
      });
    }
    setLanguage(multi_language);
    setIsLoading(false);
  };

  const handleNavigate = (value) => {
    if (formType == "ADD") {
      navigate(`/programmes`);
    } else {
      navigate(
        `/programmes/programmes-detail/${value ? value : programme_slug}`
      );
    }
  };

  useEffect(() => {
    if (programme_slug) {
      if (state) {
        handleFormat(state);
      } else {
        getProgrammeDetail();
      }
    }
  }, []);

  useEffect(() => {
    set_rerander_object(true);
  }, [spacificLanguage.detailed_description]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  console.log(
    spacificLanguage.detailed_description,
    "detailed_descriptiondetailed_descriptiondetailed_description"
  );

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex align-items-center">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => handleNavigate(programme_slug)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-2 mb-4">Programme Content</h2>
        </div>
      </div>

      <form className="row" onSubmit={handleSubmit}>
        <div className="row partition-border ms-2 pt-4 pb-4">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Content Languages
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="content_languages"
                value={spacificLanguage.content_languages ?? "en"}
                onChange={handleChange}
                label="Content Languages"
              >
                <MenuItem value="en">English</MenuItem>
                {language.map((item, index) => (
                  <MenuItem value={item.code}>{item.language}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
            <TextField
              id="outlined-basic"
              label="Programme Name"
              variant="outlined"
              fullWidth
              name="title"
              value={spacificLanguage?.title}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Program Lock Statement"
              variant="outlined"
              fullWidth
              name="program_lock_statement"
              value={spacificLanguage?.program_lock_statement}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Delegate First Tab Heading"
              variant="outlined"
              fullWidth
              name="delegate_first_tab_heading"
              value={spacificLanguage?.delegate_first_tab_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description"
                multiline
                rows={6}
                required
                name="short_description"
                value={spacificLanguage?.short_description}
                onChange={handleChange}
              />
              <FormHelperText>Maximum limit 500 characters</FormHelperText>
            </FormControl>
          </div>

          <div className="col-12 mt-4">
            <h4>Detailed Description *</h4>
            {rerander_editor && (
              <GeneralCkeditor
                setInputs={setSpacificLanguage}
                inputs={spacificLanguage}
                name="detailed_description"
                editorHeight={320}
              />
            )}
          </div>
        </div>

        <div className="text-end mt-4">
          <button className="small-contained-button">
            {formType == "ADD" ? "Submit" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
