import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { memberListingActivity } from "src/DAL/member/member";
import moment from "moment";
import { show_proper_words } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CoinsHistory() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [categoriesData, setCategoriesData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const params = useParams();

  const getCoinsHistory = async () => {
    setIsLoading(true);
    const result = await memberListingActivity(params.id, page, rowsPerPage);
    if (result.code == 200) {
      const categories = result.coins_history.map((item) => {
        let coins_count = "+";
        if (item.action_type === "coins_decremented") {
          coins_count = "-";
        }
        coins_count += item.coins_count_by_action;
        let createdAt = moment.utc(item.createdAt).format("DD-MM-YYYY hh:mm A");
        return { ...item, createdAt, coins_count };
      });
      setCategoriesData(categories);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "activity_type",
      label: "Activity",
      renderData: (row) => {
        let module_title = row.module_info?.module_title;
        if (!module_title) {
          if (row.challenge?.title) {
            module_title = row.challenge?.title;
          }
        }
        return (
          <>
            <span>{show_proper_words(row.activity_type)}</span>
            {module_title && (
              <span style={{ color: "#e4c073" }}>{` (${module_title})`}</span>
            )}
          </>
        );
      },
    },
    { id: "coins_count", label: "Coins" },
    { id: "createdAt", label: "Activity Date" },
  ];

  useEffect(() => {
    getCoinsHistory();
  }, [page, rowsPerPage]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={categoriesData}
          className="card-with-background"
          pagePagination={true}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          is_hide={true}
        />
      </div>
    </>
  );
}
