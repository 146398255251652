import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import {
  delete_gaps_video_api,
  list_gaps_video_api,
} from "src/DAL/GapsVideos/GapsVideo";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function GapsVideos() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [videoList, setVideoList] = useState([]);

  const get_video_list = async () => {
    const result = await list_gaps_video_api();
    if (result.code == 200) {
      const videos_list = result.gaps_video_list.map((videos) => {
        return {
          ...videos,
          icon_image: {
            src: s3baseUrl + videos?.image?.thumbnail_1,
            alt: videos.title,
          },
        };
      });
      setVideoList(videos_list);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await delete_gaps_video_api(deleteDoc._id);
    if (result.code === 200) {
      get_video_list();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "icon_image",
      label: "Image",
      type: "thumbnail",
    },
    { id: "title", label: "Title" },
    { id: "coins", label: "Video Enable Coins" },
    { id: "order", label: "Order" },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
  ];

  const handleEdit = (value) => {
    navigate(`/gaps-videos/edit-gaps-video/${value._id}`);
  };

  const handleNavigate = () => {
    navigate(`/gaps-videos/add-gaps-video`);
  };

  useEffect(() => {
    get_video_list();
  }, []);
  const handleQuestionsConfiguration = (value) => {
    console.log(value, "valuevalue");
    navigate(`/gaps-videos/questions-configuration/${value._id}`);
  };
  const handleLessonsQuestions = (value) => {
    navigate(`/gaps-videos/gaps-videos-questions?created_for_id=${value._id}`, {
      state: value,
    });
  };
  let child_menu_options = [
    {
      label: "Questions Setting",
      icon: "akar-icons:edit",
      handleClick: handleQuestionsConfiguration,
    },
    {
      label: "Manage Questions",
      icon: "akar-icons:edit",
      handleClick: handleLessonsQuestions,
    },
    // {
    //   label: "Questions Answers",
    //   icon: "akar-icons:edit",
    //   // handleClick: handleNavigateQuestionsAnswers,
    // },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Questions Configuration",
      icon: "akar-icons:edit",
      child_options: child_menu_options,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fulid">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Gaps Videos</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Gaps Video
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={videoList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          localSearchName="road_map_levels"
          pagePagination={true}
        />
      </div>
    </>
  );
}
