import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

export default function EmojiPicker(props) {
  const { inputRef, setInput, moreOptions, use_field } = props;
  console.log(inputRef, "inputRefinputRefinputRef");
  const handleEmojiSelect = (emoji) => {
    const input = inputRef?.current;
    const { selectionStart, selectionEnd, value } = input;

    const newValue =
      value.substring(0, selectionStart || 0) +
      emoji.native +
      value.substring(selectionEnd || 0);

    if (use_field) {
      setInput((old) => ({
        ...old,
        [use_field]: newValue,
      }));
    } else {
      setInput(newValue);
    }

    // Set the new cursor position after the inserted emoji
    const newCursorPosition = selectionStart + emoji.native.length;
    setTimeout(() => {
      input.focus();
      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  return (
    <div className="picker-emoji">
      <Picker
        {...moreOptions}
        data={data}
        onEmojiSelect={handleEmojiSelect}
        theme="dark"
        dynamicWidth={false}
        searchDisabled={false}
        showFrequent={true}
      />
    </div>
  );
}
