import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  clientSettingApi,
  updateClientContentSettingApi,
} from "src/DAL/ClientSetting/ClientSetting";
import { s3baseUrl } from "src/config/config";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function UpdateContentSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isImgLoading, setImgIsLoading] = useState("");
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    description: "",
    login_page_image: "",
    payment_request_currency_conversion_text: "",
    forgot_password_page_image: "",
    email_verification_page_image: "",
    pods_default_image: "",
    mobile_app_banner: "",
    loginImage: {},
    forgetImage: {},
    verificationImage: {},
    mobile_app_banner_link: "",
    brandName: "",
    goal_statement_button_text: "",
    current_event_heading: "",
    goal_statement_button_info: "",
    pods_default_title: "",
    pods_default_description: "",
    upcoming_event_heading: "",
    dashboard_description: "",
    mission_control_description: "",
    the_source_description: "",
    leader_boards_description: "",
    profile_description: "",
    programs_description: "",
    bonus_content_description: "",
    lessons_description: "",
    affirmation_description: "",
    gratitude_description: "",
    pods_description: "",
    quotes_description: "",
    memories_description: "",
    ninteen_day_plan_description: "",
    ninteen_day_chart_description: "",
    self_image_description: "",
    chat_page_description: "",
    daily_task_description: "",
    calender_description: "",
    visioneering_description: "",
    program_recording_description: "",
    consultant_program_recording_description: "",
    lesson_recording_description: "",
    vault_description: "",
    goal_statement_description: "",
    login_page_description: "",
    forgot_password_page_description: "",
    email_verification_page_description: "",
    support_ticket_page_title: "",
    challenge_page_top_description: "",
    habit_page_top_description: "",
    attitude_assessment_description: "",
    dynamite_diary_description: "",
    mediation_description: "",
    lifescript_description: "",
    gratitude_daily_description: "",
    money_script_description: "",
    shop_description: "",
    shop_thaks_page_content: "",
    mission_levels_description: "",
    show_mobile_app_banner: false,
    show_address_popup: false,
    address_verification_description: "",
  });

  console.log(inputs);
  const clientDetail = async () => {
    setIsLoading(true);
    const result = await clientSettingApi();
    if (result.code == 200) {
      let generalSetting = result?.content_setting?.client_content_setting;
      setInputs(generalSetting);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fileChangedHandler = async (e, i) => {
    const { name } = e.target;
    if (e.target.files[0]) {
      setImgIsLoading(name);
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      if (name == "mobile_app_banner") {
        formData.append("width", "1200");
      } else {
        formData.append("width", "600");
      }
      const imageUpload = await uploadImageOns3(formData);
      if (imageUpload.code == 200) {
        setInputs({
          ...inputs,
          [name]: imageUpload.image_path,
        });
        setTimeout(() => {
          setImgIsLoading("");
        }, 600);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setImgIsLoading("");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    if (
      !inputs.address_verification_description ||
      inputs.address_verification_description.length <= 0
    ) {
      enqueueSnackbar("Address Verification Description is required", {
        variant: "error",
      });
      setIsLoadingForm(false);
      return;
    }

    var postData = {
      dashboard_description: inputs.dashboard_description,
      mission_control_description: inputs.mission_control_description,
      the_source_description: inputs.the_source_description,
      leader_boards_description: inputs.leader_boards_description,
      profile_description: inputs.profile_description,
      programs_description: inputs.programs_description,
      bonus_content_description: inputs.bonus_content_description,
      lessons_description: inputs.lessons_description,
      affirmation_description: inputs.affirmation_description,
      gratitude_description: inputs.gratitude_description,
      pods_description: inputs.pods_description,
      quotes_description: inputs.quotes_description,
      memories_description: inputs.memories_description,
      ninteen_day_plan_description: inputs.ninteen_day_plan_description,
      ninteen_day_chart_description: inputs.ninteen_day_chart_description,
      self_image_description: inputs.self_image_description,
      chat_page_description: inputs.chat_page_description,
      daily_task_description: inputs.daily_task_description,
      calender_description: inputs.calender_description,
      program_recording_description: inputs.program_recording_description,
      consultant_program_recording_description:
        inputs.consultant_program_recording_description,
      lesson_recording_description: inputs.lesson_recording_description,
      vault_description: inputs.vault_description,
      goal_statement_description: inputs.goal_statement_description,
      login_page_description: inputs.login_page_description,
      forgot_password_page_description: inputs.forgot_password_page_description,
      email_verification_page_description:
        inputs.email_verification_page_description,
      support_ticket_page_title: inputs.support_ticket_page_title,
      goal_statement_button_info: inputs.goal_statement_button_info,
      pods_default_title: inputs.pods_default_title,
      pods_default_description: inputs.pods_default_description,
      goal_statement_button_text: inputs.goal_statement_button_text,
      current_event_heading: inputs.current_event_heading,
      upcoming_event_heading: inputs.upcoming_event_heading,
      login_page_image: inputs.login_page_image,
      forgot_password_page_image: inputs.forgot_password_page_image,
      email_verification_page_image: inputs.email_verification_page_image,
      pods_default_image: inputs.pods_default_image,
      challenge_page_top_description: inputs.challenge_page_top_description,
      habit_page_top_description: inputs.habit_page_top_description,
      attitude_assessment_description: inputs.attitude_assessment_description,
      dynamite_diary_description: inputs.dynamite_diary_description,
      visioneering_description: inputs.visioneering_description,
      mediation_description: inputs.mediation_description,
      lifescript_description: inputs.lifescript_description,
      gratitude_daily_description: inputs.gratitude_daily_description,
      money_script_description: inputs.money_script_description,
      shop_description: inputs.shop_description,
      shop_thaks_page_content: inputs.shop_thaks_page_content,
      mission_levels_description: inputs.mission_levels_description,
      mobile_app_banner_link: inputs.mobile_app_banner_link,
      mobile_app_banner: inputs.mobile_app_banner,
      show_mobile_app_banner: inputs.show_mobile_app_banner,
      payment_request_currency_conversion_text:
        inputs.payment_request_currency_conversion_text,
      show_address_popup: inputs.show_address_popup,
      address_verification_description: inputs.address_verification_description,
    };

    let Data = {
      client_content_setting: postData,
    };

    const result = await updateClientContentSettingApi(Data);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    clientDetail();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin">
        <div className="col-6">
          <h2>Content Settings</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0 mb-2">
              <div className="col-5">
                <p className="">Login Page Background Image </p>
                <FormHelperText className="pt-0">
                  Recommended Size(1920 X 1080) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {isImgLoading === "login_page_image" ? (
                  <CircularProgress
                    className={classes.loading}
                    size="1.5rem"
                    color="primary"
                  />
                ) : (
                  <img
                    src={
                      inputs.login_page_image
                        ? s3baseUrl + inputs.login_page_image
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="login_page_image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0 mb-2">
              <div className="col-5">
                <p className="">Forgot Password Page Background Image </p>
                <FormHelperText className="pt-0">
                  Recommended Size (1920 X 1080) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {isImgLoading === "forgot_password_page_image" ? (
                  <CircularProgress
                    className={classes.loading}
                    size="1.5rem"
                    color="primary"
                  />
                ) : (
                  <img
                    src={
                      inputs.forgot_password_page_image
                        ? s3baseUrl + inputs.forgot_password_page_image
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file1">
                  <Input
                    accept="image/*"
                    id="contained-button-file1"
                    multiple
                    type="file"
                    name="forgot_password_page_image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0 mb-2">
              <div className="col-5">
                <p className="">Verification Page Background Image</p>
                <FormHelperText className="pt-0">
                  Recommended Size (1920 X 1080) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {isImgLoading === "email_verification_page_image" ? (
                  <CircularProgress
                    className={classes.loading}
                    size="1.5rem"
                    color="primary"
                  />
                ) : (
                  <img
                    src={
                      inputs.email_verification_page_image
                        ? s3baseUrl + inputs.email_verification_page_image
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file2">
                  <Input
                    accept="image/*"
                    id="contained-button-file2"
                    multiple
                    type="file"
                    name="email_verification_page_image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0 mb-2">
              <div className="col-5">
                <p className="">Pods Default Image</p>
                <FormHelperText className="pt-0">
                  Recommended Size (1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {isImgLoading === "pods_default_image" ? (
                  <CircularProgress
                    className={classes.loading}
                    size="1.5rem"
                    color="primary"
                  />
                ) : (
                  <img
                    src={
                      inputs.pods_default_image
                        ? s3baseUrl + inputs.pods_default_image
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="pods_default_image">
                  <Input
                    accept="image/*"
                    id="pods_default_image"
                    multiple
                    type="file"
                    name="pods_default_image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0 mb-2">
              <div className="col-5">
                <p className="">Mobile App Banner</p>
                <FormHelperText className="pt-0">
                  Recommended Size (1200 X 150) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {isImgLoading === "mobile_app_banner" ? (
                  <CircularProgress
                    className={classes.loading}
                    size="1.5rem"
                    color="primary"
                  />
                ) : (
                  <img
                    src={
                      inputs.mobile_app_banner
                        ? s3baseUrl + inputs.mobile_app_banner
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="mobile_app_banner">
                  <Input
                    accept="image/*"
                    id="mobile_app_banner"
                    multiple
                    type="file"
                    name="mobile_app_banner"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show Mobile App Banner
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={inputs.show_mobile_app_banner}
                name="show_mobile_app_banner"
                label="Show Mobile App Banner"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Mobile App Banner Link"
              variant="outlined"
              fullWidth
              required
              name="mobile_app_banner_link"
              value={inputs.mobile_app_banner_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Goal Statement Button Text"
              variant="outlined"
              fullWidth
              name="goal_statement_button_text"
              value={inputs.goal_statement_button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Goal Statement Button Info"
              variant="outlined"
              fullWidth
              name="goal_statement_button_info"
              value={inputs.goal_statement_button_info}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Pods Default Title"
              variant="outlined"
              fullWidth
              name="pods_default_title"
              value={inputs.pods_default_title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show Address Popup
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={inputs.show_address_popup}
                name="show_address_popup"
                label="Show Address Popup"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-multiline-flexible"
              label="Payment Request Currency Conversion Text"
              multiline
              rows={6}
              name="payment_request_currency_conversion_text"
              value={inputs.payment_request_currency_conversion_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-12 mt-4">
            <h4 className="mb-3">Pods Default Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="pods_default_description"
              editorHeight={350}
            />
          </div>
          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Current Events Heading in live Events"
              variant="outlined"
              fullWidth
              name="current_event_heading"
              value={inputs.current_event_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Upcoming Events Heading in live Events"
              variant="outlined"
              fullWidth
              name="upcoming_event_heading"
              value={inputs.upcoming_event_heading}
              onChange={handleChange}
            />
          </div> */}
          <div className="col-12 mt-4">
            <h4 className="mb-3">Login Page Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="login_page_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Forget Page Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="forgot_password_page_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Verification Page Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="email_verification_page_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Mission Control Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="mission_control_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">The Source Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="the_source_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Leader Boards Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="leader_boards_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Dashboard Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="dashboard_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Profile Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="profile_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Programme Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="programs_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Bonus Content Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="bonus_content_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Lessons Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="lessons_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Pods Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="pods_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Affirmations Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="affirmation_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Gratitude Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="gratitude_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Quotes Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="quotes_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Memories Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="memories_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">90 Day Plan Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="ninteen_day_plan_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">90 Day Tracker Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="ninteen_day_chart_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Self Image Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="self_image_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Chat Page Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="chat_page_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Daily Task Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="daily_task_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Calender Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="calender_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Lesson Recording Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="lesson_recording_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Delegates Program Recording Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="consultant_program_recording_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Program Recording Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="program_recording_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Vault Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="vault_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Goal Statement Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="goal_statement_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Support Ticket Page Title</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="support_ticket_page_title"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Challenge Page Top Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="challenge_page_top_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Habit Page Top Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="habit_page_top_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Attitude Assessment Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="attitude_assessment_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Dynamite Diary Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="dynamite_diary_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Visioneering Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="visioneering_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Meditation Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="mediation_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Lifescript Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="lifescript_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Gratitude Daily Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="gratitude_daily_description"
              editorHeight={350}
            />
          </div>{" "}
          <div className="col-12 mt-4">
            <h4 className="mb-3">Money Script Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="money_script_description"
              editorHeight={350}
            />
          </div>{" "}
          <div className="col-12 mt-4">
            <h4 className="mb-3">Shop Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="shop_description"
              editorHeight={350}
            />
          </div>{" "}
          <div className="col-12 mt-4">
            <h4 className="mb-3">Shop Thanks Page Content</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="shop_thaks_page_content"
              editorHeight={350}
            />
          </div>{" "}
          <div className="col-12 mt-4">
            <h4 className="mb-3">Mission Levels Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="mission_levels_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Address Verification Description *</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="address_verification_description"
              editorHeight={350}
            />
          </div>
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
