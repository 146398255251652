import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { dd_date_time_format } from "src/utils/constant";

const TABLE_HEAD = [
  { id: "number", label: "#", type: "number" },
  { id: "page_name", label: "Page Title" },
  { id: "plan_title", label: "Plan Title" },
  { id: "mainPlan", label: "Main Plan" },
  { id: "affiliate_name", label: "Referral" },
  {
    id: "preview_link_data",
    label: "Agreement PDF",
    type: "link",
  },
  {
    id: "created_at",
    label: "Subscription Date",
  },
  {
    id: "register_url",
    label: "Register Link",
  },
  {
    id: "view_count",
    label: "Viewed MC Setting",
    alignRight: false,
  },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MemberSubscribers({ tabData }) {
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const classes = useStyles();

  const getMembersList = () => {
    setIsLoading(true);
    const members = tabData?.map((event, index) => {
      let event_plan = event.plan;
      let plan_title = "N/A";
      let mainPlan = "N/A";
      let created_at = "N/A";
      let affiliate_name = "N/A";
      if (event_plan?.plan_title) {
        plan_title = event_plan?.plan_title;
      }
      let payment_access = event_plan?.payment_access;
      if (plan_title != "N/A") {
        if (event_plan?.is_plan_free) {
          plan_title = plan_title + " (Free)";
        } else if (payment_access == "recursion") {
          plan_title = plan_title + " (Recurring)";
        } else {
          plan_title = plan_title + " (OneTime)";
        }
      }
      let affiliate = event.affiliate?.affiliate_user_info;
      if (affiliate && affiliate.first_name) {
        affiliate_name = affiliate.first_name + " " + affiliate.last_name;
      }

      if (event?.main_plan) {
        let title = "";
        if (event.main_plan.is_plan_free === true) {
          title = "Free";
        } else if (event.main_plan.payment_access === "recursion") {
          title = "Recurring";
        } else if (event.main_plan.payment_access === "onetime") {
          title = "OneTime";
        }
        mainPlan = event.main_plan?.plan_title + " (" + title + ")";
      }
      return {
        ...event,
        page_name: event.page?.sale_page_title,
        created_at: dd_date_time_format(event.createdAt),
        mainPlan,
        plan_title: plan_title,
        affiliate_name: affiliate_name,
        preview_link_data: {
          to: s3baseUrl + event.aggrement_pdf_url,
          target: "_blank",
          show_text: event.aggrement_pdf_url ? "Preview" : "",
          className: "anchor-style",
        },
      };
    });
    setSubscriptionsData(members);
    setIsLoading(false);
  };

  useEffect(() => {
    getMembersList();
  }, [tabData]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={subscriptionsData}
          className="card-with-background"
        />
      </div>
    </>
  );
}
