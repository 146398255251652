import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import {
  AddGroupApi,
  EditGroupApi,
  group_detail_api,
} from "src/DAL/group/group";
import {
  list_members_and_programs_v2,
  memberAndGroupsEventInCalendarGroupsListApi,
  payment_plan_list_api,
} from "src/DAL/member/member";
import { useEffect } from "react";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const get_full_name = (user) => {
  return user?.first_name + " " + user?.last_name + " (" + user?.email + ")";
};

export default function AddOrUpdateCalendarGroup() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { group_slug } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [membersList, setMembersList] = useState([]);
  const [excludeMembersList, setExcludeMembersList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [member, setMember] = useState([]);
  const [excludeMembers, setExcludeMembers] = useState([]);
  const [programmeList, setProgramList] = useState([]);
  const [programsName, setProgramsName] = useState([]);
  const [salePageName, setSalePageName] = useState([]);
  const [missionsName, setMissionsName] = useState([]);
  const [eventsName, setEventsName] = useState([]);
  const [badgeLevels, setBadgeLevels] = useState([]);
  const [selectedBadgeLevels, setSelectedBadgeLevels] = useState([]);
  const [serchText, setSerchText] = useState("");
  const [excludeSerchText, setExcludeSerchText] = useState("");
  const [customSearch, setCustomSearch] = useState("");
  const [paymentPlanName, setPaymentPlanName] = useState([]);
  const [paymentPlanList, setPaymentPlanList] = useState([]);
  const [customSearchPlan, setCustomSearchPlan] = useState("");

  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    room_type: "general",
    short_description: "",
    detailed_description: "",
    zoom_link: "",
    password: "",
    group_by: "program",
  });

  const getGroupsAndMembers = async () => {
    const result = await memberAndGroupsEventInCalendarGroupsListApi("");
    if (result.code === 200) {
      let members = [];
      if (result.members.length > 0) {
        members = result.members.map((user) => {
          user.full_name = get_full_name(user);
          return user;
        });
      }
      setMembersList(members);
      setExcludeMembersList(members);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_program_page_event_list = async () => {
    let postData = {
      search: customSearch,
      type: inputs.group_by,
    };
    const result = await list_members_and_programs_v2(postData);
    if (result.code === 200) {
      setBadgeLevels(result?.badge_levels);
      if (inputs.group_by == "sale_page") {
        let sale_pages = result.data.map((sale_page) => {
          let page_type = sale_page.type !== "template" ? "" : "| Template";
          return {
            ...sale_page,
            sale_page_title: sale_page.sale_page_title + " " + page_type,
          };
        });
        setProgramList(sale_pages);
      } else {
        setProgramList(result.data);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getSearchGroupsAndMembers = async () => {
    const result = await memberAndGroupsEventInCalendarGroupsListApi(serchText);
    if (result.code === 200) {
      let members = [];
      if (result.members.length > 0) {
        members = result.members.map((user) => {
          user.full_name = get_full_name(user);
          return user;
        });
      }
      setMembersList(members);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getSearchExcludeMembers = async () => {
    const result = await memberAndGroupsEventInCalendarGroupsListApi(
      excludeSerchText
    );
    if (result.code === 200) {
      let members = [];
      if (result.members.length > 0) {
        members = result.members.map((user) => {
          user.full_name = get_full_name(user);
          return user;
        });
      }
      setExcludeMembersList(members);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getSearchPaymentPlan = async () => {
    let sale_page_id = salePageName.map((sale_page) => sale_page._id);
    let postData = {
      search: customSearchPlan,
      type: "plan",
      sale_page: sale_page_id,
    };
    const result = await list_members_and_programs_v2(postData);
    if (result.code === 200) {
      let payment_plans = result.data.map((payment_plan) => {
        let find_page = salePageName.find(
          (page) => page._id === payment_plan.sale_page
        );

        let plan_title =
          payment_plan.plan_title +
          (find_page ? " | " + find_page.sale_page_title : "");

        return {
          ...payment_plan,
          plan_title: plan_title,
        };
      });

      setPaymentPlanList(payment_plans);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const get_group_info = async () => {
    let result = await group_detail_api(group_slug);
    if (result.code == 200) {
      handleFormatData(result.group);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // if (selectedBadgeLevels?.length == 0) {
    //   enqueueSnackbar("Badge Levels is required!", { variant: "error" });
    //   setIsLoading(false);
    //   return;
    // }

    let member_array = member
      .filter((user) => user._id)
      .map((user) => ({
        member_id: user._id,
      }));

    let exclude_member_array = excludeMembers
      .filter((user) => user._id)
      .map((user) => user._id);

    let event_array = eventsName.map((event) => ({
      event_slug: event.event_slug,
    }));

    let program_array = programsName.map((group) => ({
      program_slug: group.program_slug,
    }));

    let sale_page_array = salePageName.map((sale_page) => ({
      _id: sale_page._id,
    }));

    let missions_array = missionsName.map((mission) => ({
      _id: mission?._id,
      title: mission?.title,
    }));

    let badge_levels = selectedBadgeLevels.map((level) => ({
      _id: level?._id,
      title: level?.title,
    }));

    let payment_plan_array = paymentPlanName.map((payment_plan) => ({
      _id: payment_plan._id,
    }));

    let postData = {
      title: inputs.title,
      status: inputs.status,
      member: member_array,
      group_by: inputs.group_by,
      exclude_members: exclude_member_array,
      plans: payment_plan_array,
      badge_levels: badge_levels,
    };

    if (inputs.group_by == "program") {
      postData.program = program_array;
    } else if (inputs.group_by == "event") {
      postData.event = event_array;
    } else if (inputs.group_by == "sale_page") {
      postData.sale_page = sale_page_array;
    } else if (inputs.group_by == "mission") {
      postData.missions = missions_array;
    }

    const result = group_slug
      ? await EditGroupApi(postData, group_slug)
      : await AddGroupApi(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/calendar-groups`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFormatData = (data) => {
    let members_aray = [];
    if (data.member?.length > 0) {
      data.member.map((member) => {
        member = member._id;
        members_aray.push({ ...member, full_name: get_full_name(member) });
      });
    }
    setMember(members_aray);

    let exclude_members_aray = [];
    if (data.exclude_members?.length > 0) {
      data.exclude_members.map((member) => {
        member = member._id;
        exclude_members_aray.push({
          ...member,
          full_name: get_full_name(member),
        });
      });
    }
    setExcludeMembers(exclude_members_aray);

    let sale_pages_aray = [];
    if (data.sale_pages?.length > 0) {
      data.sale_pages.map((sale_page) => {
        sale_page = sale_page._id;
        sale_pages_aray.push(sale_page);
      });
    }
    setSalePageName(sale_pages_aray);

    let payment_plan_aray = [];
    if (data.plans?.length > 0) {
      payment_plan_aray = data.plans.map((payment_plan) => {
        let find_page = sale_pages_aray.find(
          (page) => page._id === payment_plan._id.sale_page
        );

        let plan_title =
          payment_plan?._id?.plan_title +
          (find_page ? " | " + find_page.sale_page_title : "");

        return { _id: payment_plan._id._id, plan_title };
      });
    }

    setPaymentPlanName(payment_plan_aray);

    let programs_aray = [];
    if (data.program?.length > 0) {
      data.program.map((program) => {
        program = program._id;
        programs_aray.push(program);
      });
    }
    setProgramsName(programs_aray);
    setMissionsName(data?.missions ?? []);

    let events_aray = [];
    if (data.event?.length > 0) {
      data.event.map((event) => {
        event = event._id;
        events_aray.push(event);
      });
    }
    setEventsName(events_aray);
    setSelectedBadgeLevels(data?.badge_levels ?? []);

    setInputs(data);
    setIsLoading(false);
    window.history.replaceState({}, document.title);
  };

  useEffect(() => {
    setSerchText("");
  }, [member]);

  useEffect(() => {
    setExcludeSerchText("");
  }, [excludeMembers]);

  useEffect(() => {
    if (!isLoading) {
      const timeoutId = setTimeout(() => {
        getSearchGroupsAndMembers();
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  }, [serchText]);

  useEffect(() => {
    if (salePageName.length > 0) {
      getSearchPaymentPlan();
    }
  }, [salePageName, customSearchPlan]);

  useEffect(() => {
    if (!isLoading) {
      const timeoutId = setTimeout(() => {
        getSearchExcludeMembers();
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  }, [excludeSerchText]);

  useEffect(() => {
    if (!isLoading) {
      const timeoutId = setTimeout(() => {
        get_program_page_event_list();
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  }, [customSearch]);

  useEffect(() => {
    get_program_page_event_list();
    setCustomSearch("");
  }, [inputs.group_by]);

  useEffect(() => {
    getGroupsAndMembers();
    if (group_slug) {
      setIsLoading(true);
      if (state) {
        handleFormatData(state);
      } else {
        get_group_info();
      }
    }
  }, []);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/calendar-groups`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-1">{`${group_slug ? "Edit" : "Add"} Group`}</h2>
        </div>
      </div>

      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-0">
          <TextField
            id="outlined-basic"
            label="Group Name"
            variant="outlined"
            fullWidth
            required
            name="title"
            value={inputs.title}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-0">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Group Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              label="Group Status"
              onChange={handleChange}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Group By</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="group_by"
              value={inputs.group_by}
              label="Group By*"
              onChange={handleChange}
            >
              <MenuItem value="program">Programme</MenuItem>
              <MenuItem value="event">Event</MenuItem>
              <MenuItem value="sale_page">Sale Page</MenuItem>
              <MenuItem value="mission">Missions</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Badge Levels "
            selectedOption={selectedBadgeLevels}
            setSelectedOption={setSelectedBadgeLevels}
            optionsList={badgeLevels}
            limitTags={2}
            multiple
            name="title"
          />
        </div>

        {inputs.group_by == "event" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Events"
              selectedOption={eventsName}
              setSelectedOption={setEventsName}
              optionsList={programmeList}
              setCustomSearch={setCustomSearch}
              multiple
              name="title"
            />
          </div>
        )}
        {inputs.group_by == "program" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Programmes"
              selectedOption={programsName}
              setSelectedOption={setProgramsName}
              optionsList={programmeList}
              setCustomSearch={setCustomSearch}
              multiple
              name="title"
            />
          </div>
        )}
        {inputs.group_by == "sale_page" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Sale Pages"
              selectedOption={salePageName}
              setSelectedOption={setSalePageName}
              optionsList={programmeList}
              setCustomSearch={setCustomSearch}
              multiple
              name="sale_page_title"
            />
          </div>
        )}
        {inputs.group_by == "mission" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Missions"
              selectedOption={missionsName}
              setSelectedOption={setMissionsName}
              optionsList={programmeList}
              setCustomSearch={setCustomSearch}
              multiple
              name="title"
            />
          </div>
        )}
        {inputs.group_by == "sale_page" && salePageName.length > 0 && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Payment Plans"
              selectedOption={paymentPlanName}
              setSelectedOption={setPaymentPlanName}
              optionsList={paymentPlanList}
              setCustomSearch={setCustomSearchPlan}
              multiple
              name="plan_title"
            />
          </div>
        )}
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Members"
            selectedOption={member}
            setSelectedOption={setMember}
            optionsList={membersList}
            setCustomSearch={setSerchText}
            multiple
            name="full_name"
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Exclude Members"
            selectedOption={excludeMembers}
            setSelectedOption={setExcludeMembers}
            optionsList={excludeMembersList}
            setCustomSearch={setExcludeSerchText}
            multiple
            name="full_name"
          />
        </div>
        <div className="text-end mt-4">
          <button className="small-contained-button">
            {group_slug ? "Update" : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}
