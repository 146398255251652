import { invokeApi } from "../../bl_libs/invokeApi";

export const challengesListingApi = async (data) => {
  const requestObj = {
    path: `/api/challenge`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const challengesDetailApi = async (slug) => {
  const requestObj = {
    path: `/api/challenge/detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const challengesDetailPageApi = async (slug) => {
  const requestObj = {
    path: `/api/challenge/challange_detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const AddChallengeApi = async (data) => {
  const requestObj = {
    // path: `/api/challenge`,
    path: `/api/challenge/add_challenge_v2`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addChallengeCoinConfigurationApi = async (data, slug) => {
  const requestObj = {
    path: `/api/challenge/coins_configuration/${slug}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditChallengeApi = async (data, slug) => {
  const requestObj = {
    // path: `/api/challenge/${slug}`,
    path: `/api/challenge/update_challenge_v2/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const EditPodsApi = async (data, slug) => {
  const requestObj = {
    path: `/api/room/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteChallengeApi = async (slug) => {
  const requestObj = {
    path: `/api/challenge/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_challenge_reward_setting = async () => {
  const requestObj = {
    path: `/api/default_setting/challenge_reward_settings/get`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_challenge_reward_settings = async (data) => {
  const requestObj = {
    path: `/api/default_setting/challenge_reward_settings/update`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const duplicate_challenge_api = async (id, data) => {
  const requestObj = {
    path: `/api/challenge/duplicate_challenge/${id}?duplicate_members=${
      data ? data : false
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// Membership level coins configration
export const _get_membership_level_coins_configration = async () => {
  const requestObj = {
    path: `/api/attitude_assessment_question/get/membership_level_coins_configration`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _update_membership_level_coins_configration = async (data) => {
  const requestObj = {
    path: `/api/attitude_assessment_question/update/membership_level_coins_configration`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
