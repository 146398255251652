import React from "react";
import MemberCardsState from "./MemberCardsState";

export default function MembersListStats({ statsArray, ViewAllMembers }) {
  return (
    <div className="row ">
      {statsArray?.length > 0 &&
        statsArray?.map((stat) => {
          return (
            <MemberCardsState
              title={stat.title}
              count={stat.count}
              value={stat.value}
              icon={stat.icon}
              ViewAllMembers={ViewAllMembers}
              className="mb-3"
            />
          );
        })}
    </div>
  );
}
