import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  road_Delete_Api,
  _bonus_listApi,
} from "src/DAL/BonusRoadMAp/BonusRoadMApApi";
import { s3baseUrl } from "src/config/config";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";

import StripeSubscriptionList from "./StripeSubscriptionList";
import StripeTransactionsList from "./StripeTransactionsList";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { Icon } from "@iconify/react";
import { download_csv_file } from "src/utils/convertHtml";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import StripeFilter from "./StripeFilter";
const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },
  {
    id: "table_avatar",
    label: "Reached Status",
    alignRight: false,
    type: "thumbnail",
  },
  {
    id: "without_border_icon",
    label: "Before Status",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "coins", label: "Coins", alignRight: false },
  { id: "order", label: "order", alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const EMPTY_OBJECT = {};

const RenderStripeTransactions = () => {
  const { state } = useLocation();
  const [tabValue, setTabValue] = useState(0);
  const [downloadCSVData, setDownloadCSVData] = useState([]);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const headers = [
    { label: "Member Name", key: "member_name" },
    { label: "Email", key: "member_email" },
    { label: "Amount", key: "amount" },
    { label: "Transaction Date", key: "created_at" },
    { label: "Status", key: "status" },
  ];

  const handleExportClick = () => {
    download_csv_file(headers, downloadCSVData);
  };

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TABS_OPTIONS = [
    {
      title: "All",
      component: (
        <StripeTransactionsList
          status="all"
          state={state}
          setDownloadCSVData={setDownloadCSVData}
          filterDrawerState={filterDrawerState}
          handleOpenFilterDrawer={handleOpenFilterDrawer}
          handleCloseFilterDrawer={handleCloseFilterDrawer}
          setFilterDrawerState={setFilterDrawerState}
        />
      ),
    },
    {
      title: "Succeeded",
      component: (
        <StripeTransactionsList
          status="succeeded"
          state={state}
          setDownloadCSVData={setDownloadCSVData}
          filterDrawerState={filterDrawerState}
          handleOpenFilterDrawer={handleOpenFilterDrawer}
          handleCloseFilterDrawer={handleCloseFilterDrawer}
          setFilterDrawerState={setFilterDrawerState}
        />
      ),
    },

    {
      title: "Failed",
      component: (
        <StripeTransactionsList
          status="failed"
          state={state}
          setDownloadCSVData={setDownloadCSVData}
          filterDrawerState={filterDrawerState}
          handleOpenFilterDrawer={handleOpenFilterDrawer}
          handleCloseFilterDrawer={handleCloseFilterDrawer}
          setFilterDrawerState={setFilterDrawerState}
        />
      ),
    },
  ];
  let breadCrumbMenu = [
    {
      title: state
        ? state?.first_name + " " + state?.last_name + " (" + state.email + ")"
        : "Member",
      navigation: -1,
      active: false,
    },
    {
      title: "Stripe Transactions",
      active: true,
    },
  ];

  return (
    <>
      <div className="container-fluid">
        {state?.email ? (
          <div className="row">
            <div className="col-12 display-flex mb-4">
              <span>
                <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
              </span>
            </div>
          </div>
        ) : (
          <div className="row mb-4">
            <div className="col-lg-4 col-sm-12">
              <h2>Stripe Transactions</h2>
            </div>
            <div className="col-lg-8 col-sm-12 text-end d-flex justify-content-end">
              <div>
                {downloadCSVData?.length > 0 && (
                  <button
                    className="small-contained-button me-2 "
                    onClick={handleExportClick}
                  >
                    Export Csv &nbsp;&nbsp;
                    <Icon icon="ic:round-cloud-download" fontSize={20} />
                  </button>
                )}
                <button
                  className="small-contained-button me-2 mt-1 mb-4"
                  onClick={handleOpenFilterDrawer}
                >
                  Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
                </button>
              </div>
            </div>
          </div>
        )}

        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
      </div>
    </>
  );
};

export default RenderStripeTransactions;
