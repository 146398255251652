import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { mission_schedule_detail_for_questions } from "src/DAL/MissionApi/MissionApiNew";
import ManageQuestionComponent from "./ManageQuestionComponent";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ManageQuestionAcsess() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [previousInfo, setPreviousInfo] = useState(null);
  const [isTabLoading, setIsTabLoading] = useState(false);
  const [afterActionQuestionsList, setAfterActionQuestionsList] = useState([]);
  const [onScreenQuestions, setOnScreenQuestions] = useState([]);
  const [selectedAfterActionQuestions, setSelectedAfterActionQuestions] =
    useState([]);
  const [selectedOnScreenQuestions, setSelectedOnScreenQuestions] = useState(
    []
  );

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const getSaleLeadList = async () => {
    const result = await mission_schedule_detail_for_questions(params.id);
    if (result.code === 200) {
      setPreviousInfo(result?.mission_schedule);
      const selected_after_action_questions =
        result?.selected_after_action_questions.map((item) => {
          return {
            ...item,
            show_question:
              result.selected_questions.find(
                (selectedItem) => selectedItem._id === item._id
              )?.show_question ?? "after_action",
          };
        });

      const selected_on_screen_questions =
        result?.selected_on_screen_questions.map((item) => {
          return {
            ...item,
            show_question:
              result.selected_questions.find(
                (selectedItem) => selectedItem._id === item._id
              )?.show_question ?? "on_screen",
          };
        });

      setAfterActionQuestionsList(selected_after_action_questions);
      setOnScreenQuestions(selected_on_screen_questions);

      const filteredSelectedAfterAction = result.selected_questions.filter(
        (selectedItem) =>
          result.selected_after_action_questions.some(
            (question) => question._id === selectedItem._id
          )
      );
      const filteredSelectedOnScreen = result.selected_questions.filter(
        (selectedItem) =>
          result.selected_on_screen_questions.some(
            (question) => question._id === selectedItem._id
          )
      );

      setSelectedAfterActionQuestions(filteredSelectedAfterAction);
      setSelectedOnScreenQuestions(filteredSelectedOnScreen);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABS_OPTIONS = [
    {
      title: "After Action Question",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <ManageQuestionComponent
          dataList={afterActionQuestionsList}
          selected={selectedAfterActionQuestions}
          setSelected={setSelectedAfterActionQuestions}
          setOnScreenQuestions={setOnScreenQuestions}
          setAfterActionQuestionsList={setAfterActionQuestionsList}
          type="after_action"
          setIsTabLoading={setIsTabLoading}
        />
      ),
    },

    {
      title: "On Screen Question",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <>
          <ManageQuestionComponent
            dataList={onScreenQuestions}
            selected={selectedOnScreenQuestions}
            setSelected={setSelectedOnScreenQuestions}
            setOnScreenQuestions={setOnScreenQuestions}
            setAfterActionQuestionsList={setAfterActionQuestionsList}
            type="on_screen"
            setIsTabLoading={setIsTabLoading}
          />
        </>
      ),
    },
  ];

  let breadCrumbMenu = [
    {
      title: `Mission (${previousInfo?.mission_info?.title})`,
      navigation: `/mission`,
      active: false,
    },
    {
      title: `${previousInfo?.title}`,
      navigation: `/mission/challenge/schedules/${previousInfo?.mission_info?._id}`,
      active: false,
    },
    {
      title: "Manage Schedule Question",
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    getSaleLeadList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="col-12 mb-3">
        <span>
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </span>
      </div>

      <MUICustomTabs
        data={TABS_OPTIONS}
        value={tabValue}
        handleChange={handleChange}
      />
    </div>
  );
}
