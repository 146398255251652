import { CircularProgress } from "@mui/material";
import React from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import { dd_date_format, show_proper_words } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ManageLevelAccess({ tabData }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [badgeLevelHistory, setBadgeLevelHistory] = useState([]);

  const getBadgeLevelHistoryList = () => {
    setIsLoading(true);
    let levels = tabData?.map((item) => {
      return {
        ...item,
        table_avatar: {
          src: s3baseUrl + item?.membership_level_badge_icon?.thumbnail_1,
          alt: item?.membership_level_badge_title,
        },
      };
    });
    setBadgeLevelHistory(levels);
    setIsLoading(false);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
    },
    { id: "membership_level_badge_title", label: "Title" },

    {
      id: "date",
      label: "Assigned Date ",
      renderData: (row) => {
        return <div>{row?.date ? dd_date_format(row?.date) : "N/A"}</div>;
      },
    },

    {
      label: "Assigned From",
      renderData: (row) => {
        let action_info = row?.action_info;
        let actionFrom = "N/A";
        let actionTitle = "N/A";
        if (action_info) {
          actionFrom = action_info?.action_from;
          actionTitle = action_info?.action_title;
        }

        return (
          <div>
            {action_info
              ? `${show_proper_words(actionFrom)} (${actionTitle})`
              : "N/A"}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getBadgeLevelHistoryList();
  }, [tabData]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={badgeLevelHistory}
          className="card-with-background"
        />
      </div>
    </>
  );
}
