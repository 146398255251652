import React from "react";
import { useEffect, useState } from "react";
import { Link as RouterLink, Navigate, useNavigate } from "react-router-dom";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import { s3baseUrl } from "../../config/config";
import { useSnackbar } from "notistack";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { get_wheel_of_life_members_api } from "src/DAL/QuestionSurvey/QuestionSurvey";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { get_Order_list } from "src/DAL/ShopProductCategoryList/ProductCategory";
import { convertCurrencyToSign } from "src/utils/constant";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import History from "../MembersList/History";
import ShopOrderDetail from "./ShopOrderDetail";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  change_order_status_order_api,
  delete_lesson_api,
  delete_order_api,
} from "src/DAL/Programme/Lessons/Lessons";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import SupportTicketFilterDepartment from "../SupportTickets/SupportTicketFilterDepartment";
import OrderHistoryFilter from "./OrderHistoryFilter";
import FilteredChip from "src/components/FilteredChip";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ShopOrder() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [changeStatusOpen, setChangeStatusOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteChangeStatus, setOpenChangeStatus] = useState(false);
  const [leadStatusData, setleadStatusData] = useState({});
  const [editDrawerStateDep, setEditDrawerStateDep] = useState(false);
  const EMPTY_FILTER = {
    order_status: [
      { chip_label: "Processing", chip_value: "processing" },
      { chip_label: "Dispatched", chip_value: "dispatched" },
      { chip_label: "Delivered", chip_value: "delivered" },
    ],
  };
  const ALTER_FILTER = {
    order_status: [],
  };

  const [inputs, setInputs] = useState(EMPTY_FILTER);
  const [filterUpdated, setFilterUpdated] = useState(EMPTY_FILTER);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  // -----------------filter start
  const handleOpenEditDrawerDep = () => {
    setEditDrawerStateDep(true);
  };
  const handleNavigateAddOrder = () => {
    navigate("/shop-order/add-order");
  };
  const handleCloseEditDrawerDep = () => {
    setEditDrawerStateDep(false);
  };
  const handleChangeValues = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleClearFilter = () => {
    let clear_data = {
      ...EMPTY_FILTER,
      order_status: [],
    };
    setPage(0);
    setPageCount(1);
    getMemberListing(clear_data);
    setInputs(clear_data);
  };
  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    getMemberListing(data);

    setInputs(data);
  };
  // ------------------end
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };
  const show_history = (value) => {
    setleadStatusData(value);
    setChangeStatusOpen(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getMemberListing(inputs);
    localStorage.setItem("order_detail_data", JSON.stringify(inputs));
  };
  const handleCopyReferralId = async (e) => {
    await navigator.clipboard.writeText(e.affliliate?.affiliate_url_name);
    enqueueSnackbar("Referral Id Copied to clipboard", { variant: "success" });
  };

  const getMemberListing = async (ticket_data) => {
    setIsLoading(true);

    let search_keyword =
      localStorage.getItem("wheel_of_life_search_text") == null
        ? searchText
        : localStorage.getItem("wheel_of_life_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    const formData = { ...ticket_data };
    if (formData.order_status.length > 0) {
      formData.order_status = formData.order_status.map(
        (item) => item.chip_value
      );
    }
    handleCloseEditDrawerDep();
    const result = await get_Order_list(
      page,
      rowsPerPage,
      search_keyword,
      formData
    );
    if (result.code == 200) {
      let chipData = { ...ticket_data };

      if (chipData.order_status == "all") {
        delete chipData.filter_by;
      }
      setFilterUpdated(chipData);
      const members = result.order_list.map((member, index) => {
        let other_info_Currency;
        let converted_subtotal;
        let converted_total;
        if (member.other_info) {
          other_info_Currency = member.other_info?.converted_currency;
          converted_subtotal = member.other_info?.converted_subtotal;
          converted_total = member.other_info?.converted_total;
        }
        return {
          ...member,
          full_name: member.user_info.first_name
            ? member.user_info.first_name + " " + member.user_info.last_name
            : "N/A",
          email: member.user_info.email ? member.user_info.email : "N/A",
          sub_total:
            convertCurrencyToSign(member.currency) +
            parseFloat(member.sub_total).toFixed(2),
          shipping_charges: `£${parseFloat(member.shipping_charges).toFixed(
            2
          )}`,
          tax_charges_: `£${parseFloat(member.tax_charges).toFixed(2)}`,
          chargeable_amount: converted_total
            ? convertCurrencyToSign(other_info_Currency) +
              converted_total.toFixed(2)
            : "N/A",
          total: `£${(member.total - +member.discount).toFixed(2)}`,
          total_discount: `£${member.discount.toFixed(2)}`,
          MENU_OPTIONS: handleMenu(member),
          createdAt: moment(member.createdAt).format("DD-MM-YYYY"),
        };
      });
      setData(members);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };
  const handleOpenDelete = (value) => {
    setOpenDelete(true);
    setleadStatusData(value);
  };
  const handleOpenChangeStatus = (value) => {
    setOpenChangeStatus(true);
    setleadStatusData(value);
  };

  const handle_privacy_click = (path) => {
    window.open(`${path}`, "_blank");
  };

  const handleMenu = (row) => {
    let MENU_OPTIONS = [
      {
        label: "View Detail",
        icon: "akar-icons:edit",
        handleClick: show_history,
      },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleOpenDelete,
      },
    ];
    if (row.order_status == "dispatched") {
      MENU_OPTIONS.push({
        label: "Change status to delivered",
        icon: "akar-icons:edit",
        handleClick: handleOpenChangeStatus,
      });
    }

    return MENU_OPTIONS;
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    {
      id: "mintsoft_order_id",
      label: "Mintsoft Order Id ",
      className: "text-capitalize",
      renderData: (row) => {
        return (
          <span>{row.mintsoft_order_id ? row.mintsoft_order_id : "N/A"}</span>
        );
      },
    },
    {
      id: "full_name",
      label: "Name",
      renderData: (row) => {
        return (
          <p
            className="mb-0"
            style={{ cursor: "pointer" }}
            onClick={() => show_history(row)}
          >
            {row.full_name}
          </p>
        );
      },
    },
    {
      id: "email",
      label: "Email",
      renderData: (row) => {
        return (
          <p
            className="mb-0"
            style={{ cursor: "pointer" }}
            onClick={() => show_history(row)}
          >
            {row.email}
          </p>
        );
      },
    },
    { id: "sub_total", label: "Subtotal" },
    { id: "shipping_charges", label: "Shipping Fee" },
    { id: "tax_charges_", label: "Tax Charges" },
    { id: "total_discount", label: "Discount" },
    { id: "total", label: "Grand Total" },
    { id: "chargeable_amount", label: "Charged Amount" },
    {
      id: "order",
      label: "Order Created By",
      alignRight: false,
      renderData: (row) => {
        const { action_info } = row;
        console.log(action_info, "action_infoaction_infoaction_info");
        let placedBy = "";
        if (row.order_placed_by == "client_user") {
          placedBy = "Client Portal";
        } else if (row.order_placed_by == "dynamite_storehouse") {
          placedBy = "Dynamite Storehouse (Website)";
        } else if (row.order_placed_by == "website") {
          placedBy = action_info ? `${action_info} (Website)` : "Website";
        } else {
          placedBy = "Admin Portal ";
        }
        return (
          <>
            <p>{placedBy}</p>
          </>
        );
      },
    },
    { id: "createdAt", label: "Date" },
    {
      id: "Tracking Id",
      label: "Track Package",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            {row.TrackingURL !== "" ? (
              <Tooltip title="Click here to track Order">
                <span
                  onClick={() => handle_privacy_click(row.TrackingURL)}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#e4c073",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  Click To Track
                </span>
              </Tooltip>
            ) : (
              <Tooltip
                title={`${
                  row.order_status == "cancelled"
                    ? `Order has been ${row.order_status}`
                    : `Please wait, Order is currently in  ${row.order_status}`
                }`}
              >
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#6c757d",
                    fontSize: "14px",
                    cursor: "not-allowed",
                  }}
                >
                  Click To Track
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      alignRight: false,
      renderData: (row) => {
        let chipColor;
        let chipTextcolor;
        switch (row.order_status) {
          case "pending":
            chipColor = "#ff9800";
            chipTextcolor = "#fff";
            break;
          case "processing":
            chipColor = "#c19a6b";
            chipTextcolor = "#fff";
            break;
          case "dispatched":
            chipColor = "#0288d1";
            chipTextcolor = "#fff";
            break;
          case "completed":
            chipColor = "#4caf50";
            chipTextcolor = "#fff";
            break;
          case "delivered":
            chipColor = "#4caf50";
            chipTextcolor = "#fff";
            break;
          case "cancelled":
            chipColor = "rgb(211, 47, 47)";
            chipTextcolor = "#fff";

            break;
          default:
            chipColor = "#ff9800";
            chipTextcolor = "#fff";

            break;
        }
        return (
          <Chip
            label={row.order_status}
            style={{
              backgroundColor: chipColor,
              color: chipTextcolor,
              textTransform: "capitalize",
            }}
          ></Chip>
        );
      },
    },
  ];
  const handleDelete = async () => {
    setIsLoading(true);
    let result = await delete_order_api(leadStatusData._id);
    if (result.code === 200) {
      getMemberListing(inputs);
      setOpenDelete(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeStatus = async () => {
    setIsLoading(true);
    let result = await change_order_status_order_api(leadStatusData._id);
    if (result.code === 200) {
      getMemberListing(inputs);
      setOpenChangeStatus(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    getMemberListing(inputs);
  }, [rowsPerPage, page]);

  useEffect(() => {
    localStorage.setItem("wheel_of_life_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("wheel_of_life_search_text", "");
    } else {
      localStorage.setItem("wheel_of_life_search_text", searchText);
    }
  }, [searchText]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-lg-6 col-sm-12 mb-5">
            <h2>Orders History</h2>
          </div>
          <div className="col-6 text-end">
            <button
              className="small-contained-button me-2"
              onClick={handleNavigateAddOrder}
            >
              Create Order
            </button>{" "}
            <button
              className="small-contained-button me-2"
              onClick={handleOpenEditDrawerDep}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
          <FilteredChip
            data={filterUpdated}
            tempState={inputs}
            EMPTY_FILTER={EMPTY_FILTER}
            ALTER_FILTER={ALTER_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={data}
            className="card-with-background"
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            pagePagination={true}
          />
        </div>
        <WhatsappPopUpModel
          open={changeStatusOpen}
          setOpen={setChangeStatusOpen}
          title={"Order Detail"}
          show_date_and_income={true}
          componentToPassDown={
            <ShopOrderDetail leadStatusData={leadStatusData} />
          }
        />
        <CustomDrawer
          isOpenDrawer={editDrawerStateDep}
          onOpenDrawer={handleOpenEditDrawerDep}
          onCloseDrawer={handleCloseEditDrawerDep}
          pageTitle="Filters"
          componentToPassDown={
            <OrderHistoryFilter
              handleChange={handleChangeValues}
              inputs={inputs}
              setInputs={setInputs}
              searchSubmitFilter={searchFunction}
              handleClearFilter={handleClearFilter}
            />
          }
        />
        <CustomConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete?"}
          handleAgree={handleDelete}
        />{" "}
        <CustomConfirmation
          open={openDeleteChangeStatus}
          setOpen={setOpenChangeStatus}
          title={"Are you sure you want to change status to delivered?"}
          handleAgree={handleChangeStatus}
        />
      </div>
    </>
  );
}
