import React, { useEffect } from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  get_twilio_settings,
  update_twilio_settings,
} from "src/DAL/SiteSetting/siteSetting";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function TwilioSettings() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = useState({
    mode: "",
    account_sid: "",
    auth_token: "",
    twilio_number: "",
    verify_service_sid: "",
    live_account_sid: "",
    live_auth_token: "",
    live_twilio_number: "",
    live_verify_service_sid: "",
    messaging_service_id: "",
    live_messaging_service_id: "",
    use_service_for_messaging: "phone_number",
  });

  const siteSettingData = async () => {
    setIsLoading(true);
    const result = await get_twilio_settings();
    if (result.code == 200) {
      setInputs({
        ...inputs,
        ...result?.twilio_settings,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    let postData = {
      twilio_settings: {
        mode: inputs.mode,
        account_sid: inputs.account_sid,
        auth_token: inputs.auth_token,
        twilio_number: inputs.twilio_number,
        verify_service_sid: inputs.verify_service_sid,
        live_account_sid: inputs.live_account_sid,
        live_auth_token: inputs.live_auth_token,
        live_twilio_number: inputs.live_twilio_number,
        live_verify_service_sid: inputs.live_verify_service_sid,
        use_service_for_messaging: inputs.use_service_for_messaging,
        messaging_service_id: inputs.messaging_service_id,
        live_messaging_service_id: inputs.live_messaging_service_id,
      },
    };

    const result = await update_twilio_settings(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    siteSettingData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-6">
          <h2>Twilio Settings</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Twilio Mode</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="mode"
                value={inputs.mode}
                label="Twilio Mode"
                onChange={handleChange}
              >
                <MenuItem value="sandBox">SandBox</MenuItem>
                <MenuItem value="live">Live</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Use Service For Messaging
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="use_service_for_messaging"
                value={inputs.use_service_for_messaging}
                label="Use Service For Messaging"
                onChange={handleChange}
              >
                <MenuItem value="phone_number">Phone Number</MenuItem>
                <MenuItem value="sender_id"> Sender ID </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-12 mt-3">
            <h3 style={{ color: "#e4c073" }}>Twilio Settings for SandBox</h3>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Verify Service SID"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="verify_service_sid"
              value={inputs.verify_service_sid}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Account SID"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="account_sid"
              value={inputs.account_sid}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Auth Token"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="auth_token"
              value={inputs.auth_token}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Twilio Number"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="twilio_number"
              value={inputs.twilio_number}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Messaging Service ID"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="messaging_service_id"
              value={inputs.messaging_service_id}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-3">
            <h3 style={{ color: "#e4c073" }}>Twilio Settings for Live</h3>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Live Verify Service SID"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="live_verify_service_sid"
              value={inputs.live_verify_service_sid}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Live Account SID"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="live_account_sid"
              value={inputs.live_account_sid}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Live Auth Token"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="live_auth_token"
              value={inputs.live_auth_token}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Live Twilio Number"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="live_twilio_number"
              value={inputs.live_twilio_number}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Live Messaging Service ID"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="live_messaging_service_id"
              value={inputs.live_messaging_service_id}
              onChange={handleChange}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
