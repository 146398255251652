import * as React from "react";
import Popover from "@mui/material/Popover";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
export default function PopOverViewMoreMissions({
  handleClick,
  handleClose,
  anchorEl,
  id,
  open,
  program,
  title,
}) {
  const navigate = useNavigate();

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ heigth: 100 }}
      >
        <div className="sticky-top card">
          <p className="heading_programs">{title}</p>
          <span className="icon-container" onClick={() => handleClose()}>
            <CancelOutlinedIcon fontSize="small" />
          </span>
        </div>
        {program?.map((item, index) => {
          return (
            <Tooltip key={index}>
              <p
                className="mb-1 program-hover"
                style={{ padding: "2px 5px 2px 8px" }}
              >
                {item?.title}
              </p>
            </Tooltip>
          );
        })}
      </Popover>
    </div>
  );
}
