import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  _auto_responded_message,
  delete_auto_responded_message,
} from "src/DAL/AutoRespondedMessage/AutoRespondedMessage";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },
  {
    id: "type",
    label: "Type",
    alignRight: false,
    renderData: (row) => {
      return (
        <>
          <div style={{ cursor: "pointer" }}>
            <div>
              {row.message_type == "general" && <span>General</span>}
              {row.message_type == "personal_notes" && (
                <span>Personal Notes</span>
              )}
              {row.message_type == "department" && <span>Department</span>}
              {row.message_type == "department" && (
                <span
                  style={{
                    fontSize: "20px",
                  }}
                  className="me-1 ms-1 mt-1"
                >
                  /
                </span>
              )}
              {row.message_type == "department" && (
                <span style={{ color: "#e4c073" }}>
                  {row?.department?.title}
                </span>
              )}
            </div>
          </div>
        </>
      );
    },
  },
  { id: "order", label: "Order", alignRight: false },
];
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AutoRespondedMessage() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [messagesData, setMessagesData] = useState([]);

  const getMessagesListing = async () => {
    setIsLoading(true);
    const result = await _auto_responded_message();
    if (result.code == 200) {
      setMessagesData(result.auto_responder_message);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_auto_responded_message(deleteDoc._id);
    if (result.code === 200) {
      getMessagesListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    navigate(`/auto-responded-message/edit-message/${value._id}`, {
      state: value,
    });
  };

  const handleNavigate = () => {
    navigate(`/auto-responded-message/add-message`);
  };

  useEffect(() => {
    getMessagesListing();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this message?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h2>Auto Responded Messages</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Message
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-3">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={messagesData}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background mt-3"
              pagePagination={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
