import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  CircularProgress,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import {
  Badge_list_api_new,
  update_Badge_config_api_new,
} from "src/DAL/WebsitePages/paymentPlan";
import { s3baseUrl } from "src/config/config";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import RecordNotFound from "src/components/RecordNotFound";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function BadgeConfiguration() {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [programsList, setProgramsList] = useState([]);
  const [uplaodImage, setUplaodImage] = useState(null);
  const [selected, setSelected] = useState([]);
  const [planDetail, setPlanDetail] = useState({});
  const [addArray, setaddArray] = useState([]);

  const handleAdd = (index) => {
    let newArray = programsList.map((item) => {
      return { ...item, no_of_badges: 1 };
    });
    let newAddArray = addArray.slice();
    newAddArray.splice(index + 1, 0, {
      number_of_challenges: "",
      badges: newArray,
      isExpanded: false,
      selected_badges: [],
    });
    setaddArray(newAddArray);
  };

  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);
    setaddArray(delValue);
  };

  function hasDuplicateChallenges(array) {
    const uniqueChallenges = new Set();
    for (let obj of array) {
      const challenges = obj.number_of_challenges;
      if (uniqueChallenges.has(challenges)) {
        return true;
      }
      uniqueChallenges.add(challenges);
    }
    return false;
  }
  const getProgramsList = async () => {    
    const result = await Badge_list_api(params.road_map_levels_type_id);
    if (result.code == 200) {
      setPlanDetail(result.pyramid);
      let selected_programs = [];
      let selected_badges_array = result.pyramid.badge_configration.map(
        (item, index) => {
          let object = {
            badges: result.badge_list,
            isExpanded: index == 0 ? true : false,
            number_of_challenges: item.number_of_challenges,
            selected_badges: item.selected_badges,
            image: item.image,
            congratulations_message: item.congratulations_message,
          };
          return object;
        }
      );
      setaddArray(selected_badges_array);
      setSelected(selected_programs);
      var programArray = [];
      result.badge_list.map((item, index) => {
        item.table_avatar = {
          src: s3baseUrl + item.icon.thumbnail_2,
          alt: item.title,
        };
        item.no_of_badges = 1;
        programArray.push(item);
      });
      let firstObject = {
        number_of_challenges: "",
        isExpanded: true,
        selected_badges: [],
        badges: programArray,
        image: "",
        congratulations_message: "",
      };
      addArray.push(firstObject);
      if (result.pyramid.badge_configration.length == 0) {
        setaddArray([firstObject]);
      }
      setProgramsList(programArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    const hasEmptyValues = addArray.some(
      (obj) => obj.image == "" || obj.congratulations_message == ""
    );
    if (hasEmptyValues) {
      setIsLoadingForm(false);
      enqueueSnackbar("Please fill all the fields", {
        variant: "error",
      });
    } else {
      if (hasDuplicateChallenges(addArray)) {
        setIsLoadingForm(false);
        enqueueSnackbar("Duplicate Numbers challenges are not allowed", {
          variant: "error",
        });
      } else {
        let arry =
          addArray &&
          addArray.length > 0 &&
          addArray.map((item, index) => {
            let program_object = {
              number_of_challenges: item.number_of_challenges,
              selected_badges: item.selected_badges,
              image: item.image,
              congratulations_message: item.congratulations_message,
            };
            return program_object;
          });
        let programObject = {
          badge_configration: arry,
        };
        const result = await update_Badge_config_api_new(
          params.road_map_levels_type_id,
          programObject
        );
        if (result.code == 200) {
          setIsLoadingForm(false);
          enqueueSnackbar(result.message, { variant: "success" });
        } else {
          setIsLoadingForm(false);
          enqueueSnackbar(result.message, { variant: "error" });
        }
      }
    }
  };

  const expandArea = (index, open) => {
    let temp_state = [...addArray];
    let temp_element = { ...temp_state[index] };
    if (temp_element.isExpanded === false) {
      temp_element.isExpanded = true;
    } else if (open == "open") {
      temp_element.isExpanded = true;
    } else {
      temp_element.isExpanded = false;
    }
    temp_state[index] = temp_element;
    setaddArray(temp_state);
  };

  useEffect(() => {
    getProgramsList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const handleChangeInputs = (
    newValue,
    index,
    child_options,
    child_index,
    child_id
  ) => {
    const value = newValue.target.value;
    const name = newValue.target.name;

    const copyArray = [...addArray];
    const selectedObject = copyArray[index];
    let findedChild = selectedObject.selected_badges.find(
      (item) => item._id == child_id
    );
    if (child_options == "child_options" && findedChild) {
      findedChild.no_of_badges = value;
    } else {
      selectedObject[name] = value;
    }
    setaddArray(copyArray);
  };

  const handleChangeOthers = (value, name, index) => {
    const copyArray = [...addArray];
    const selectedObject = copyArray[index];
    selectedObject[name] = value;
    setaddArray(copyArray);
    expandArea(index, "open");
  };

  const fileChangedHandlerFB = async (e, index) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "300");
    setUplaodImage(index);
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      const copyArray = [...addArray];
      const selectedObject = copyArray[index];
      selectedObject["image"] = imageUpload.image_path;
      setaddArray(copyArray);
      setUplaodImage(null);
    }
  };

  let breadCrumbMenu = [
    {
      title: "Road Map Levels",
      navigation: `/road-map-levels`,
      active: false,
    },
    {
      title: "Pyramids",
      navigation: -1,
      active: false,
    },
    {
      title: planDetail?.title,
      active: true,
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-6">
          <h2>Badge Configuration</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        {addArray &&
          addArray.length > 0 &&
          addArray?.map((item, index) => {
            return (
              <>
                <div className="row mt-2">
                  <div className="col-12 hide-focus-color">
                    <Accordion
                      className="question-background"
                      expanded={item.isExpanded}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon onClick={() => expandArea(index)} />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="svg-color text-white"
                      >
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-5 ps-0">
                              <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
                                <TextField
                                  className="text-muted"
                                  InputLabelProps={{ style: { fontSize: 14 } }}
                                  id="outlined-basic"
                                  label="No of Completed Challenges"
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  name="number_of_challenges"
                                  type="number"
                                  value={item.number_of_challenges}
                                  onChange={(e) => handleChangeInputs(e, index)}
                                  required={true}
                                />
                              </div>
                            </div>{" "}
                            <div className="col-5 ps-0">
                              <MUIAutocomplete
                                inputLabel="Select Badges"
                                limitTags={2}
                                selectedOption={item.selected_badges}
                                size="small"
                                setSelectedOption={(e) => {
                                  handleChangeOthers(
                                    e,
                                    "selected_badges",
                                    index
                                  );
                                }}
                                optionsList={item.badges}
                                autoComplete="new-password"
                                name="title"
                                multiple
                              />
                            </div>
                            <div
                              className="col-1 ps-0"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              <span className="cross-icon">
                                {addArray.length > 1 ? (
                                  <>
                                    <Tooltip title="Remove">
                                      <RemoveCircleOutlineIcon
                                        onClick={() => handleDelete(index)}
                                        className="diary-icon-remove"
                                      />
                                    </Tooltip>
                                  </>
                                ) : (
                                  ""
                                )}
                                <Tooltip title="Add">
                                  <AddCircleOutlineIcon
                                    onClick={() => handleAdd(index)}
                                    className="diary-icon-add"
                                  />
                                </Tooltip>
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionSummary>
                      <>
                        <AccordionDetails className="accordian_detail">
                          <div
                            className="container-fluid"
                            style={{ color: "white" }}
                          >
                            <div className="row mb-3">
                              {item.selected_badges &&
                              item.selected_badges.length > 0 ? (
                                item.selected_badges.map(
                                  (items, child_index) => {
                                    return (
                                      <>
                                        <div className="col-lg-6 mt-4 small-container">
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            placeholder="No.Of Badges"
                                            required
                                            value={items.no_of_badges}
                                            onChange={(e) =>
                                              handleChangeInputs(
                                                e,
                                                index,
                                                "child_options",
                                                child_index,
                                                items._id
                                              )
                                            }
                                            type="number"
                                            name="no_of_badges"
                                            InputLabelProps={{
                                              shrink: true,
                                              style: { fontSize: "14px" },
                                            }}
                                            label={items.title}
                                            InputProps={{
                                              style: {
                                                fontSize: "13px",
                                                paddingLeft: "4px",
                                                paddingRight: "6px",
                                              },
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Avatar
                                                    sx={{
                                                      borderRadius: "5px",
                                                    }}
                                                    alt="User Image"
                                                    src={
                                                      s3baseUrl +
                                                      items?.icon?.thumbnail_2
                                                    }
                                                  />
                                                </InputAdornment>
                                              ),
                                              inputProps: {
                                                readOnly: false,
                                                min: 1,
                                              },
                                            }}
                                            sx={{
                                              borderRadius: "5px",
                                              fontSize: "13px",
                                              width: "50%",
                                            }}
                                          />
                                        </div>
                                      </>
                                    );
                                  }
                                )
                              ) : (
                                <div className="mt-5">
                                  <RecordNotFound title="Badges" />
                                </div>
                              )}
                              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                                <div className="row w-100 div-style ms-0 pt-0">
                                  <div className="col-5">
                                    <p className="Reward GIF">Reward GIF *</p>
                                    Recommended Size (2000 X 1200) ("GIF" )
                                  </div>
                                  <div className="col-2">
                                    {uplaodImage == index ? (
                                      <CircularProgress
                                        size="1.2rem"
                                        color="primary"
                                      />
                                    ) : (
                                      <>
                                        {item.image ? (
                                          <img
                                            src={s3baseUrl + item.image}
                                            height="50"
                                          />
                                        ) : (
                                          <span className="invisible"></span>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div className="col-5 text-end pt-2">
                                    <label
                                      htmlFor={`contained-button-fileFb${
                                        index + 3324
                                      }`}
                                    >
                                      <Input
                                        accept="image/gif"
                                        id={`contained-button-fileFb${
                                          index + 3324
                                        }`}
                                        type="file"
                                        name="InvoiceImage"
                                        onChange={(e) =>
                                          fileChangedHandlerFB(e, index)
                                        }
                                      />

                                      <Button
                                        className="small-contained-button"
                                        startIcon={<FileUploadIcon />}
                                        component="span"
                                      >
                                        Upload
                                      </Button>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                                <h4>Congratulation Message * </h4>
                                <GeneralCkeditor
                                  setInputs={setaddArray}
                                  inputs={addArray}
                                  name="congratulations_message"
                                  index={index}
                                  is_array={true}
                                  editorHeight={250}
                                />
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </>
                    </Accordion>
                  </div>
                </div>
              </>
            );
          })}
        <div className="ms-auto text-end">
          <button
            className="small-contained-button mt-3 ml-auto"
            disabled={uplaodImage !== null}
          >
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
