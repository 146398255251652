import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  _membership_level_pyramid_list,
  membership_level_pyramid_delete,
} from "src/DAL/BonusRoadMAp/BonusRoadMApApi";
import { s3baseUrl } from "src/config/config";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const MapParamid = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [levelsList, setLevelsList] = useState([]);
  const [mapInfo, setMapInfo] = useState({});

  const get_map_levels_List = async () => {
    const result = await _membership_level_pyramid_list(params?.map_id);
    if (result.code == 200) {
      const road_map_levels = result.pyramid_list.map((pyramid_list) => {
        return {
          ...pyramid_list,
          icon_image: {
            src: s3baseUrl + pyramid_list?.icon?.thumbnail_1,
            alt: pyramid_list.title,
          },
          before_status: {
            src: s3baseUrl + pyramid_list?.before_status_image?.thumbnail_1,
            alt: pyramid_list.title,
          },
          reached_status: {
            src: s3baseUrl + pyramid_list?.reached_status_image?.thumbnail_1,
            alt: pyramid_list.title,
          },
        };
      });
      setLevelsList(road_map_levels);
      setMapInfo(result.road_map_level);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    navigate(`/road-map-levels/pyramid/edit/${value._id}`);
  };

  const handleAgreeDelete = (value) => {
    setDeleteValue(value._id);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await membership_level_pyramid_delete(deleteValue);
    if (result.code === 200) {
      get_map_levels_List();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigate = () => {
    navigate(`/road-map-levels/pyramid/add/${params.map_id}`);
  };

  const handleBagdeConfiguration = (value) => {
    navigate(`/road-map-levels/pyramid/badge-configuration/${value._id}`);
  };

  let breadCrumbMenu = [
    {
      title: "Road Map Levels",
      navigation: `/road-map-levels`,
      active: false,
    },
    {
      title: mapInfo?.title,
      active: true,
    },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Badge Configuration",
      icon: "akar-icons:edit",
      handleClick: handleBagdeConfiguration,
    },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Title" },
    {
      id: "icon_image",
      label: "Icon",
      type: "thumbnail",
    },
    {
      id: "reached_status",
      label: "Reached Status",
      type: "thumbnail",
    },
    {
      id: "before_status",
      label: "Before Status",
      type: "thumbnail",
    },
    { id: "coins", label: "Coins" },
    { id: "order", label: "order" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  useEffect(() => {
    get_map_levels_List();
  }, [params.map_id]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fulid">
        <div className="row mb-5">
          <div className="col-lg-8 ">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
          <div className="col-lg-4 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Pyramid
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={levelsList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background mt-3"
          pagePagination={true}
        />
      </div>
    </>
  );
};

export default MapParamid;
