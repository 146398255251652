import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  delete_road_map_levels_api,
  road_map_levels_list_api,
} from "src/DAL/RoadMapLevelsApi/RoadMapLevelsApi";
import { s3baseUrl } from "src/config/config";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  {
    id: "table_avatar",
    label: "Icon",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "title", label: "Title", alignRight: false },

  { id: "order", label: "Order", alignRight: false },
  {
    id: "status",
    label: "Status",
    type: "row_status",
  },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MemberShipLevels() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [roadMapLevels, setRoadMapLevels] = useState([
    {
      _id: "64cb708bb4959581b7e1af42",
      title: "Source Member ",
      status: true,
      video_url: "https://vimeo.com/854296581?share=copy",
      free_video_url: "https://vimeo.com/854296581?share=copy",
      icon: "road_map_levels/ca44082d-2255-4b4e-a051-c09ef1485380.webp",
      short_description: "Dynamite Level 2\r\n",
      detail_description: "<p>Dynamite Level 3</p>",
      order: 1,
      alias_title: "dynamite",

      createdAt: "2024-03-16T15:06:18.340Z",
      updatedAt: "2024-03-16T15:11:22.517Z",
      table_avatar: {
        src: "https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/badge/cb4f2dba-738b-4d6e-873f-ebafd23bafba.jpg",
        alt: "Dynamite Level ",
      },
    },
    {
      _id: "64cb7ac8bc92d38a76c03128",
      title: "PTA Level ",
      status: true,
      video_url: "https://vimeo.com/645929260",
      free_video_url: "https://youtu.be/D0UnqGm_miA",
      icon: "road_map_levels/55fa6fe6-227f-4a3e-955e-2c7a0eb97e24.png",
      short_description: "Rocket ",
      detail_description: "<p>Rocket&nbsp;</p>",
      order: 2,
      alias_title: "pta",

      createdAt: "2024-03-16T15:06:18.340Z",
      updatedAt: "2024-03-16T15:14:28.697Z",
      table_avatar: {
        src: "https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/badge/ea87472a-fd32-42ff-a2a9-f2a1d60eb718.jpeg",
        alt: "Rocket ",
      },
    },
    {
      _id: "64cb7b2bbc92d38a76c0339d",
      title: "Ignite Level ",
      status: true,
      video_url: "https://vimeo.com/645929260",
      free_video_url: "",
      icon: "road_map_levels/c562e380-cd38-4203-93a4-9a21c6e03d39.webp",
      short_description: "Star ",
      detail_description: "<p>Star&nbsp;</p>",
      order: 3,
      alias_title: "elite",

      createdAt: "2024-03-16T15:06:18.340Z",
      updatedAt: "2024-03-16T15:15:48.765Z",
      table_avatar: {
        src: "https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/badge/ea87472a-fd32-42ff-a2a9-f2a1d60eb718.jpeg",
        alt: "Star ",
      },
    },
  ]);

  const getRoadMapList = async () => {
    const result = await road_map_levels_list_api();
    if (result.code == 200) {
      const road_map_levels = result.road_map_levels.map((road_map_levels) => {
        return {
          ...road_map_levels,
          title: road_map_levels.title,
          table_avatar: {
            src: s3baseUrl + road_map_levels.icon,
            alt: road_map_levels.title,
          },
        };
      });
      setRoadMapLevels(road_map_levels);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    // const result = await delete_road_map_levels_api(deleteDoc._id);
    // if (result.code === 200) {
    //   getRoadMapList();
    //   enqueueSnackbar(result.message, { variant: "success" });
    // } else {
    //   enqueueSnackbar(result.message, { variant: "error" });
    // }
  };

  const handleMemverShipLevelPairamid = (value) => {
    navigate(`/membership-levels/pyramid`, {
      state: value,
    });
  };
  const handleEdit = (value) => {
    navigate(`/membership-levels/edit-/membership-levels/${value._id}`, {
      state: value,
    });
  };
  const handleBagdeConfiguration = (value) => {
    navigate(`/road-map-levels/badge-configuration/${value._id}`, {
      state: value,
    });
  };

  const handleNavigate = () => {
    navigate(`/membership-levels/add-/membership-levels`);
  };

  // useEffect(() => {
  //   getRoadMapList();
  // }, []);

  const MENU_OPTIONS = [
    {
      label: "Membership Level pyramid",
      icon: "akar-icons:edit",
      handleClick: handleMemverShipLevelPairamid,
    },
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    // {
    //   label: "Badge Configuration",
    //   icon: "akar-icons:edit",
    //   handleClick: handleBagdeConfiguration,
    // },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  // if (isLoading == true) {
  //   return <CircularProgress className={classes.loading} color="primary" />;
  // }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this membership level?"}
        handleAgree={handleDelete}
      />

      <div className="container-fulid">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Membership Levels</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Membership Levels
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={roadMapLevels}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          localSearchName="road_map_levels"
          pagePagination={true}
        />
      </div>
    </>
  );
}
