import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Button,
  CircularProgress,
  FormHelperText,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import {
  get_onboarding_settings,
  update_onboarding_settings_api,
} from "src/DAL/SiteSetting/siteSetting";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AppOnBoradSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = useState({
    onboarding_image_1: "",
    onboarding_image_2: "",
    onboarding_image_3: "",
    onboarding_title_1: "",
    onboarding_title_2: "",
    onboarding_title_3: "",
    onboarding_description_1: "",
    onboarding_description_2: "",
    onboarding_description_3: "",
    button_text: "",
  });

  const getDefaultSetting = async () => {
    const result = await get_onboarding_settings();
    if (result.code === 200) {
      if (result.app_onboarding_settings) {
        setInputs({
          ...inputs,
          ...result.app_onboarding_settings,
        });
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const fileChangedHandler = async (e, i) => {
    const { name } = e.target;
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "500");
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        [name]: imageUpload.image_path,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let postData = {
      app_onboarding_settings: {
        ...inputs,
      },
    };
    const result = await update_onboarding_settings_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleDeleteImage = (path) => {
    setInputs((prev) => ({
      ...prev,
      [path]: "",
    }));
  };

  useEffect(() => {
    getDefaultSetting();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6">
          <h2>App Onboarding Setting</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Onboarding Title 1"
            variant="outlined"
            fullWidth
            name="onboarding_title_1"
            value={inputs.onboarding_title_1}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Onboarding Title 2"
            variant="outlined"
            fullWidth
            name="onboarding_title_2"
            value={inputs.onboarding_title_2}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Onboarding Title 3"
            variant="outlined"
            fullWidth
            name="onboarding_title_3"
            value={inputs.onboarding_title_3}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Button Text"
            variant="outlined"
            fullWidth
            name="button_text"
            value={inputs.button_text}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0 mb-2">
            <div className="col-5">
              <p className="">Onboarding Image 1</p>
              <FormHelperText className="pt-0">
                Recommended Size (500 X 500) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {inputs?.onboarding_image_1 && (
                <div className="del_img_icon">
                  <span
                    className="pointer_cursor"
                    onClick={() => {
                      handleDeleteImage("onboarding_image_1");
                    }}
                  >
                    X
                  </span>
                  <img
                    src={
                      inputs?.onboarding_image_1
                        ? s3baseUrl + inputs?.onboarding_image_1
                        : ""
                    }
                    className="imgage_border"
                    height="50"
                  />
                </div>
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file2">
                <Input
                  accept="image/*"
                  id="contained-button-file2"
                  multiple
                  type="file"
                  name="onboarding_image_1"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>{" "}
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0 mb-2">
            <div className="col-5">
              <p className="">Onboarding Image 2</p>
              <FormHelperText className="pt-0">
                Recommended Size (500 X 500) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {inputs?.onboarding_image_2 && (
                <div className="del_img_icon">
                  <span
                    className="pointer_cursor"
                    onClick={() => {
                      handleDeleteImage("onboarding_image_2");
                    }}
                  >
                    X
                  </span>
                  <img
                    src={
                      inputs?.onboarding_image_2
                        ? s3baseUrl + inputs?.onboarding_image_2
                        : ""
                    }
                    className="imgage_border"
                    height="50"
                  />
                </div>
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file2su">
                <Input
                  accept="image/*"
                  id="contained-button-file2su"
                  multiple
                  type="file"
                  name="onboarding_image_2"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0 mb-2">
            <div className="col-5">
              <p className="">Onboarding Image 3</p>
              <FormHelperText className="pt-0">
                Recommended Size (500 X 500) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {inputs?.onboarding_image_3 && (
                <div className="del_img_icon">
                  <span
                    className="pointer_cursor"
                    onClick={() => {
                      handleDeleteImage("onboarding_image_3");
                    }}
                  >
                    X
                  </span>
                  <img
                    src={
                      inputs?.onboarding_image_3
                        ? s3baseUrl + inputs?.onboarding_image_3
                        : ""
                    }
                    className="imgage_border"
                    height="50"
                  />
                </div>
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file2fg1">
                <Input
                  accept="image/*"
                  id="contained-button-file2fg1"
                  multiple
                  type="file"
                  name="onboarding_image_3"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <h4>Onboarding Description 1</h4>
          <TextareaAutosize
            aria-label="empty textarea"
            className="new-textarea-autosize"
            value={inputs.onboarding_description_1}
            name="onboarding_description_1"
            onChange={handleChange}
            placeholder="Onboarding Description 1"
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <h4>Onboarding Description 2</h4>
          <TextareaAutosize
            aria-label="empty textarea"
            className="new-textarea-autosize"
            value={inputs.onboarding_description_2}
            name="onboarding_description_2"
            onChange={handleChange}
            placeholder="Onboarding Description 2"
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <h4>Onboarding Description 3</h4>
          <TextareaAutosize
            aria-label="empty textarea"
            className="new-textarea-autosize"
            value={inputs.onboarding_description_3}
            name="onboarding_description_3"
            onChange={handleChange}
            placeholder="Onboarding Description 3"
          />
        </div>
        <div className="text-end mt-4">
          <button
            onClick={handleSubmit}
            className="small-contained-button"
            id="fixedbutton"
          >
            {isLoadingForm ? "Updating" : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
}
