import * as React from "react";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import {
  Checkbox,
  CircularProgress,
  OutlinedInput,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect } from "react";
import {
  add_dynamite_lifestyle_settings_spacific,
  get_dynamite_lifestyle_settings_spacific,
} from "src/DAL/HelpVideos/Categories";
import { useSnackbar } from "notistack";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { infoImages } from "src/assets";
import { show_proper_words } from "src/utils/constant";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function TeamGeneralSettingsSpacificData({ title }) {
  const params = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [allBadges, setAllBadges] = useState([]);

  const [inputs, setInputs] = useState({
    member_export_csv: false,
    all_member_export_csv: false,
    nurture_export_csv: false,
    view_profile: false,
    Show_nurture_in_filter: false,
    cosmos_feeds_filters: false,
    show_nurture_filter_in_lead_centre: false,
    show_delegates_filter_in_lead_centre: false,
    feed_pin_unpin_option: false,
    edit_delete_option_in_source_all_source_feeds: false,
    choose_level_in_cosmos_feeds: false,
    show_all_users_in_lead_centre: false,
    show_all_users_in_mention_feed: false,
    multiple_levels_in_source_all_source_scadule_feeds: false,
    event_info_in_source_all_source_scadule_feeds: false,
    show_option_mark_request_as_paid: false,
    show_option_mark_request_as_cancelled: false,
    default_filter: "",
    show_members_list_for_booking: "nurture_members",
    book_call_with_delegate: "self",
    allow_mission_control_group_members_option: false,
    allow_community_level_in_group: false,
    is_chat_allowed: false,
    enable_poll_feed: false,
    other_link: "",
    is_feed_search_allowed: false,
    enable_survey_feed: false,
    allow_to_send_sms: false,
    allow_manage_access_programs_in_payment_request: false,
    allow_agreement_configration_in_payment_request: false,
    show_members_list_for_payment_request: "nurture_members",
    badge_levels: [],
    other_delegate_team_type: [],
    cosmos_feed_filters: [],
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let trueObj = Object.entries(inputs).reduce((result, [key, value]) => {
      if (value === true) {
        result[key] = value;
      }
      return result;
    }, {});

    let data = {
      portal_access_settings: trueObj,
    };

    data.portal_access_settings.badge_levels = inputs.badge_levels;
    data.portal_access_settings.cosmos_feed_filters =
      inputs.cosmos_feed_filters;
    data.portal_access_settings.default_filter = inputs.default_filter;
    data.portal_access_settings.other_link = inputs.other_link;
    data.portal_access_settings.book_call_with_delegate =
      inputs.book_call_with_delegate;
    data.portal_access_settings.show_members_list_for_booking =
      inputs.show_members_list_for_booking;
    data.portal_access_settings.other_delegate_team_type =
      inputs.other_delegate_team_type;
    data.portal_access_settings.show_members_list_for_payment_request =
      inputs.show_members_list_for_payment_request;

    const result = await add_dynamite_lifestyle_settings_spacific(
      params.id,
      data
    );
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getContentSettings = async (e) => {
    setIsLoading(true);
    const result = await get_dynamite_lifestyle_settings_spacific(params.id);
    if (result.code === 200) {
      setUserData(result.info);
      setAllBadges(result.all_badges);
      if (result.portal_access_settings) {
        setInputs((old) => ({ ...old, ...result.portal_access_settings }));
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    if (name == "cosmos_feeds_filters") {
      setInputs((values) => ({
        ...values,
        default_filter: "",
        choose_level_in_cosmos_feeds: false,
      }));
      setInputs((values) => ({ ...values, cosmos_feed_filters: [] }));
    }
    if (name == "book_call_with_delegate" && value == "self") {
      setInputs((old) => ({ ...old, other_delegate_team_type: [] }));
    }
  };

  useEffect(() => {
    let findValue = inputs.cosmos_feed_filters.find((item) => {
      return item == inputs.default_filter;
    });
    if (!findValue) {
      setInputs((values) => ({ ...values, default_filter: "" }));
    }
  }, [inputs.cosmos_feed_filters]);

  const handleSelectOther = (name) => {
    setInputs((values) => ({ ...values, [name]: !inputs[name] }));
    if (name == "cosmos_feeds_filters") {
      setInputs((values) => ({
        ...values,
        default_filter: "",
        choose_level_in_cosmos_feeds: false,
      }));
      setInputs((values) => ({ ...values, cosmos_feed_filters: [] }));
    }
  };

  let breadCrumbMenu = [
    {
      title: title,
      navigation: -1,
      active: false,
    },
    {
      title: "Manage Templates Access",
      title: userData
        ? userData?.first_name +
          " " +
          userData?.last_name +
          " (" +
          userData?.email +
          ")"
        : "Manage Templates Access",
      active: true,
    },
  ];

  useEffect(() => {
    getContentSettings();
  }, [params.id]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="col-12 mb-3">
        <span>
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </span>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>{title}</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
            <div
              className="card  p-2   ps-0 cursor-pointer position-relative"
              onClick={() => handleSelectOther("member_export_csv")}
            >
              <div className="d-flex align-items-center">
                <Checkbox checked={inputs.member_export_csv} />
                Show export CSV button in members tab ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    "In this dropdown menu, you can manage the 'Export CSV' button in the 'Members' tab. If it is set to 'Yes', the delegate will see the 'Export CSV' button at the top right corner. If it is set to 'No', the button will be hidden"
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() => handleSelectOther("all_member_export_csv")}
            >
              <div className="d-flex align-items-center">
                <Checkbox checked={inputs.all_member_export_csv} />
                Show export CSV button in all members tab ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    "In this dropdown menu, you can manage the 'Export CSV' button in the 'All Members' tab. If it is set to 'Yes', the delegate will see the 'Export CSV' button at the top right corner. If it is set to 'No', the button will be hidden"
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2  ps-0  cursor-pointer"
              onClick={() => handleSelectOther("nurture_export_csv")}
            >
              <div className="d-flex align-items-center">
                <Checkbox checked={inputs.nurture_export_csv} />
                Show export CSV button in nurture tab ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    "In this dropdown menu, you can manage the 'Export CSV' button in the 'Nurture Members' tab. If it is set to 'Yes', the delegate will see the 'Export CSV' button at the top right corner. If it is set to 'No', the button will be hidden."
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() => handleSelectOther("view_profile")}
            >
              <div className="d-flex align-items-center">
                <Checkbox checked={inputs.view_profile} />
                Show member profile option in all 3 member's tab?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p class="pt-2">From this dropdown menu, you can manage whether a delegate can access a member's profile in the "member," "nurture," or "all members" tab.</p>
<ul>
<li>If set to "yes," when clicking on a member's name, the member's profile will open, and the option to view the profile will also appear in the three-dot menu.</li>
<li>If set to "no," the option to view the profile will not be available in the three-dot menu, and clicking on the member's name will not open the profile.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() => handleSelectOther("is_chat_allowed")}
            >
              <div className="d-flex align-items-center">
                <Checkbox checked={inputs.is_chat_allowed} />
                Chat allowed?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p>You can manage through this drop-down whether to give a delegate the option to initiate chats or not.</p>
<ul>
<li>If you select "Yes" from the drop-down menu, the delegate will be able to start a chat with their members.</li>
<li>If you select "No" from the drop-down menu, the delegate will not be able to initiate chats.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() => handleSelectOther("feed_pin_unpin_option")}
            >
              <div className="d-flex align-items-center">
                <Checkbox checked={inputs.feed_pin_unpin_option} />
                Show Feed Pin & unpin Option in feeds ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p class="pt-2">From this dropdown menu, you can manage the pin and unpin options for a delegate on the Cosmos, Source, and All Source Feeds.</p>
<ul>
<li>If "Yes" is selected: The delegate will be able to pin their own posts in the Cosmos and also pin or unpin posts by other members in the Source or All Source feeds.</li>
<li>If "No" is selected: The delegate will not have the option to pin or unpin posts anywhere.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() =>
                handleSelectOther(
                  "edit_delete_option_in_source_all_source_feeds"
                )
              }
            >
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={inputs.edit_delete_option_in_source_all_source_feeds}
                />
                Show options to edit and delete posts in the source and All
                Source feed. ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p class="pt-2">This dropdown menu allows you to control whether a delegate can edit or delete posts on another member's feed in both the source feed and all source feeds.</p>
<ul>
<li>If you select "Yes," the delegate will be able to edit and delete posts created by any member in the source feed and all source feeds.</li>
<li>If you select "No," the delegate will not have the option to edit or delete posts in these feeds.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() =>
                handleSelectOther(
                  "event_info_in_source_all_source_scadule_feeds"
                )
              }
            >
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={inputs.event_info_in_source_all_source_scadule_feeds}
                />
                show event info in source,all source & schedule feeds ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p classname="pt-2">This dropdown menu allows you to control whether delegates can create posts in multiple levels simultaneously for Source, All Source and Scheduled Feeds. </p>
<ul>
<li>If you select "Yes" from the dropdown, delegates will have the option to choose more than one level when creating a post. The post will then be created separately for each selected level.</li>
<li>If you select "No," delegates will only be able to create posts on one level at a time.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() =>
                handleSelectOther(
                  "multiple_levels_in_source_all_source_scadule_feeds"
                )
              }
            >
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={
                    inputs.multiple_levels_in_source_all_source_scadule_feeds
                  }
                />
                Choose Multiple levels when create post in source,all source &
                scheduled Feeds ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p classname="pt-2">This dropdown menu allows you to control whether delegates can create posts in multiple levels simultaneously for Source, All Source and Scheduled Feeds. </p>
<ul>
<li>If you select "Yes" from the dropdown, delegates will have the option to choose more than one level when creating a post. The post will then be created separately for each selected level.</li>
<li>If you select "No," delegates will only be able to create posts on one level at a time.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() => handleSelectOther("Show_nurture_in_filter")}
            >
              <div className="d-flex align-items-center">
                <Checkbox checked={inputs.Show_nurture_in_filter} />
                Show choose nurture field In members & all members filter tab ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p  class="pt-2">You can manage this using a dropdown menu to decide if the "nurture" field should be available for filtering and shown as a column in the listing of the members or all members tab.</p>
<ul>
<li>If "yes" is selected, the "nurture" option will appear in the filter and as a column in the listing.</li>
<li>If "no" is selected, it will be hidden in both the filter and the listing.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() => handleSelectOther("cosmos_feeds_filters")}
            >
              <div className="d-flex align-items-center">
                <Checkbox checked={inputs.cosmos_feeds_filters} />
                Show levels Filters in Cosmos feeds ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p classname="pt-2">This dropdown menu allows you to specify if you want the delegate to have access to the filters available in the cosmos field.</p>
<p>If you select "yes", another dropdown will appear where you can choose the type of options you want to include in the filter, allowing the delegate to filter posts effectively.</p>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          {inputs?.cosmos_feeds_filters && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
                <div
                  className="card p-2 ps-0 cursor-pointer"
                  onClick={() =>
                    handleSelectOther("choose_level_in_cosmos_feeds")
                  }
                >
                  <div className="d-flex align-items-center">
                    <Checkbox checked={inputs.choose_level_in_cosmos_feeds} />
                    Show Choose Level field when create post In Cosmos Feeds ?
                  </div>
                  <span className="icons-position">
                    <Tooltip
                      title={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `<p class="pt-2">This dropdown menu allows you to manage which types of delegates can create feeds in the Cosmos. You have the option to choose whether or not to allow delegates to select a type when creating a feed.</p>
<ul>
<li>If you choose "yes", then all the types available in the dropdown below will be shown, and the delegate can add feeds in any of those types.&nbsp;</li>
<li>If you choose "no", then the delegate will only be able to add feeds in their specified type.</li>
</ul>`,
                          }}
                        />
                      }
                    >
                      <img
                        id="dont-show"
                        src={infoImages}
                        className="info-image-spacific"
                      />
                    </Tooltip>
                  </span>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-11 mt-3">
                <FormControl fullWidth required>
                  <InputLabel id="cosmos_feed_filters-label">
                    Select Cosmos Feed Filters ?
                  </InputLabel>
                  <Select
                    labelId="cosmos_feed_filters-label"
                    id="cosmos_feed_filters"
                    multiple
                    value={inputs.cosmos_feed_filters}
                    onChange={handleChange}
                    input={
                      <OutlinedInput label="Select Cosmos Feed Filters ? " />
                    }
                    name="cosmos_feed_filters"
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"consultant"}>Consultant</MenuItem>
                    <MenuItem value={"delegate"}>Delegates</MenuItem>
                    <MenuItem value={"marketing"}>Marketing Team</MenuItem>
                    <MenuItem value={"inner_circle"}>Inner Circle</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 mt-3">
                <span className="">
                  <Tooltip
                    title={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `<p class="p-1">This dropdown menu allows you to manage which types you want to add in the filter in the Cosmos and add while creating a feed. You can choose multiple or just one type from the dropdown menu.</p>`,
                        }}
                      />
                    }
                  >
                    <img
                      id="dont-show"
                      src={infoImages}
                      className="info-image"
                    />
                  </Tooltip>
                </span>
              </div>
              {inputs.cosmos_feed_filters?.length > 0 && (
                <>
                  <div className="col-lg-5 col-md-5 col-sm-11 mt-3">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Default Filter in Cosmos Feed Filters ? *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="default_filter"
                        required
                        value={inputs.default_filter}
                        label="Select Default Filter in Cosmos Feed Filters ? *"
                        onChange={handleChange}
                      >
                        {inputs.cosmos_feed_filters.map((item) => {
                          return (
                            <MenuItem value={item}>
                              {show_proper_words(item)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-1 col-md-1 col-sm-1 mt-3">
                    <span className="">
                      <Tooltip
                        title={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: `<p class="pt-2">This dropdown menu allows you to select the default type of filter to be applied in The Cosmos field.</p>`,
                            }}
                          />
                        }
                      >
                        <img
                          id="dont-show"
                          src={infoImages}
                          className="info-image"
                        />
                      </Tooltip>
                    </span>
                  </div>
                </>
              )}
            </>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() => handleSelectOther("show_all_users_in_lead_centre")}
            >
              <div className="d-flex align-items-center">
                <Checkbox checked={inputs.show_all_users_in_lead_centre} />
                Show All Members In Lead Centre ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p>You can manage via this dropdown whether a delegate member should see all members or only the members they are specifically assigned to as either a delegate or nurture.</p>
<ul>
<li>If "All" is selected in the dropdown The delegate will see all members in the lead center, regardless of whether they are assigned to them as a delegate or nurture.</li>
<li>If "Delegate" or "Nurture" is selected in the dropdown, In the lead center member list, the delegate will only see the members they are specifically assigned to as a delegate or nurture.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() =>
                handleSelectOther("show_delegates_filter_in_lead_centre")
              }
            >
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={inputs.show_delegates_filter_in_lead_centre}
                />
                Show Delegates Filter In Lead Centre ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p>You can manage in the dropdown whether or not to give the option to filter by delegate in the lead center's filter options.</p>
<ul>
<li>If "yes" is selected, the option to choose a delegate will appear in the filter options, allowing you to filter by delegate name.</li>
<li>If "no" is selected, the option to choose a delegate will be hidden from the filter options.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() =>
                handleSelectOther("show_nurture_filter_in_lead_centre")
              }
            >
              <div className="d-flex align-items-center">
                <Checkbox checked={inputs.show_nurture_filter_in_lead_centre} />
                Show Nurtures Filter In Lead Centre ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p>You can manage in the dropdown whether or not to give the option to filter by nurture in the lead center's filter options.</p>
<ul>
<li>If "yes" is selected, the option to choose nurture will appear in the filter options, allowing you to filter by nurture name.</li>
<li>If "no" is selected, the option to choose nurture will be hidden from the filter options.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() =>
                handleSelectOther("show_all_users_in_mention_feed")
              }
            >
              <div className="d-flex align-items-center">
                <Checkbox checked={inputs.show_all_users_in_mention_feed} />
                Show All Members In Mention Feed ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p>You can manage via this dropdown whether a delegate member should see all members or only the members they are specifically assigned to as either a delegate or nurture.</p>
<ul>
<li>If "All" is selected in the dropdown The delegate will see all members in the at the time of mentioning a member in The Source feeds, regardless of whether they are assigned to them as a delegate or nurture.</li>
<li>If "Delegate" or "Nurture" is selected in the dropdown, at the time of mentioning a member in The Source feeds, the delegate will only see the members they are specifically assigned to as a delegate or nurture.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() => handleSelectOther("enable_poll_feed")}
            >
              <div className="d-flex align-items-center">
                <Checkbox checked={inputs.enable_poll_feed} />
                Enable Poll Feed ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p>In this dropdown, you have the ability to control whether or not you want to allow the delegate to create the poll feed.</p>
<ul>
<li>If you select "Yes" from the dropdown, the delegate will be able to create polls in the feeds of The Cosmos, Source Feed, All Source Feed, Portals, and My Portals.</li>
<li>If you select "No", the delegate will not have the option to create polls in any of these feeds.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() => handleSelectOther("is_feed_search_allowed")}
            >
              <div className="d-flex align-items-center">
                <Checkbox checked={inputs.is_feed_search_allowed} />
                Allow Search In Feeds ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p>In the delegate portal that controls whether delegates can search within the 'Source' and 'All Source' feeds will be managed from this drop-down. The delegate should be able to view both the feeds they have created and search for feeds associated with specific members.</p>
<ul>
<li><strong>If 'Yes' is selected</strong> in the dropdown, the delegate will see the search option to search across all feeds.</li>
<li><strong>If 'No' is selected</strong>, the delegate cannot search for it.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() => handleSelectOther("enable_survey_feed")}
            >
              <div className="d-flex align-items-center">
                <Checkbox checked={inputs.enable_survey_feed} />
                Enable Survey In Feeds ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p>In this dropdown, you have the ability to control whether or not you want to allow the delegate to create the survey feed.</p>
<ul>
<li>If you select "Yes" from the dropdown, the delegate will be able to create surveys in the feeds of The Cosmos, Source Feed, All Source Feed, Portals, and My Portals.</li>
<li>If you select "No", the delegate will not have the option to create surveys in any of these feeds.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() => handleSelectOther("allow_to_send_sms")}
            >
              <div className="d-flex align-items-center">
                <Checkbox checked={inputs.allow_to_send_sms} />
                Allow SMS Option ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p>You can manage whether to give this delegate the option of SMS in the member profile using this checkbox.</p>
<ul>
<li>If you want to give the option, please check the box.</li>
<li>If it is not checked, the delegate will not have the SMS option.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-12 mt-4">
            <h2 style={{ color: "rgb(246, 189, 75)" }}>
              Payment Request Settings
            </h2>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() =>
                handleSelectOther("show_option_mark_request_as_paid")
              }
            >
              <div className="d-flex align-items-center">
                <Checkbox checked={inputs.show_option_mark_request_as_paid} />
                Show Option Mark Request As Paid ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p>With this dropdown, you can decide whether to give the delegate the option to mark a payment request as paid or not.</p>
<ul>
<li>If "Yes" is selected, the delegate will see the "mark as paid" option for one-time pending payment requests. Once the delegate marks it as paid, a transaction will be created against that payment request for the admin.</li>
<li>The "mark as paid" option will not be available for recurring payment requests.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() =>
                handleSelectOther("show_option_mark_request_as_cancelled")
              }
            >
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={inputs.show_option_mark_request_as_cancelled}
                />
                Show Option Mark Request As Cancelled ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p>With this dropdown, you can handle whether or not to give the delegate the option to mark a payment request as canceled.</p>
<ul>
<li>If you select "Yes," the delegate will have the option to mark as canceled in cases of both one-time and recurring pending payment requests.</li>
<li>When the delegate marks a request as canceled, the status of the payment request will also change to inactive.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() =>
                handleSelectOther(
                  "allow_manage_access_programs_in_payment_request"
                )
              }
            >
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={
                    inputs.allow_manage_access_programs_in_payment_request
                  }
                />
                Allow Manage Programs Access ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p class="pt-2">You can manage whether to give the delegate access to the 'Manage Programme' option in the payment request using this dropdown.</p>
<ul>
<li>If 'Yes' is selected from the dropdown, the 'Manage Programme' access option will appear in the three-dot menu of the payment request for the delegate.</li>
<li>If 'No' is selected, it will not be shown.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() =>
                handleSelectOther(
                  "allow_agreement_configration_in_payment_request"
                )
              }
            >
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={
                    inputs.allow_agreement_configration_in_payment_request
                  }
                />
                Allow Agreement Configuration ?
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p class="pt-2">You can manage whether to give the delegate access to the 'Agreement Configuration' option in the payment request using this dropdown.</p>
<ul>
<li>If 'Yes' is selected, the 'Agreement Configuration' option will appear in the three-dot menu of the payment request for the delegate.</li>
<li>If 'No' is selected, it will not be shown.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show Members List For Payment Request ?*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                name="show_members_list_for_payment_request"
                value={inputs.show_members_list_for_payment_request}
                label="Show Members List For Payment Request ?*"
                onChange={handleChange}
              >
                <MenuItem value={"all_members"}>All Members</MenuItem>
                <MenuItem value={"nurture_members"}>
                  Nurture and Delegate Members
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p>This dropdown allows you to control which list of existing members appears when creating a payment request in the delegate portal.</p>
<ul>
<li>If you select "Nurtured and Delegate Members" in the dropdown, the list will only show members to whom that delegate is assigned as nurture and delegate.&nbsp;</li>
<li>If you select "All Members", the list will display every member, regardless of whether the delegate is assigned as nurture or delegate to them.</li>
</ul>
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-12 mt-4">
            <h2 style={{ color: "rgb(246, 189, 75)" }}>
              Mission Control Group Settings
            </h2>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() =>
                handleSelectOther("allow_mission_control_group_members_option")
              }
            >
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={inputs.allow_mission_control_group_members_option}
                />
                Show Option to choose All members in groups ?*
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p>With this dropdown, you can handle whether or not to give the delegate the option to mark a payment request as canceled.</p>
<ul>
<li>If you select "Yes," the delegate will have the option to mark as canceled in cases of both one-time and recurring pending payment requests.</li>
<li>When the delegate marks a request as canceled, the status of the payment request will also change to inactive.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2 h-100 ">
            <div
              className="card p-2 ps-0 cursor-pointer"
              onClick={() =>
                handleSelectOther("allow_community_level_in_group")
              }
            >
              <div className="d-flex align-items-center">
                <Checkbox checked={inputs.allow_community_level_in_group} />
                Show Option to choose badge level in groups*
              </div>
              <span className="icons-position">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<ul>
<li>If you Chose Yes then Badge Level drop-down shows while creating group  then group will be shows to these members who have Badge levels. Resources Related to group will shows accordingly.</li>
<li>Otherwise Group will be shown to all members and access based on group resources shows accordingly.</li>
</ul>`,
                      }}
                    />
                  }
                >
                  <img
                    id="dont-show"
                    src={infoImages}
                    className="info-image-spacific"
                  />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-12 mt-4">
            <h2 style={{ color: "rgb(246, 189, 75)" }}>Bookings Settings</h2>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show Members List For Booking ?*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                name="show_members_list_for_booking"
                value={inputs.show_members_list_for_booking}
                label="Show Members List For Booking ?*"
                onChange={handleChange}
              >
                <MenuItem value={"all_members"}>All Members</MenuItem>
                <MenuItem value={"nurture_members"}>Nurture Members</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `   <p>By this dropdown, you can choose whether to give the delegate the option to create groups for all members or just their own members in the delegate portal.</p>
<ul>
<li>If 'Yes' is selected, the delegate will have the option to create a group for all members, regardless of whether they are their own members or not.</li>
<li>If 'No' is selected, the delegate will only be able to create groups for their own members, the ones they are designated as a delegate or nurturer for.</li>
</ul>`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-multiple-name-label3">
                Book Call With Delegate
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label3"
                id="demo-multiple-name3"
                value={inputs.book_call_with_delegate}
                name="book_call_with_delegate"
                onChange={handleChange}
                input={<OutlinedInput label=" Book Call With Delegate " />}
              >
                <MenuItem value={"self"}>Self</MenuItem>
                <MenuItem value={"other"}>Other</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p>You can manage via this dropdown whether a delegate member should see all members or only the members they are specifically assigned to as either a delegate or nurture.</p>
<ul>
<li>If "All" is selected in the dropdown The delegate will see all members in the at the time of mentioning a member in The Source feeds, regardless of whether they are assigned to them as a delegate or nurture.</li>
<li>If "Delegate" or "Nurture" is selected in the dropdown, at the time of mentioning a member in The Source feeds, the delegate will only see the members they are specifically assigned to as a delegate or nurture.</li>
</ul>`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          {inputs.book_call_with_delegate == "other" && (
            <>
              <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-multiple-name-label">
                    Other delegate Team Type ?
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    name="other_delegate_team_type"
                    multiple
                    value={inputs.other_delegate_team_type}
                    onChange={handleChange}
                    input={
                      <OutlinedInput label="  Other delegate Team Type ? " />
                    }
                  >
                    <MenuItem value={"consultant"}>Consultant Users</MenuItem>
                    <MenuItem value={"delegate"}>Delegates Users</MenuItem>
                    <MenuItem value={"marketing"}>Marketing Team</MenuItem>
                    <MenuItem value={"inner_circle"}>Inner Circle</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
                <span className="">
                  <Tooltip
                    title={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `<p class="p-1">This dropdown menu allows you to manage which types you want to add in the filter in the Cosmos and add while creating a feed. You can choose multiple or just one type from the dropdown menu.</p>`,
                        }}
                      />
                    }
                  >
                    <img
                      id="dont-show"
                      src={infoImages}
                      className="info-image"
                    />
                  </Tooltip>
                </span>
              </div>
            </>
          )}
          <>
            <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
              <TextField
                fullWidth
                type="URL"
                label="Portal Default Tab Link"
                name="other_link"
                value={inputs.other_link}
                onChange={handleChange}
                className="legend"
              />
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
              <span className="">
                <Tooltip
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<p>In this field, you will add a link. When the delegate logs in, they will be redirected to this link. However, if the delegate does not have access to the specific tab or section of the link, they will be redirected back to the dashboard.</p>
<p>Ensure that the link you add belongs to the delegate portal. If you add any other external link, the system will automatically redirect them elsewhere.</p>`,
                      }}
                    />
                  }
                >
                  <img id="dont-show" src={infoImages} className="info-image" />
                </Tooltip>
              </span>
            </div>
          </>
          <div className="col-12 mt-4">
            <h2 style={{ color: "rgb(246, 189, 75)" }}>
              Badge Levels Settings
            </h2>
          </div>
          <div className="col-md-5 col-sm-11 mt-4">
            <MUIAutocomplete
              inputLabel="Badge Levels"
              selectedOption={inputs.badge_levels || []}
              setSelectedOption={(v) => handleChangeOthers("badge_levels", v)}
              optionsList={allBadges}
              name="title"
              multiple={true}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
