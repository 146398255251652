import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import {
  adminUserDeleteApi,
  adminUserList,
} from "src/DAL/AdminUserApi/AdminUserApi";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AdminUserList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const getProductCategories = async () => {
    setIsLoading(true);
    const result = await adminUserList();
    if (result.code == 200) {
      const ListData = result.admin_users.map((items) => {
        return {
          ...items,
          table_avatar: {
            src: s3baseUrl + items.image?.thumbnail_1,
            alt: items.name,
          },
          last_login_activity: items.last_login_activity
            ? moment(items.last_login_activity).format("DD-MM-YYYY hh:mm A")
            : "N/A",
          status: items.status,
        };
      });
      setCategoryList(ListData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    navigate(`/admin-user/edit-admin-user/${value?._id}`, {
      state: value,
    });
  };
  const handleChangePassword = (value) => {
    navigate(`/admin-user/change-password/${value?._id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await adminUserDeleteApi(deleteDoc?._id);
    if (result.code === 200) {
      getProductCategories();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleManageStripeReader = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/admin-user/manage-stripe-reader/${value._id}`, {
      state: value,
    });
  };

  const ManageNavItemsAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/admin-user/manage-navitems-access/${value._id}`, {
      state: value,
    });
  };

  const handleNavigate = () => {
    navigate(`/admin-user/add-admin-user`);
  };

  useEffect(() => {
    getProductCategories();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Change Password",
      icon: "ant-design:lock",
      handleClick: handleChangePassword,
    },
    {
      label: "Manage Stripe Reader Access",
      icon: "akar-icons:edit",
      handleClick: handleManageStripeReader,
    },
    {
      label: "Manage Nav Items Access",
      icon: "akar-icons:edit",
      handleClick: ManageNavItemsAccess,
    },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
    },
    { id: "role", label: "Role" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "last_login_activity", label: "Last Login Activity" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-8 col-sm-12">
            <h2>Admin Users</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Admin User
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={categoryList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
