import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import {
  _detail_tab_configration,
  _update_tab_configration,
} from "src/DAL/DynamiteEvents/DynamiteEvents";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function EventTabConfiguration() {
  const navigate = useNavigate();
  const { event_slug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [eventName, setEventName] = useState("");

  const [inputs, setInputs] = useState([
    {
      chat_tab_title: "Chat",
      chat_tab_status: true,
      is_default_tab: true,
      order: 1,
    },
    {
      replays_tab_title: "Replays",
      replays_tab_status: true,
      is_default_tab: false,
      order: 2,
    },
  ]);

  const getEventDetail = async () => {
    setIsLoading(true);
    const result = await _detail_tab_configration(event_slug);
    if (result.code === 200) {
      handleFormat(result.dynamite_event);
      setEventName(result?.dynamite_event?.title);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  function checkOrderNumbers(postData) {
    const orderNumbers = new Set();

    for (const data of postData) {
      if (orderNumbers.has(data.order)) {
        return false;
      }
      orderNumbers.add(data.order);
    }

    return true;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    let postData = [
      {
        chat_tab_title: inputs[0].chat_tab_title,
        chat_tab_status: inputs[0].chat_tab_status,
        is_default_tab: inputs[0].is_default_tab,
        order: +inputs[0].order,
      },

      {
        replays_tab_title: inputs[1].replays_tab_title,
        replays_tab_status: inputs[1].replays_tab_status,
        is_default_tab: inputs[1].is_default_tab,
        order: +inputs[1].order,
      },
    ];
    const isOrderValid = checkOrderNumbers(postData);
    if (!isOrderValid) {
      enqueueSnackbar("Order numbers cannot be the same", {
        variant: "error",
      });
      setIsLoading(false);
      return;
    }
    let isValidOrder = true;

    postData.forEach((file, index) => {
      const currentOrder = file.order;

      if (currentOrder < 1 || currentOrder > 2) {
        isValidOrder = false;
        enqueueSnackbar("Order cannot be less then 1 or greater then 2", {
          variant: "error",
        });
        setIsLoading(false);
        return;
      }
    });
    if (isValidOrder) {
      let data = {
        tab_configration: postData,
      };
      const result = await _update_tab_configration(data, event_slug);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        handleNavigate();
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event, index) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((prevInputs) => {
      const updatedInputs = [...prevInputs];
      const inputToUpdate = updatedInputs[index];
      inputToUpdate[name] = value;
      return updatedInputs;
    });
  };

  const handleChangeOrder = (event, index) => {
    const { name, value } = event.target;

    setInputs((prevInputs) => {
      const updatedInputs = [...prevInputs];

      if (name === "order" && (value === "1" || value === "2")) {
        const otherIndex = index === 0 ? 1 : 0;
        updatedInputs[index]["order"] = value;
        updatedInputs[otherIndex]["order"] = value === "1" ? "2" : "1";
      } else {
        updatedInputs[index][name] = value;
      }

      return updatedInputs;
    });
  };
  const handleChangeDefaultTab = (event, index) => {
    const { name, value } = event.target;

    if (name === "is_default_tab") {
      setInputs((prevInputs) =>
        prevInputs.map((input, i) => ({
          ...input,
          [name]: i === index ? value : !value,
        }))
      );
    }
  };

  const handleFormat = (data) => {
    if (data?.tab_configration.length > 0) {
      console.log(state, "statestatestate");
      setInputs(data?.tab_configration);
    }
    setIsLoading(false);
  };

  const handleNavigate = () => {
    navigate(`/dynamite-events`);
  };
  let breadCrumbMenu = [
    {
      title: `Dynamite Events ( ${
        state ? state?.title : eventName ? eventName : ""
      } )`,
      navigation: `/dynamite-events`,
      active: false,
    },

    {
      title: "Tabs Configuration",
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    if (event_slug) {
      if (state) {
        handleFormat(state);
      } else {
        getEventDetail();
      }
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <span className="mb-3">
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <h2>Tabs Configuration</h2>
        </div>
      </div>

      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-3 col-md-3 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Chat Tab Title"
            variant="outlined"
            fullWidth
            name="chat_tab_title"
            value={inputs[0].chat_tab_title}
            onChange={(event) => handleChange(event, 0)}
            required
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Chat Tab Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="chat_tab_status"
              value={inputs[0].chat_tab_status}
              label="Chat tab status"
              onChange={(event) => handleChange(event, 0)}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Default Active Tab
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="is_default_tab"
              value={inputs[0].is_default_tab}
              label="Default Active Tab"
              onChange={(event) => handleChangeDefaultTab(event, 0)}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label=" Chat Tab Order"
            variant="outlined"
            fullWidth
            required
            name="order"
            type="number"
            value={inputs[0].order}
            onChange={(event) => handleChangeOrder(event, 0)}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Replay Tab Title"
            variant="outlined"
            fullWidth
            name="replays_tab_title"
            value={inputs[1].replays_tab_title}
            onChange={(event) => handleChange(event, 1)}
            required
          />
        </div>

        <div className="col-lg-3 col-md-3 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Replay Tab Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="replays_tab_status"
              value={inputs[1].replays_tab_status}
              label="Replay tab status"
              onChange={(event) => handleChange(event, 1)}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Default Active Tab
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="is_default_tab"
              value={inputs[1].is_default_tab}
              label=" Default Active Tab"
              onChange={(event) => handleChangeDefaultTab(event, 1)}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Replay Tab Order"
            variant="outlined"
            fullWidth
            required
            name="order"
            type="number"
            value={inputs[1].order}
            onChange={(event) => handleChangeOrder(event, 1)}
          />
        </div>

        <div className="text-end mt-4">
          <button className="small-contained-button">Submit</button>
        </div>
      </form>
    </div>
  );
}
