import React from "react";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import arrowCircleUpFill from "@iconify/icons-eva/arrow-circle-up-fill";
import { Icon } from "@iconify/react";
import { CircularProgress } from "@mui/material";
import { clientPortalUrl, s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { member_login_api } from "src/DAL/member/member";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  DeletePageSubscriberMemberApi,
  delete_multiple_subscribers,
  pageSubscriberListingForPlan,
} from "src/DAL/WebsitePages/WebsitePages";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import GeneralRequestForCsv from "src/pages/MembersList/GeneralRequestForCsv";
import { dd_date_time_format } from "../../../utils/constant";
import ImportCSV from "./ImportCSV";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const subcribers_csv = [
  {
    label: "First Name",
    value: "first_name",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Last Name",
    value: "last_name",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Email", value: "email", is_checked: true, is_disabled: true },
  {
    label: "Contact Number",
    value: "contact_number",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Plan_title", value: "plan_title", is_checked: false },
  { label: "Page_title", value: "page_title", is_checked: false },
  { label: "Referral_user", value: "referral_user", is_checked: false },
  { label: "aggrement_pdf_url", value: "aggrement_pdf_url", is_checked: false },
  {
    label: "Subscription_date",
    value: "subscription_date",
    is_checked: false,
  },
];

export default function PagePlanSubscribersList() {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { state } = useLocation();
  const [openDelete, setOpenDelete] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const classes = useStyles();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [eventUserSampleFile, setEventUserSampleFile] = useState("");
  const [selected, setSelected] = useState([]);
  const [openMultiDelete, setOpenMultiDelete] = useState(false);
  const [showExportcsvFile, setShowExportcsvFile] = useState(false);
  const [showImportcsvFile, setImportExportcsvFile] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});
  const showPopUPcsv = () => {
    setShowExportcsvFile(true);
  };
  const showImportPopUPcsv = () => {
    setImportExportcsvFile(true);
  };

  const getMemberListing = async () => {
    setIsLoading(true);
    let postData = {
      page_id: params.page_id,
      plan_id: params.plan_id,
    };
    const result = await pageSubscriberListingForPlan(
      page,
      rowsPerPage,
      searchText,
      postData
    );
    if (result.code == 200) {
      const members = result.event_subscriber?.map((member) => {
        let full_name = "N/A";
        let created_at = "N/A";
        let PageTitle = "N/A";
        let plan_title = "N/A";
        let mainPlan = "N/A";
        let affiliate_name = "N/A";
        let register_url = "N/A";
        if (member?.register_url) {
          register_url = member.register_url;
        }
        if (
          member?.affiliate_info &&
          member?.affiliate_info.affiliate_user_info
        ) {
          affiliate_name =
            member?.affiliate_info.affiliate_user_info.first_name +
            " " +
            member?.affiliate_info.affiliate_user_info.last_name +
            "(" +
            member?.affiliate_info.affiliate_url_name +
            ")";
        }
        if (member.page_info) {
          PageTitle = member.page_info.sale_page_title;
        }
        if (member?.main_plan) {
          let title = "";
          if (member.main_plan.is_plan_free === true) {
            title = "Free";
          } else if (member.main_plan.payment_access === "recursion") {
            title = "Recurring";
          } else if (member.main_plan.payment_access === "onetime") {
            title = "OneTime";
          }
          mainPlan = member.main_plan?.plan_title + " (" + title + ")";
        }
        if (member.plan_info) {
          plan_title = member.plan_info.plan_title;
        }
        if (member.member_info) {
          full_name =
            member.member_info.first_name + " " + member.member_info.last_name;
        }
        return {
          ...member,
          full_name,
          mainPlan,
          plan_title,
          PageTitle: PageTitle,
          contact_number: "",
          first_name: member.member_info.first_name,
          last_name: member.member_info.last_name,
          affiliate_name,
          register_url,
          email: member?.member_info?.email,
          page: member.page?.sale_page_title,
          created_at: dd_date_time_format(member.createdAt),
          table_avatar: {
            src: s3baseUrl + member?.member_info?.profile_image,
            alt: member?.member_info?.first_name,
          },
        };
      });

      setData(members);
      setEventUserSampleFile(result.import_event_user_sample_file);
      setTotalCount(result?.total_event_subscriber_count);
      setTotalPages(result.total_pages);
      setFilterQuery(result.query);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getMemberListing();
  };

  const LoginAsMember = async (value) => {
    setIsLoading(true);
    const data = {
      email: value.email,
      user_type: "client",
    };
    const result = await member_login_api(data);
    if (result.code === 200) {
      window.open(`${clientPortalUrl}login-by-admin/${result.token}`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeletePageSubscriberMemberApi(deleteDoc._id);
    if (result.code === 200) {
      getMemberListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeMultiDelete = () => {
    setOpenMultiDelete(true);
  };

  const handleMultipleDelete = async () => {
    setOpenMultiDelete(false);
    const subscriber_id = [];
    selected.map((subscribe) => {
      subscriber_id.push(subscribe._id);
    });

    const postDate = {
      subscriber_ids: subscriber_id,
    };

    const result = await delete_multiple_subscribers(postDate);
    if (result.code === 200) {
      setSelected([]);
      getMemberListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  let MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Login As Member",
      icon: "ant-design:lock",
      handleClick: LoginAsMember,
    },
  ];

  useEffect(() => {
    getMemberListing();
  }, [rowsPerPage, page]);

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
    },
    { id: "full_name", label: " Name" },
    { id: "email", label: "Email", alignRight: false },
    { id: "PageTitle", label: "Page Title" },
    { id: "mainPlan", label: "Main Plan" },
    { id: "created_at", label: "Subscribe Date" },
    {
      id: "affiliate_name",
      label: "Referral User ",
    },
    {
      id: "register_url",
      label: "Register Url",
    },

    {
      id: "full_name",
      label: "Agreement PDF",
      renderData: (row) => {
        return (
          <>
            {row.aggrement_pdf_url && (
              <a
                href={s3baseUrl + row.aggrement_pdf_url}
                target="_blank"
                className="anchor-style"
              >
                Preview
              </a>
            )}
          </>
        );
      },
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const websitePages = window.location.pathname.includes("/website-pages");

  let breadCrumbMenu = [
    {
      title: websitePages ? "Pages" : "Template Pages",
      navigation: `${websitePages ? `/website-pages` : `/template-pages`}`,
      active: false,
    },
    {
      title: "Payment Plans",
      navigation: `${
        websitePages
          ? `/website-pages/${params?.page_id}/payment-plans`
          : `/template-pages/${params?.page_id}/payment-plans`
      }`,
      active: false,
    },
    {
      title: state?.data?.plan_title,
      active: true,
    },
  ];

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-12 mb-3">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
          <div className="col-lg-4 col-md-12">
            <h2>Member Subscriber</h2>
          </div>
          <div className="col-lg-8 col-md-12 text-end d-flex justify-content-end">
            <div className="">
              <div>
                <button
                  className="small-contained-button me-2"
                  onClick={showImportPopUPcsv}
                >
                  Import Csv&nbsp;&nbsp;{" "}
                  <Icon icon={arrowCircleUpFill} height={20} />
                </button>

                <a href={s3baseUrl + eventUserSampleFile}>
                  <button className="small-contained-button me-2 mt-1 mb-4">
                    Export Sample File &nbsp;&nbsp;
                    <Icon icon={arrowCircleDownFill} height={20} />
                  </button>
                </a>
                <button
                  className="small-contained-button me-2"
                  onClick={showPopUPcsv}
                >
                  Request For Csv&nbsp;&nbsp;{" "}
                  <Icon icon={arrowCircleDownFill} height={20} />
                </button>
              </div>
            </div>
          </div>

          <div className="col-12 mt-3">
            {selected.length > 0 && (
              <div className="page_del_btn">
                <button
                  className="small-contained-button"
                  onClick={handleAgreeMultiDelete}
                >
                  Delete
                </button>
              </div>
            )}
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={data}
              MENU_OPTIONS={MENU_OPTIONS}
              selected={selected}
              setSelected={setSelected}
              checkbox_selection={true}
              className="card-with-background"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
              pagePagination={true}
            />
          </div>
          <CustomConfirmation
            open={openMultiDelete}
            setOpen={setOpenMultiDelete}
            title={"Are you sure you want to delete these subscribers?"}
            handleAgree={handleMultipleDelete}
          />
          <GeneralPopUpModel
            open={showExportcsvFile}
            setOpen={setShowExportcsvFile}
            title={"Export CSV file request  "}
            componentToPassDown={
              <GeneralRequestForCsv
                options_array={subcribers_csv}
                collection_name={"plan_subscribers"}
                filter_data={filterQuery}
                setShowExportcsvFile={setShowExportcsvFile}
              />
            }
          />
          <GeneralPopUpModel
            open={showImportcsvFile}
            setOpen={setImportExportcsvFile}
            title={"Import CSV file"}
            componentToPassDown={
              <ImportCSV
                setImportExportcsvFile={setImportExportcsvFile}
                getMemberListing={getMemberListing}
              />
            }
          />
        </div>
      </div>
    </>
  );
}
