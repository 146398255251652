import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { delete_lead_status } from "src/DAL/SaleSections/LeadStatus";

import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import EditSchedule from "./EditSchedule";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import {
  delete_mission_schadule_vedio_list,
  get_mission_scadule_vedios_list,
} from "src/DAL/MissionApi/MissionApiNew";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SchedulesVideosList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [referredpop, setReferredpop] = useState(false);
  const [levelsArray, setLevelsArray] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedObject, setSelectedObject] = useState(null);
  const params = useParams();
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [previousInfo, setPreviousInfo] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  const searchFunction = (e) => {
    if (e) {
      e.preventDefault();
    }
    setPage(0);
    setPageCount(1);
    localStorage.setItem("schedule_search", searchText);

    getSaleLeadList(searchText);
  };

  const getSaleLeadList = async (text) => {
    setIsLoading(true);
    let postData = {
      search_text: text ? text : "",
    };
    const result = await get_mission_scadule_vedios_list(
      postData,
      params.id,
      page,
      rowsPerPage
    );
    if (result.code === 200) {
      setPreviousInfo(result?.mission_lavel_info);
      const lead_status = result.video.map((status) => {
        return {
          ...status,
          title: status.title,
          table_avatar: {
            src: s3baseUrl + status.image?.thumbnail_1,
            alt: status.title,
          },
        };
      });
      setUserList(lead_status);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_number_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    navigate(
      `/mission/Challenges/schedule/videos/addOrUpdate/${params.id}/${value._id}`,
      {
        state: value,
      }
    );
  };
  const handlelevel = (value) => {
    navigate(`/mission/Challenges/manage-level-configuration/${value.title}`, {
      state: value,
    });
  };
  const handleschedules = (value) => {
    navigate(`/mission/challenge/schedules/vedios/${value.title}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await delete_mission_schadule_vedio_list(deleteDoc._id);
    if (result.code === 200) {
      getSaleLeadList();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  console.log(params, "paramsparamsparamsparams");
  const handleNavigate = () => {
    navigate(`/mission/Challenges/schedule/videos/addOrUpdate/${params.id}`);
  };
  const handleLessonsQuestions = (value) => {
    let newValue = {
      ...previousInfo,
      ...value,
      vedio_id: params.id,
    };
    navigate(
      `/mission/challenge/schedules/vedios?created_for_id=${value._id}`,
      {
        state: newValue,
      }
    );
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },

    {
      label: "Manage Questions",
      icon: "akar-icons:edit",
      handleClick: handleLessonsQuestions,
    },
  ];

  const handleOPen = (value) => {
    setReferredpop(true);
    setLevelsArray(value);
  };
  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "title",
      label: "Title",
      alignRight: false,
    },
    {
      id: "table_avatar",
      label: "Image",
      alignRight: false,
      type: "thumbnail",
    },
    {
      id: "reward_coins",
      label: "Rewarded Coins  of Video",
      alignRight: false,
    },
    {
      id: "coins_to_unlock",
      label: "Coins To Unlock Video",
      alignRight: false,
    },
    {
      id: "order",
      label: "Order",
      alignRight: false,
    },
    {
      id: "status",
      label: "Status",
      alignRight: false,
      type: "row_status",
    },
  ];
  let breadCrumbMenu = [
    // {
    //   title: `  Mission Levels  (${previousInfo?.title})`,
    //   navigation: `/mission`,
    //   active: false,
    // },
    {
      title: `  Mission   (${previousInfo?.mission_info?.mission_lavel_info?.title})`,
      // navigation: `/mission/challenge/${previousInfo?.mission_info?.mission_lavel_info?._id}`,
      navigation: `/mission`,
      active: false,
    },
    {
      title: ` Missions Schedule (${previousInfo?.mission_schedule_info?.mission_info?.title})`,
      navigation: `/mission/challenge/schedules/${previousInfo?.mission_schedule_info?.mission_info?._id}`,
      active: false,
    },
    {
      title: "videos",
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    let search_text = localStorage.getItem("schedule_search");
    if (search_text) {
      setSearchText(search_text);
      getSaleLeadList(search_text);
    } else {
      setSearchText("");
      getSaleLeadList("");
    }
  }, [page, rowsPerPage]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container">
        <div className="row mb-4">
          <div className="col-12 mb-4">
            {previousInfo && (
              <span>
                <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
              </span>
            )}
          </div>
          <div className="col-lg-8 col-sm-12">
            <h2>videos</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add videos
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
        <GeneralPopUpModel
          open={open}
          setOpen={setOpen}
          title={"Edit Schedule"}
          componentToPassDown={
            <EditSchedule
              verifyTransaction={selectedObject}
              setChangeStatus={setOpen}
            />
          }
        />
      </div>
    </>
  );
}
