import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { TextField, TextareaAutosize } from "@mui/material";
import { update_transaction_status_api } from "src/DAL/Transaction/transactionApi";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";

export default function EditSchedule({ verifyTransaction, setChangeStatus }) {
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState({
    title: "",
    description: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.verification_note.length == 0) {
      enqueueSnackbar("verification note is not allowed to be empty", {
        variant: "error",
      });
    } else {
      let postData = {
        verification_note: inputs.verification_note,
      };

      const result = await update_transaction_status_api(
        verifyTransaction._id,
        postData
      );

      if (result.code === 200) {
        setChangeStatus(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    setInputs(verifyTransaction);
  }, [verifyTransaction]);

  return (
    <div className="container">
      <form>
        <div className="row notification-statement">
          <div className="col-12 mt-3">
            <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
              <TextField
                className="text-muted"
                InputLabelProps={{ style: { fontSize: 14 } }}
                id="outlined-basic"
                label="Title"
                variant="outlined"
                fullWidth
                name="title"
                value={inputs.title}
                // onChange={(e) => handleChangeInputs(e, index)}
                required={true}
              />
            </div>
          </div>{" "}
          <div className="col-12 ">
            <p className="tracking_code_heading text-white">Description *</p>

            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="description"
              editorHeight={310}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
