import { useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import Chart from "react-apexcharts";
import { download_csv_file } from "src/utils/convertHtml";
import { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, CircularProgress, Avatar } from "@mui/material";
import {
  detail_funnel_report,
  ga4_real_time_report,
} from "src/DAL/FunnelPage/FunnelPage";
import { Card } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import FunnelStepEvent from "./FunnelStepEvent";
import { useSnackbar } from "notistack";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import FolderIcon from "@mui/icons-material/Folder";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const RealTimeOverview = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const classes = useStyles();
  const [openFilter, setOpenfilter] = useState(false);
  const [stepLocation, setStepLocation] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [userList, setUserList] = useState({});
  const [audience, setAudience] = useState({});
  const [pageTitle, setPageTitle] = useState([]);
  const [eventCount, setEventCount] = useState([]);

  const getSetpList = async () => {
    setIsLoading(true);
    const result = await ga4_real_time_report();
    if (result.code === 200) {
      setUserList(result?.realTime_activeUsers);
      setAudience(result?.realTime_audience);
      setEventCount(result?.realTime_event_count);
      const filteredData = result?.realTime_pages_views.filter(
        (item) => item.unifiedScreenName !== ""
      );
      setPageTitle(filteredData);
      setData(result?.realTime_activeUsers_graph);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    {
      id: "audienceName",
      label: "Audience",
    },
    {
      id: "activeUsers",
      label: "Active Users",
    },
  ];

  const TABLE_HEAD2 = [
    { id: "number", label: "#", type: "number" },
    {
      id: "unifiedScreenName",
      label: "Page Title",
      tooltip: "The web page title and developer-supplied app screen name.",
    },
    {
      id: "screenPageViews",
      label: "Views",
      tooltip:
        "The number of pageviews on a website and screenviews on an app.",
    },
  ];

  const TABLE_HEAD3 = [
    { id: "number", label: "#", type: "number" },
    {
      id: "eventName",
      label: "Event Name",
      tooltip: "The name of the triggered event.",
    },
    {
      id: "eventCount",
      label: "Event Count",
      tooltip: "The number of times users triggered an event.",
    },
  ];

  function formatMinutesAgo(minutes) {
    if (parseInt(minutes) === 1) {
      return "1 min ago";
    } else {
      return `${parseInt(minutes)} mins ago`;
    }
  }

  let categories =
    data &&
    data.length > 0 &&
    data.map((item) => formatMinutesAgo(item.minutesAgo));
  let activeUsers =
    data && data.length > 0 && data.map((item) => parseInt(item.activeUsers));

  let barChart = {
    options: {
      fill: {
        colors: ["#e4c073"],
      },
      plotOptions: {
        bar: {
          columnWidth: "10%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      chart: {
        id: "bar",
      },
      yaxis: {
        labels: {
          style: {
            colors: "#fff",
          },
        },
      },
      tooltip: {
        theme: "dark",
      },
      xaxis: {
        categories: categories ? categories : [""],
        labels: {
          style: {
            colors: "#fff",
          },
        },
      },
    },
    series: [
      {
        name: "Active Users",
        data: activeUsers ? activeUsers : [""],
      },
    ],
  };

  useEffect(() => {
    getSetpList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mb-3 mt-2">
        <div className="col-12">
          <h2 className="ms-2">Realtime Report</h2>
        </div>
        <div className="col-12 col-md-4 mt-3">
          <div class="profile-card">
            <h3>{userList?.fiveMinutesAgo ? userList?.fiveMinutesAgo : 0}</h3>
            <h6 class="text-muted">Active Users In Last 5 Minutes</h6>
          </div>
        </div>
        <div className="col-12 col-md-4 mt-3">
          <div class="profile-card">
            <h3>
              {userList?.thirtyMinutesAgo ? userList?.thirtyMinutesAgo : 0}
            </h3>
            <h6 class="text-muted">Active Users In Last 30 Minutes</h6>
          </div>
        </div>
        <div className="col-12 col-md-4 mt-3">
          <div class="profile-card">
            <h3>{audience?.activeUsers ? audience?.activeUsers : 0}</h3>
            <h6 class="text-muted">Active Users by Audience</h6>
          </div>
        </div>
        <div className="col-12 mt-3">
          <div className="card p-3 border_r_16">
            <h3 style={{ color: "#e4c073" }}>Active Users per Minute</h3>
            <Chart
              options={barChart.options}
              series={barChart.series}
              type="bar"
              height={280}
            />
          </div>
        </div>
        {/* <div className="col-12 col-md-6 mt-3 d-flex">
          <Card style={{ overflowX: "auto" }}>
            <h3 className="ms-3 mt-3" style={{ color: "#e4c073" }}>
              Active Users by Audience
            </h3>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={audience}
              className="card-with-background"
              is_hide={true}
              rows_per_page_count="10"
            />
          </Card>
        </div> */}
        <div className="col-12 mt-3">
          <Card style={{ overflowX: "auto" }}>
            <h3 className="ms-3 mt-3" style={{ color: "#e4c073" }}>
              Views by Page Title
            </h3>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD2}
              data={pageTitle}
              className="card-with-background"
              is_hide={true}
              rows_per_page_count="5"
              rowArray={[5, 10, 20]}
            />
          </Card>
        </div>
        {/* <div className="col-12 mt-3">
          <Card style={{ overflowX: "auto" }}>
            <h3 className="ms-3 mt-3" style={{ color: "#e4c073" }}>
              Event Count by Event Name
            </h3>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD3}
              data={eventCount}
              className="card-with-background"
              is_hide={true}
              hide_footer_pagination={true}
            />
          </Card>
        </div> */}
      </div>
      {/* <div className="mixed-chart card">
        <Chart
          options={barChart.options}
          series={barChart.series}
          type="bar"
          height={380}
        />
      </div>
      <WhatsappPopUpModel
        open={openFilter}
        setOpen={setOpenfilter}
        show_date_and_income={true}
        title={"Step Events"}
        componentToPassDown={<FunnelStepEvent data={stepLocation} />}
      /> */}
    </div>
  );
};

export default RealTimeOverview;
