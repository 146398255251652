import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  FormHelperText,
  Button,
} from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import {
  UPdate_mission_level_list,
  _get_membership_levels_mission_detail,
  addMission,
  badges_list_api_mission,
} from "src/DAL/MissionApi/MissionApiNew";
import { s3baseUrl } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateMissions() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const [formType, setFormType] = useState("ADD");
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState();
  const [oldImage, setOldImage] = useState("");
  const [badges, setBadges] = useState([]);
  const [membershipLevelList, setMembershipLevelList] = useState([]);
  const [badgeLevelList, setBadgeLevelList] = useState([]);
  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    short_description: "",
    detailed_description: "",
    lavel_info: null,
    achieveable_badge_text: "",
    badges: [],
    order: 0,
    section_border_color_for_app: "#ffffff",
    tag_line: "",
    membership_level_id: "",
    badge_level_id: "",
    achieveable_rewards_text: "",
  });

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!file && formType == "ADD") {
      setIsLoading(false);
      enqueueSnackbar("image is required", { variant: "error" });
      return;
    }
    let find_badge_level = null;
    let find_membership_Level = membershipLevelList.find(
      (level) => level._id == inputs?.membership_level_id
    );

    if (find_membership_Level) {
      find_badge_level = find_membership_Level?.membership_level_badges.find(
        (badge) => badge._id == inputs?.badge_level_id
      );

      if (!find_badge_level) {
        setIsLoading(false);
        enqueueSnackbar("Badge Level is required", { variant: "error" });
        return;
      }
    } else {
      setIsLoading(false);
      enqueueSnackbar("Membership Level is required", { variant: "error" });
      return;
    }

    let membership_level_info = {
      _id: find_membership_Level?._id,
      title: find_membership_Level?.title,

      badge_level_info: {
        _id: find_badge_level?._id,
        title: find_badge_level?.title,
      },
    };

    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("short_description", inputs.short_description);
    formData.append("detailed_description", inputs.detailed_description);
    formData.append("status", inputs.status);
    formData.append("achieveable_badge_text", inputs.achieveable_badge_text);
    formData.append("tag_line", inputs.tag_line);
    formData.append(
      "achieveable_rewards_text",
      inputs?.achieveable_rewards_text
    );
    formData.append(
      "membership_level_info",
      JSON.stringify(membership_level_info)
    );

    formData.append(
      "section_border_color_for_app",
      inputs.section_border_color_for_app
    );
    if (inputs.badges.length > 0) {
      formData.append("badges", JSON.stringify(inputs.badges));
    }

    if (formType === "EDIT") {
      formData.append("order", inputs.order);
    }
    if (file) {
      formData.append("image", inputs.image);
    }

    const result =
      formType === "ADD"
        ? await addMission(formData)
        : await UPdate_mission_level_list(formData, params.id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getRoadMapList = async () => {
    setIsLoading(true);
    const result = await badges_list_api_mission();
    if (result.code == 200) {
      setBadges(result.badges);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeCommunity = (value) => {
    setInputs((values) => ({ ...values, ["badges"]: value }));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleMembershipLevelChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, badge_level_id: "", [name]: value }));
  };

  useEffect(() => {
    getRoadMapList();
    if (params && params.id) {
      if (state) {
        setFormType("EDIT");
        setInputs(state);
        setInputs((values) => ({
          ...values,
          ["badges"]: state?.lavel_info?.badges
            ? state?.lavel_info?.badges
            : [],
        }));
        setOldImage(state?.image?.thumbnail_1);
      }
    }
  }, []);

  const getMissionDetail = async () => {
    setIsLoading(true);
    const result = await _get_membership_levels_mission_detail();
    console.log("result  ___get_membership_levels_mission_detail", result);
    if (result.code === 200) {
      setMembershipLevelList(result?.membership_levels);
      // setSelectedMembershipLevel(result?.mission?.membership_levels || []);
      // setMissionResult(result?.mission);
      // setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    getMissionDetail();
  }, []);

  useEffect(() => {
    let find = membershipLevelList.find(
      (level) => level._id == inputs?.membership_level_id
    );

    if (find) {
      setBadgeLevelList(find?.membership_level_badges ?? []);
    } else {
      setBadgeLevelList([]);
    }
  }, [inputs?.membership_level_id]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-2">{`${
            formType === "ADD" ? "Add" : "Edit"
          } Mission Level`}</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              name="title"
              value={inputs.title}
              onChange={handleChange}
              required
            />
          </div>
          {formType === "EDIT" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                name="order"
                value={inputs.order}
                onChange={handleChange}
                required
              />
            </div>
          )}

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Select Membership Level
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="membership_level_id"
                value={inputs?.membership_level_id}
                label="Select Membership Level "
                onChange={handleMembershipLevelChange}
              >
                {membershipLevelList.map((membership) => {
                  return (
                    <MenuItem key={membership?._id} value={membership?._id}>
                      {membership?.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          {inputs?.membership_level_id && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Select Badge Level
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="badge_level_id"
                  value={inputs?.badge_level_id}
                  label="Select Badge Level "
                  onChange={handleMembershipLevelChange}
                >
                  {badgeLevelList.map((badgeLevel) => {
                    return (
                      <MenuItem key={badgeLevel?._id} value={badgeLevel?._id}>
                        {badgeLevel?.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Rewards on Completion"
              variant="outlined"
              fullWidth
              name="achieveable_rewards_text"
              value={inputs?.achieveable_rewards_text}
              onChange={handleChange}
              required
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status*"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Badges"
              selectedOption={inputs.badges}
              setSelectedOption={handleChangeCommunity}
              optionsList={badges}
              limitTags={3}
              multiple
              name="title"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Achieveable Badge Text"
              variant="outlined"
              fullWidth
              name="achieveable_badge_text"
              value={inputs.achieveable_badge_text}
              onChange={handleChange}
              required={inputs.badges.length > 0 ? true : false}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Section Border Color For App"
              variant="outlined"
              fullWidth
              type="color"
              name="section_border_color_for_app"
              value={inputs.section_border_color_for_app}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Tag Line"
              variant="outlined"
              fullWidth
              name="tag_line"
              value={inputs.tag_line}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Image * </p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {file ? (
                  <img src={file} height="50" />
                ) : oldImage ? (
                  <img src={s3baseUrl + oldImage} height="50" />
                ) : (
                  ""
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />
                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image?.name}</p>
            )}
          </div>
          <div className="col-12 mt-3">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description "
                multiline
                required
                rows={4}
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="col-12 mt-4">
            <h4>Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="detailed_description"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
