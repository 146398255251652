import * as React from "react";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { makeStyles } from "@mui/styles";
import { CircularProgress, TextField, Tooltip } from "@mui/material";
import { useEffect } from "react";
import {
  add_dynamite_lifestyle_settings,
  get_dynamite_lifestyle_settings,
} from "src/DAL/HelpVideos/Categories";
import { useSnackbar } from "notistack";
import { show_proper_words } from "src/utils/constant";
import { infoImages } from "src/assets";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function TeamGeneralSettings({ type, title }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [navItems, setNavitems] = useState([]);
  const [otherTeam, setOtherTeam] = useState([]);
  const [allBadges, setAllBadges] = useState([]);
  const [inputs, setInputs] = useState({
    member_export_csv: false,
    all_member_export_csv: false,
    nurture_export_csv: false,
    view_profile: false,
    Show_nurture_in_filter: false,
    allow_to_send_sms: false,
    cosmos_feeds_filters: false,
    feed_pin_unpin_option: false,
    edit_delete_option_in_source_all_source_feeds: false,
    choose_level_in_cosmos_feeds: false,
    multiple_levels_in_source_all_source_scadule_feeds: false,
    event_info_in_source_all_source_scadule_feeds: false,
    show_all_users_in_lead_centre: false,
    show_all_users_in_mention_feed: false,
    // enable_live_meeting_feed: false,
    // enable_live_stream_feed: false,
    show_delegates_filter_in_lead_centre: false,
    show_nurture_filter_in_lead_centre: false,
    default_filter: "",
    is_chat_allowed: false,
    show_option_mark_request_as_paid: false,
    show_option_mark_request_as_cancelled: false,
    show_members_list_for_booking: "nurture_members",
    book_call_with_delegate: "self",
    allow_mission_control_group_members_option: false,
    allow_community_level_in_group: false,
    enable_poll_feed: false,
    other_link: "",
    is_feed_search_allowed: false,
    enable_survey_feed: false,
    allow_manage_access_programs_in_payment_request: false,
    allow_agreement_configration_in_payment_request: false,
    show_members_list_for_payment_request: "nurture_members",
    badge_levels: [],
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    inputs.cosmos_feed_filters = navItems;
    inputs.other_delegate_team_type = otherTeam;
    let data = {
      access_settings_type: type,
      access_object: inputs,
    };
    const result = await add_dynamite_lifestyle_settings(data);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getContentSettings = async (e) => {
    const result = await get_dynamite_lifestyle_settings(type);
    if (result.code === 200) {
      if (
        result.access_object &&
        Object.keys(result.access_object).length > 0
      ) {
        setInputs(result.access_object);
        setNavitems(
          result?.access_object?.cosmos_feed_filters
            ? result?.access_object?.cosmos_feed_filters
            : []
        );
        setOtherTeam(
          result?.access_object?.other_delegate_team_type
            ? result?.access_object?.other_delegate_team_type
            : []
        );
        setAllBadges(result.all_badges);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(name, value, "asdasds");
    setInputs((values) => ({ ...values, [name]: value }));
    if (name == "cosmos_feeds_filters") {
      setInputs((values) => ({
        ...values,
        ["default_filter"]: "",
        ["choose_level_in_cosmos_feeds"]: false,
      }));
      setNavitems([]);
    }
    if (name == "book_call_with_delegate" && value == "self") {
      setOtherTeam([]);
    }
  };

  const handleChangeNavItem = (event) => {
    const {
      target: { value },
    } = event;
    setNavitems(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeTeamType = (event) => {
    const {
      target: { value },
    } = event;
    setOtherTeam(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    getContentSettings();
  }, [type]);

  useEffect(() => {
    let findValue = navItems.find((item) => {
      return item == inputs.default_filter;
    });
    if (!findValue) {
      setInputs((values) => ({
        ...values,
        ["default_filter"]: "",
      }));
    }
  }, [navItems]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex"></div>
      <div className="row">
        <div className="col-12">
          <h2 style={{ color: "rgb(246, 189, 75)" }}>{title}</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="form-settings">
        <div className="row">
          <div className="col-12 mt-4">
            <h3 style={{ color: "rgb(246, 189, 75)" }}>Members Settings</h3>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show export CSV button in members tab ?*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="member_export_csv"
                required
                value={inputs.member_export_csv}
                label="  Show export CSV button in members tab ?*"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <div className="">
              <Tooltip
                title={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `
<p>In this dropdown menu, you can manage the 'Export CSV' button in the 'Members' tab. If it is set to 'Yes', the delegate will see the 'Export CSV' button at the top right corner. If it is set to 'No', the button will be hidden</p>
<p><img src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/upload/5c32c6ad-31f8-4bf3-8d66-9afa01b9e76a.png" alt="" width="600" height="305" /></p>
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show export CSV button in all members tab ?*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="all_member_export_csv"
                required
                value={inputs.all_member_export_csv}
                label=" Show export CSV button in all members tab ?*"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `
<p>In this dropdown menu, you can manage the 'Export CSV' button in the 'All Members' tab. If it is set to 'Yes', the delegate will see the 'Export CSV' button at the top right corner. If it is set to 'No', the button will be hidden</p>
<p> <img
                  src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/20cbc38f-f2d7-414d-b5b1-49df77eac788.png"
                  alt="Gallery Image"
                  style="display: block;"
                /></p>
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show export CSV button in nurture tab ?*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="nurture_export_csv"
                required
                value={inputs.nurture_export_csv}
                label=" Show export CSV button in nurture tab*"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `
<p>In this dropdown menu, you can manage the 'Export CSV' button in the 'Nurture Members' tab. If it is set to 'Yes', the delegate will see the 'Export CSV' button at the top right corner. If it is set to 'No', the button will be hidden.</p>
<p> <img  src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/dabbe0e9-2cba-4fc9-b022-0614cd34c9f2.png" alt="Gallery Image" style="display: block;"></p>
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show member profile option in all 3 member's tab*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="view_profile"
                required
                value={inputs.view_profile}
                label="Show member profile option in all 3 member's tab*"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p class="pt-2">From this dropdown menu, you can manage whether a delegate can access a member's profile in the "member," "nurture," or "all members" tab.</p>
<ul>
<li>If set to "yes," when clicking on a member's name, the member's profile will open, and the option to view the profile will also appear in the three-dot menu.</li>
<li>If set to "no," the option to view the profile will not be available in the three-dot menu, and clicking on the member's name will not open the profile.</li>
</ul> 
<img  src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/3c820f9c-1604-48d3-8807-0811e9ff3caf.png" alt="Gallery Image" style="display: block;">

`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>{" "}
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Chat Allowed?*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_chat_allowed"
                required
                value={inputs.is_chat_allowed}
                label="Chat Allowed?*"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p>You can manage through this drop-down whether to give a delegate the option to initiate chats or not.</p>
<ul>
<li>If you select "Yes" from the drop-down menu, the delegate will be able to start a chat with their members.</li>
<li>If you select "No" from the drop-down menu, the delegate will not be able to initiate chats.</li>
</ul>

`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show choose nurture field In members & all members filter tab ?*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="Show_nurture_in_filter"
                required
                value={inputs.Show_nurture_in_filter}
                label="Show choose nurture field In members & all members
                filter tab ?*"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p  class="pt-2">You can manage this using a dropdown menu to decide if the "nurture" field should be available for filtering and shown as a column in the listing of the members or all members tab.</p>
<ul>
<li>If "yes" is selected, the "nurture" option will appear in the filter and as a column in the listing.</li>
<li>If "no" is selected, it will be hidden in both the filter and the listing.</li>
</ul>

<img  src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/2eded084-5c24-4b67-bcdd-2b6181ced41d.png" alt="Gallery Image" style="display: block;">
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Allow SMS Option?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="allow_to_send_sms"
                required
                value={inputs.allow_to_send_sms}
                label="Allow SMS Option ?"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p>From this dropdown, we can decide whether to give the delegate the SMS option in the member profile.</p>
<ul>
<li>If 'Yes' is chosen, the SMS option will be displayed in the member's profile on the delegate portal.</li>
<li>If 'No' is chosen, the option will not be shown to the delegate.</li>
</ul>
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-12 mt-4">
            <h3 style={{ color: "rgb(246, 189, 75)" }}>Feeds Settings</h3>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show Feed Pin & unpin Option in feeds ?*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="feed_pin_unpin_option"
                required
                value={inputs.feed_pin_unpin_option}
                label="   Show Feed Pin & unpin Option in feeds ?*"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p class="pt-2">From this dropdown menu, you can manage the pin and unpin options for a delegate on the Cosmos, Source, and All Source Feeds.</p>
<ul>
<li>If "Yes" is selected: The delegate will be able to pin their own posts in the Cosmos and also pin or unpin posts by other members in the Source or All Source feeds.</li>
<li>If "No" is selected: The delegate will not have the option to pin or unpin posts anywhere.</li>
</ul>
<img  src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/d97687e7-781b-448c-8f83-4dfb28299cb6.png" alt="Gallery Image" style="display: block;">
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show options to edit and delete posts in the source and All
                Source feed. ? *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="edit_delete_option_in_source_all_source_feeds"
                required
                value={inputs.edit_delete_option_in_source_all_source_feeds}
                label="   Show options to edit and delete posts in the source and All Source feed. ? *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p class="pt-2">This dropdown menu allows you to control whether a delegate can edit or delete posts on another member's feed in both the source feed and all source feeds.</p>
<ul>
<li>If you select "Yes," the delegate will be able to edit and delete posts created by any member in the source feed and all source feeds.</li>
<li>If you select "No," the delegate will not have the option to edit or delete posts in these feeds.</li>
</ul>
<img  src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/50789e70-96d3-4b77-b82a-ff94a2370997.png" alt="Gallery Image" style="display: block;">

`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Choose Multiple levels when create post in source,all source &
                scheduled Feeds ? *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="multiple_levels_in_source_all_source_scadule_feeds"
                required
                value={
                  inputs.multiple_levels_in_source_all_source_scadule_feeds
                }
                label="  Show Choose Multiple levels field  when create post in source& all
                source Feeds ? *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>{" "}
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p classname="pt-2">This dropdown menu allows you to control whether delegates can create posts in multiple levels simultaneously for Source, All Source and Scheduled Feeds. </p>
<ul>
<li>If you select "Yes" from the dropdown, delegates will have the option to choose more than one level when creating a post. The post will then be created separately for each selected level.</li>
<li>If you select "No," delegates will only be able to create posts on one level at a time.</li>
</ul>
<img  src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/f93841a6-05e5-4cc6-b8b2-097dc360ec51.png" alt="Gallery Image" style="display: block;">

`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                show event info in source,all source & schedule feeds ? *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="event_info_in_source_all_source_scadule_feeds"
                required
                value={inputs.event_info_in_source_all_source_scadule_feeds}
                label="show event info in source,all source & schedule feeds ? *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p classname="pt-2">This dropdown allows you to decide if the delegate can add event information when creating or scheduling posts across all sources and scheduled feeds.</p>
<ul>
<li>If you select "Yes," the delegate can add event information when they create or schedule a post.</li>
<li>If you select "No," the delegate will not have this option.</li>
</ul>

<img src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/450ab485-b6d2-4df7-a769-d4275f01599d.png" alt="Gallery Image" style="display: block;">
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show levels Filters in Cosmos feeds ? *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="cosmos_feeds_filters"
                required
                value={inputs.cosmos_feeds_filters}
                label="Show levels Filters in Cosmos feeds ? *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p classname="pt-2">This dropdown menu allows you to specify if you want the delegate to have access to the filters available in the cosmos field.</p>
<p>If you select "yes", another dropdown will appear where you can choose the type of options you want to include in the filter, allowing the delegate to filter posts effectively.</p>

<img class="rounded" src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/fc907409-339d-4756-96b1-c15657ff3110.png" alt="Gallery Image" style="display: block;">
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          {inputs.cosmos_feeds_filters && (
            <>
              <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Show Choose Level field when create post In Cosmos Feeds ?*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="choose_level_in_cosmos_feeds"
                    required
                    value={inputs.choose_level_in_cosmos_feeds}
                    label=" Show Choose Level field when create post In
                Cosmos Feeds ?*"
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
                <span className="">
                  <Tooltip
                    title={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `<p class="pt-2">This dropdown menu allows you to manage which types of delegates can create feeds in the Cosmos. You have the option to choose whether or not to allow delegates to select a type when creating a feed.</p>
<ul>
<li>If you choose "yes", then all the types available in the dropdown below will be shown, and the delegate can add feeds in any of those types.&nbsp;</li>
<li>If you choose "no", then the delegate will only be able to add feeds in their specified type.</li>
</ul>

<img src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/30ce3229-ff1b-4227-a592-1f9a016b51d7.png" alt="Gallery Image" style="display: block;">
`,
                        }}
                      />
                    }
                  >
                    <img
                      id="dont-show"
                      src={infoImages}
                      className="info-image"
                    />
                  </Tooltip>
                </span>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-multiple-name-label">
                    Select Cosmos Feed Filters ?
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={navItems}
                    onChange={handleChangeNavItem}
                    input={
                      <OutlinedInput label="Select Cosmos Feed Filters ? " />
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"consultant"}>Consultant</MenuItem>
                    <MenuItem value={"delegate"}>Delegates</MenuItem>
                    <MenuItem value={"marketing"}>Marketing Team</MenuItem>
                    <MenuItem value={"inner_circle"}>Inner Circle</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
                <span className="">
                  <Tooltip
                    title={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `<p class="p-1">This dropdown menu allows you to manage which types you want to add in the filter in the Cosmos and add while creating a feed. You can choose multiple or just one type from the dropdown menu.</p>
                          <img src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/cf698223-d412-4bb6-b91d-546d01a55da7.png" alt="Gallery Image" style="display: block;">
                          `,
                        }}
                      />
                    }
                  >
                    <img
                      id="dont-show"
                      src={infoImages}
                      className="info-image"
                    />
                  </Tooltip>
                </span>
              </div>

              {navItems.length > 0 && (
                <>
                  <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Default Filter in Cosmos Feed Filters ? *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="default_filter"
                        required
                        value={inputs.default_filter}
                        label="Select Default Filter in Cosmos Feed Filters ? *"
                        onChange={handleChange}
                      >
                        {navItems.map((item) => {
                          return (
                            <MenuItem value={item}>
                              {show_proper_words(item)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
                    <span className="">
                      <Tooltip
                        title={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: `<p class="pt-2">This dropdown menu allows you to select the default type of filter to be applied in The Cosmos field.</p>
                              <img src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/cf698223-d412-4bb6-b91d-546d01a55da7.png" alt="Gallery Image" style="display: block;">
                              `,
                            }}
                          />
                        }
                      >
                        <img
                          id="dont-show"
                          src={infoImages}
                          className="info-image"
                        />
                      </Tooltip>
                    </span>
                  </div>
                </>
              )}
            </>
          )}
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-multiple-name-label3">
                Show Members In Mention Feed
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label3"
                id="demo-multiple-name3"
                value={inputs.show_all_users_in_mention_feed}
                name="show_all_users_in_mention_feed"
                onChange={handleChange}
                input={<OutlinedInput label="Show Members In Mention Feed " />}
                MenuProps={MenuProps}
              >
                <MenuItem value={true}>Show All Members</MenuItem>
                <MenuItem value={false}>
                  Show Nurture & Delegate Members
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p>You can manage via this dropdown whether a delegate member should see all members or only the members they are specifically assigned to as either a delegate or nurture.</p>
<ul>
<li>If "All" is selected in the dropdown The delegate will see all members in the at the time of mentioning a member in The Source feeds, regardless of whether they are assigned to them as a delegate or nurture.</li>
<li>If "Delegate" or "Nurture" is selected in the dropdown, at the time of mentioning a member in The Source feeds, the delegate will only see the members they are specifically assigned to as a delegate or nurture.</li>
</ul>`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-multiple-name-label3">
                Enable Poll Feed
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label3"
                id="demo-multiple-name3"
                value={inputs.enable_poll_feed}
                name="enable_poll_feed"
                onChange={handleChange}
                input={<OutlinedInput label="Enable Poll Feed " />}
                MenuProps={MenuProps}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p>In this dropdown, you have the ability to control whether or not you want to allow the delegate to create the poll feed.</p>
<ul>
<li>If you select "Yes" from the dropdown, the delegate will be able to create polls in the feeds of The Cosmos, Source Feed, All Source Feed, Portals, and My Portals.</li>
<li>If you select "No", the delegate will not have the option to create polls in any of these feeds.</li>
</ul>`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-multiple-name-label3">
                Enable Survey In Feeds
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label3"
                id="demo-multiple-name3"
                value={inputs.enable_survey_feed}
                name="enable_survey_feed"
                onChange={handleChange}
                input={<OutlinedInput label="Enable Survey In Feeds" />}
                MenuProps={MenuProps}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p class="pt-2">In this dropdown, you have the ability to control whether or not you want to allow the delegate to create the survey feed.</p>
<ul>
<li>If you select "Yes" from the dropdown, the delegate will be able to create surveys in the feeds of The Cosmos, Source Feed, All Source Feed, Portals, and My Portals.</li>
<li>If you select "No", the delegate will not have the option to create surveys in any of these feeds.</li>
</ul>`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-name-label3">
                Allow Search In Feeds
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label3"
                id="demo-multiple-name3"
                value={inputs.is_feed_search_allowed}
                name="is_feed_search_allowed"
                onChange={handleChange}
                input={<OutlinedInput label="Allow Search In Feeds" />}
                MenuProps={MenuProps}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p>In the delegate portal that controls whether delegates can search within the 'Source' and 'All Source' feeds will be managed from this drop-down. The delegate should be able to view both the feeds they have created and search for feeds associated with specific members.</p>
<ul>
<li><strong>If 'Yes' is selected</strong> in the dropdown, the delegate will see the search option to search across all feeds.</li>
<li><strong>If 'No' is selected</strong>, the delegate cannot search for it.</li>
</ul>`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          {/* <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-multiple-name-label3">
                Enable Live Meeting in Source Feeds
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label3"
                id="demo-multiple-name3"
                value={inputs.enable_live_meeting_feed}
                name="enable_live_meeting_feed"
                onChange={handleChange}
                input={
                  <OutlinedInput label="Enable Live Meeting in Source Feeds" />
                }
                MenuProps={MenuProps}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p>From this dropdown, you can control whether or not the delegate should have the option to start a live meeting in the source feed.</p>
<ul>
<li>If 'Yes' is selected, the delegate will have the option to start a live meeting.</li>
<li>If 'No' is selected, the delegate will not have access to this option.</li>
</ul>`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-multiple-name-label3">
                Enable Live Stream in Source Feeds
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label3"
                id="demo-multiple-name3"
                value={inputs.enable_live_stream_feed}
                name="enable_live_stream_feed"
                onChange={handleChange}
                input={
                  <OutlinedInput label="Enable Live Stream in Source Feeds" />
                }
                MenuProps={MenuProps}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p>From this dropdown, you can control whether or not the delegate should have the option to start a live stream in the source feed.</p>
<ul>
<li>If 'Yes' is selected, the delegate will have the option to start a live stream.</li>
<li>If 'No' is selected, the delegate will not have access to this option.</li>
</ul>`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div> */}
          <div className="col-12 mt-4">
            <h3 style={{ color: "rgb(246, 189, 75)" }}>Lead Centre Settings</h3>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-multiple-name-label3">
                Show Members In Lead Centre
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label3"
                id="demo-multiple-name3"
                value={inputs.show_all_users_in_lead_centre}
                name="show_all_users_in_lead_centre"
                onChange={handleChange}
                input={<OutlinedInput label="Show Members In Lead Centre " />}
                MenuProps={MenuProps}
              >
                <MenuItem value={true}>Show All Leads</MenuItem>
                <MenuItem value={false}>Show Nurture & Delegate Leads</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p>You can manage via this dropdown whether a delegate member should see all members or only the members they are specifically assigned to as either a delegate or nurture.</p>
<ul>
<li>If "All" is selected in the dropdown The delegate will see all members in the lead center, regardless of whether they are assigned to them as a delegate or nurture.</li>
<li>If "Delegate" or "Nurture" is selected in the dropdown, In the lead center member list, the delegate will only see the members they are specifically assigned to as a delegate or nurture.</li>
</ul>

<img  src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/a9539ddb-25d1-42e3-ab90-b7f59cdf8d7d.png" alt="Gallery Image" style="display: block;">
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-multiple-name-label3">
                Show Delegates Filter In Lead Centre
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label3"
                id="demo-multiple-name3"
                value={inputs.show_delegates_filter_in_lead_centre}
                name="show_delegates_filter_in_lead_centre"
                onChange={handleChange}
                input={
                  <OutlinedInput label="Show Delegates Filter In Lead Centre" />
                }
                MenuProps={MenuProps}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p>You can manage in the dropdown whether or not to give the option to filter by delegate in the lead center's filter options.</p>
<ul>
<li>If "yes" is selected, the option to choose a delegate will appear in the filter options, allowing you to filter by delegate name.</li>
<li>If "no" is selected, the option to choose a delegate will be hidden from the filter options.</li>
</ul>
<img  src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/a9539ddb-25d1-42e3-ab90-b7f59cdf8d7d.png" alt="Gallery Image" style="display: block;">
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-multiple-name-label3">
                Show Nurtures Filter In Lead Centre
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label3"
                id="demo-multiple-name3"
                value={inputs.show_nurture_filter_in_lead_centre}
                name="show_nurture_filter_in_lead_centre"
                onChange={handleChange}
                input={
                  <OutlinedInput label="Show Nurtures Filter In Lead Centre" />
                }
                MenuProps={MenuProps}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p>You can manage in the dropdown whether or not to give the option to filter by nurture in the lead center's filter options.</p>
<ul>
<li>If "yes" is selected, the option to choose nurture will appear in the filter options, allowing you to filter by nurture name.</li>
<li>If "no" is selected, the option to choose nurture will be hidden from the filter options.</li>
</ul>

<img src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/6ca66a84-3fd8-403f-9d9a-c302465e794f.png" alt="Gallery Image" style="display: block;">
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-12 mt-4">
            <h3 style={{ color: "rgb(246, 189, 75)" }}>
              Payment Request Settings
            </h3>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show Option Mark Request As Paid ? *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="show_option_mark_request_as_paid"
                required
                value={inputs.show_option_mark_request_as_paid}
                label="Show Option Mark Request As Paid ? *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p>With this dropdown, you can decide whether to give the delegate the option to mark a payment request as paid or not.</p>
<ul>
<li>If "Yes" is selected, the delegate will see the "mark as paid" option for one-time pending payment requests. Once the delegate marks it as paid, a transaction will be created against that payment request for the admin.</li>
<li>The "mark as paid" option will not be available for recurring payment requests.</li>
</ul>
<img src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/c0c0a34e-8ade-4bfc-83a8-46ecfccb6c7e.png" alt="Gallery Image" style="display: block;">
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show Option Mark Request As Cancelled ? *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="show_option_mark_request_as_cancelled"
                required
                value={inputs.show_option_mark_request_as_cancelled}
                label="Show Option Mark Request As Cancelled ? *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p>With this dropdown, you can handle whether or not to give the delegate the option to mark a payment request as canceled.</p>
<ul>
<li>If you select "Yes," the delegate will have the option to mark as canceled in cases of both one-time and recurring pending payment requests.</li>
<li>When the delegate marks a request as canceled, the status of the payment request will also change to inactive.</li>
</ul>
<img  src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/4d7e8d92-1242-43b5-9388-32c9b24c4d52.png" alt="Gallery Image" style="display: block;">

`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Allow Manage Programs Access ?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                name="allow_manage_access_programs_in_payment_request"
                value={inputs.allow_manage_access_programs_in_payment_request}
                label="Allow Manage Programs Access ?"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p class="pt-2">You can manage whether to give the delegate access to the 'Manage Programme' option in the payment request using this dropdown.</p>
<ul>
<li>If 'Yes' is selected from the dropdown, the 'Manage Programme' access option will appear in the three-dot menu of the payment request for the delegate.</li>
<li>If 'No' is selected, it will not be shown.</li>
</ul>`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Allow Agreement Configuration ?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                name="allow_agreement_configration_in_payment_request"
                value={inputs.allow_agreement_configration_in_payment_request}
                label="Allow Agreement Configuration ?"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p class="pt-2">You can manage whether to give the delegate access to the 'Agreement Configuration' option in the payment request using this dropdown.</p>
<ul>
<li>If 'Yes' is selected, the 'Agreement Configuration' option will appear in the three-dot menu of the payment request for the delegate.</li>
<li>If 'No' is selected, it will not be shown.</li>
</ul>`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show Members List For Payment Request ?*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                name="show_members_list_for_payment_request"
                value={inputs.show_members_list_for_payment_request}
                label="Show Members List For Payment Request ?*"
                onChange={handleChange}
              >
                <MenuItem value={"all_members"}>All Members</MenuItem>
                <MenuItem value={"nurture_members"}>
                  Nurture and Delegate Members
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p>This dropdown allows you to control which list of existing members appears when creating a payment request in the delegate portal.</p>
<ul>
<li>If you select "Nurtured and Delegate Members" in the dropdown, the list will only show members to whom that delegate is assigned as nurture and delegate.&nbsp;</li>
<li>If you select "All Members", the list will display every member, regardless of whether the delegate is assigned as nurture or delegate to them.</li>
</ul>

`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-12 mt-4">
            <h3 style={{ color: "rgb(246, 189, 75)" }}>Bookings Settings</h3>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show Members List For Booking ?*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                name="show_members_list_for_booking"
                value={inputs.show_members_list_for_booking}
                label="Show Members List For Booking ?*"
                onChange={handleChange}
              >
                <MenuItem value={"all_members"}>All Members</MenuItem>
                <MenuItem value={"nurture_members"}>Nurture Members</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p>You can manage from this drop-down menu, at the time of adding a booking which members should be shown to the delegate.</p>
<ul>
<li>If 'All Members' is selected in the drop-down, the delegate can book a call with any member.</li>
<li>If 'Nurture Members' is selected, only those members whom the delegate is nurturing will be shown.</li>
</ul>

                      <img class="rounded" src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/67ff6064-acba-41d7-be0c-2c1dc6ab54c8.png" alt="Gallery Image" style="display: block;"></img>

`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-multiple-name-label3">
                Book Call With Delegate
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label3"
                id="demo-multiple-name3"
                value={inputs.book_call_with_delegate}
                name="book_call_with_delegate"
                onChange={handleChange}
                input={<OutlinedInput label=" Book Call With Delegate " />}
                MenuProps={MenuProps}
              >
                <MenuItem value={"self"}>Self</MenuItem>
                <MenuItem value={"other"}>Other</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<p>You can manage this using a drop-down menu where a delegate can choose who the call will be booked with at the time of making a booking.</p>
<ul>
<li>If 'self' is selected from the options, the call will be booked with the delegates themselves.</li>
<li>If 'other' is selected, the delegate can book the call with any other member, allowing them to arrange a call for any member with any other delegate</li>
</ul>
<img src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/dynamite_gallery/881998c5-09a9-4840-bc10-86842e1fc720.png" alt="Gallery Image" style="display: block;">
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          {inputs.book_call_with_delegate == "other" && (
            <>
              <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-multiple-name-label">
                    Other delegate Team Type ?
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={otherTeam}
                    onChange={handleChangeTeamType}
                    input={
                      <OutlinedInput label="  Other delegate Team Type ? " />
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={"consultant"}>Consultant Users</MenuItem>
                    <MenuItem value={"delegate"}>Delegates Users</MenuItem>
                    <MenuItem value={"marketing"}>Marketing Team</MenuItem>
                    <MenuItem value={"inner_circle"}>Inner Circle</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
                <span className="">
                  <Tooltip
                    title={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `<p>You can manage the dropdown so that the delegate sees which delegate lists are displayed when adding a booking.</p>
<ul>
<li>The type chosen in the dropdown will determine which delegate list appears, and the delegate can book a call with any member from that list.</li>
<li>When booking a call, the booking pages and slots shown will correspond to the delegate chosen in the dropdown.</li>
</ul>`,
                        }}
                      />
                    }
                  >
                    <img
                      id="dont-show"
                      src={infoImages}
                      className="info-image"
                    />
                  </Tooltip>
                </span>
              </div>
            </>
          )}
          <div className="col-12 mt-4">
            <h3 style={{ color: "rgb(246, 189, 75)" }}>
              Mission Control Group Settings
            </h3>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show Option to choose All members in groups ?*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                name="allow_mission_control_group_members_option"
                value={inputs.allow_mission_control_group_members_option}
                label="Show Option to choose All members in groups ?*"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `   <p>By this dropdown, you can choose whether to give the delegate the option to create groups for all members or just their own members in the delegate portal.</p>
<ul>
<li>If 'Yes' is selected, the delegate will have the option to create a group for all members, regardless of whether they are their own members or not.</li>
<li>If 'No' is selected, the delegate will only be able to create groups for their own members, the ones they are designated as a delegate or nurturer for.</li>
</ul>
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show Option to choose badge level in groups*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                name="allow_community_level_in_group"
                value={inputs.allow_community_level_in_group}
                label="Show Option to choose badge level in groups*"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `
<ul>
<li>If you Chose Yes then Badge Level drop-down shows while creating group  then group will be shows to these members who have Badge levels. Resources Related to group will shows accordingly.</li>
<li>Otherwise Group will be shown to all members and access based on group resources shows accordingly.</li>
</ul>
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>{" "}
          <div className="col-lg-5 col-md-5 col-sm-11 mt-4">
            <TextField
              fullWidth
              type="URL"
              label="Portal Default Tab Link"
              name="other_link"
              value={inputs.other_link}
              onChange={handleChange}
              className="legend"
            />
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `
                      <p>In this field, you will add a link. When the delegate logs in, they will be redirected to this link. However, if the delegate does not have access to the specific tab or section of the link, they will be redirected back to the dashboard.</p>
<p>Ensure that the link you add belongs to the delegate portal. If you add any other external link, the system will automatically redirect them elsewhere.</p>
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="col-12 mt-4">
            <h3 style={{ color: "rgb(246, 189, 75)" }}>
              Badge Levels Settings
            </h3>
          </div>
          <div className="col-md-5 col-sm-11 mt-4">
            <MUIAutocomplete
              inputLabel="Badge Levels"
              selectedOption={inputs.badge_levels || []}
              setSelectedOption={(v) => handleChangeOthers("badge_levels", v)}
              optionsList={allBadges}
              name="title"
              multiple={true}
            />
          </div>
          <div className="col-1 mt-4">
            <span className="">
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `
                      <p>In this field, you will add a link. When the delegate logs in, they will be redirected to this link. However, if the delegate does not have access to the specific tab or section of the link, they will be redirected back to the dashboard.</p>
<p>Ensure that the link you add belongs to the delegate portal. If you add any other external link, the system will automatically redirect them elsewhere.</p>
`,
                    }}
                  />
                }
              >
                <img id="dont-show" src={infoImages} className="info-image" />
              </Tooltip>
            </span>
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
