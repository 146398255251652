import { format } from "date-fns";
import React from "react";
import Chart from "react-apexcharts";

const formatDate = (dateStr) => {
  const date = new Date(
    `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(6, 8)}`
  );
  return format(date, "MMM dd");
};

const transformData = (dataList, metric) => {
  const categories = dataList.map((item) => formatDate(item?.date));
  const metricData = dataList.map((item) => {
    switch (metric) {
      case "Users":
        return parseInt(item.totalUsers, 10);
      case "Active Users":
        return parseInt(item.activeUsers, 10);
      case "New Users":
        return parseInt(item.newUsers, 10);
      case "Sessions Per User":
        return parseFloat(item.sessionsPerUser).toFixed(2);
      default:
        return 0;
    }
  });
  return {
    categories,
    series: [
      {
        name: metric,
        data: metricData,
        color: "#e4c073",
      },
    ],
  };
};

const AnalyticsChart = ({ dataList, metric }) => {
  const transformedData = transformData(dataList, metric);

  const options = {
    chart: {
      type: "line",
    },
    xaxis: {
      categories: transformedData?.categories,
      labels: {
        style: {
          colors: Array(transformedData?.categories?.length).fill("#fff"),
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: ["#fff"],
        },
      },
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      theme: "dark",
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  };

  return (
    <Chart
      options={options}
      series={transformedData.series}
      type="line"
      height={340}
    />
  );
};

export default AnalyticsChart;
