import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  Chip,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import {
  _mission_schadule_setting_list,
  mission_schedule_detail_api,
} from "src/DAL/MissionApi/MissionApiNew";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import { s3baseUrl } from "src/config/config";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateScaduleSettings() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state, title } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [missionJourneyOldImage, setmissionJourneyOldImage] = useState();
  const [missionJourneyImage, setmissionJourneyImage] = useState();
  const [scheduleDetail, setScheduleDetail] = useState(null);
  const [inputs, setInputs] = useState({
    unlock_button_text: "",
    unlock_cancel_button_text: "",
    unlock_detail_description: "",
    schedule_completed_description: "",
    congratulation_description: "",
    action_statement_heading: "",
    mission_journey_image: {},
    complete_schedule_button_text: "",
    allow_user_to_create_reward_feed: false,
    show_in_mission_journey: false,
    mission_journey_title: "",
    gratitude_action_title: "",
    dynamite_dairy_action_title: "",
    onscreen_question_title: "",
    general_note_title: "",
    is_show_general_note: false,
    mission_journey_description: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      inputs.show_in_mission_journey &&
      !missionJourneyOldImage &&
      !missionJourneyImage
    ) {
      setIsLoading(false);
      enqueueSnackbar("Mission Journey Image is required", {
        variant: "error",
      });
      return;
    }
    if (!inputs.unlock_detail_description) {
      setIsLoading(false);
      enqueueSnackbar("Unlock Detail Description is required", {
        variant: "error",
      });
      return;
    }
    let postData = {
      content_settings: inputs,
    };
    console.log(
      missionJourneyOldImage,
      missionJourneyImage,
      inputs.mission_journey_image
    );
    if (
      (missionJourneyOldImage || missionJourneyImage) &&
      inputs.mission_journey_image
    ) {
      postData.content_settings.mission_journey_image =
        inputs.mission_journey_image;
    }
    const result = await _mission_schadule_setting_list(postData, params.id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const agreementHeaderLogoHandler = async (e) => {
    setmissionJourneyImage(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "600");

    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        ["mission_journey_image"]: imageUpload.image_path,
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDetail = async () => {
    setIsLoading(true);
    let result = await mission_schedule_detail_api(params.id);
    if (result.code === 200) {
      if (result?.mission_schedule?.content_settings) {
        setScheduleDetail({
          mission_title: result?.mission_info?.title,
          title: result?.mission_schedule?.title,
        });
        setInputs(result.mission_schedule.content_settings);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleClickChip = (value) => {
    setInputs((values) => ({
      ...values,
      unlock_detail_description: values.unlock_detail_description + value,
    }));
  };

  let breadCrumbMenu = [
    {
      title: `Mission (${scheduleDetail?.mission_title})`,
      navigation: `/mission`,
      active: false,
    },
    {
      title: `${scheduleDetail?.title}`,
      navigation: `/mission/challenge/schedules/${params?.mission_id}`,
      active: false,
    },
    {
      title: "Mission Schedule Settings",
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    if (params && params.id) {
      if (
        state &&
        state.content_settings &&
        Object.keys(state.content_settings).length > 0
      ) {
        setScheduleDetail({
          mission_title: state?.mission_info?.title,
          title: state?.title,
        });
        setInputs(state.content_settings);
        setInputs((values) => ({
          ...values,
          ["allow_user_to_create_reward_feed"]:
            state.content_settings.allow_user_to_create_reward_feed ?? false,
        }));
        setmissionJourneyOldImage(
          state?.content_settings?.mission_journey_image
        );
        setIsLoading(false);
      } else {
        handleDetail();
      }
    }
  }, []);
  
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        {scheduleDetail ? (
          <div className="col-12 mb-4 ms-2">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
        ) : (
          <div className="col-12 d-flex">
            <span>
              <IconButton
                className="back-screen-button mb-4"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
            </span>
            <h2 className="ms-2">Mission Schedule Settings</h2>
          </div>
        )}
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row notification-statement">
          <>
            <div className="col-6 mt-3">
              <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
                <TextField
                  className="text-muted"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  id="outlined-basic"
                  label="Unlock Button Text"
                  variant="outlined"
                  fullWidth
                  name="unlock_button_text"
                  onChange={handleChange}
                  value={inputs.unlock_button_text}
                  required={true}
                />
              </div>
            </div>
            <div className="col-6 mt-3">
              <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
                <TextField
                  className="text-muted"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  id="outlined-basic"
                  label="Unlock Cancel Button Text"
                  variant="outlined"
                  fullWidth
                  name="unlock_cancel_button_text"
                  onChange={handleChange}
                  value={inputs.unlock_cancel_button_text}
                  required={true}
                />
              </div>
            </div>
            <div className="col-6 mt-3">
              <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
                <TextField
                  className="text-muted"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  id="outlined-basic"
                  label="Action Statement Heading"
                  variant="outlined"
                  fullWidth
                  name="action_statement_heading"
                  onChange={handleChange}
                  value={inputs.action_statement_heading}
                  required={true}
                />
              </div>
            </div>{" "}
            <div className="col-6 mt-3">
              <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
                <TextField
                  className="text-muted"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  id="outlined-basic"
                  label="Complete Schedule Button Text"
                  variant="outlined"
                  fullWidth
                  name="complete_schedule_button_text"
                  onChange={handleChange}
                  value={inputs.complete_schedule_button_text}
                  required={true}
                />
              </div>
            </div>
            <div className="col-6 mt-3">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Allow User to Create Reward Feed{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="allow_user_to_create_reward_feed"
                  value={inputs.allow_user_to_create_reward_feed}
                  label="Allow User to Create Reward Post"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
          </>

          <div className="col-6 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show Journal Note
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_show_general_note"
                value={inputs.is_show_general_note ?? false}
                label="Show Journal Note"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-6 mt-3">
            <TextField
              className="text-muted"
              InputLabelProps={{ style: { fontSize: 14 } }}
              id="outlined-basic"
              label="Journal Note Title"
              variant="outlined"
              fullWidth
              required={inputs?.is_show_general_note}
              name="general_note_title"
              onChange={handleChange}
              value={inputs.general_note_title}
            />
          </div>
          <div className="col-6 mt-3">
            <TextField
              className="text-muted"
              InputLabelProps={{ style: { fontSize: 14 } }}
              id="outlined-basic"
              label="Onscreen Question Title"
              variant="outlined"
              fullWidth
              name="onscreen_question_title"
              onChange={handleChange}
              value={inputs.onscreen_question_title}
            />
          </div>

          <div className="col-6 mt-3">
            <TextField
              className="text-muted"
              InputLabelProps={{ style: { fontSize: 14 } }}
              id="outlined-basic"
              label="Gratitude Action Title"
              variant="outlined"
              fullWidth
              name="gratitude_action_title"
              onChange={handleChange}
              value={inputs.gratitude_action_title}
            />
          </div>
          <div className="col-6 mt-3">
            <TextField
              className="text-muted"
              InputLabelProps={{ style: { fontSize: 14 } }}
              id="outlined-basic"
              label="Dynamite Dairy Action Title"
              variant="outlined"
              fullWidth
              name="dynamite_dairy_action_title"
              onChange={handleChange}
              value={inputs.dynamite_dairy_action_title}
            />
          </div>

          <div className="col-6 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show in Mission Journey
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="show_in_mission_journey"
                value={inputs.show_in_mission_journey ?? false}
                label="Show in Mission Journey"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.show_in_mission_journey && (
            <>
              <div className="col-6 mt-3">
                <TextField
                  className="text-muted"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  id="outlined-basic"
                  label="Mission Journey Title"
                  variant="outlined"
                  fullWidth
                  required={true}
                  name="mission_journey_title"
                  onChange={handleChange}
                  value={inputs.mission_journey_title}
                />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div className="row w-100 div-style ms-0 pt-0">
                  <div className="col-5">
                    <p>Mission Journey Image *</p>
                    <FormHelperText className="pt-0">
                      (Recommended Size 500 X 500) (jpg, jpeg, png, gif)
                    </FormHelperText>
                  </div>
                  <div className="col-2">
                    {missionJourneyImage ? (
                      <img src={missionJourneyImage} height="50" />
                    ) : (
                      missionJourneyOldImage && (
                        <img
                          src={s3baseUrl + missionJourneyOldImage}
                          height="50"
                        />
                      )
                    )}
                  </div>
                  <div className="col-5 text-end pt-2">
                    <label htmlFor="mission_journey_image">
                      <Input
                        accept="image/*"
                        id="mission_journey_image"
                        multiple
                        type="file"
                        name="mission_journey_image"
                        onChange={agreementHeaderLogoHandler}
                      />

                      <Button
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
                {inputs?.mission_journey_image?.name == "" ? (
                  ""
                ) : (
                  <p className="text-secondary">
                    {inputs?.mission_journey_image?.name}
                  </p>
                )}
              </div>
              <div className="col-12 mt-3">
                <TextField
                  className="text-muted"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  id="outlined-basic"
                  label="Mission Journey Description"
                  variant="outlined"
                  fullWidth
                  name="mission_journey_description"
                  onChange={handleChange}
                  value={inputs.mission_journey_description}
                  multiline
                  rows={4}
                />
              </div>
            </>
          )}
          <div className="col-12 ">
            <p className="tracking_code_heading text-white">
              Unlock Detail Description *
            </p>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="unlock_detail_description"
              editorHeight={310}
            />
          </div>
          <div className="col-12 mt-3">
            <Chip
              className="me-2 editor_chip"
              label="Community Level"
              variant="outlined"
              onClick={() => {
                handleClickChip("{community_level}");
              }}
            />
            <Chip
              className="me-2 editor_chip"
              label="Coins Count"
              variant="outlined"
              onClick={() => {
                handleClickChip("{coins_count}");
              }}
            />
            <Tooltip title="These are two shortcodes. The first shortcode represents the members' community level. If you add this to the description, it will display the member's current level. The second short code, coins_count, represents the total count of the member’s coins.">
              <InfoIcon />
            </Tooltip>
          </div>
          <div className="col-12 ">
            <p className="tracking_code_heading text-white">
              Schedule Completed Description
            </p>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="schedule_completed_description"
              editorHeight={310}
            />
          </div>
          <div className="col-12 ">
            <p className="tracking_code_heading text-white">
              Congratulation Description on completing Schedule
            </p>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="congratulation_description"
              editorHeight={310}
            />
          </div>

          <div className="text-end mt-4">
            <button className="small-contained-button">Update</button>
          </div>
        </div>
      </form>
    </div>
  );
}
