import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import {
  _get_create_mission_branch_code,
  delete_mission_list,
  get_mission_list,
} from "src/DAL/MissionApi/MissionApiNew";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import Levels from "./Levels";
import MissionRoadMapLevels from "./MissionRoadMapLevels";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import MissionFilter from "./MissionFilter";
import FilteredChip from "src/components/FilteredChip";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const EMPTY_FILTER = {
  badge_levels: [],
  search_text: "",
};

export default function ChallengesList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [previousInfo, setPreviousInfo] = useState(null);
  const [referredpop, setReferredpop] = useState(false);
  const [levelsArray, setLevelsArray] = useState(false);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleChangeOthers = (event, name) => {
    setFilterData((values) => ({ ...values, [name]: event }));
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    data.search_text = searchText;

    getSaleLeadList(data);
    localStorage.setItem(
      "mission_challenge_filter_data",
      JSON.stringify(filterData)
    );
    setFilterData(data);
    handleCloseFilterDrawer();
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    let data = filterData;
    data.search_text = searchText;
    getSaleLeadList(data);
    localStorage.setItem(
      "mission_challenge_filter_data",
      JSON.stringify(filterData)
    );

    handleCloseFilterDrawer();
  };

  const handleClearFilter = () => {
    setFilterData(EMPTY_FILTER);
    localStorage.setItem(
      "mission_challenge_filter_data",
      JSON.stringify(EMPTY_FILTER)
    );
    getSaleLeadList(EMPTY_FILTER);
    setSearchText("");
    setFilterDrawerState(false);
  };

  const getSaleLeadList = async (filterData) => {
    setIsLoading(true);
    let postData = { ...filterData };

    const result = await get_mission_list(
      postData,
      // params.id,
      page,
      rowsPerPage
    );
    if (result.code === 200) {
      let chipData = { ...filterData };
      setFilterData({ ...chipData });
      if (chipData.search_text) {
        delete chipData.search_text;
      }
      setFilterStateUpdated(chipData);
      setPreviousInfo(result?.mission_lavel_info);
      const lead_status = result.mission.map((status) => {
        return {
          ...status,
          title: status.title,
          MENU_OPTIONS: handleMenu(status),
          table_avatar: {
            src: s3baseUrl + status.image?.thumbnail_1,
            alt: status.title,
          },
        };
      });
      setUserList(lead_status);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_number_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/mission/addOrUpdate/${value._id}`, {
      state: value,
    });
  };
  const handleSettings = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/mission/settings/${value._id}`, {
      state: value,
    });
  };

  const handleNotificationsSettings = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/mission/notifications/${value._id}`, {
      state: value,
    });
  };
  const handleManageMembershipLevels = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/mission/challenges/manage-membership-levels/${value._id}`, {
      state: value,
    });
  };
  const handleBadges = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/mission/challenges/badge-configuration/${value._id}`, {
      state: value,
    });
  };

  const handleBadgesToUnlock = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/mission/challenges/badged-to-unlock/${value._id}`, {
      state: value,
    });
  };

  const handleMissionMembers = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/mission/missions-members/${value._id}`, {
      state: value,
    });
  };
  const handleschedules = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/mission/challenge/schedules/${value._id}`, {
      state: value,
    });
  };

  const updateListObject = (value, app_branch_url) => {
    const newList = userList.map((item) => {
      if (item._id === value?._id) {
        return {
          ...item,
          app_branch_url: app_branch_url,
        };
      }
      return item;
    });
    setUserList(newList);
  };

  const CopyToClipboard = async (data) => {
    try {
      await navigator.clipboard.writeText(data);
      enqueueSnackbar("URL copied to clipboard!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to copy URL to clipboard.", { variant: "error" });
    }
  };

  const handleGenerateCopyURL = async (value) => {
    if (value?.app_branch_url) {
      CopyToClipboard(value?.app_branch_url);
    } else {
      const result = await _get_create_mission_branch_code(value._id);
      if (result.code === 200) {
        updateListObject(value, result?.mission_branch_url?.app_branch_url);
        CopyToClipboard(result?.mission_branch_url?.app_branch_url);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await delete_mission_list(deleteDoc._id);
    if (result.code === 200) {
      getSaleLeadList(EMPTY_FILTER);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    // navigate(`/mission/Challenges/addOrUpdate/${params.id}`);
    navigate(`/mission/addOrUpdate`);
  };

  const handleLessonsQuestions = (value) => {
    delete value.MENU_OPTIONS;
    let newValue = {
      ...previousInfo,
      ...value,
    };
    navigate(`/mission/challenge?created_for_id=${value._id}`, {
      state: newValue,
    });
  };

  const handleQuestionsConfiguration = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/mission/challenges/manage-question-settings/${value._id}`, {
      state: value,
    });
  };
  const handleNavigateQuestions = (value) => {
    delete value.MENU_OPTIONS;
    let newValue = {
      ...previousInfo,
      ...value,
    };
    navigate(
      `/mission/challenge/on-board-questions?created_for_id=${value._id}`,
      {
        state: newValue,
      }
    );
  };

  const handleNavigateAnswers = (value) => {
    delete value.MENU_OPTIONS;
    let newValue = {
      ...previousInfo,
      ...value,
    };
    navigate(
      `/mission/challenge/on-board-questions/answers?created_for_id=${value._id}`,
      {
        state: newValue,
      }
    );
  };

  let child_menu_options = [
    {
      label: "Questions Setting",
      icon: "akar-icons:edit",
      handleClick: handleQuestionsConfiguration,
    },
    {
      label: "Manage Questions",
      icon: "akar-icons:edit",
      handleClick: handleLessonsQuestions,
    },
  ];
  let on_board_child_menu_options = [
    {
      label: "On Board Questions List",
      icon: "akar-icons:edit",
      handleClick: handleNavigateQuestions,
    },
    {
      label: "Question Answers",
      icon: "akar-icons:edit",
      handleClick: handleNavigateAnswers,
    },
  ];

  const handleMenu = (row) => {
    const MENU_OPTIONS = [
      {
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleEdit,
      },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
        color: "#da3030",
      },
      {
        label: "Missions Settings",
        icon: "akar-icons:edit",
        handleClick: handleSettings,
      },
      {
        label: "Missions Members",
        icon: "akar-icons:edit",
        handleClick: handleMissionMembers,
      },
      {
        label: "Mission Schedule",
        icon: "akar-icons:edit",
        handleClick: handleschedules,
      },
      {
        label: "Manage Rewards on Completion",
        icon: "akar-icons:edit",
        handleClick: handleBadges,
      },
      {
        label: "Questions Configuration",
        icon: "akar-icons:edit",
        child_options: child_menu_options,
      },
      {
        label: "Mission Onboard Questions",
        icon: "akar-icons:edit",
        handleClick: handleNavigateQuestions,
      },
      {
        label: "Copy Link For App",
        icon: "akar-icons:edit",
        handleClick: handleGenerateCopyURL,
      },
    ];

    MENU_OPTIONS.splice(3, 0, {
      label: "Manage Notification Sequence ",
      icon: "akar-icons:edit",
      handleClick: handleNotificationsSettings,
    });

    return MENU_OPTIONS;
  };

  const handleOPen = (value) => {
    setReferredpop(true);
    setLevelsArray(value);
  };
  const handleCopiedUrl = () => {
    enqueueSnackbar("App Url copied to clipboard", { variant: "success" });
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: " Title",
    },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
    },
    {
      id: "mission_duration",
      label: "Mission Duration (Days)",
    },
    {
      id: "badge_level",
      label: " Badge Level ",
      renderData: (row) => {
        return (
          <span>
            {row?.membership_level_info?.badge_level_info?.title
              ? row?.membership_level_info?.badge_level_info?.title
              : "N/A"}
          </span>
        );
      },
    },
    {
      id: "order",
      label: "Order",
    },

    { id: "status", label: "Status", type: "row_status" },
  ];

  let breadCrumbMenu = [
    {
      title: `Mission Level (${previousInfo?.title})`,
      navigation: `/mission`,
      active: false,
    },
    {
      title: "Missions",
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem(`mission_challenge_filter_data`);

    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterData(filter_data);
    setFilterStateUpdated(filter_data);
    getSaleLeadList(filter_data);

    // let search_text = localStorage.getItem("mission_challenge");
    // if (search_text) {
    //   setSearchText(search_text);
    //   // getSaleLeadList(search_text);
    //   getSaleLeadList(filterData);
    // } else {
    //   setSearchText("");
    //   getSaleLeadList(EMPTY_FILTER);
    // }
  }, [page, rowsPerPage]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container">
        <div className="row mb-2">
          <div className="col-12 mb-4">
            {previousInfo && (
              <span>
                <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
              </span>
            )}
          </div>

          <div className="col-lg-4 col-sm-12">
            <h2>Missions</h2>
          </div>

          <div className="col-lg-8 col-sm-12 text-end d-flex justify-content-end">
            <div>
              <button
                className="small-contained-button me-2 mt-1 mb-2"
                onClick={handleOpenFilterDrawer}
              >
                Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
              </button>
              <button
                onClick={handleNavigate}
                className="small-contained-button"
              >
                Add Missions
              </button>
            </div>
          </div>

          <FilteredChip
            data={filterStateUpdated}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          className="card-with-background"
          pagePagination={true}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
        <WhatsappPopUpModel
          open={referredpop}
          setOpen={setReferredpop}
          title={"Road Map Levels  "}
          show_date_and_income={true}
          componentToPassDown={
            <MissionRoadMapLevels levelsArray={levelsArray} />
          }
        />

        <CustomDrawer
          isOpenDrawer={filterDrawerState}
          onOpenDrawer={handleOpenFilterDrawer}
          onCloseDrawer={handleCloseFilterDrawer}
          pageTitle="Filters"
          componentToPassDown={
            <>
              <MissionFilter
                filterData={filterData}
                handleChangeOthers={handleChangeOthers}
                searchSubmitFilter={searchFunction}
                handleClearFilter={handleClearFilter}
              />
            </>
          }
        />
      </div>
    </>
  );
}
