import { invokeApi } from "../../bl_libs/invokeApi";
export const pagesListingApi = async (type) => {
  const requestObj = {
    path: `/api/sale_page?type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_create_sale_page_branch_code = async (id) => {
  const requestObj = {
    path: `/api/sale_page/create_sale_page_branch_code/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const pages_isting_with_pagination_api = async (data, page, limit) => {
  const requestObj = {
    path: `/api/sale_page/get_sale_page_with_filter?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const pagesPlanListingApi = async (id) => {
  const requestObj = {
    path: `/api/sale_page/get_plans_by_sale_page/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const pageSubscriberListingApi = async (id, page, limit) => {
  const requestObj = {
    path: `/api/event_subscriber/subscriber_list_with_page_id/${id}?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const pageSubscriberListingApiNew = async (
  id,
  page,
  limit,
  search,
  postData
) => {
  const requestObj = {
    path: `/api/event_subscriber/subscriber_list_for_member/v2/${id}?page=${page}&limit=${limit}&search_text=${search}&plan_id=${
      postData.plan ? postData.plan : ""
    }&start_date=${postData.start_date ? postData.start_date : ""}&end_date=${
      postData.end_date ? postData.end_date : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const pageSubscriberListingForPlan = async (
  page,
  limit,
  search,
  postData
) => {
  console.log(postData, "postData");
  const requestObj = {
    path: `/api/event_subscriber/subscriber_list_for_member/v2/${
      postData.page_id
    }?page=${page}&limit=${limit}&search_text=${search}&plan_id=${
      postData.plan_id ? postData.plan_id : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _import_subscriber_from_csv = async (data) => {
  const requestObj = {
    path: `/admin_users/import/subscriber_from_csv`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const socailSharingUpdateApi = async (data, id) => {
  const requestObj = {
    path: `/api/sale_page/update_page/social_sharing/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_mc_page_settings_api = async (data, page_slug) => {
  const requestObj = {
    path: `/api/sale_page/update_page_mission_control_settings/${page_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeletePageSubscriberMemberApi = async (id) => {
  const requestObj = {
    path: `/api/event_subscriber/delete_event_subscriber/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const templateListingApi = async (data) => {
  const requestObj = {
    path: `/api/template_configuration/active_template_configuration`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_data_for_sale_page_api = async () => {
  const requestObj = {
    path: `/api/sale_page/get_data_for_sale_page/add`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_page_list_for_consultant_api = async (id) => {
  const requestObj = {
    path: `/api/sale_page/get_page_list_for_consultant/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const list_thanks_and_payement_page_for_selection_api = async () => {
  const requestObj = {
    path: `/api/sale_page/list_thanks_and_payement_page/selection`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const manage_template_access_for_consultant_api = async (id, data) => {
  const requestObj = {
    path: `/api/consultant/manage_template_access_for_consultant/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_agreeement_configuration = async (id, data) => {
  const requestObj = {
    path: `/api/sale_page/update/agreement/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_Commission_configuration = async (id, data) => {
  const requestObj = {
    path: `/api/payment_plan/update_commission_info/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_reminder_configuration = async (data) => {
  const requestObj = {
    path: `/api/content_setting/payment_notify_schedule_update`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const call_reminder_setting = async (data) => {
  const requestObj = {
    path: `/api/content_setting/call_reminder_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_welcome_reminder_setting = async (data) => {
  const requestObj = {
    path: `/api/content_setting/update_welcome_reminder_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const manage_commission_access_for_consultant_api = async (data) => {
  const requestObj = {
    path: `/api/consultant/access_sales_commission`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const import_template_data_api = async (id) => {
  const requestObj = {
    path: `/app/import_template_data`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: id,
  };
  return invokeApi(requestObj);
};

export const addWebsiteEvent = async (data) => {
  const requestObj = {
    path: `/api/home_event/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editWebsiteEvent = async (data, id) => {
  const requestObj = {
    path: `/api/home_event/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteWebsiteEvent = async (id) => {
  const requestObj = {
    path: `/api/home_event/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const deleteSalePageApi = async (slug) => {
  const requestObj = {
    path: `/api/sale_page/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const updateTemplatePagePlanChart = async (data, id) => {
  const requestObj = {
    path: `/api/sale_page/update_comparison_chart_info/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_comparison_chart_info = async (id) => {
  const requestObj = {
    path: `/api/sale_page/detail_comparison_chart_info/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _dublicate_sale_page_api = async (slug) => {
  const requestObj = {
    path: `/api/sale_page/dublicate_sale_page/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const WebsiteEventDetail = async (id) => {
  const requestObj = {
    path: `/api/home_event/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addPageApi = async (data) => {
  const requestObj = {
    path: `/api/sale_page`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editPageApi = async (data, id) => {
  const requestObj = {
    path: `/api/sale_page/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailPageApi = async (id) => {
  const requestObj = {
    path: `/api/sale_page/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const page_detail_by_id = async (id) => {
  const requestObj = {
    path: `/api/sale_page/sale_page_detail_by_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const page_detail_forPackage = async (id) => {
  const requestObj = {
    path: `/plan_packages/list_plans_for_package/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const detailContentPageApi = async (id) => {
  const requestObj = {
    path: `/api/sale_page/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const updatePageContentSettingApi = async (data, id) => {
  const requestObj = {
    path: `/api/sale_page/update_sale_page_detail/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const payment_update_theme_setting_api = async (data, slug) => {
  const requestObj = {
    path: `/api/sale_page/update_theme_setting/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_theme_setting_list_api = async (slug) => {
  const requestObj = {
    path: `/api/sale_page/get_theme/setting/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const websiteEventListApi = async (api_path) => {
  // let api_path = id;
  // if (id) {
  //   api_path = `/api/home_event/home_event_by_sale_page/${id}`;
  // }
  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const addPageEventApi = async (data) => {
  const requestObj = {
    path: `/api/home_event/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editPageEventApi = async (data, id) => {
  const requestObj = {
    path: `/api/home_event/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const changePageAccessApi = async (data) => {
  const requestObj = {
    path: `/api/sale_page/control_page_access`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const changeDelegateCommissionApi = async (data) => {
  const requestObj = {
    path: `/api/consultant/access_sales_commission_by_page_id`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const sale_page_list_with_plans_api = async () => {
  const requestObj = {
    path: `/api/sale_page/list/with_plans`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const sale_page_list_with_plans_and_payment_request_api = async (
  type
) => {
  const requestObj = {
    path: `/admin_users/payment_request_and_pages_list/filter?type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const list_pages_for_billing_tickets_api = async () => {
  const requestObj = {
    path: `/app/list_pages_for_billing_tickets`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const list_pages_for_mentorship = async () => {
  const requestObj = {
    path: `/api/sale_page/with_plans/list/for_mentorship`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const sale_page_list_with_all_plans_api = async () => {
  const requestObj = {
    path: `/api/sale_page/list/with_plans_listing`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const sale_page_list_with_all_plans_api_for_check_in = async () => {
  const requestObj = {
    path: `/app/list_pages_for_billing_tickets`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const subscription_list_with_page_and_plan = async (
  page,
  limit,
  data
) => {
  const requestObj = {
    path: `/admin_users/subscription/list?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const subscription_stripe_list = async (
  page,
  limit,
  searchText,
  data,
  getrecord
) => {
  const requestObj = {
    path: `/admin_users/get_stripe_information_for_admin?page=${
      page && page
    }&limit=${limit}&search_text=${searchText && searchText}&record_type=${
      getrecord && getrecord
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_multiple_subscribers = async (data) => {
  const requestObj = {
    path: `/api/event_subscriber/delete/multiple_subscribers`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const template_configuration_api = async (data) => {
  const requestObj = {
    path: `/api/template_configuration`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_template_configuration_api = async (data, slug) => {
  const requestObj = {
    path: `/api/template_configuration/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_template_configuration_api = async (page, limit, search) => {
  const requestObj = {
    path: `/api/template_configuration?page=${page}&limit=${limit}&search_text=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_detail_template_configuration_api = async (slug) => {
  const requestObj = {
    path: `/api/template_configuration/get_template/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const del_template_configuration_api = async (slug) => {
  const requestObj = {
    path: `/api/template_configuration/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

//
export const _get_membership_levels_web_pages_detail = async (slug) => {
  const requestObj = {
    path: `/api/program/membership_levels/get/${slug}?type=plan`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_membership_levels_for_web_pages = async (data, slug) => {
  const requestObj = {
    path: `/api/program/membership_levels/update/${slug}?type=plan`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _Get_Mission_Control_Settings = async (slug) => {
  const requestObj = {
    path: `/api/sale_page/detail/mission_control_settings/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: {},
  };
  return invokeApi(requestObj);
};

export const _update_mission_control_settings = async (data, slug) => {
  const requestObj = {
    path: `/api/sale_page/update/mission_control_settings/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data || {},
  };
  return invokeApi(requestObj);
};
