export const set_to_localStorage = (key, data) => {
  if (typeof data === "object") {
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    localStorage.setItem(key, data);
  }
};

export const get_from_localStorage = (key) => {
  const item = localStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  } else {
    return item;
  }
};

export const del_from_local_storage = (key) => {
  let item = localStorage.getItem(key);
  if (item) {
    localStorage.removeItem(key);
  }
};
